import {Component} from 'react';

import AlertManager from '../../../managers/alert/alert-manager';
import AlertTypes from '../../../managers/alert/alert-types';

import Input from '../../../components/input-with-label';

import Popup from '../../../components/popup';

import {CreateButton, CancelButton} from '../../../components/buttons';

import GrantsApi from '../../../lib/grants/grants-api';

export default class GrantCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                name: '',
            }
        }
    }

    onChange = (e) => {
        this.setState({
            values: Object.assign({}, this.state.values, {

                [e.target.name]: e.target.value

            })
        })
    }

    create() {
        if (this.state.values.name.length == 0) return;

        GrantsApi.create(this.state.values).then(data => {
            window.location.href = `/grants/view/${data.id}`;
        }).catch(() => {

        });
    }

    render() {
        return (
            <Popup onClose={this.props.setPopup} type="grant-create">
                <div class="popup__inner">
                    <h1>Create a new grant</h1>

                    <Input label={'Name'} name="name" value={this.state.values.name} onChange={this.onChange.bind(this)}
                           placeholder="Please name grant"/>
                    {/* <Input label={'Prefix'} name="prefix" value={this.state.values.prefix?  this.state.values.prefix.toUpperCase() : ''}
                           onChange={e => {
                               this.onChange({target: {name: e.target.name, value: e.target.value.toLowerCase()}});
                           }} placeholder="e.g. DIG"/> */}

                    <div class="popup__buttons">
                        <CreateButton responsive={false} onClick={this.create.bind(this)}/>
                        <CancelButton responsive={false} onClick={this.props.setPopup}/>
                    </div>
                </div>
            </Popup>
        )
    }
}
