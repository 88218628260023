import React, {Component, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../components/breadcrumb";
import PageLayout from "../../../containers/layout/page-layout";
import Sponsors from '../../../components/sponsors';

import Editor from './editor';

import {MinMaxLayout} from "../../../containers/layout";
import {
    CreateButton,
    SaveButton,
    DeleteButton,
    ProceedButton,
    AddButton,
    ViewButton
} from "../../../components/buttons";

import GrantDeletePopup from '../components/grant-delete-popup';
import ModifyGrant from '../components/grant-modify';

import AlertManager from '../../../managers/alert/alert-manager';

import GrantsApi from '../../../lib/grants/grants-api';

import ResultsError from '../../../components/content-search/search-results-error';
import {stringToFriendlyDatetime} from "../../../lib/helpers/datetime-helpers";

const crumbs = [{
    name: 'Grants',
    link: '/grants'
},
    {
        name: 'Grant Editor',
        link: '#'
    }
];

class GrantEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasGrantPreview: false,
            grant: {},
            grantVersion: {},
            deletePopup: false,
            modifyGrant: false

        };
        this.grantStateHandler = null;

        this.grantPreviewChangeTimeOut = null;

    }

    generatePreview(openWindow = true) {


        GrantsApi.generatePreview(this.state.grant.id, this.state.grantVersion).then(data => {
            if(openWindow) {
                window.open(`/grants/view/${this.state.grant.id}/preview`, '_blank', 'location=yes,height=900,width=1200,scrollbars=yes,status=yes');

            }

        }).catch(({response}) => {

            if (response.status === 422) {
                Bugsnag.notify(response.data);
                AlertManager.bigError('There has been a unexpected error with your input. Please Provide the following output to the dev team. ', JSON.stringify(response.data.errors));
                return;
            }
            AlertManager.error('Sorry we could not save this version');
        });
    }

    updatePreviewIfRequired() {


        clearTimeout(this.grantPreviewChangeTimeOut);
        this.grantPreviewChangeTimeOut = setTimeout(() => {
            this.generatePreview(false);
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.state.hasGrantPreview === true) {
            if((this.state.grantVersion !== prevState.grantVersion) || (this.state.grant !== prevState.grant)) {

                this.updatePreviewIfRequired();
            }


        }

    }

    save() {
        GrantsApi.createVersion(this.state.grant.id, this.state.grantVersion).then(data => {
            this.setState({grantVersion: data});
            AlertManager.success('Successfully saved your current version');
        }).catch(({response}) => {

            if (response.status === 422) {
                Bugsnag.notify(response.data);
                AlertManager.bigError('There has been a unexpected error with your input. Please Provide the following output to the dev team. ', JSON.stringify(response.data.errors));
                return;
            }
            AlertManager.error('Sorry we could not save this version');
        });
    }

    addSection() {
        this.grantStateHandler.addSection(this.state.grantVersion);
    }

    handlePublish() {
        GrantsApi.createVersion(this.state.grant.id, this.state.grantVersion).then(v => {
            GrantsApi.publish(this.state.grant.id, v.id).then(g => {
                this.setState({grantVersion: v, grant: g});
                AlertManager.success('Grant Published');
            });
        });
    }

    refreshGrant() {
        GrantsApi.get(this.props.grantId).then(data => {
            const latest = data.latest_grant_versions[0];

            this.setState({grant: data, grantVersion: latest});
        });
    }

    sections() {
        return this.state?.grantVersion?.config?.sections ? this.state.grantVersion.config.sections : [];
    }


    render() {
        const {deletePopup, grant, modifyGrant, grantVersion} = this.state;

        return (
            <>
                {deletePopup && <GrantDeletePopup grant={grant} setPopup={s => this.setState({deletePopup: s})}/>}
                {modifyGrant && <ModifyGrant onClose={() => this.setState({modifyGrant: null})} grant={grant}
                                             refreshGrant={this.refreshGrant.bind(this)}/>}

                <PageLayout
                    topLeft={<Breadcrumb crumbs={crumbs}/>}
                    topRight={<Sponsors/>}
                    middle={<div className='grant-editor__middle utils__gap--20'>
                        <div className="utils__rowSpaceBetween utils__gap--20">
                            <div className="utils__row utils__gap--20">
                                <CreateButton onClick={() => this.setState({modifyGrant: true})}>Grant
                                    Details</CreateButton>
                                <AddButton onClick={this.addSection.bind(this)}>Add Section</AddButton>
                            </div>
                            <div className='utils__row utils__gap--20'>
                                <div className="utils__row utils__gap--20">

                                    <ProceedButton onClick={this.handlePublish.bind(this)}>Publish</ProceedButton>
                                    <SaveButton onClick={this.save.bind(this)} mobile={true}/>
                                    <DeleteButton onClick={() => this.setState({deletePopup: true})}/>
                                </div>
                            </div>

                        </div>
                        <div className={'grant-editor__info-bar'}>
                            <div className="grant-editor__info-bar-details">
                                <div className='grant-editor__published'>
                                    {
                                        grant.updated_at &&
                                        <>
                                            <strong>Last
                                                updated:</strong> {stringToFriendlyDatetime(grant.updated_at)} | &nbsp;
                                        </>
                                    }
                                    {
                                        (grant?.published_grants_version_id !== null && grantVersion.id) &&
                                        <>
                                            <strong>Latest
                                                Version:</strong> ...{grantVersion.id.substr(grantVersion.id.length - 13)} | <strong>Published
                                            Version:</strong> ...{grant.published_grants_version_id.substr(grant.published_grants_version_id.length - 12)} | <strong>Published:</strong> {stringToFriendlyDatetime(grant.published)}<br/>
                                        </>

                                    }

                                </div>
                            </div>
                            <div className="grant-editor__info-bar-actions">
                                <ViewButton onClick={() => {
                                    this.setState({hasGrantPreview : true}, () => {
                                        this.generatePreview()

                                    })
                                }}>Generate Preview</ViewButton>
                            </div>
                        </div>
                    </div>
                    }>


                    <div className={'grant-editor'}>
                        <MinMaxLayout
                            main={<Editor
                                setCurrentGrant={({grant, grantVersion}) => this.setState({
                                    grant: grant,
                                    grantVersion: grantVersion
                                })}
                                setStateHandler={handler => {
                                    this.grantStateHandler = handler;
                                }}
                                {...this.props}
                            />}
                        />
                        {this.sections().length === 0 &&
                            <ResultsError
                                message={'Please add a section'}
                                body={<>
                                    <b>You current have no sections for your grant. Please add a section, blocks and
                                        questions</b>
                                    <p>Need help? Contact Us</p>
                                </>}
                            />
                        }
                    </div>

                </PageLayout>
            </>
        );
    }

}

export default GrantEditor;


const el = document.getElementById('grant-editor');

if (el) {
    ReactDOM.render(<GrantEditor grantId={el.getAttribute('data-grant-id')}/>, el);
}
