export default {
    1: [
        {
            field : 'logo',
            type : 'image'
        },
        {
            field : 'website'
        },
        {
            field : 'biography',
            type : 'textarea'
        }
    ]
}
