
export default function TextArea({ label = '', onChange, name, value, placeholder, error, right }) {
    return (
        <div className="text-area">
            { (label || right) && <div className="text-area__row">
                <p>{ label }</p>
                <p>{ right }</p>
            </div> }
            <textarea placeholder={placeholder} onChange={onChange} name={name} value={value}/>
            { error && 
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    { error.map((err, index) => {
                        return <p key={index} className="text-area__error">{ err }</p>
                    })}
                </div>
            }
        </div>
    )
}
