import {Component} from 'react';

import CodeMirror from '@uiw/react-codemirror';
import {javascript} from '@codemirror/lang-javascript';

export default function DistributionConfig({value, onChange, errors}) {


    const handleChange = (value) => {

        onChange( value);
    }



    return (
        <div className="distributions-settings">
            <div className="distributions-settings__section">
                <div className="distribution-settings__row">
                    <p>This code populates settings with additional fields required for this distribution</p>
                </div>
            </div>
            <div className="distributions-settings__section">
                <div className="distribution-settings__row">
                    <div className="input-outer">
                        <CodeMirror
                            value={value}
                            height="500px"
                            width={'100%'}
                            extensions={[javascript()]}
                            onChange={handleChange}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}
