import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import ResultItem from '../components/search-results/result-item';
import Breadcrumb from '../../js/components/breadcrumb';
import Dropdown from 'react-dropdown';

const SearchResults = () => {
    const [ crumbs, setCrumbs ] = useState([{
        name: 'Search Results',
        link: '#'
    }]);

    const [ param, setParam ] = useState('');
    const [ results, setResults ] = useState([]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const param = urlParams.get('term');

        setParam(param);

        axios.post('/api/admin/search', { term: param }).then(({ data: { data } }) => {
            setResults(data);

        })
    }, [])

    return (
        <div className="search-result__outer">
            <div class="website__topbar">
                <Breadcrumb crumbs={crumbs} />
                {/* <Dropdown className="input input--idle" options={[]} onChange={() => {}} value={""} placeholder="Sort results by"/> */}
            </div>
            <div class="website__content">
                <div class="search-results">
                    <p class="search-results__results-text">{ results.length } results for { param }</p>
                    <div class="search-results__outer">
                        <div class="search-results__preview">
                            <div class="search-results">
                                { results.length > 0 && results.map((result, index) => {
                                    return <ResultItem key={index} data={result} />
                                })}
                            </div>
                            {/* <div class="search-results__bottom">
                                <button class="button button__fit button--alternate">Previous Page</button>
                                <div class="search-results__pagination">

                                </div>
                                <button class="button button__fit button--alternate">Next Page</button>
                            </div> */}
                        </div>
                        {/* <div class="search-results__filter">
                            <p class="search-filter__title">Filter</p>
                            <p class="search-filter__subtitle">Choose Content Type</p>
                            <div class="search-filter__filters">
                                <div class="search-filter">Example Content Type <span>X</span></div>
                                <div class="search-filter">Example Content Type <span>X</span></div>
                                <div class="search-filter">Example Content Type <span>X</span></div>
                                <div class="search-filter">Example Content Type <span>X</span></div>
                                <div class="search-filter">Example Content Type <span>X</span></div>
                                <div class="search-filter">Example Content Type <span>X</span></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchResults;

let el = document.getElementById('search-results');

if (el) {
    ReactDOM.render(<SearchResults />, el);
}
