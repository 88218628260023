export default function ResultsList({titleOne = null, titleTwo = null, titleThree = null, children}) {

    return (
        <div className={'results-list'}>
            {
                (titleOne || titleTwo || titleThree) &&
                <div className={'results-list__head'}>
                    <div className={'results-list__title'}>{titleOne ?? ''}</div>
                    <div className="results-list__header-secondary">
                        <div className={'results-list__title'}>{titleTwo ?? ''}</div>
                        <div className={'results-list__title'}>{titleThree ?? ''}</div>
                    </div>

                </div>
            }
            <div className="results-list__items">
                {children}
            </div>
        </div>
    );

}
