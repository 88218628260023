import CreatableSelect from 'react-select/async-creatable';

import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

export default function(props) {
    const { label, placeholder, fill } = props;

    return (
        <LabelContainer label={label} placeholder={placeholder} fill={fill}>
            <ErrorContainer errors={props?.errors}>
                <CreatableSelect cacheOptions defaultOptions { ...props }/>
            </ErrorContainer>
        </LabelContainer>
    )
}