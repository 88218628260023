import React, {Component} from 'react';

class Textarea extends Component {

    render() {
        const { name, errors = [], value = '', onChange, placeholder } = this.props;
        return (
            <div id={name} className="input-outer">
                <label htmlFor={name}>{placeholder}</label>


                    <textarea className={`input ${errors.length > 0 ? "input-outer__error" : ""}`}
                           name={name} value={value} onChange={onChange}/>

                {errors.length > 0 && <div className="input-outer__errors">{errors.map((err, index) => <p key={index}>{err}</p>)}</div>}

            </div>
        );
    }
}

export default Textarea;
