import React, { Component } from 'react';
import Textarea from '../../../components/inputs/textarea';

import SidePopup from '../../../components/side-popup';
import SidePopupSection from '../../../components/side-popup-section';

import PopupContext from '../../../context/context-popup';

import { SendButton } from '../../../components/buttons';

import TasksApi from '../../../lib/tasks';
import Blurred from '../../../components/blurred';

import Container from '../../../containers/container';
import Row from '../../../utils/row';
import List from '../../../components/list/list';

import { stringToFriendlyDatetime } from '../../../lib/helpers/datetime-helpers';
import InputsSwitch from '../../../components/inputs/inputs-switch';

import Button from '../../../components/buttons/button';

export default class TasksView extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            note: null,
            task: null,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        const { task } = this.props;
        const prevTask = prevProps.task;

        if (prevTask.completed_at !== task.completed_at) {
          this.fetch();
        }
      }

    fetch() {
        TasksApi.getTask(this.props.task.id).then(({ data }) => {
            this.setState({ task: data })
        });
    }

    createNote() {
        TasksApi.createNote(this.state.task.id, this.state.note, () => {

        }).then(() => {
            this.setState({ note: null }, () => {
                this.fetch();
            });
        })
    }

    messageEnterSubmit(e) {
        if (e.key === "Enter" && e.shiftKey == false) {
            e.preventDefault();
            this.createNote();
        }
    }

    onChange(e) {
        this.setState({ note: e.target.value });
    }

    getRelationUrl(task) {
        const split = task.related_to?.split(`\\`);
        const relation = split !== undefined ? split[split?.length - 1] : '';

        if (task.related === null) return null;

        switch(relation) {
            case 'Conversation':
                return '/contacts/messages?id=' + task.related.id;
        }

        return null;
    }

    render() {
        const { setPopup } = this.context;

        if (this.state.task === null) return null;

        const { name, assigned_to, completed_at, completed_by, created_at, updated_at, description } = this.state.task;

        const notes = this.state.task?.admin_task_notes;

        const Wrapper = this.state.task?.completed_at !== null ? Blurred : React.Fragment;

        const relation = this.getRelationUrl(this.state.task);

        return (
            <SidePopup type='tasks' replaceContent={
                <>
                    <div className="chatbox__head">
                        <div className="chatbox-head__text">
                            <p className="chatbox-head-text__title"></p>
                        </div>
                        <span className="chatbox__close" onClick={() => {
                            this.props.onClose();
                            setPopup(null);
                            console.log(this.props.onClose);
                            
                        }}>X</span>
                    </div>
                    <List gap="40px">
                        <SidePopupSection title="Details">
                            <List gap="30px">
                                <Container gap="20px">
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))',
                                        gap: '20px'
                                    }}>
                                        <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Name</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px">{ name }</Container.Text>
                                            </Container.Content>
                                        </Container>
                                        <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Description</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px">{ description }</Container.Text>
                                            </Container.Content>
                                        </Container>

                                        { completed_at !== null && <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Completed At</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px" color={completed_at === null ? 'rgb(255, 53, 86)' : ''}>{ stringToFriendlyDatetime(completed_at) }</Container.Text>
                                            </Container.Content>
                                        </Container> }

                                        { assigned_to !== null && <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Assigned To</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px">{ assigned_to?.name }</Container.Text>
                                            </Container.Content>
                                        </Container> }

                                        <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Date Created</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px">{ stringToFriendlyDatetime(created_at) }</Container.Text>
                                            </Container.Content>
                                        </Container>
                                        <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Date Updated</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px">{ stringToFriendlyDatetime(updated_at) }</Container.Text>
                                            </Container.Content>
                                        </Container>

                                        { relation !== null && <Container style={{flex: '1 0 0'}}>
                                            <Container.Header>
                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Relation</Container.Title>
                                            </Container.Header>
                                            <Container.Content>
                                                <Container.Text fontSize="16px"><a href={relation}>View</a></Container.Text>
                                            </Container.Content>
                                        </Container> }

                                        { completed_by !== null &&
                                            <Container style={{flex: '1 0 0'}}>
                                                <Container.Header>
                                                    <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Completed By</Container.Title>
                                                </Container.Header>
                                                <Container.Content>
                                                    <Container.Text fontSize="16px">{ completed_by.name }</Container.Text>
                                                </Container.Content>
                                            </Container>
                                        }

                                        { completed_at === null &&
                                            <Container style={{flex: '1 0 0'}}>
                                                <Container.Header>
                                                    <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Completed</Container.Title>
                                                </Container.Header>
                                                <Container.Content>
                                                    <Button className="button--score" onClick={() => this.props.onComplete(this.state.task.id)}>Complete</Button> 
                                                </Container.Content>
                                            </Container>
                                        }
                                    </div>
                                </Container>
                            </List>
                        </SidePopupSection>
                        <SidePopupSection title="Notes" style={{ height: 'inherit' }}>
                            <div className="chatbox__chat">
                                <div className="chatbox__messages">
                                    { notes?.map((note, index) => {
                                        return (
                                            <div key={index} id={`comment-${index}`} className="chatbox-message__outer chatbox-message-task">
                                                <div className="chatbox-message-full">
                                                    <p className="chatbox-message__message">{ note?.note }</p>
                                                    <p className="chatbox-message__sender">{ note?.user?.name }</p>
                                                </div>
                                                <p className="chatbox-message__date">{ stringToFriendlyDatetime(note.updated_at) }</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Wrapper>
                                    <div className="chatbox__input">
                                        <textarea placeholder="Enter Note" value={this.state.note} onChange={this.onChange.bind(this)} onKeyPress={this.messageEnterSubmit.bind(this)}/>
                                        <div className="chatbox-input__buttons">
                                            <SendButton mobile={true} onClick={this.createNote.bind(this)}/>
                                        </div>
                                    </div>
                                </Wrapper>
                            </div>
                        </SidePopupSection>
                    </List>
                </>
            }/>
        )
    }
}
