import MediaHelper from '../../lib/helpers/media';

import { ModifyButton } from '../buttons';

export default ({ distribution = {}}) => {
    const img = distribution.settings ? distribution.settings.distribution_image  : '';

    return (
        <div class="distribution-item">
            <div class="distribution-item__img" style={{ background : `url(${img ? MediaHelper.formatPublicPath(`/images/${img}`) :''})`}}></div>
            <div class="distribution-item__content">
                <p className={'distribution-item__type'}>{ distribution.distribution_type.name }</p>
                <b className={'distribution-item__name'}>{ distribution.name }</b>
                <p className={'distribution-item__domain'}>{ distribution.domain }</p>

                <ModifyButton onClick={() => window.location = `/content/distributions/${distribution.id}`}>Settings</ModifyButton>
            </div>
        </div>
    )
}
