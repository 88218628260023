import { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';

import Input from '../input-with-label';

import Popup from '../popup';
import {slugFormatter} from "../../lib/helpers/formatters";

import { CreateButton, CancelButton } from '../buttons';

export default ({ setPopup }) => {
    const [ contentTypes, setContentTypes ] = useState([]);
    const [ selectedContentType, setSelectedContentType ] = useState(null);

    const [ errors, setErrors ] = useState({});

    const [ data, setData ] = useState({
        title: '',
        content_type_id: '',
        slug: ''
    })

    const confirm = async () => {
        try {
            await axios.post('/api/content/items', data).then((response) => {
                setErrors({});

                window.location.href = '/content/' + response.data.data.id + '/edit';
            });
        } catch (error) {
            const err = error.response.data.errors;

            setErrors(err);
        }
    }

    const onChange = (e) => {
        setData({
            ...data,
            [ e.target.name ]: e.target.value,
        });
    }

    const onTypeChange = (e) => {
        setData({
            ...data,
            content_type_id: e.value,
        });

        setSelectedContentType(e.value);
    }

    useEffect(async () => {
        axios.get(`/api/content/content-types`, { params: { page: 1, per_page: 100 } })
        .then(({ data: { data } }) => {
            const l = data.map(d => ({ label: d.label ? d.label : d.name, value: d.id }))
            setContentTypes(l);
        });
    }, []);

    const handleSlugChange = (e)  => {
        setData({
            ...data,
            slug : slugFormatter(e.target.value)
        })
    }


    const getSelectedOptionValue = () => {

        let o =  {
            value : '',
            label : 'Please Select'
        };

        const selectedIndex = _.findIndex(contentTypes, t => {
            return t.value === selectedContentType;
        });

        if(selectedIndex !== -1 ){
            o = contentTypes[selectedIndex];
        }

        return o;
    }

    return (
        <Popup type="new-content">
            <div className="popup__inner">
                <h1>Create new content</h1>

                <Input name="title" placeholder="Title" value={data.title} onChange={onChange} errors={errors.title}/>
                <Input name="slug" placeholder="Slug" value={data.slug} onChange={handleSlugChange} errors={errors.slug}/>

                <div className="input-outer">
                    <label for="email">Choose a content type</label>
                    <Dropdown className="input input--idle" options={contentTypes} onChange={onTypeChange} value={getSelectedOptionValue()} placeholder="Type"/>

                    { Object.keys(errors).length > 0 &&
                        errors.content_type_id != null &&
                        errors.content_type_id.length > 0 &&
                            <div className="input-outer__errors">{ errors.content_type_id.map((err, index) => <p key={index}>{ err }</p>) }</div>
                    }
                </div>

                <div className="popup__buttons">
                    <CreateButton responsive={false} onClick={confirm}/>
                    <CancelButton responsive={false} onClick={setPopup}/>
                </div>
            </div>
        </Popup>
    )
}
