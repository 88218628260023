import React, {Component} from 'react';

class TextInput extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {data, config, sectionConfig, contentIndex, handleChange, fixed,} = this.props;

        return (
            <div class="input-outer input-outer__small" style={{margin : '20px 0px 20px 0px'}}>
                <input placeholder="Title" value={data} onChange={(evt) => handleChange({
                    title: config.name,
                    contentItemConfig: config,
                    sectionConfig,
                    contentIndex,
                    data: {body : evt.target.value, meta: {}},
                    fixed : fixed
                })}/>
            </div>
        )
    }
}

export default TextInput;
