import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';
import ReactSlider from 'react-slider';

export default (props) => {
    const { label, fill, grow, errors } = props;

    const getProps = () => {
        let newProps = { ...props };

        delete newProps.label;
        delete newProps.fill;
        delete newProps.grow;
        delete newProps.errors;

        return newProps;
    }

    props = getProps();
    
    return (
        <LabelContainer label={label} fill={fill} grow={grow}>
            <ErrorContainer errors={errors} fill={fill}>
                <ReactSlider
                    className="customSlider"
                    thumbClassName="customSlider-thumb"
                    trackClassName="customSlider-track"
                    markClassName="customSlider-mark"
                    { ...props }
                />
            </ErrorContainer>
        </LabelContainer>
    )
}
