import {PasswordButton} from '../../../components/buttons';

import Input from '../../../components/input-with-label';

import {MdAssignmentAdd} from 'react-icons/md';

import {Component, createRef} from 'react';

import Row from '../../../utils/row';
import {CancelButton, AddButton, SendButton} from '../../../components/buttons';
import Divider from '../../../components/divider/divider';
import List from '../../../components/list/list';

import Container from '../../../containers/container';
import {stringToFriendlyDatetime} from '../../../lib/helpers/datetime-helpers';

import FileUploader from '../../../components/popups/images/file-uploader-popup';
import FileTypes from '../../../lib/files/file-types';

import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import contactsApi from '../../../lib/contacts/contacts-api';
import PopupContext from '../../../context/context-popup';
import ViewButton from '../../../components/buttons/view-button';

import MeApi from '../../../lib/users/me-api';
import TasksApi from '../../../lib/tasks';

import ContainerTable from '../../../containers/container-table';

import SeventyThirtyLayout from '../../../containers/layout/fifty-fifty-layout';
import {MinMaxLayout} from '../../../containers/layout';

import {TiTick} from 'react-icons/ti';
import ClipLoader from "react-spinners/ClipLoader";

import ButtonNext from '../../../components/buttons-navigation/button-next';

import TasksView from '../../tasks/popups/tasks-view';


import Auth from '../../../lib/users/auth';


function MessageSent({message}) {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`} className="chatbox-message__outer chatbox-message__outer--sent">
            <div className="chatbox-message chatbox-message--sent" style={{
                maxWidth: '70%',
                gap: '40px'
            }}>
                <p className="chatbox-message__message">{message.body}</p>
                <Row gap="10px" alignItems='center'>
                    <Container.Icon style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%'
                    }} src={message?.from?.user_profile?.profile_img || '/assets/user-icon.svg'}/>
                    <p className="chatbox-message__sender">{message?.from?.name}</p>
                </Row>
            </div>
            <Row gap="10px" alignItems='center'>
                <Row gap="5px" alignItems='center'>
                    {message.read_by?.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Container.Icon className={`sent-read-by-${index}`} style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%'
                                }} src={item?.user_profile?.profile_img || '/assets/user-icon.svg'}/>

                                <Tooltip
                                    anchorSelect={`.sent-read-by-${index}`}
                                    content={`${item?.name} - ${stringToFriendlyDatetime(item?.created_at)}`}
                                />
                            </Row>
                        )
                    })}
                </Row>
                <p className="chatbox-message__date">{CREATED_AT_DATE}</p>
            </Row>
        </div>
    )
}

function MessageReceived({message}) {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`} className="chatbox-message__outer chatbox-message__outer--received">
            <div className="chatbox-message chatbox-message--received" style={{
                maxWidth: '70%',
                gap: '40px'
            }}>
                <p className="chatbox-message__message">{message.body}</p>
                <Row gap="10px" alignItems='center'>
                    <Container.Icon style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%'
                    }} src={message?.from?.user_profile?.profile_img || '/assets/user-icon.svg'}/>
                    <p className="chatbox-message__sender">{message?.from?.name}</p>
                </Row>
            </div>
            <Row gap="10px" alignItems='center'>
                <p className="chatbox-message__date">{CREATED_AT_DATE}</p>
                <Row gap="5px" alignItems='center'>
                    {message.read_by?.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Container.Icon className={`recieved-read-by-${index}`} style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%'
                                }} src={item?.user_profile?.profile_img || '/assets/user-icon.svg'}/>

                                <Tooltip
                                    anchorSelect={`.recieved-read-by-${index}`}
                                    content={`${item?.name} - ${stringToFriendlyDatetime(item?.created_at)}`}
                                />
                            </Row>
                        )
                    })}
                </Row>
            </Row>
        </div>
    )
}

export default class AccountMessagesMessage extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);
        this.state = {
            showFileUploader: false,
            conversation: null,
            messages: this.props.data?.latest_messages,
            message: '',
            loading: [],
            activeTask: null,
            tasks : []
        };

        this.messagesRef = createRef(null);
    }


    componentDidMount() {
        this.fetch();
        this.readMessages();
        this.getTasks();
    }

    getTasks() {
        TasksApi.adminTasks(1, 100, {related_to : "App\\Models\\Conversation", related_to_id : this.props.id}).then(data => {
           this.setState({ tasks : data.data});
        });
    }

    fetch() {
        return new Promise((resolve, reject) => {
            MeApi.getConversation(this.props.id).then((data) => {
                this.setState({conversation: data}, () => resolve());
            })
        })
    }

    readMessages() {
        return MeApi.readConversationMessages(this.props.id);
    }


    sendMessage() {
        if (this.state.message.length == 0) return;

        this.postMessage({
            body: this.state.message,
        })

        this.setState({message: ''})
    }

    postMessage(params, callback = () => {
    }) {
        MeApi.createConversationMessage(this.state.conversation.id, params).then(async () => {
            callback();

            this.scrollTop();
        })
    }

    onChange(e) {
        const {value} = e.target;

        this.setState({message: value})
    }


    messageEnterSubmit(e) {
        if (e.key === "Enter" && e.shiftKey == false) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    setShowFileUploader(bool) {
        this.setState({
            showFileUploader: bool,
        })
    }

    scrollTop() {
        document.querySelector('#content').scrollTop = document.querySelector('#content').scrollHeight;
    }

    addToLoading(id) {
        this.setState({
            loading: {
                ...this.state.loading,
                [id]: true,
            }
        });
    }

    removeFromLoading(id) {
        const loading = {...this.state.loading}

        delete loading[id];

        this.setState({loading});
    }

    isLoading(id) {
        return this.state.loading[id];
    }

    completeTask(id) {
        this.addToLoading(id);

        TasksApi.completeTask(id).then(() => {
            this.removeFromLoading(id);
            this.fetch().then(() => {
                if (this.state.activeTask !== null) {
                    const task = this.state.tasks.find(t => t.id === id);

                    this.showTask(task);
                }
            });

            setTimeout(() => {
                this.getTasks();
            }, 2000);
        });
    }

    showTask(data) {
        const {setPopup, closePopup} = this.context;

        this.setState({activeTask: data.id})

        setPopup(<TasksView task={data} onComplete={(task) => this.completeTask(task)} onClose={() => {
            this.setState({activeTask: null});
            closePopup();
        }}/>, true);
    }

    renderCompletion(data, row, completed = false) {

        if (this.isLoading(data.id)) {
            return <ClipLoader
                color={'white'}
                loading={true}
                size={30}
            />
        }

        if (completed === false) {
            return (
                <div style={{
                    width: '30px',
                    height: '30px',
                    background: 'white',
                    borderRadius: '50%',
                    cursor: 'pointer'
                }} onClick={() => {
                    if (completed === false) this.completeTask(data.id);
                }}></div>
            )
        }

        return <TiTick style={{fontSize: '30px', color: 'white'}}/>;
    }

    render() {
        if (this.state.conversation === null) return null;

        let tasks = []

        this.state.tasks.forEach((task) => {
            tasks.push([
                task,
                task.name,
                task.description,
                task,
                task.admin_task_notes?.length,
                task.id,
            ])
        });

        const {popup, overlap} = this.context;

        return (
            <>
                {overlap === true && popup}

                <MinMaxLayout
                    main={
                        <List gap="40px" style={{
                            paddingBottom: '20px'
                        }}>
                            <List gap="20px">
                                <CancelButton onClick={this.props.return}
                                              style={{width: 'fit-content'}}>Return</CancelButton>
                            </List>

                            <Container style={{
                                backgroundColor: '#F5F8FA',
                                padding: '20px',
                                borderRadius: '20px'
                            }}>
                                <Container.Header>
                                    <List gap="40px">
                                        <List gap="15px">
                                            <Container gap="30px">
                                                <Row wrap={true} gap="20px 50px" style={{flexGrow: '1'}}>
                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Date
                                                                Created</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{stringToFriendlyDatetime(this.state.conversation.created_at)}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Updated
                                                                At</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{stringToFriendlyDatetime(this.state.conversation.updated_at)}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Type</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{this.state.conversation.subject || '-'}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8"></Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}></Container.Text>
                                                        </Container.Content>
                                                    </Container>
                                                </Row>

                                                <Row wrap={true} gap="30px 50px" style={{flexGrow: '1'}}>
                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Email</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{this.state.conversation.created_by?.email || '-'}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">First
                                                                Name</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{this.state.conversation.created_by?.first_name || '-'}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Last Name</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{this.state.conversation.created_by?.last_name || '-'}</Container.Text>
                                                        </Container.Content>
                                                    </Container>

                                                    <Container style={{flex: '1 0 0'}}>
                                                        <Container.Header>
                                                            <Container.Title style={{whiteSpace: 'nowrap'}}
                                                                             color="#929BA8">Phone
                                                                Number</Container.Title>
                                                        </Container.Header>
                                                        <Container.Content>
                                                            <Container.Text fontSize="16px"
                                                                            style={{whiteSpace: 'nowrap'}}>{this.state.conversation.created_by?.phone_number || '-'}</Container.Text>
                                                        </Container.Content>
                                                    </Container>
                                                </Row>
                                            </Container>
                                        </List>

                                        <Container.Title color="#151515" fontSize="20px">Messages</Container.Title>
                                    </List>
                                    <Divider/>
                                </Container.Header>
                                <Container.Content>
                                    <List gap="40px">
                                        <div className="chatbox__messages" ref={this.messagesRef}>
                                            {this.state?.messages?.map(message => {
                                                if (window.user.id == message?.from?.id) {
                                                    return <MessageSent message={message}/>
                                                } else {
                                                    return <MessageReceived message={message}/>
                                                }
                                            })}
                                        </div>

                                        <div className="chatbox__input" style={{opacity: 0.3, position: 'relative'}}>
                                            <div style={{
                                                position: 'absolute',
                                                left: '0px',
                                                top: '0px',
                                                width: '100%',
                                                height: '100%'
                                            }}></div>
                                            <textarea placeholder="Enter message" value={this.state.message} disabled/>
                                            <div className="chatbox-input__buttons">
                                                <SendButton mobile={true} disabled/>
                                            </div>
                                        </div>
                                    </List>
                                </Container.Content>
                            </Container>

                            <Container style={{
                                backgroundColor: '#F5F8FA',
                                padding: '20px',
                                borderRadius: '20px'
                            }}>
                                <Container.Header>
                                    <Container.Title color="#354859" fontSize="20px">Tasks</Container.Title>
                                    <Divider top="20px" bottom="0px"/>
                                </Container.Header>
                                <Container.Content>
                                    <ContainerTable
                                        className="dashboard-progress"
                                        columns={[
                                            {field: 'COMPLETION', width: '10%'},
                                            {field: 'NAME', width: '20%'},
                                            {field: 'DESCRIPTION', width: '20%'},
                                            {field: 'ASSIGNED TO', width: '20%'},
                                            {field: 'NOTES', width: '20%'},
                                            {field: '', width: '30%'},
                                        ]}
                                        data={
                                            [...tasks]
                                        }
                                        style={{
                                            backgroundColor: 'transparent',
                                            padding: '0px',
                                        }}
                                        rowStyle={{
                                            backgroundColor: 'white',
                                        }}
                                        replaceColumn={{
                                            0: (data, row) => {
                                                const completed = data.completed_at !== null;
                                                return (
                                                    <div style={{
                                                        backgroundColor: completed === true ? '#00C8C3' : '#ff3556',
                                                        width: '100%',
                                                        height: '70px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '10px 0px 0px 10px'
                                                    }}>
                                                        {this.renderCompletion(data, row, completed)}
                                                    </div>
                                                )
                                            },
                                            3: (data, row) => {
                                                const completed = data.completed_at !== null;

                                                return (
                                                    <div>
                                                        {
                                                            data.assigned_to &&
                                                            data.assigned_to.name
                                                        }
                                                        {
                                                            (completed === false && Auth.user.id !== data.assigned_to?.id )&&
                                                                <>
                                                                    &nbsp;<a href={'#'} onClick={async e => {
                                                                    e.preventDefault();
                                                                    await MeApi.takeTask(data.id);
                                                                     this.getTasks();
                                                                }}>Take</a>
                                                                </>

                                                        }

                                                    </div>
                                                )
                                            },
                                            5: (data, row) => {
                                                const task = this.state.tasks.find(t => t.id === data);

                                                return (
                                                    <Row style={{
                                                        width: '100%',
                                                        paddingRight: '25px'
                                                    }} justifyContent='flex-end'>
                                                        <ButtonNext
                                                            onClick={() => this.showTask(task)}>View</ButtonNext>
                                                    </Row>
                                                )
                                            }
                                        }}
                                    />
                                </Container.Content>
                            </Container>
                        </List>
                    }
                />
            </>
        );
    }
}

const MessageFile = ({message, sent}) => {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`}
             className={`chatbox-message__outer ${sent ? 'chatbox-message__outer--sent' : 'chatbox-message__outer--received'}`}>
            <div className={`chatbox-message ${sent ? 'chatbox-message--sent' : 'chatbox-message--received'}`}>
                <img src="/assets/document-icon.svg"/>
                <p className="chatbox-message__fileName">{message?.application_file?.file?.name}</p>
                <p className="chatbox-message__sender">{message.user.name}</p>
            </div>
            <p className="chatbox-message__date">{message.read_by ? `Read By ${message.read_by.name} | ${CREATED_AT_DATE}` : CREATED_AT_DATE}</p>
        </div>
    )
}

