export default ({
                    message = 'Uh-oh!',
                    body = <>
                        <b>It looks like there are no results for what you searched for.</b>
                        <p>Please try refining your search or using the filters to find what you’re looking for.</p>
                        <p>Need help? Contact Us</p>
                    </>
                }) => {
    return (
        <div className="results-error">
            <h1>{message}</h1>
            {body}
        </div>
    )
}
