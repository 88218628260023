import { useEffect, useState } from 'react';

import Popup from '../../../components/popup';

import GrantsApi from '../../../lib/grants/grants-api';

import { DeleteButton, CancelButton } from '../../../components/buttons';

import AlertManager from '../../../managers/alert/alert-manager';

export default ({ setPopup, grant }) => {
   const confirm = () => {
    GrantsApi.delete(grant.id, (err) => {
        if (err.length > 0) return AlertManager.error(err[0][0]);

        AlertManager.error(err);
    }).then(() => {
        window.location.href = "/grants";
    });
   }

    return (
        <Popup type="new-version" onClose={() => setPopup(false)}>
            <div class="popup__inner">
                <h1>Are you sure you want to delete this?</h1>

                <div class="popup__buttons">
                    <DeleteButton responsive={false} onClick={confirm}/>
                    <CancelButton responsive={false} onClick={() => setPopup(false)}/>
                </div>
            </div>
        </Popup>
    )
}
