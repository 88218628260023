import { useEffect, useRef } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';

import { Editor } from '@tinymce/tinymce-react';
import VideoPreview from '../../../../../../components/video-preview';

const videoTypes = [
    {
        label: 'Vimeo',
        value: 'vimeo',
    }
]

export default function QuestionTypeVideo({ 
    question,
    index,
    onQuestionChange,
}) {
    const ref = useRef(null);
    
    const onChange = (e) => {
        const { name, value } = e.target;
        const body = { ...question.body }

        body[name] = value;

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    useEffect(() => {
        onChange({
            target: {
                name: 'type',
                value: 'vimeo',
            }
        });
    }, [])

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Video Title" fill={true} name='title' value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Video Description" fill={true}>
                        <Editor
                            onInit={(evt, editor) => ref.current = editor}
                            value={question?.description}
                            onEditorChange={(value, editor) => {
                                onQuestionChange({
                                    target: {
                                        name: 'description', 
                                        value: value
                                    }
                                });
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </LabelContainer>
                </>
            } right={
                <List gap="20px">
                    <Dropdown label="Select Provider" items={videoTypes} fill={true} value={videoTypes.find(i => i.value === question.body?.type)} onChange={item => {
                        onChange({
                            target: {
                                name: 'type',
                                value: item.value,
                            }
                        })
                    }}/>
                    <Input label="Video ID" fill={true} name="value" value={question.body?.value ?? ''} onChange={onChange}/>
                    <VideoPreview toShow={['vimeo']} value={question.body?.value}/>
                </List>
            }/>
        </QuestionType>
    )
}