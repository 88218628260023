import React, {Component} from 'react';

import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";

import {datetimeLocalToString} from "../../lib/helpers/datetime-helpers";

import ELearningApi from '../../lib/e-learning/e-learning-api';

class AppointmentsCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            appointments : {},
        };

        this.calEl = null;
    }


    componentDidMount() {
        this.getAppointments();
    }


    getAppointments(date = this.state.date) {
        const { relation } = this.props;

        let d = dayjs(date);

        new ELearningApi().getManualAssessments().then(response => {
            this.setRef({ appointments: this.formatDates(response.data) });
        });
    }


    formatDates(data) {
        let byDate = {};

        data.forEach(a => {
            const start = dayjs(a.date).format('DD/MM/YY');

            if(byDate.hasOwnProperty(start) === false) {
                byDate[start] = [a];
            }else {
                byDate[start].push(a);
            }

        });

        this.setState({appointments : byDate});

        return byDate;
    }

    setRef(r) {


        this.calEl = r;
    }

    handleChange(date) {
        this.props.onChange(date);
        this.setState({date}, () => {
            this.getAppointments();
        });
    }

    dayHasAppointments(date) {
        const d = dayjs(date).format('DD/MM/YY');

        return this.state.appointments.hasOwnProperty(d);
    }

    handleViewChange(data) {
        this.getAppointments(data.activeStartDate);
        this.props.onChange(data.activeStartDate);
    }

    render() {
        return (
            <div>
                <Calendar
                    className={'appointment-cal'}
                    inputRef={this.setRef.bind(this)}
                    onChange={this.handleChange.bind(this)}
                    onActiveStartDateChange={this.handleViewChange.bind(this)}
                    formatDay={(local, date) => {
                            return(
                                <div className={'appointment-cal__day'}>
                                    { dayjs(date).date()}
                                    {
                                        this.dayHasAppointments(date) &&
                                            <div className={'appointment-cal__pip'}></div>
                                    }
                                </div>
                            );
                    }}
                    value={this.state.date}
                />
            </div>
        );
    }
}

AppointmentsCalendar.defaultProps = {
    relation : null,
    onChange : date => console.log(date)
};

export default AppointmentsCalendar;
