import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
import ContentApi from '../content/content-api';
dayjs.extend(utc)


class ContentLock {
    constructor(content, currentUserId) {
        this.onUnlocked = () => console.log('Content unlocked');
        this.content = content;
        this.poll =  5000;
        this.currentUserId = currentUserId;
    }

    isLockedByAnotherUser() {

        if(this.content.locked_by === null) {
            return false;
        }


        if(this.currentUserId === this.content.locked_by.id) {
            return false;
        }

        return  this.withinTimeBounds();

    }

    withinTimeBounds() {

        let lockedAt = dayjs(this.content.locked_at);
        let now = dayjs().subtract(dayjs().utcOffset(), 'minutes');
        let diff = now.diff(lockedAt, 'seconds');

        return diff < (this.poll / 1000) + 2 ;
    }


    registerEvents() {
        // window.onbeforeunload  = (e) => {
        //     this.unlock();
        //     return  'Are you sure?';
        // }
        // window.onhashchange =  () => {
        //      this.unlock();
        // }
    }

    unlock() {
        // window.localStorage.setItem('content-unlock', this.content.id);
    }

     lock() {
        this.registerEvents();
        ContentApi.lock(this.content.id).then(response => {
            if(response.status === 200) {
                setTimeout(() => this.lock(), this.poll);
            }
        });

    }

}

export default ContentLock;
