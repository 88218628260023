import React from 'react';
import BlueArrowButton from '../buttons/blue-arrow-button';

export default ({title, subtitle, body, link}) => {

    return (
        <div className={'list-card'}>
            <div className="list-card__content">
                <div className="list-card__top">
                    {subtitle}
                </div>
                <div className="list-card__title">
                    {title}
                </div>
                <div className="list-card__body">
                    {
                        body
                    }
                </div>
            </div>
            <div className="list-card__button-wrapper">
                {link &&
                <BlueArrowButton onClick={() => window.location = link}/>
                }
            </div>
        </div>
    );

}
