window.onload= () => {
    const sidebarIcon = document.getElementById("sidebar-icon");
    const sidebar = document.querySelector(".sidebar");
    const content = document.querySelector(".content");

    if (sidebarIcon) {
        sidebarIcon.onclick = () => {
            if (sidebar.style.transform != "translateX(0%)") {
                sidebar.style.transform = "translateX(0%)";
                content.style.width = "calc(100% - 183px)";
                content.style.marginLeft = "183px";
                sidebarIcon.src = "/assets/cross.svg";
            } else {
                sidebar.style.transform = "translateX(-100%)";
                content.style.width = "100%";
                content.style.marginLeft = "0px";
                sidebarIcon.src = "/assets/hamburger.svg";
            }
        }
    } 
}
