import {ExportButton, ProgressButton, DocumentUploadButton} from '../../../components/buttons';

import Dropdown from '../../../components/dropdowns/dropdown';
import InformationSection from './grant-information-section';
import {GrantStatusHeader} from "./grant-status-header";
import React from "react";
import ApplicationApi from "../../../lib/applications/application-api";
import Row from '../../../utils/row';
import LabelContainer from '../../../components/label-container';
import Container from '../../../containers/container';

const STATUS_CLAIM_PROCESSED = 'claim processed';

const STATUS_OFFER_LETTER_SENT = 'offer letter sent';

const STATUS_OFFER_ACCEPTED = 'offer accepted';

const STATUS_OFFER_REJECTED = 'offer rejected';

const STATUS_DOCUMENTS_REQUESTED = 'documents requested';

const STATUS_DOCUMENTS_RECEIVED = 'documents received';

const STATUS_CLAIM_SUBMITTED_FOR_PAYMENT = 'claim submitted for payment';

const STATUS_COMPLETED = 'completed';

const STATUS_CLAIM_APPROVED_FOR_PAYMENT = 'claim approved for payment';


const OPTIONS = {
    STATUS_CLAIM_PROCESSED: {
        value: STATUS_CLAIM_PROCESSED,
        label: 'Application Accepted: Claim processed',
        message: 'The payment for this claim has now been paid by BMBC Finance to the Applicant.'
    },
    STATUS_OFFER_LETTER_SENT: {
        value: STATUS_OFFER_LETTER_SENT,
        label: 'Application Accepted: Offer letter sent',
        message: 'The offer letter for this application has been sent to the applicant to review, sign and return via email. Once received, the claims process can continue for this application.'

    },

    STATUS_OFFER_ACCEPTED: {
        value: STATUS_OFFER_ACCEPTED,
        label: 'Application Accepted: Offer Accepted',
        message: 'The offer for this application has been accepted by the applicant and the claims process can now proceed.'

    },
    STATUS_OFFER_REJECTED: {
        value: STATUS_OFFER_REJECTED,
        label: 'Application Accepted: Offer rejected',
        message: 'This application is no longer accepted for claims processing. The grant manager should notify the applicant via email why this claim has been overturned to rejection.'

    },

    STATUS_DOCUMENTS_REQUESTED: {
        value: STATUS_DOCUMENTS_REQUESTED,
        label: 'Application Accepted: Grant documents requested',
        message: 'This application requires new documents or it has queries raised about existing documents submitted. Please contact the applicant and inform them about what is required to continue their claim.'

    },
    STATUS_DOCUMENTS_RECEIVED: {
        value: STATUS_DOCUMENTS_RECEIVED,
        label: 'Application Accepted: Grant documents Received',
        message: 'The applicant has returned the required documents for this application. Please continue the claims process of this application.'

    },
    STATUS_CLAIM_SUBMITTED_FOR_PAYMENT: {
        value: STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
        label: 'Application Accepted: Claim submitted for payment',
        message: 'This application has been submitted for payment with the BMBC Finance team. The payment should be made within 1 week of the date submitted.'

    },
    STATUS_COMPLETED: {
        value: STATUS_COMPLETED,
        label: 'Application Accepted: Completed',
        message: 'This claim has been marked completed following payment. No further action is required at this stage.'

    },
    STATUS_CLAIM_APPROVED_FOR_PAYMENT: {
        value: STATUS_CLAIM_APPROVED_FOR_PAYMENT,
        label: 'Application Accepted: Claim approved for Payment',
        message: 'This application is ready to be submitted for payment with the BMBC Finance team. The required steps should now be taken by the Grant Manager(s) to proceed with the payment stage.'

    },


};

export default class GrantStatusAccepted extends React.Component {

    constructor(props) {
        super(props);
        this.api = new ApplicationApi();
    }


    getWorkflowOptions() {
        switch (this.props.application.status) {
            case('approved') :
                return [
                    OPTIONS.STATUS_OFFER_LETTER_SENT,
                    OPTIONS.STATUS_OFFER_ACCEPTED,
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_DOCUMENTS_REQUESTED,
                    OPTIONS.STATUS_DOCUMENTS_RECEIVED,
                    OPTIONS.STATUS_CLAIM_APPROVED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_OFFER_LETTER_SENT):
                return [
                    OPTIONS.STATUS_OFFER_ACCEPTED,
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_DOCUMENTS_REQUESTED,
                    OPTIONS.STATUS_DOCUMENTS_RECEIVED,
                    OPTIONS.STATUS_CLAIM_APPROVED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_OFFER_ACCEPTED):
                return [
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_DOCUMENTS_REQUESTED,
                    OPTIONS.STATUS_DOCUMENTS_RECEIVED,
                    OPTIONS.STATUS_CLAIM_APPROVED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_DOCUMENTS_REQUESTED) :
                return [
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_DOCUMENTS_RECEIVED,
                    OPTIONS.STATUS_CLAIM_APPROVED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_DOCUMENTS_RECEIVED) :
                return [
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_CLAIM_APPROVED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_CLAIM_APPROVED_FOR_PAYMENT) :
                return [
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_CLAIM_SUBMITTED_FOR_PAYMENT,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_CLAIM_SUBMITTED_FOR_PAYMENT) :
                return [
                    OPTIONS.STATUS_OFFER_REJECTED,
                    OPTIONS.STATUS_CLAIM_PROCESSED,
                    OPTIONS.STATUS_COMPLETED
                ];
            case(STATUS_CLAIM_PROCESSED) :
                return [
                    OPTIONS.STATUS_COMPLETED
                ];
            default :
                return [];
        }

    }

    getOption() {
        const option = _.find(OPTIONS, item => {
            return item.value === this.props.application.status;
        });

        return option === undefined ? null : option;
    }

    getMessage() {
        const option = this.getOption();

        return <p>{option ? option.message : `This application has been accepted and can no longer be commented on or amended. If this was done in error, contact an admin.`}</p>;
    }

    getTitle() {

        const option = this.getOption();

        return option !== null ? option.label : 'Application Accepted';
    }


    handleFlowChange(status) {

        this.api.setClaimStatus(this.props.application.id, {status: status}).then(() => {
            this.props.onRefresh();
        });
    }

    render() {
        return (
            <GrantStatusHeader
                title={this.getTitle()}
                message={this.getMessage()}
                type={'accepted'}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Row gap="20px">
                        {
                            this.props.application.status !== STATUS_COMPLETED &&
                            <InformationSection title="Manage approval workflow (cannot be reversed)">
                                <Dropdown onChange={({value}) => this.handleFlowChange(value)}
                                        items={this.getWorkflowOptions().map(item => {
                                            item.label = item.label.replace('Application Accepted: ', '');
                                            return item;
                                        })} placeholder="Select next step"/>
                            </InformationSection>
                        }
                        {
                            this.props.application?.amount_rewarded &&
                            <InformationSection title="Amount Awarded">
                                <p>£{this.props.application.amount_rewarded}</p>
                            </InformationSection>
                        }
                        {
                            this.props.application?.total_application_score &&
                            <InformationSection title="Total Application Score">
                                <p>{this.props.application.total_application_score}</p>
                            </InformationSection>
                        }
                    </Row>

                    <Row gap="20px">
                        <InformationSection title="Application Output">
                            <ExportButton responsive={false} onClick={() => window.location = `/grants/applications/${this.props.application.id}/pdf`}>Export as PDF</ExportButton>
                        </InformationSection>
                        <InformationSection title="Information">
                            <DocumentUploadButton responsive={false} onClick={() => this.props.toggleDocumentUploads(true)}>Documents Uploads</DocumentUploadButton>
                        </InformationSection>
                    </Row>
                </div>

                <LabelContainer label={<Container.Text fontSize="12px" color="#929BA8">Acceptance Message</Container.Text>}>
                    { this.props.application?.acceptance_message }
                 </LabelContainer> 
                
            </GrantStatusHeader>
        );
    }


}
