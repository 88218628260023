import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../components/breadcrumb";
import PageLayout from "../../containers/layout/page-layout";
import {MinMaxLayout} from "../../containers/layout";

import Input from '../../components/input-with-label';
import Checkbox from '../../components/checkbox';
import TextArea from '../../components/textarea-with-label';
import Dropdown from '../../components/dropdowns/dropdown';
import RadioButton from '../../components/radio-button';
import DateInput from '../../components/input-date';

import FileUploader from '../../components/popups/images/file-uploader-popup';
import { SmallPill } from '../../components/pills';

import DocumentIcon from './components/grant-document-icon';

import Chatbox from '../../components/chatbox/chatbox';

import TabBar from '../../components/tab-bar';

import FileTypes from '../../lib/files/file-types';

import StickyContainer from '../../components/layout/sticky-container';

import DOMPurify from 'dompurify';

import he from 'he';


import {
    SendButton,
    CommentButton,
    ScoreButton,
    PercentButton,
    AddButton,
    HistoryButton, ExportButton
} from '../../components/buttons';

import AuthAPI from '../../lib/users/auth';

import Sponsors from '../../components/sponsors';

import GrantApplicationDecision from './components/popups/grant-application-decision';
import GrantApplicationAssessors from './components/popups/grant-application-assessors';
import GrantApplicationStartScoring from './components/popups/grant-application-start-scoring';
import GrantApplicationFinalScoresQuestion from './components/popups/grant-application-final-scores-question';
import GrantApplicationFinalScoresBlock from './components/popups/grant-application-final-scores-block';

import ApplicationApi from '../../lib/applications/application-api';
import Auth from '../../lib/users/auth';

import GrantsApplicationReview from './grant-application-view-review';

import {stringToFriendlyDatetime} from '../../lib/helpers/datetime-helpers';

import Table from '../../components/table/table';

import StatusAccepted from './components/grant-status-accepted';
import StatusRejected from './components/grant-status-rejected';
import ScoreGroup from './components/popups/grant-application-view-score-group';
import ScoreQuestion from './components/popups/grant-application-view-score-question';

import MediaQuery from 'react-responsive'
import {GrantStatusHeader} from "./components/grant-status-header";
import Alerts from "../../managers/alert/alert-types";
import AlertManager from '../../managers/alert/alert-manager';


const QuestionCanBeScored = (block, question) => {
    if (block?.override_questions_scoring_range === true) {
        if (block?.scoring_range.length >= 1) {
            return true;
        }
    }

    return question?.scoring_range.length >= 1;
}

const BlockCanBeScored = (block) => block?.scoring_range.length >= 1 && block?.scoring_weight_percentage >= 1;


import GrantApplicationProgress from './components/grant-application-progress';
import {sanatizeString} from "../../lib/helpers/formatters";


const crumbs = [{
    name: 'Grants',
    link: ''
},
    {
        name: 'Applications',
        link: '#'
    }
];

const InformationSection = ({title, children}) => {
    return (
        <div className="information-section">
            <p className="information-section__title">{title}</p>
            {children}
        </div>
    )
}

const AmendLock = ({toggled, onClick, busy = false}) => {

    if (busy === true) {
        return (
            <div className={`comment-lock comment-lock--busy`}>
                <svg className={'comment-lock__busy-icon'} width="200px" height="200px" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#c6c7d1" strokeWidth="3" r="35"
                            strokeDasharray="164.93361431346415 56.97787143782138">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                                          values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                </svg>
            </div>
        );
    }

    return (
        <div className={`comment-lock ${toggled ? 'comment-lock--toggled' : ''}`} onClick={onClick}>
            {toggled ?
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.003" viewBox="0 0 18 20.003">
                    <path id="Path_359" data-name="Path 359"
                          d="M7,10H20a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H5V9A7,7,0,0,1,18.262,5.869l-1.789.894A5,5,0,0,0,7,9ZM5,12v8H19V12Zm5,3h4v2H10Z"
                          transform="translate(-3 -1.997)" fill="#fff"/>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                    <path id="Path_357" data-name="Path 357"
                          d="M19,10h1a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H5V9A7,7,0,1,1,19,9ZM5,12v8H19V12Zm6,2h2v4H11Zm6-4V9A5,5,0,1,0,7,9v1Z"
                          transform="translate(-3 -2)" fill="#c6c7d2"/>
                </svg>
            }
        </div>
    )
}

const QuestionTypes = {
    TEXT_INPUT: 'text-input',
    DROPDOWN_SELECTION: 'dropdown-selection',
    RADIO_BUTTONS: 'radio-buttons',
    DATE_PICKER: 'date-picker',
    CHECKBOXES: 'checkboxes',
    CURRENCY_INPUT: 'currency-input',
    TABLE_INPUT: 'table-input',
    UPLOAD_DOCUMENTS: 'upload-documents',
    SIC_CODE: 'sic-code',
}

class GrantsApplicationSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupScoringPopup: null,
            questionScoringPopup: null,
            amendBusy: null,
            finalQuestionScoringPopup: null,
            finalBlockScoringPopup: null,
        }

        this.api = new ApplicationApi();
    }

    handleAmmendClick(questionId) {

        if (this.state.amendBusy !== null) {
            return;
        }

        const amend = this.props.amends.filter(amend => amend.question_id == questionId && amend.archived == 0 && amend.response == null)[0];

        if (amend) {

            return this.setState({amendBusy: questionId}, () => {
                return this.archiveAmend(amend.id);
            });
        }
        this.setState({amendBusy: questionId}, () => {
            this.api.makeAmmend(this.props.application.id, questionId).then(() => {

                this.setState({amendBusy : null})
                this.props.fetchAmends();
                this.props.refreshApplication();
            }).catch(e => {
                this.setState({amendBusy: null})
            })
        });

    }

    archiveAmend(amendId) {
        this.api.archiveAmend(this.props.application.id, amendId).then((data) => {
            this.setState({amendBusy: null});
            this.props.refreshApplication();
            this.props.fetchAmends();
        }).catch(e => {
            this.setState({amendBusy: null});
        })
    }

    setGroupScoring(block) {
        this.setState({groupScoringPopup: block})
    }

    setQuestionScoring(question) {
        this.setState({questionScoringPopup: question})
    }

    setShowFinalQuestionScores(question) {
        this.setState({ finalQuestionScoringPopup: question });
    }

    setShowFinalBlockScores(block) {
        this.setState({ finalBlockScoringPopup: block });
    }

    canBeCommented() {
        const status = this.props.application.status;

        return status !== 'in progress';
    }


    getQuestionType(blockId, questionId, question) {
        const content = this.props.versions[this.props.applicationVersion].content;
        const obj = content.find(c => c.question_id == questionId && c.block_id == blockId);

        let component = <p>No rendering of that type</p>;

        switch (question.type) {
            case QuestionTypes.TEXT_INPUT:
                const decoded = he.decode(obj?.value || '');
                const sanitized = DOMPurify.sanitize(decoded, {
                    FORBID_TAGS: ['img', 'video', 'picture',' audio', 'iframe'],
                    FORBID_ATTR: ['href']
                });

                component = <p style={{ width: '100%' }} className="markdown" dangerouslySetInnerHTML={{ __html: sanitized }}></p>
                break;

            case QuestionTypes.SIC_CODE:
                component = <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    {obj?.value.map((obj, index) => {
                        return <p key={index} style={{ fontSize: '14px' }}>{ obj }</p>
                    })}
                </div>
                break;

            case QuestionTypes.UPLOAD_DOCUMENTS:
                if (Array.isArray(obj?.value)) {
                    component = (
                        <div className="utils__row utils__flexWrap utils__gap--20">
                            {obj?.value.map((obj, index) => {
                                obj.file.path = `/applications/${this.props.application.id}/files/${obj.file.name}`;

                                return (
                                    <DocumentIcon key={index} file={obj.file}/>
                                )
                            })}
                        </div>
                    )
                } else {
                    const file = obj?.value?.file

                    component = <DocumentIcon file={file}/>
                }


                break;

            case QuestionTypes.CHECKBOXES:
                component = (
                    <div className="utils__row utils__flexWrap utils__gap--20">
                        {obj?.value.map((option, index) => {
                            return <p key={index}>{option}</p>
                        })}
                    </div>
                )
                break;

            case QuestionTypes.DROPDOWN_SELECTION:
                component = <p>{obj?.value}</p>;

                break;

            case QuestionTypes.RADIO_BUTTONS:
                component = (
                    <div className="utils__row utils__flexWrap utils__gap--20">
                        {obj?.value.map((option, index) => {
                            return <p key={index}>{option}</p>
                        })}
                    </div>
                )
                break;

            case QuestionTypes.TABLE_INPUT:
                component = <Table columns={question.columns}
                                   allowNewRows={question.allow_new_rows}
                                   defaultRowCount={question.default_row_count}
                                   maxRowCount={question.max_row_count}
                                   onChange={() => {
                                   }}
                                   readOnly={true}
                                   value={obj?.value}/>
                break;

            case QuestionTypes.DATE_PICKER:
                const val = Array.isArray(obj?.value) ? obj?.value.join(' - ') : obj?.value;
                component = <p>{val}</p>

                break;

            case QuestionTypes.CURRENCY_INPUT:
                component = <p>{question.currency == 'GBP' ? '£' : '€'}{obj?.value}</p>

                break;
        }

        if (obj?.value == undefined) return <p>No Response</p>;

        return component;
    }

    canBeScored() {
        return this.props.canBeScored;
    }

    canBeAmended() {
        return this.props.canBeAmended;
    }

    showFinalQuestionScore() {
        if (this.canBeScored()) return false;
        if (this.props.application?.accepted === 1) return true
    }

    getQuestionFinalScore(questionId, question, block) {
        const finalScore = this.props.allScores.final_scores?.filter(item => {
            return item.question_id === questionId;
        });
        const userScore = this.props.allScores.scores?.filter(item => {
            return (item.user_id === Auth.user.id && item.question_id === questionId);
        });

        const questionScores = this.props.allScores.scores?.filter(item => {
            return item.question_id === questionId;
        });


        let scoreRangeLength = 0;

        if (block.override_questions_scoring_range === true) {
            scoreRangeLength = Array.isArray(block.scoring_range) ? this.getHighestValuePropertyFromArray(block.scoring_range) : 0;
        } else {
            scoreRangeLength = Array.isArray(question.scoring_range) ? this.getHighestValuePropertyFromArray(question.scoring_range) : 0;
        }


        if (finalScore.length === 1) {
            return {
                button_text: `${finalScore[0].score}/${scoreRangeLength}`,
                meta_text: <>
                    Average Score entered by <br></br>
                    Manager at {stringToFriendlyDatetime(finalScore[0].created_at)}
                </>,
                color: 'green'
            };
        }


        let collectedScoresText = `${questionScores.length}/${this.props.scorers.length} scores received`

        if (userScore.length >= 1) {

            return {
                button_text: `${userScore[0].score}/${scoreRangeLength}`,
                meta_text: <>
                    You entered at {stringToFriendlyDatetime(userScore[0].created_at)}<br></br>
                    {collectedScoresText}
                </>,
                color: 'blue'

            };
        }


        return {
            button_text: `0/${scoreRangeLength}`,
            meta_text: <>
                Awaiting your score <br></br>
                {collectedScoresText}
            </>,
            color: null
        };

    }

    getHighestValuePropertyFromArray(items)
    {
        let sorted = items.sort((a, b) => {
           return b.value - a.value;
        });

        return sorted.length >= 1 ? sorted[0]?.value : 0;
    }

    getBlockScore(blockId) {
        const blockScoreIndex = _.findIndex(this.props.allScores.block_scores, (block) => {
            return block.block_id === blockId;
        });


        if (blockScoreIndex === -1) {
            return {
                button_text: '0',
                meta_text: 'Not entered by Manager',
                color: null
            };
        }

        const score = this.props.allScores.block_scores[blockScoreIndex];
        return {
            button_text: score.percentage_score,
            meta_text: <>Entered by Manager on <br></br> {stringToFriendlyDatetime(score.created_at)}</>,
            color: 'green'
        };

    }



    showQuestionButton(block, question) {

        if (block?.override_questions_scoring_range === true) {
            if (block?.scoring_range.length >= 1) {
                return true;
            }
        }

        return question?.scoring_range.length >= 1;

    }

    render() {
        const {blocks, description} = this.props.section;

        const isManager = AuthAPI.isManagerOfGrant(this.props.application.grant.managers);

        return (
            <>
                { this.state.finalBlockScoringPopup !== null && <GrantApplicationFinalScoresBlock
                    scores={this.props.allScores}
                    { ...this.state.finalBlockScoringPopup }
                    setPopup={() => this.setState({ finalBlockScoringPopup: null })}
                /> }

                { this.state.finalQuestionScoringPopup !== null && <GrantApplicationFinalScoresQuestion
                    finalScores={this.props.finalScores}
                    isManager={isManager}
                    scores={this.props.allScores}
                    { ...this.state.finalQuestionScoringPopup }
                    setPopup={() => this.setState({ finalQuestionScoringPopup: null })}
                /> }

                {this.state.groupScoringPopup !== null &&
                <ScoreGroup scorers={this.props.scorers} scores={this.props.allScores}
                            applicationId={this.props.application.id}
                            isManager={isManager} {...this.state.groupScoringPopup} setPopup={state => {
                    this.setGroupScoring(state);

                    if (state === null) {
                        this.props.onScoreChange();
                    }

                }}/>}
                {this.state.questionScoringPopup !== null &&
                <ScoreQuestion
                    scorers={this.props.scorers}
                    scores={this.props.allScores} applicationId={this.props.application.id}
                    isManager={isManager} {...this.state.questionScoringPopup} setPopup={state => {
                    if (state === null) {
                        this.props.onScoreChange();
                    }
                    this.setQuestionScoring(state);

                }}/>}

                <div className="application-view__section">
                    <div className="application-view-segment application-view__spacing">
                        <p className="application-view-segment__title">Section Description</p>
                        <p className="application-view-segment__description">{description}</p>
                    </div>

                    {blocks.map((block, index) => {
                        const {title, description, questions, id} = block;


                        const blockScore = this.getBlockScore(block.id);
                        return (
                            <div key={index} className="application-view-group__outer">
                                <div className="application-view__group">
                                    <div className="application-view-segment">
                                        <p className="application-view-segment__title">{block.number}. {title}</p>
                                        {description && <p className="application-view-segment__description"
                                                           dangerouslySetInnerHTML={{__html: description}}></p>}
                                    </div>
                                    {
                                        this.canBeScored() && BlockCanBeScored(block) &&
                                        <div className="application-view-segment">
                                            <div className={'application-view-segment__item'}>
                                                <PercentButton
                                                    color={blockScore.color}
                                                    onClick={() => {
                                                        if (isManager) {
                                                            this.setGroupScoring({
                                                                block: block,
                                                                section_id: this.props.section.id,
                                                                block_id: block.id,
                                                            })
                                                        }
                                                    }}>
                                                    {blockScore.button_text}
                                                </PercentButton>
                                                <div className="application-view-segment__item-meta">
                                                    {blockScore.meta_text}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.showFinalQuestionScore() && isManager && block.scoring_weight_percentage >= 1 && <div className="application-view-segment">
                                            <div className={'application-view-segment__item'}>
                                                <PercentButton
                                                    color={blockScore.color}
                                                    onClick={() => {
                                                        if (isManager) {
                                                            this.setShowFinalBlockScores({
                                                                block: block,
                                                                section_id: this.props.section.id,
                                                                block_id: block.id,
                                                            })
                                                        }
                                                    }}>
                                                    {blockScore.button_text}
                                                </PercentButton>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {questions.map((question, questionIndex) => {
                                    questionIndex = questionIndex + 1;

                                    const questionComments = this.props.comments.filter(comment => comment.question_id == question.id);
                                    const questionAmends = this.props.amends.filter(amend => amend.question_id == question.id);
                                    const amend = questionAmends.filter(amend => amend.archived == 0 && amend.response == null && amend.responded_by == null)[0];
                                    const questionScoreMeta = this.getQuestionFinalScore(question.id, question, block);

                                    const unread = questionComments.filter(comment => {
                                        return comment.read_by == null && comment.read_by_id == null && (comment.user.super_admin == 0 && comment.user.admin == 0);
                                    })

                                    const latestAmend = questionAmends[questionAmends.length - 1];

                                    return (
                                        <div className="application-view-question__row" key={questionIndex}>
                                            <div className="application-view-segment">
                                                <div className="application-view-segment__head">
                                                    <p className="application-view-segment__title">{block.number}.{questionIndex}. {question.title}</p>
                                                    {questionAmends.length > 0 &&
                                                        <span className="span">
                                                            {
                                                                latestAmend.responded_at !== null &&
                                                                <>
                                                                    Amended
                                                                    at {stringToFriendlyDatetime(latestAmend.responded_at)} by {latestAmend.responded_by.name}
                                                                </>

                                                            }
                                                            {
                                                                latestAmend.responded_at === null &&
                                                                <>
                                                                    Amended requested
                                                                    at {stringToFriendlyDatetime(latestAmend.created)} by {latestAmend.created_by.name}
                                                                </>

                                                            }

                                                        </span>}
                                                    { question.description && <p className="application-view-segment__description" dangerouslySetInnerHTML={{  __html: question.description }}></p> }
                                                </div>
                                                {this.getQuestionType(id, question.id, question)}
                                            </div>
                                            <div className="application-view-segment">
                                                <MediaQuery minWidth={1300}>

                                                    {
                                                        this.canBeCommented() &&
                                                        <>
                                                            {questionComments.length > 0 ? unread.length > 0 ?
                                                                <CommentButton type="unread"
                                                                               onClick={() => this.props.setChatbox(true, question)}>{unread.length} unread
                                                                    comment(s)</CommentButton> :
                                                                <CommentButton
                                                                    onClick={() => this.props.setChatbox(true, question)}>{questionComments.length} Comments</CommentButton> :
                                                                <CommentButton type="nocomments"
                                                                               onClick={() => this.props.setChatbox(true, question)}>No
                                                                    Comments</CommentButton>}
                                                        </>
                                                    }

                                                    {
                                                        this.canBeAmended() &&
                                                        <AmendLock toggled={amend}
                                                                   busy={this.state.amendBusy === question.id}
                                                                   onClick={() => this.handleAmmendClick(question.id)}/>
                                                    }
                                                    { this.showFinalQuestionScore() && isManager && this.showQuestionButton(block, question) &&
                                                        <div className={'application-view-segment__item'}>
                                                            <ScoreButton color={questionScoreMeta.color}  onClick={() => this.setShowFinalQuestionScores({
                                                                        question,
                                                                        section_id: this.props.section.id,
                                                                        block_id: block.id,
                                                                        question_id: question.id,
                                                                        block: block,
                                                                        isScorer: Auth.isAssessor(this.props.scorers)
                                                                })}>
                                                                {questionScoreMeta.button_text}
                                                            </ScoreButton>
                                                        </div>
                                                    }
                                                    {
                                                        (this.canBeScored() && this.showQuestionButton(block, question)) &&
                                                        <div className={'application-view-segment__item'}>
                                                            <ScoreButton color={questionScoreMeta.color}
                                                                         onClick={() => this.setQuestionScoring({
                                                                             question,
                                                                             section_id: this.props.section.id,
                                                                             block_id: block.id,
                                                                             question_id: question.id,
                                                                             block: block,
                                                                             isScorer: Auth.isAssessor(this.props.scorers)
                                                                         })}>
                                                                {questionScoreMeta.button_text}
                                                            </ScoreButton>
                                                            <div className="application-view-segment__item-meta">
                                                                {questionScoreMeta.meta_text}

                                                            </div>
                                                        </div>

                                                    }

                                                </MediaQuery>

                                                <MediaQuery maxWidth={1299}>

                                                    {
                                                        this.canBeCommented() &&
                                                        <>
                                                            {questionComments.length > 0 ? unread.length > 0 ?
                                                                <CommentButton mobile={true} type="unread"
                                                                               onClick={() => this.props.setChatbox(true, question)}>{unread.length} unread
                                                                    comment(s)</CommentButton> :
                                                                <CommentButton mobile={true}
                                                                               onClick={() => this.props.setChatbox(true, question)}>{questionComments.length} Comments</CommentButton> :
                                                                <CommentButton mobile={true} type="nocomments"
                                                                           onClick={() => this.props.setChatbox(true, question)}>No
                                                                    Comments</CommentButton>}
                                                        </>
                                                    }

                                                    {
                                                        this.canBeAmended() &&
                                                        <AmendLock toggled={amend}
                                                                   busy={this.state.amendBusy === question.id}
                                                                   onClick={() => this.handleAmmendClick(question.id)}/>
                                                    }

                                                    {
                                                        (this.canBeScored() && this.showQuestionButton(block, question)) &&
                                                        <div className={'application-view-segment__item'}>

                                                            <ScoreButton
                                                                color={questionScoreMeta.color}
                                                                mobile={true}
                                                                onClick={() => this.setQuestionScoring({
                                                                    question,
                                                                    section_id: this.props.section.id,
                                                                    block_id: block.id,
                                                                    question_id: question.id,
                                                                    block: block,
                                                                    isScorer: Auth.isAssessor(this.props.scorers)

                                                                })}>
                                                                {questionScoreMeta.button_text}

                                                            </ScoreButton>
                                                            <div className="application-view-segment__item-meta">
                                                                {questionScoreMeta.meta_text}
                                                            </div>
                                                        </div>

                                                    }

                                                </MediaQuery>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
}

class ApplicationView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sections: [],
            selected_section: 0,
            application: null,
            applicationVersion: 0,
            showPopup: false,
            showChatbox: false,
            question: null,
            comments: [],
            scorers: [],
            scores: [],
            allScores: [],
            finalScores: [],
            total_application_score: null,
            toggleDocumentUploads: false,
            amends: [],
        }

        this.origin = new URL(window.location).origin;
        this.api = new ApplicationApi();
    }

    componentDidMount() {
        this.fetchApplication();
        this.fetchComments();
        this.fetchScores();
        this.fetchScorers();
        this.fetchAmends();
        this.fetchFinalScores();
    }

    fetchAmends() {
        this.api.getAmends(this.props.applicationId).then((data) => {
            this.setState({ amends: data });
        })
    }

    fetchFinalScores() {
        this.api.allFinalScores(this.props.applicationId).then(({ data }) => {
            this.setState({ finalScores: data });
        })
    }

    fetchApplication() {
        this.fetchAmends();

        this.api.get(this.props.applicationId).then(data => {
            let sections = [ ...data.grant_version.config.sections ];

            sections.unshift({
                name: 'Eligibility',
            });

            this.props.onLoad(data);

            this.setState({application: data, sections: sections});
        });

    }

    fetchComments() {
        this.api.getComments(this.props.applicationId).then(({data}) => {
            this.setState({comments: data})
        });
    }

    getGrantConfig() {
        return this.state.application.grant_version.config;
    }

    fetchScores() {
        this.api.scoreBySection(this.props.applicationId).then(({data}) => {
            this.setState({scores: data})
        });
        this.api.allScores(this.props.applicationId).then((data) => {
            this.setState({allScores: data}, () => {
                this.setTotalApplicationScoreFromState();
            });
        })
    }


    usersScores() {
        if (this.state.allScores.hasOwnProperty('scores') === false) {
            return [];
        }
        return this.state.allScores?.scores.filter(item => {
            return item.user_id === Auth.user.id;
        });
    }

    fetchScorers() {
        this.api.getScorers(this.props.applicationId).then(data => {
            this.setState({scorers: data});
        });
    }

    setTotalApplicationScoreFromState() {
        const blockScores = this.state.allScores.block_scores.map(item => {
            return item.percentage_score;
        });

        this.setState({
            total_application_score: blockScores.reduce((acc, value) => {
                return acc + value;
            }, 0)

        });
    }

    toggleDocumentUploads(bool) {
        console.log('toggling', bool);

        this.setState({
            toggleDocumentUploads: bool
        })
    }

    setPopup(pop) {
        this.setState({
            showPopup: pop
        })
    }

    setSection(tab) {
        this.setState({selected_section: tab})
    }

    setChatbox(bool, question = null) {
        this.setState({showChatbox: bool, question});
    }

    onVersionChange({value}) {
        this.setState({applicationVersion: value});
    }

    getTotalQuestions() {
        let count = 0;
        this.getGrantConfig()?.sections.forEach(section => {
            section.blocks.forEach(block => {

                block.questions.forEach(q => {
                    if (QuestionCanBeScored(block, q)) {
                        count++;
                    }
                });
            })
        });

        return count;
    }

    getAccessorCount() {
        return this.props.allScores.scores.filter(score => {
            return score.user_id === Auth.user.id;
        }).length;
    }


    getStatusHeader() {
        const status = this.state.application?.status;
        if (this.state.application?.accepted === 1) {
            return <StatusAccepted
                application={this.state.application}
                toggleDocumentUploads={this.toggleDocumentUploads.bind(this)}
                onRefresh={() => {
                    this.fetchApplication();
                }}
            />;
        }
        if (this.state.application?.rejected === 1) {
            return <StatusRejected
                application={this.state.application}
                toggleDocumentUploads={this.toggleDocumentUploads.bind(this)}
            />;
        }

        if (status === 'in progress') {
            return (
                <GrantStatusHeader
                    title={'Application In Progress'}
                    type={'warning'}
                    message={<p>This application is currently being prepared for submission by the applicant</p>}
                />
            )
        }

        if (status === 'pending') {
            return (
                <GrantStatusHeader
                    title={'Ready to score'}
                    message={<p>This application is ready to be scored. Click 'Start Scoring' to start the process. If
                        you are the Grant Manager, please ensure that you have added the Assessors to this
                        application.</p>}
                />
            )
        }

        if (status === 'amendments required') {
            return (
                <GrantStatusHeader
                    title={'Amendments Required'}
                    type={'warning'}
                    message={<p>This application has been returned to the applicant to review questions and amend. You
                        will be notified once their amendments have been submitted.</p>}
                />
            )
        }

        if (status === 'scoring') {
            return (
                <GrantStatusHeader
                    title={'Scoring'}
                    message={<p>This application is currently being scored by the Assessment team and Grant Manager(s).
                        A decision will be made by the Grant Manager once all required scores have been submitted.</p>}
                />
            )
        }

        return '';
    }


    canBeScored() {
        return this.state.application.status === 'scoring';
    }

    canBeAmended() {
        const status = this.state.application.status;

        if (status === 'pending') {
            return true;
        }
        if (status === 'amendments required') {
            return true;
        }

        if (status === 'scoring') {
            return true;
        }

        return false;
    }

    canStartScoring() {
        const status = this.state.application.status;

        if (status === 'pending') {
            return true;
        }

        if (status === 'amendments required') {
            return true;
        }

        return false;
    }

    startScoring() {
        this.api.startScoring(this.state.application.id).then(() => {
            AlertManager.success('Scoring can now be started');
            this.setPopup(null);
            this.fetchApplication();
        })
    }

    handleAcceptance() {
        AlertManager.success('Application Accepted');
        this.fetchApplication();
        this.setPopup(null);
    }

    handleRejection() {
        AlertManager.success('Application Rejected');
        this.fetchApplication();
        this.setPopup(null);
    }

    canAddAccessors() {
        const isManager = AuthAPI.isManagerOfGrant(this.state.application.grant.managers);

        const accepted = this.state.application.accepted === 1;
        const rejected = this.state.application.rejected === 1;

        return isManager && (accepted === false && rejected === false);
    }

    handleExportButton () {
        const status = this.state.application.status;

        switch (status) {
            case ('pending') :
            case ('scoring') :
            case ('amendments required') :
                return true;
            default :
                return false;
        }
    }

    setSectionNumbers(sections) {

        let blockNumber = 1;
        sections.forEach(section => {
           section.blocks.forEach(block => {
                block.number = blockNumber;
                blockNumber ++;
           })
        });
    }


    render() {
        if (this.state.application == null) return '';

        const {created_by, grant, latest_application_versions, grant_version} = this.state.application;
        const {sections} = grant_version.config;
        this.setSectionNumbers(sections);

        const {latest_grant_versions} = grant;

        const latest = latest_application_versions[this.state.applicationVersion];
        const {created_at, updated_at} = latest;
        const companyDetails = latest.company_details || {};


        const versions = latest_application_versions.map(({id}, index) => ({
            label: `${id === this.state.application?.final_version_id ? 'Final Submitted Version - ' : ''}${id}`,
            value: index,
        }))


        return (
            <>
                {this.state.showPopup && this.state.showPopup}

                {this.state.showChatbox &&
                <Chatbox onComment={this.fetchComments.bind(this)} application={this.state.application}
                         fetchComments={this.fetchComments.bind(this)} question={this.state.question}
                         setPopup={this.setChatbox.bind(this)}/>}

                {this.state.toggleDocumentUploads &&
                <FileUploader toggled={this.state.toggleDocumentUploads}
                              onlyShow={['browse', 'upload']}
                              onClose={() => this.toggleDocumentUploads(false)}
                              params={{
                                  path: `/api/applications/${this.props.applicationId}/files`,
                              }}
                              path={`/api/applications/${this.props.applicationId}/files`}
                              endpoint={`/api/applications/${this.props.applicationId}/files`}
                              onBrowserSelect={item => {
                                    item.deletePath = `/api/applications/${this.props.applicationId}/files/${item.id}`;
                                      item.downloadOnly = true;
                                      item.imagePreview = false;
                                      item.downloadPath = `/applications/${this.props.applicationId}/files/${item.file.name}`;
                              }}
                />
                }


                { this.getStatusHeader() }

                <div className="grant-application-view__top">
                    <div className="grant-application-view__information">
                        <InformationSection title="Application Owner">
                            <p>{created_by?.name}</p>
                        </InformationSection>
                        <InformationSection title="Business">
                            <p>{companyDetails.name}</p>
                        </InformationSection>
                        <InformationSection title="Business Type">
                            <p>{companyDetails.profile?.company_type}</p>
                        </InformationSection>
                        <InformationSection title="Email">
                            <p>{companyDetails.profile?.email}</p>
                        </InformationSection>
                        <InformationSection title="Telephone No.">
                            <p>{companyDetails.profile?.tel_no}</p>
                        </InformationSection>
                        <InformationSection title="Application Deadline">
                            <p>{stringToFriendlyDatetime(grant.available_to)}</p>
                        </InformationSection>

                        <InformationSection title="Application Started">
                            <p>{stringToFriendlyDatetime(this.state.application?.created_at)}</p>
                        </InformationSection>
                        <InformationSection title="Last Updated">
                            <p>{stringToFriendlyDatetime(this.state.application?.updated_at)}</p>
                        </InformationSection>
                        <InformationSection title="Business Address">
                            <p>
                                {companyDetails.profile?.address_one},
                                {companyDetails.profile?.address_two},
                                {companyDetails.profile?.address_city},
                                {companyDetails.profile?.address_post_code}

                            </p>
                        </InformationSection>


                    </div>

                    <div className="grant-application-view__buttons">
                        <div className="utils__row utils__gap--20">

                            {
                                this.canBeScored() &&
                                <>
                                    <InformationSection title="Your question scoring progress">
                                        <p>{this.usersScores().length}/{this.getTotalQuestions()}</p>
                                    </InformationSection>
                                </>
                            }

                            {

                                this.canAddAccessors() &&
                                <>
                                    {this.state.application?.accepted !== 1 &&
                                    <AddButton responsive={false} onClick={() => this.setPopup(<GrantApplicationAssessors grant={grant}
                                                                                                       application={this.state.application}
                                                                                                       setPopup={d => {
                                                                                                           this.setPopup(d);
                                                                                                           this.fetchScorers();
                                                                                                       }
                                                                                                       }/>)}>Assessors</AddButton>}
                                    {
                                        this.canBeScored() &&
                                        <SendButton responsive={false} onClick={() => this.setPopup(<GrantApplicationDecision
                                            onAcceptance={this.handleAcceptance.bind(this)}
                                            onRejection={this.handleRejection.bind(this)}
                                            application={this.state.application}
                                            grant={grant}
                                            totalApplicationScore={this.state.total_application_score}

                                            setPopup={this.setPopup.bind(this)}/>)}>Make
                                            Decision</SendButton>
                                    }
                                    {
                                        this.canStartScoring() &&
                                        <SendButton responsive={false} onClick={() => this.setPopup(<GrantApplicationStartScoring
                                            setPopup={this.setPopup.bind(this)}
                                            confirm={this.startScoring.bind(this)}/>)}>Start Scoring</SendButton>

                                    }

                                </>

                            }
                            {
                                this.handleExportButton() &&  <ExportButton responsive={false} onClick={() => window.location = `/grants/applications/${this.state.application.id}/pdf`}>Export as PDF</ExportButton>
                            }

                        </div>


                        <div className="utils__row utils__gap--20">
                            {this.canBeScored() && <div className="utils__row utils__gap--20 input__row">
                                <p>Add Total Application Score</p>
                                <Input onChange={e => this.setState({total_application_score: e.target.value})}
                                       value={this.state.total_application_score}/>
                            </div>}
                            <HistoryButton responsive={false} onClick={() => this.setPopup(<GrantApplicationProgress
                                application={this.state.application} setPopup={this.setPopup.bind(this)}/>)}>Application
                                History</HistoryButton>
                            <Dropdown placeholder="Update Version" value={versions[this.state.applicationVersion]}
                                      items={versions} onChange={this.onVersionChange.bind(this)}/>
                        </div>


                    </div>
                </div>


                <StickyContainer offset={0} offsetFrom='.application-status' offsetTo='.tab-bar__bar'>
                    <TabBar>
                        { this.state.sections.map((section, index) => {
                            let unreadComments = []
                            let sectionAmends = []
                            let sectionComments = [];
                            let sectionAllAmends = [];

                            if (index == 0) {
                                return <SmallPill key={index} onClick={() => this.setSection(index)}>{ section.name }</SmallPill>
                            }


                            section.blocks.forEach((block) => {
                                const questionComments = this.state.comments
                                    .filter((comment) => block.questions.find(bq => bq.id == comment.question_id));

                                sectionComments = [...sectionComments, ...questionComments];

                                const unreadQuestionComments = questionComments.filter((comment) => comment.read_by == null && comment.read_by_id == null && (comment.user.super_admin === 0 && comment.user.admin === 0));
                                unreadComments = [...unreadComments, ...unreadQuestionComments];


                                const questionWithAmends = block.questions.filter((question) => this.state.amends.find(amend => amend.question_id === question.id));

                                questionWithAmends.forEach(question =>{
                                    const amends = this.state.amends.filter(amend => {
                                        return (amend.question_id === question.id && amend.response == null && amend.responded_by == null)
                                    });

                                    amends.forEach(amend => sectionAmends.push(amend));
                                });


                                sectionAllAmends = [...sectionAllAmends, ...questionWithAmends];
                            });


                            return <SmallPill key={index} onClick={() => this.setSection(index)}>
                            <div className={'small-pill__inner'}>
                                { (sectionComments.length >= 1 || sectionAllAmends.length >= 1)  &&
                                <div className="pill__spans">
                                    {sectionComments.length > 0 &&
                                    <div
                                        className={`small-pill__span-item ${unreadComments.length >= 1 ? 'small-pill__span-item--with-margin' : ''}`}>
                                        <svg className={'small-pill__icon'} data-name="Group 1025"
                                            xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                            viewBox="0 0 15 15">
                                            <path id="Path_379" data-name="Path 379" d="M0,0H15V15H0Z"
                                                fill="none"/>
                                            <path id="Path_380" data-name="Path 380"
                                                d="M11.929,5.121a3.168,3.168,0,0,0,1.889.224V11.45a.572.572,0,0,1-.591.55H2.591A.572.572,0,0,1,2,11.45V2.65a.572.572,0,0,1,.591-.55h7.741a2.564,2.564,0,0,0-.059.55,2.629,2.629,0,0,0,.7,1.783L7.945,6.876,4.155,3.881l-.765.838L7.952,8.324Zm1.3-.821a1.715,1.715,0,0,1-1.773-1.65A1.777,1.777,0,0,1,15,2.65,1.715,1.715,0,0,1,13.227,4.3Z"
                                                fill="#fff"/>
                                        </svg>
                                        {
                                            unreadComments.length >= 1 &&
                                            <div className={'small-pill__span-item-counter'}>
                                                {unreadComments.length}
                                            </div>
                                        }

                                    </div>
                                    }
                                    {
                                        sectionAllAmends.length > 0 &&
                                        <div
                                            className={`small-pill__span-item ${sectionAmends.length >= 1 ? 'small-pill__span-item--with-margin' : ''}`}>

                                            <svg className={'small-pill__icon'} id="Group_1027"
                                                data-name="Group 1027"
                                                xmlns="http://www.w3.org/2000/svg" width="16.133"
                                                height="16.133" viewBox="0 0 16.133 16.133">
                                                <path id="Path_358" data-name="Path 358"
                                                    d="M0,0H16.133V16.133H0Z" fill="none"/>
                                                <path id="Path_359" data-name="Path 359"
                                                    d="M5.165,6.665H12.2a.563.563,0,0,1,.541.583v5.833a.563.563,0,0,1-.541.583H3.541A.563.563,0,0,1,3,13.081V7.248a.563.563,0,0,1,.541-.583h.541V6.081A4.033,4.033,0,0,1,7,2.106a3.706,3.706,0,0,1,4.259,2.149l-.968.521A2.647,2.647,0,0,0,7.249,3.242a2.881,2.881,0,0,0-2.084,2.84ZM4.082,7.831V12.5h7.576V7.831Zm2.706,1.75H8.953v1.167H6.788Z"
                                                    transform="translate(0.196 -0.171)" fill="#fff"/>
                                            </svg>
                                            {
                                                sectionAmends.length >= 1 &&
                                                <div className={'small-pill__span-item-counter'}>
                                                    {sectionAmends.length}
                                                </div>
                                            }

                                        </div>

                                    }

                                </div>
                                }
                                <div className={'small-pill__text'}>
                                    { section.name }
                                </div>
                                <div className={'small-pill__arrow'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.792" height="11.412" viewBox="0 0 6.792 11.412">
                                        <g id="Button_-_Arrow" data-name="Button - Arrow" transform="translate(0.706 0.706)">
                                            <path id="Path_2" data-name="Path 2" d="M11.5,45.5l-5,5.586L1.5,45.5" transform="translate(-45.5 11.5) rotate(-90)" fill="none" stroke="#fcf7ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </div>

                        </SmallPill>
                        })}
                    </TabBar>
                </StickyContainer>

                {this.state.selected_section == 0 && this.state.application !== null
                    ? <GrantsApplicationReview grant={grant} application={this.state.application}/>
                    : <GrantsApplicationSection versions={latest_application_versions}
                                                application={this.state.application}
                                                refreshApplication={this.fetchApplication.bind(this)}
                                                latestGrantVersions={latest_grant_versions}
                                                applicationVersion={this.state.applicationVersion}
                                                sectionId={this.state.selected_section - 1}
                                                section={sections[this.state.selected_section - 1]}
                                                setChatbox={this.setChatbox.bind(this)}
                                                canBeScored={this.canBeScored()}
                                                canBeAmended={this.canBeAmended()}
                                                comments={this.state.comments}
                                                scores={this.state.scores}
                                                allScores={this.state.allScores}
                                                scorers={this.state.scorers}
                                                finalScores={this.state.finalScores}
                                                fetchAmends={this.fetchAmends.bind(this)}
                                                amends={this.state.amends}
                                                onScoreChange={() => {
                                                    this.fetchScores();
                                                }}
                    />
                }
            </>
        )
    }

}

class GrantApplicationView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            crumbs: [
                {
                    name: 'Grants',
                    link: '/grants'
                },
                {
                    name: 'Applications',
                    link: '/grants/applications'
                }
            ]
        };
    }


    render() {
        return (
            <PageLayout
                topLeft={<Breadcrumb crumbs={this.state.crumbs}/>}
                topRight={<Sponsors/>}
            >
                <div className={'grant-application-view'}>
                    <MinMaxLayout
                        main={<ApplicationView
                            onLoad={application => {
                                this.setState({
                                    crumbs: [
                                        {
                                            name: 'Grants',
                                            link: '/grants'
                                        },
                                        {
                                            name: 'Applications',
                                            link: '/grants/applications'
                                        },
                                        {
                                            name: `#${application.name ? application.name.toUpperCase() : ' '}`,
                                            link: '#'
                                        }
                                    ]
                                });
                            }}
                            applicationId={this.props.applicationId}
                        />}
                    />
                </div>
            </PageLayout>
        );
    }
}


const el = document.getElementById('grant-application-view');

if (el)
{
    ReactDOM
        .render(
            <GrantApplicationView applicationId={
                el
                    .getAttribute(
                        'data-application-id'
                    )
            }

            />,
            el
        )
    ;
}
