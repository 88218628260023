import React, {Component} from 'react';
import CreatableSelect from "react-select/creatable";

class CustomCreatableSelect extends Component {
    render() {
        return (
            <div id={this.props.name} className="input-outer">
                <label htmlFor={this.props.name}>{this.props.placeholder}</label>
                <CreatableSelect
                    {...this.props}
                />

            </div>
        );
    }
}


export default CustomCreatableSelect;
