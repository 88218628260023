import NavButton from './nav-button/nav-button';

export default ({children, tabs = [], tab, setTab = () => {}}) => {
    return (
        <div class="tab-bar__bar">
            {!children && tabs.map((name, index) => {
                return <NavButton key={index} disabled={index !== tab} onClick={() => setTab(index)}>{name}</NavButton>
            })}
            { children && children }
        </div>
    )
}
