import { useEffect, useState } from 'react';

import Popup from '../../../../components/popup';

import { CancelButton, ProceedButton } from '../../../../components/buttons';

export default ({ setPopup, confirm = () => {} }) => {
    return (
        <Popup type="application-start-scoring" onClose={setPopup}>
            <div class="popup__inner">
                <h1>Are you sure you want to start scoring?</h1>
                <p>Any open amends will be closed</p>

                <div class="popup__buttons">
                    <ProceedButton responsive={false} onClick={confirm} color="green">Confirm</ProceedButton>
                    <CancelButton  responsive={false} onClick={() => setPopup(null)}>Cancel</CancelButton>
                </div>
            </div>
        </Popup>
    )
}
