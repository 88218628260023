import { useContext, useState } from 'react';

import IndexCard from '../../../components/index/index-card';

import { ModifyButton, ProceedButton, ViewButton, DeleteButton } from "../../../components/buttons";
import { stringToFriendlyDatetime } from "../../../lib/helpers/datetime-helpers";

import ELearningView from './popups/e-learning-view';
import ELearningArchive from './popups/path/path-archive';

import ELearningAPI from '../../../lib/e-learning/e-learning-api';

import PopupContext from '../../../context/context-popup';

export default function PathList({ paths, fetch }) {
    const { setPopup } = useContext(PopupContext);

    const archive = (id) => {
        setPopup(<ELearningArchive path={id} setPopup={setPopup} fetch={fetch}/>);
    }

    return (
        <div className="grant-cards">
            { paths.map((path, index) => {

                return <IndexCard
                    key={index}
                    title={path?.name}
                    subtitle={'Category'}
                    meta={''}
                    info={'# Courses'}
                    status={path?.published ? <p className="color--green">Active</p> : <p className="color--red">Inactive</p>}
                    buttons={
                        <>
                            <ViewButton onClick={() => setPopup(<ELearningView setPopup={setPopup} path={path}/>)}>View</ViewButton>
                            <div className="utils__row">
                                <ModifyButton mobile={true} onClick={() => window.location.href = '/e-learning/learning-paths/' + path.id}/>
                                <DeleteButton mobile={true} onClick={() => archive(path?.id)}/>
                            </div>
                        </>

                    }
                />
            })}
        </div>
    )
}
