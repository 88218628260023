
import Popup from '../../../../components/popup';
import InputCurrency from '../../../../components/input-currency';
import InputsTextArea from '../../../../components/inputs/inputs-text-area';


import { ProceedButton, CancelButton} from '../../../../components/buttons';
import ApplicationApi from "../../../../lib/applications/application-api";


import React from 'react';
import List from '../../../../components/list/list';

export default class GrantApplicationDecisionApprove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            amount_rewarded: null,
            acceptance_message: '',
            errors: {}
        };

        this.api = new ApplicationApi();
    }



    handleSubmit() {
        this.api.accept(this.props.application.id, {
            total_application_score:  this.props.totalApplicationScore,
            amount_rewarded : this.state.amount_rewarded,
            acceptance_message: this.state.acceptance_message,
        }, (errors) => {
            this.setState({ errors });
        }).then(() => {
            this.props.onAcceptance();
        });
    }

    onChange(e) {
        const { name, value } = e.target;
        const state = { ...this.state }

        state[name] = value;
        
        this.setState(state);
    }

    render() {
        const {rewardedError, rewarded} = this.state;
        const { grant } = this.props;
        return (
            <Popup type="application-decision application-decision__approve">
                <div class="popup__inner">
                    <h1>You have chosen to approve application <span>{ grant.name }</span></h1>
                    <p>Once you confirm to proceed, this application will be submitted for claims processing and this
                        cannot be reversed.</p>

                    <div className="application-decision-approve__content">
                        <List gap="20px">
                            <InputCurrency 
                                currencyType='GBP' 
                                label="Amount Rewarded" 
                                name="amount_rewarded"
                                value={this.state.amount_rewarded}
                                error={this.state.errors?.amount_rewarded}
                                onChange={this.onChange.bind(this)}
                            />
                            <InputsTextArea
                                name="acceptance_message"
                                label="This amount awarded should reflect the application terms and conditions for VAT being inclusive or exclusive."
                                onChange={this.onChange.bind(this)}
                                value={this.state.acceptance_message}
                                errors={this.state.errors?.acceptance_message}
                            />
                        </List>
                    </div>

                    <p>Once you confirm to proceed, the applicant will be notified of the application being accepted, and The comments above will be made visible to them.</p>

                    <div class="popup__buttons">
                        <ProceedButton responsive={false} onClick={this.handleSubmit.bind(this)}>Proceed</ProceedButton>
                        <CancelButton responsive={false} onClick={() => this.props.setPopup(null)}>Cancel</CancelButton>
                    </div>
                </div>
            </Popup>
        );
    }


}
