import { PopupProvider } from "../../context/context-popup";

export default function PageLayout({ children, topLeft, topRight, middle, bottom }) {
    return (
        <PopupProvider>
            <div className="layout-container">
                <div className="layout__topbar">
                    { topLeft }
                    { topRight }
                </div>

                { middle && <div className="layout__middle">{ middle }</div> }

                <div className="layout">
                    { children }
                </div>

                { bottom && <div className="layout__bottom">{ bottom }</div> }
            </div>
        </PopupProvider>
    )
}
