import {Component} from 'react';

import SidePopup from '../../../components/side-popup';
import SidePopupSection from '../../../components/side-popup-section';

import Input from '../../../components/input-with-label';
import TextArea from '../../../components/textarea-with-label';

import GrantsApi from '../../../lib/grants/grants-api';

import Dropdown from '../../../components/dropdowns/dropdown';

import CustomSwitch from '../../../components/ui/custom-switch';

import {SaveButton} from '../../../components/buttons';

export default class GrantQuestionScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                scoring_range: [],
                scoring_criteria: '',
                max_score : null,
                comments_enabled: false,
            }
        }

        this.head = (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Add Scoring</p>
                    <span>></span>
                    <p>{this.props.breadcrumb}</p>
                </div>
                <SaveButton onClick={this.handleSave.bind(this)}></SaveButton>
            </div>
        )
    }

    componentDidMount() {
        this.setState({values: Object.assign({}, this.state.values, this.props.question)});
    }

    handleSave() {
        this.updateQuestion(this.state.values);
        this.props.onClose();
    }

    toggleComments() {
        this.setState({values: Object.assign({}, this.state.values, {comments_enabled: !this.state.values.comments_enabled})});
    }

    updateQuestion(params) {
        const {sectionIndex, blockIndex, grantStateHandler, grantVersion} = this.props;

        grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, this.props.questionIndex,  params);
    }

    onChange(e) {
        let {name, value} = e.target;

        if (name == 'grant_value') {
            value = Number(value);
        }

        this.setState({values: Object.assign({}, this.state.values, {[name]: value})});
    }

    renderScoringRange(values) {
        return (
            <SidePopupSection title="Scoring range">
                <div className="side-popup-content__section">
                    <p>Please select the highest number that can be scored for this question</p>
                    <Dropdown
                        value={values.max_score}
                        placeholder='Select max score'
                        onChange={selected => {

                            this.setState({values : Object.assign({}, this.state.values, {
                                    scoring_range:  []
                                })}, () =>  this.onChange({ target : { name : 'max_score', value : selected.value}}));
                        }}
                        items={[1,2,3,4,5,6,7,8,9,10].map(i =>  {
                            return {label : i, value : i};
                        })}
                    />
                </div>
                <div className="side-popup-content__section">
                    <p>Enter score label</p>
                    <div className="grant-score-scores">
                        { this.renderScoreInputs() }

                    </div>
                </div>
            </SidePopupSection>
        )
    }

    renderOverridenScoring() {
        return (
            <div className="override-scoring__outer">
                <img src="/assets/block-lock.svg" />
                <h4>Scoring range preset in Question Group Settings</h4>
                <p>To view the scoring criteria for this question, please click the scoring icon on the question group.

                If you need to change the scoring on this or another question, please unselect “Questions to have the same scoring range?” In the Question group scoring settings. </p>
            </div>
        )
    }

    renderScoreInputs() {
        let items = [];

        if(this.state.values.max_score < 1) {
            return [];
        }
        for(let i = 0; i < (this.state.values.max_score + 1); i ++) {

            const rangeIndex = _.findIndex(this.state.values.scoring_range, r => {
                return r.value === i;
            });



            items.push(<Input name={i} value={rangeIndex !== -1 ? this.state.values.scoring_range[rangeIndex].label : ''} label={`Score ${i }`} placeholder={i} onChange={e => {

                let ranges = [...this.state.values.scoring_range];

                const value = { value : parseInt(e.target.name), label : e.target.value};
                if(rangeIndex !== -1) {
                    ranges[rangeIndex] = value;

                }else {
                    ranges.push(value);
                }

                this.setState({values : Object.assign({}, this.state.values, {
                        scoring_range: ranges
                    })}, () => console.log(this.state.values, ranges))

            }} />);
        }

        return items;
    }


    render() {
        const values = this.state.values;

        return (
            <SidePopup type="grant-details-popup" head={this.head} onClose={this.props.onClose} content={
                <>
                    { this.props.block.override_questions_scoring_range ? this.renderOverridenScoring() : this.renderScoringRange(values) }

                    <SidePopupSection title="Scoring Criteria">
                        <div className="side-popup-content__section">
                            <p>Please write the scoring criteria question for the assessor to follow:</p>
                            <Input value={values.scoring_criteria} name="scoring_criteria"
                                   placeholder="Please write the scoring criteria question for the assessor to follow"
                                   onChange={this.onChange.bind(this)}/>
                        </div>
                    </SidePopupSection>
                    {/*<SidePopupSection title="Settings">*/}
                    {/*    <div className="side-popup-content__section">*/}
                    {/*        <p>Enable Comments?</p>*/}
                    {/*        <CustomSwitch onChange={this.toggleComments.bind(this)} checked={values.comments_enabled}/>*/}
                    {/*    </div>*/}
                    {/*</SidePopupSection>*/}
                </>
            }/>
        )
    }
}
