import { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';

import SidePopup from "./side-popup"

export default  ({ set }) => {
    const [ messages, setMessages ] = useState([]);

    const Skeleton = () => {
        return (
            <>
                { new Array(20).fill(0).map((_, index) => {
                    return (
                        <ContentLoader key={index} viewBox="0 0 300 125">
                            <rect x="0" y="0" rx="20" ry="20" width="300" height="125" />
                        </ContentLoader>
                    )
                 }) }
            </>
        )
    }

    useEffect(() => {
        setMessages(new Array(10).fill(0))
    }, [])

    return (
        <SidePopup type="notifications-popup messages-popup" onClose={set}>
            <div class="notifications-popup__head">
                <div class="notifications-popup__start">
                    <h1 class="side-popup__title">Messages</h1>
                    <div class="notifications-popup__icon">{ messages.length }</div>
                </div>
                <div class="notifications-popup__links">
                    <p>Clear read messages</p>
                </div>
            </div>
            <div class="notifications-popup__content">
                { messages.length > 0 ? messages.map((_, index) => {
                    return (
                        <div class="notifications-item notifications-item--messages" key={index}>
                            <img src="/assets/user-icon.svg"/>
                            <div class="notifications-item__info">
                                <p class="notifications-item__date">00:00 | DD/MM/YY</p>
                                <div class="notifications-item__bar">
                                    <p class="notifications-item__name">User Name / Company Name</p>
                                    <p class="notifications-item__type">Source</p>
                                </div>
                                <div class="notifications-item__bar">
                                    <p class="notifications-item__description">Light description of notification.</p>
                                    <button class="notifications-item__button">></button>
                                </div>
                            </div>
                        </div>
                    )
                }) : <Skeleton/> }
            </div>
        </SidePopup>
    )
}
