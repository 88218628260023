import { Component } from 'react';

import SidePopup from '../../../components/side-popup';
import SidePopupSection from '../../../components/side-popup-section';

import Input from '../../../components/input-with-label';
import TextArea from '../../../components/textarea-with-label';

import GrantsApi from '../../../lib/grants/grants-api';

import Dropdown from '../../../components/dropdowns/dropdown';

import CustomSwitch from '../../../components/ui/custom-switch';

import { SaveButton } from '../../../components/buttons';

export default class GrantScoreGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                scoring_weight_percentage: false,
                override_questions_scoring_range: false,
                scoring_range: []
            }
        }

        this.head = (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Add Scoring</p>
                    <span>></span>
                    <p>{ this.props.breadcrumb }</p>
                </div>
                <SaveButton onClick={this.handleSave.bind(this)}></SaveButton>
            </div>
        )
    }

    componentDidMount() {

        this.setState({  values : Object.assign({}, this.state.values, this.props.block)});

    }

    handleSave() {
        this.updateBlock(this.state.values);
        this.props.onClose();
    }

    toggleScoringRange() {
        this.setState({ values: { ...this.state.values, override_questions_scoring_range: !this.state.values.override_questions_scoring_range } });
    }

    updateBlock(params) {
        const { sectionIndex, blockIndex, grantStateHandler, grantVersion } = this.props;

        grantStateHandler.updateBlock(grantVersion, sectionIndex, blockIndex, params);
    }

    onChange(e) {
        let { name, value } = e.target;

        if (name == 'grant_value') {
            value = Number(value);
        }

        if (name == 'total_percentage') {
            if (value < 0 || value > 100) return;
        }

        this.setState({ values: Object.assign({}, this.state.values, { [name] : value })});
    }


    renderScoreInputs() {
        let items = [];

        if (this.state.values.max_score < 1) {
            return [];
        }

        for(let i = 0; i < (this.state.values.max_score + 1); i ++) {

            const rangeIndex = _.findIndex(this.state.values.scoring_range, r => {
                return r.value === i;
            });



            items.push(<Input name={i} value={rangeIndex !== -1 ? this.state.values.scoring_range[rangeIndex].label : ''} label={`Score ${i}`} placeholder={i} onChange={e => {

                let ranges = [...this.state.values.scoring_range];

                const value = { value : parseInt(e.target.name), label : e.target.value};
                if(rangeIndex !== -1) {
                    ranges[rangeIndex] = value;

                }else {
                    ranges.push(value);
                }

                this.setState({values : Object.assign({}, this.state.values, {
                        scoring_range: ranges
                    })}, () => console.log(this.state.values, ranges))

            }} />);
        }

        return items;
    }


    render() {
        const values = this.state.values;
        return (
            <SidePopup type="grant-details-popup" head={this.head} onClose={this.props.onClose} content={
                <>
                    <SidePopupSection title="Scoring Weight (%)">
                        <div className="side-popup-content__section">
                            <p>What percentage of the total application does this group score? Percentage scores will be rounded to to closest decimal place</p>
                            <Input type="number" min={0} max={100} value={values.scoring_weight_percentage} name="scoring_weight_percentage" placeholder="%" onChange={this.onChange.bind(this)}/>
                        </div>
                    </SidePopupSection>
                    <SidePopupSection title="Settings">
                        <div className="side-popup-content__section">
                            <p>Questions to have the same scoring range</p>
                            <CustomSwitch onChange={this.toggleScoringRange.bind(this)} checked={values.override_questions_scoring_range} />
                        </div>
                    </SidePopupSection>
                    <SidePopupSection title="Set scoring range for all questions">
                        <div className="side-popup-content__section">
                            <p>Please select the number of scoring slots including the value of 0</p>
                            <Dropdown
                                value={values.max_score}
                                placeholder='Select number of scoring slots'
                                onChange={selected => {

                                    this.setState({values : Object.assign({}, this.state.values, {
                                            scoring_range:  []
                                        })}, () =>  this.onChange({ target : { name : 'max_score', value : selected.value}}));
                                }}
                                items={[1,2,3,4,5,6,7,8,9,10].map(i =>  {
                                    return {label : i, value : i};
                                })}
                            />
                        </div>
                        <div className="side-popup-content__section">
                            <p>Enter score label</p>
                            <div className="grant-score-scores">
                                { this.renderScoreInputs() }
                            </div>
                        </div>
                    </SidePopupSection>
                </>
            }/>
        )
    }


}
