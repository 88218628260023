export default ({
    children,
    name,
    type = '',
    label,
    value,
    placeholder,
    min,
    max,
    errors = [],
    onChange = () => {},
    readonly = false,
    select = false,
    switchToggled = false,
    onSelect = ()=> console.log('Selected')
 }) => {
     const change = (e) => {
        if (min !== null && e.target.value.length !== 0 && e.target.value < min) return;
        if (max!== null && e.target.value.length !== 0 && e.target.value > max) return;

        onChange(e);
     }

    return (
        <div id={name} class="input-outer">
            { label ? <label className={label ? 'utils__row' : ''} for={name}>{ label ? label : ''  } </label> : "" }

            { select ?
                <div class="input-outer__select">
                    <input min={min} max={max} type={type} class={errors.length > 0 ? "input-outer__error" : ""} name={name} value={value || ''} onChange={change}/>
                    <button onClick={onSelect}>Select</button>
                </div> :
                children ?  <div className="utils__row">
                                <input min={min} max={max} readOnly={readonly} type={type} class={errors.length > 0 ? "input-outer__error" : ""} placeholder={placeholder} name={name} value={value || ''} onChange={change}/>
                                { children }
                            </div> : <input min={min} max={max} readOnly={readonly} type={type} class={errors.length > 0 ? "input-outer__error" : ""} placeholder={placeholder} name={name} value={(value !== null) ? value :  ''} onChange={change}/>
            }

            { errors.length > 0 && <div class="input-outer__errors">{ errors.map((err, index) => <p key={index}>{ err }</p>) }</div> }

        </div>
    )
}
