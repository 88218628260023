import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../components/breadcrumb";
import PageLayout from "../../containers/layout/page-layout";
import { MinMaxLayout } from "../../containers/layout";

import ContentContainer from "../../components/layout/content-container";

import ResultsList from "../../components/results/results-list";
import ResultsListItem from "../../components/results/results-list-item";
import ResultsListItemMeta from '../../components/results/results-list-item-meta';

import GrantCards from './components/grant-cards';

import ActivityItem from '../../components/activity/activity-item';
import SearchBarItem from '../../components/search/search-bar-item';

import { CreateButton } from '../../components/buttons';

import Pills from './components/grant-application-pills';
import Filters from './components/grant-application-filters';
import Navigation from './components/grants-application-navigation';
import ResultListItemDetails from "../../components/results/results-list-item-details";
import NavButton from "../../components/nav-button/nav-button";

import GrantsApi from '../../lib/grants/grants-api';

import CreateGrant from './components/grant-create';
import ModifyGrant from './components/grant-modify';

import Sponsors from '../../components/sponsors';

const crumbs = [{
        name: 'Grants',
        link: ''
    }
];

class GrantsList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            grants: [],
            grantsMeta: {},
            currentPage: 1,
            createPopupShown: false,
        };
    }

    componentDidMount() {
        this.getGrants();
    }

    getGrants() {
        GrantsApi.getGrants(this.state.currentPage, 20).then(({ data, meta }) => {
            this.setState({ grants: data, grantsMeta: meta })
        })
    }

    refreshGrants() {
        this.getGrants();
    }

    createGrantClick() {
        this.setState({ createPopupShown: true });
    }

    closePopup() {
        this.setState({ createPopupShown: false });
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.refreshGrants());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.grantsMeta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    render() {

        return (
            <>
                { this.state.createPopupShown && <CreateGrant setPopup={this.closePopup.bind(this)} refreshGrants={this.refreshGrants.bind(this)}/> }

                <ContentContainer
                    title={'Grants'}
                    count={this.state.grantsMeta.total}

                    top={<div className="grant-index-top utils__rowSpaceBetween">
                            {/*<SearchBarItem color="search-bar--white"/>*/}
                            <CreateButton onClick={this.createGrantClick.bind(this)}>Create Grant</CreateButton>
                        </div>}

                    header={<><Filters/><Pills /></>}

                    bottom={<Navigation back={this.goBack.bind(this)}
                                        next={this.goNext.bind(this)}
                                        setPage={this.setPage.bind(this)}
                                        meta={this.state.grantsMeta}
                                        backDisabled={this.state.currentPage == 1}
                                        nextDisabled={this.state.currentPage == this.state.grantsMeta.last_page}/>}>

                        <GrantCards grants={this.state.grants}/>
                </ContentContainer>
            </>

        );
    }
}

const GrantIndex = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'grant-index'}>
                <MinMaxLayout main={<GrantsList/>}
                />
            </div>
        </PageLayout>

    );
}

export default GrantIndex;


const el = document.getElementById('grant-index');

if (el)
{
    ReactDOM.render(<GrantIndex/>, el);
}
