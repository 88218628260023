import AlertManager from '../../managers/alert/alert-manager';
import ApiBase from "../api-base";


class ContentApi extends ApiBase {

    constructor() {
        super();
        this.defaultError = 'There was an error handling your Content';
    }


    async getContent(id) {

        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/items/${id}`);
            return response.data.data;
        });
    }

    async deleteContent(id) {
        return this.handleResponse(async () => {
            return await axios.delete(`/api/content/items/${id}`)
        });
    }


    async getDistributionMaps(contentId) {
        return this.handleResponse(async () => {
            return await axios.get(`/api/content/items/${contentId}/distribution-maps`)
        });
    }

    async updateContent(contentId, data, validationCallback) {
        return this.handleResponse(async () => {
            return await axios.post(`/api/content/items/${contentId}`, data);
        }, validationCallback);
    }

    async getVersion(contentId, versionId) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/items/${contentId}/versions/${versionId}`);
            return response.data.data;
        });
    }

    async createNewVersion(contentId, version) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/content/items/${contentId}/versions`, version);
            return response;
        });
    }

    async updateContentVersion(contentId, version) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/content/items/${contentId}/versions/${version.id}`, version);
            return response.data.data;
        });
    }

    async lock(contentId) {
        return this.handleResponse(async () => {
            return await axios.post(`/api/content/items/${contentId}/lock`);
        })
    }

    async unlock(contentId) {
        return this.handleResponse(async () => {
            return await axios.post(`/api/content/items/${contentId}/unlock`);
        })
    }

    getContentVersions(contentId) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/items/${contentId}/versions?per_page=100`);
            return response.data.data;
        });
    }

    distributeVersion(contentId, versionId, distributionId, published = false) {
        return this.handleResponse(async () => {
            return await axios.post(`/api/content/items/${contentId}/versions/${versionId}/distribute`, {
                distribution_ids: [distributionId],
                published
            })
        });
    }

    unDistributeVersion(contentId, versionId, distributionId) {
        return this.handleResponse(async () => {
            return await axios.post(`/api/content/items/${contentId}/versions/${versionId}/un-distribute`, {
                distribution_ids: [distributionId]
            })
        });
    }

    getTags(page = 1, perPage = 100) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/tags`, {
                params: {
                    page: page,
                    per_page: perPage
                }
            });
            return response.data;
        });
    }

    storeTags(tags = []) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/content/tags`, {
                    tags
            });
            return response.data;
        });
    }

    getContentTypes(page = 1, perPage = 100) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/content-types`, {
                params: {
                    page: page,
                    per_page: 100
                }
            });
            return response.data;
        });
    }

    createNewContentType(params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/content/content-types`, params);
            return response.data.data;
        }, validationCallback);
    }

    getContentType(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/content-types/${id}`);
            return response.data.data;
        });
    }
    updateContentType(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/content/content-types/${id}`, params);
            return response.data.data;
        }, validationCallback);
    }

    deleteContentType(id) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/content/content-types/${id}`);
            return response.data.data;
        });
    }

}


export default new ContentApi();
