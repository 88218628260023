import { useEffect, useState, useContext } from 'react';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';

import Popup from '../../../../../components/popup';

import { CreateButton, CancelButton } from '../../../../../components/buttons';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';
import Divider from '../../../../../components/divider/divider';
import LabelContainer from '../../../../../components/label-container';

import Dropdown from "react-dropdown";
import PopupContext from '../../../../../context/context-popup';

export default function ELearningCourseCreate({ onSuccess }) {
    const { setPopup } = useContext(PopupContext);
    
    const [ name, setName ] = useState(null);
    const [ host, setHost ] = useState(null);

    const [ errors, setErrors ] = useState({});

    const create = async () => {
       new ELearningApi().createCourse({ name, host_name: host }, (err) => setErrors(err)).then(onSuccess)
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Create a course</h1>

                <Input label="Course Name" placeholder="Enter a value" value={name} onChange={(e) => setName(e.target.value)} errors={errors?.name}/>
                <Input label="Course Host" placeholder="Enter a value" value={host} onChange={(e) => setHost(e.target.value)} errors={errors?.host_name}/>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
