import ChevronRight from './chevron-right';

const Button = (props) => {
    const { 
        children, 
        className, 
        icon = null, 
        color = null, 
        responsive = true, 
        disabled = false, 
        mobile = false, 
        arrow = true, 
        toggled = true,
    } = props;

    let classes = `button ${className}`;

    if (mobile) classes += ' button--mobile';
    if (color) classes += ' button--' + color;
    if (responsive) classes += ' button--can-be-mobile';
    if (!toggled) classes += ' button--untoggled';
    if (disabled) {
        if (color) {
            classes += ` button--${color}--disabled`
        } else {
            classes += ` ${className}--disabled`;
        }
    }
    
    if (mobile && !icon) return new Error('[Button] Mobile buttons need to have icons');

    return (
        <button { ...props } className={classes} disabled={disabled}>
            <div>
                { icon !== null ? typeof icon == 'object' ? icon : <img src={`/assets/buttons/${icon}-icon.svg`}/> : "" }
                <p>{ children }</p> 
            </div>
            { arrow ? <ChevronRight/> : '' }
        </button>
    )
}

export default Button;
