import CustomDropdown from '../../../components/dropdowns/dropdown';
import NavButton from '../../../components/nav-button/nav-button';

export default function GrantApplicationNavigation({ backDisabled = false, nextDisabled = false, next, back, meta, setPage }) {
    const getOptions = () => {
        const items = new Array(meta?.last_page || 0).fill(0).map((_, index) => index + 1);

        const newItems = items.map((item) => ({
            label: item,
            value: item,
        }));

        return newItems;
    }

    const onChange = (e) => setPage(e.value);

    return (
        <div className="content-container-navigation__outer">
            <div className="content-container__navigation content-container-navigation__desktop">
                <NavButton type="previous" onClick={back} disabled={backDisabled}>Previous</NavButton>
                <CustomDropdown items={getOptions()} placeholder="Jump to page" icon="arrow" onChange={onChange}/>
                <NavButton type="next" onClick={next} disabled={nextDisabled}>Next</NavButton>
            </div>
            
            <div className="content-container__navigation content-container-navigation__mobile">
                <CustomDropdown items={getOptions()} placeholder="Jump to page" icon="arrow" onChange={onChange}/>

                <div className="row-gap">
                    <NavButton type="previous" onClick={back} disabled={backDisabled}>Previous</NavButton>
                    <NavButton type="next" onClick={next} disabled={nextDisabled}>Next</NavButton>
                </div>
            </div>
        </div>
    )
}