const { default: axios } = require('axios');

require('./bootstrap');
require('./dropdown-handler');
require('./sidebar-handler');
require('./search-handler');
require('./lib/media/upload-test');

require('./components/topbar');
require('./components/popups/images/images-browse');


//Containers
require('./containers/sidebar');

//Views

require('./views/dashboard');
require('./views/account');
require('./views/myteam');
require('./views/content-editor');
require('./views/companies');
require('./views/company');
require('./views/content-distributions');
require('./views/content-distributions-settings');
require('./views/content-distributions');
require('./views/content-distributions-settings');
require('./views/forgot-password');
require('./views/content-search');
require('./views/search-results');
require('./views/user');
require('./views/contacts');
require('./views/contact');
require('./views/contacts/index');
require('./views/content-types/content-types');
require('./views/content-types/content-type');
require('./views/content-types/helper');
require('./views/events/events');
require('./views/events/event');
require('./views/distributions/menu-edit');
require('./views/tasks');

require('./views/e-learning/views/dashboard/index');

require('./views/e-learning/views/course/course');
require('./views/e-learning/views/course/courses');

require('./views/e-learning/views/learning-paths/path');
require('./views/e-learning/views/learning-paths/index');

require('./views/e-learning/views/learners/learners');

require('./views/e-learning/views/course-works/course-works');
require('./views/e-learning/views/course-works/course-work');

require('./views/grants/grant-applications');
require('./views/grants/grant-application-view');

require('./views/grants/editor/grant-editor');
require('./views/grants/index');

require('./views/settings');
require('./views/messages/index');

require('./views/grants/grants-preview-status-bar');


const sidebar = document.querySelector(".sidebar");
const searchOuter = document.querySelector(".search-outer");

window.onresize = () => {
    const popup = document.querySelector('.popup');
    const popupInner = document.querySelector('.popup__inner');

    if (window.innerWidth >= 991) {
        if (searchOuter && searchOuter.style.display == "flex") searchOuter.style.display = "none";
        if (sidebar && sidebar.classList.contains("slide-out")) sidebar.classList.remove("slide-out");
    }
}

