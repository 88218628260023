import { useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import {stringToUTCDateTime} from "../lib/helpers/datetime-helpers";

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

function InputDate({ format = null, name, value, onChange }) {
    const [open, setOpen] = useState(false);

    let props = {}

    if (format !== null) {
        props.inputFormat = format;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                { ...props }
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                defaultValue={''}
                emptyLabel={''}
                label="DD/MM/YY HH:MM:SS"
                value={stringToUTCDateTime(value) || ''}
                onChange={(newValue) => {
                    let val = '';

                    if (newValue == null) return onChange({ target: { value: '' }});

                    if (newValue.hasOwnProperty('$d')) {
                        const { $d } = newValue;

                        val = $d == 'Invalid Date' ? '' : $d;
                    }

                    onChange({ target: { value: val }});
                }}
                renderInput={(params) => <TextField {...params} fullWidth onClick={(e) => setOpen(true)}/>}
            />
        </LocalizationProvider>
    )
}

export default function DateInput({ format, type, allowDateRange, value, onChange }) {
    let dateOne = null, dateTwo = null, startName, endName;

    if(Array.isArray(value)) {
        dateOne = value[0];
        dateTwo = value[1];
    } else {
        dateOne = value;
    }

    if (allowDateRange) {
        return (
            <div className="input-date__outer">
                <div className="input-date__container">
                    <InputDate value={dateOne} onChange={(e) => {               
                            onChange([ stringToUTCDateTime(e.target.value), stringToUTCDateTime(dateTwo) ])
                    }} type={type}/>

                    <img src="/assets/date-picker-arrow.svg" />

                    <InputDate value={dateTwo} onChange={(e) => {
                            onChange([ stringToUTCDateTime(dateOne), stringToUTCDateTime(e.target.value) ])
                    }} type={type}/>

                </div>
            </div>

        )

    } else {
        return (
            <div className="input-date__container">
                <InputDate format={format} value={value} onChange={(e) => {
                    onChange(stringToUTCDateTime(e.target.value));
                }} />
            </div>
        )
    }
}