class Auth {

    constructor(user) {
        this.user = user;
    }


    isSuperAdmin() {
        return this.user.super_admin === 1;
    }

    hasPermission(name) {

        if(this.user.super_admin === 1) {
            return true;
        }
        const index = _.findIndex(this.user.permissions, permission => {

           return permission.name === name;
        });

        return index !== -1;
    }

    isManagerOfGrant(managers) {
        return managers.find(manager => manager.id == this.user.id);
    }

    isAssessor(assessors) {
        return assessors.find(assessor => assessor.id == this.user.id);
    }
}


export  default new Auth(window.user);
