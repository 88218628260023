import { ViewButton, ModifyButton, ProceedButton } from '../../../components/buttons';

export default function GrantCard({title, subtitle, info, status, meta, buttons}) {
    // const { name, published } = grant;
    //
    // const fundingBody = grant?.funding_body || 'No funding body Set';
    //
    // const avaliableFrom = grant?.avaliable_from || 'Avaliable From';
    // const avaliableTo = grant?.avaliable_to || 'Avaliable To';
    //
    // const avaliableToFrom = avaliableFrom + ' to ' + avaliableTo;

    return (
        <div className="grant-card">
            <div className="grant-card__top">
                <p className="color--blue">{meta}</p>
                <p className="color--green">{ status }</p>
            </div>
            <div className="grant-card__header">
                <h4>{ title }</h4>
                <p>{ subtitle }</p>
            </div>
            <div className="grant-card__content">
                <p>{ info }</p>
            </div>
            <div className="grant-card__buttons">
                {buttons }
            </div>
        </div>
    )
}
