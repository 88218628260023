import { useEffect, useState } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';
import ContainerGrid from '../../../../../../containers/container-grid';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { AddButton, CancelButton } from '../../../../../../components/buttons';

export default function QuestionTypeMultipleChoice({
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({
            target: {
                name: 'config',
                value: config
            }
        });
    }

    const onButtonChange = (index, e) => {
        const body = question?.body !== null ? [ ...question?.body ] : [];
        const { name, value } = e.target;

        body[index][name] = value;

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const onButtonAnswerState = (index) => {
        let body = question?.correct_answers || [];

        if (body.includes(index)) {
            body = body.filter((item) => item !== index);
        } else {
            body.push(index);
        }

        onQuestionChange({
            target: {
                name: 'correct_answers',
                value: body,
            }
        })
    }

    const addButton = () => {
        const body = question?.body !== null ? [ ...question?.body ] : [];
        const index = body.length;

        body[index] = {
            value: null,
        }

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const removeButton = (index) => {
        let correct_answers = question?.correct_answers || [];
        let answers = [];

        const body = question?.body !== null ? [ ...question?.body ] : [];

        const buttonsWithAnswers = [ ...body ].map((item, index) => ({
            ...item,
            isAnswer: correct_answers?.includes(index)
        }))

        body.splice(index, 1);
        buttonsWithAnswers.splice(index, 1);

        buttonsWithAnswers.forEach((item, i) => {
            if (item.isAnswer) answers.push(i);
        })

        onQuestionChange({
            target: {
                name: 'correct_answers',
                value: answers,
            }
        })

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const renderButtons = () => {
        const arr = question?.body || [];

        return arr.map((_, index) => {
            const button = question?.body?.[index];

            const value = questions[button?.value];

            const pickedQuestion = {
                label: value?.title,
                value: button?.value,
            }

            return (
                <LabelContainer key={index} label={`Multi Choice Button ${index + 1}`} grow={1}>
                    <List gap="20px">
                        <Row>
                            <Input fill={true} value={button?.value} name="value" onChange={(e) => onButtonChange(index, e)}/>
                            <CancelButton mobile={true} onClick={() => removeButton(index)}/>
                        </Row>
                        <Row>
                            <Checkbox 
                                text="Correct Answer"
                                onChange={() => onButtonAnswerState(index)}
                                checked={question?.correct_answers?.includes(index)}
                            />
                        </Row>
                    </List>
                </LabelContainer>
            )
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <CodeMirror
                            height="300px"
                            value={question?.description || ''}
                            onChange={e => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: e,
                                    }
                                })
                            }}
                            extensions={[html()]}
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <AddButton onClick={addButton}>Add Checkbox</AddButton>
                    </Row>
                </>
            }/>

            <List gap="20px">
                <ContainerGrid>
                    { renderButtons() }
                </ContainerGrid>
            </List>

            <Input 
                label="Correct Answer Response" 
                placeholder="Input Value"
                value={question?.config?.correct_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'correct_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
            <Input 
                label="Incorrect Answer Response"
                placeholder="Input Value"
                value={question?.config?.incorrect_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'incorrect_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
        </QuestionType>
    )
}
