import React, {Component} from 'react';
import dayjs from "dayjs";

class EditorPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            html: null,
            distributionMap: null,
            loaded: true,
            size: 'desktop'
        };

        this.content;
    }


    filteredDistributions() {

        return this.props.distributionMaps.filter(d => {
            return (d.distribution.type_id === 1 && d.published === 1);
        });
    }

    componentDidMount() {
        this.setState({distribution: true});

    }


    handleDistributionsClick(distributionMap) {

        this.setState({distributionMap});
    }

    updateSelected() {
        if (!this.displayable()) {
            return;
        }

        if (this.state.loaded) {
            this.loadContentIdRequired();

            this.postMessage(Object.assign({}, this.getSelectedPropsAsConfig(), {'type': 'highlight'}));
        }

    }

    getSelectedPropsAsConfig() {
        const selected = {...this.props.selected};

        return  {
            content : selected.content ? { name : selected.content} : null,
            section : selected.section ? { name: selected.section} : null
        };
    }

    getContentItemData(section = {}, content = {}) {
        if(content === null || section === null) {
            return;
        }
        let contentVersion = {...this.props.contentVersion};
        let svi = _.findIndex(contentVersion.sections, s => {

            return s.name === section.name;
        });

        if (svi !== -1) {

            let sv = contentVersion.sections[svi];
            let ci = _.findIndex(sv.contents, c => {
                return c.title === content.name;
            });


            if (ci !== -1) {
                return sv.contents[ci].body;
            }
        }
        return '';
    }

    updateContent() {
        if (!this.displayable()) {
            return;
        }
        const selected = this.getSelectedPropsAsConfig();

        let data = Object.assign(
            {},
            selected,
            {
                'type': 'change',
                changeData: this.getContentItemData(
                    selected.section,
                    selected.content
                )
            });
        this.postMessage(data);

    }

    loadContentIdRequired() {
        if (!this.content) {
            this.content = this.iframe.contentWindow;
        }
    }

    postMessage(data) {
        if (this.state.loaded) {
            this.loadContentIdRequired();

            this.content.postMessage(data, this.state.distributionMap.distribution.domain);
        }
    }

    updateAllContent() {

        if (!this.displayable()) {
            return;
        }


        const contentVersion = {...this.props.contentVersion};

        contentVersion.sections.forEach(section => {

            section.contents.forEach(content => {

                let data = {
                    'type': 'change',
                    changeData: content.body,
                    content: {
                        name: content.title
                    },
                    section
                };
                this.postMessage(data)
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.selected !== this.props.selected) {
            this.updateSelected();
        }
        if (prevProps.contentVersion !== this.props.contentVersion) {
            this.updateContent();
        }

        if (prevProps.contentVersionId !== this.props.contentVersionId) {
            this.updateAllContent();
        }

        if (this.props.distributionMaps.length >= 1 && !this.state.distributionMap) {
            this.setState({distributionMap: this.props.distributionMaps[0]});
        }

        if(this.props.distributionMaps !== prevProps.distributionMaps) {
            this.handleDistributionMapChange();
        }
    }

    handleDistributionMapChange() {
        if(!this.state.distributionMap) {
            return;
        }
        let mi = _.findIndex(this.props.distributionMaps, m => {

           return m.distribution.id === this.state.distributionMap.distribution.id;
        });

        this.setState({distributionMap : this.props.distributionMaps[mi]});
    }


    handleIframe(r) {
        if (!this.iframe && r !== null) {
            this.iframe = r;
            this.iframe.onload = () => {
                this.setState({loaded: true}, () => {
                    this.updateAllContent();
                });
            }
        }
    }

    displayable() {

        if (this.props.published === null) {
            return false;
        }

        if (dayjs(this.props.published).isAfter(dayjs())) {
            return false;
        }


        if (!this.state.distributionMap) {
            return false;
        }

        if (this.state.distributionMap.published === 0) {
            return false;
        }

        return this.state.distribution && this.filteredDistributions().length >= 1;
    }

    getSrcUrl() {
        let map = this.state.distributionMap;

        if (map === null) {
            return null;
        }
        let path = map.slug_override ? map.slug_override : `${this.props.content.content_type.slug}/${this.props.content.slug}`;



        return `${map.distribution.domain}/${path}`;
    }

    render() {

        return (
            <div>


                {
                    this.displayable() &&
                    <>
                        <div className={'editor-preview-top'}>
                            <div>
                                Available Distributions for preview:
                                {this.filteredDistributions().map((d, i) => {
                                    if (this.state.distributionMap) {
                                        if (this.state.distributionMap.distribution.id === d.distribution.id) {
                                            return (<span>{d.distribution.name}</span>);
                                        }
                                    }

                                    return (<button style={{marginLeft: '20px'}} key={i}
                                                    onClick={() => this.handleDistributionsClick(d)}>{d.distribution.name}</button>);
                                })}
                            </div>
                            <div className={'editor-preview-top__actions'}>
                                <div onClick={() => this.setState({size: 'mobile'})}>Mobile</div>
                                <div onClick={() => this.setState({size: 'tablet'})}>Tablet</div>
                                <div onClick={() => this.setState({size: 'desktop'})}>Desktop</div>
                            </div>

                        </div>

                        <div className={`editor-preview editor-preview--${this.state.size}`}>

                            <iframe ref={r => this.handleIframe(r)} src={this.getSrcUrl()}>

                            </iframe>


                        </div>
                    </>
                }

            </div>


        );
    }



}

EditorPreview.defaultProps = {
    distributions: [],
    contentVersion: {},
    content: {},
    contentVersionId: null,
    selected: {},
    distributionMaps: []
}

;

export default EditorPreview;
