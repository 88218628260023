

import NewContent from '../components/content-search/create-new';

import React from "react";

import ReactDOM from 'react-dom';

import Breadcrumb from '../../js/components/breadcrumb';

import { CreateButton, CancelButton } from '../components/buttons';

import SearchBox from '../../js/components/content-search/search-box';
import SearchResults from '../../js/components/content-search/search-results';
import axios from 'axios';
import auth from "../lib/users/auth";

const isPhone = true;

const crumbs = [{
    name: 'Content Search',
    link: ''
},
    {
        name: '',
        link: '#'
    }
];

class ContentSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            defaultResults: [],
            filters: [],
            selectedContentTypes: [],
            contentTypes: [],
            indexScreen: true,
            searchValue: "",
            pagination: 1,
            popup: false,
        };
    }


    togglePopup() {
        this.setState({popup: !this.state.popup});
    }


    search() {



        if (this.state.searchValue.length == 0) return;

        if (this.timer) {
            clearTimeout(this.timer);
        }


        this.timer = setTimeout(() => {
            return axios.post('/api/content/items/search', {
                term: this.state.searchValue,
                filters: {content_type_ids: this.state.selectedContentTypes}
            }).then(({data: {data}}) => {


                this.setState({
                    indexScreen: false,
                    results: data.map(item => {
                        const index = _.findIndex(this.state.contentTypes, t => t.id === item.content_type_id);
                        const contentType = index !== -1 ? this.state.contentTypes[index].name : {}
                        return {
                            title: item.title,
                            tags: item.tags,
                            id: item.id,
                            type: contentType.label ? contentType.label : contentType.name
                        }
                    })
                });
            });
        });


    }

    onChange(e) {

        this.setState({
            searchValue: e.target.value
        }, () => {


            this.changed();
        });
    }

    changed() {
        if (this.state.searchValue.length >= 1) {


            return this.search();
        }
        this.getItems();
    }


    selectContentType(id) {
        let value = [...this.state.selectedContentTypes, id];

        if (this.state.selectedContentTypes.includes(id)) {
            value = this.state.selectedContentTypes.filter(e => e !== id);
        }
        this.setState({
            selectedContentTypes: value
        }, () => this.changed());
    }


    getItems(pageNumber = 1) {
        if (pageNumber == null) return;


        return axios.get(`/api/content/items`, {
            params: {
                page: pageNumber,
                per_page: 20,
                content_type_ids: this.state.selectedContentTypes
            }
        }).then(({data: {data, meta}}) => {
            this.setState({
                results: data.map(item => {
                    return {
                        id: item.id,
                        type: item.content_type.label ? item.content_type.label : item.content_type.name,
                        tags: item.tags.map(tag => tag.name),
                        title: item.title

                    };
                }),
                meta: meta,
                indexScreen: true,
                defaultResults: data,
                pagination: meta.current_page,
            });
        })
    }

    componentDidMount() {
        axios.get('/api/content/content-types').then(({data: {data}}) => {
            this.setState({
                contentTypes: data,
            }, () => this.changed());
        });

    }

    labelOrName(label, name) {
        if(label) {
            if (label.length >= 1) {
                return label;
            }
        }

        return name;
    }

    render() {
        if (!isPhone) return <h1>Not avaliable on phone</h1>;
        const state = this.state;
        const togglePopup = this.togglePopup.bind(this);

        return (
            <>
                {state.popup && <NewContent setPopup={togglePopup}/>}

                <div class="content-search">
                    <div class="website__topbar">
                        <Breadcrumb crumbs={crumbs}/>
                        {
                            auth.hasPermission('content_create') &&
                            <CreateButton onClick={togglePopup}>Create New</CreateButton>
                        }
                    </div>
                    <div class="website__content">
                        <div class="content-search__inner">
                            <div class="content-searcher content-box">
                                <p class="content-box__title">Search your content</p>

                                <div class="content-searcher__search">
                                    <SearchBox onChange={this.onChange.bind(this)}/>
                                </div>

                                <SearchResults contentTypes={state.contentTypes} results={state.results}
                                               meta={state.meta} getItems={this.getItems.bind(this)}
                                               indexScreen={state.indexScreen} searchValue={state.searchValue}
                                               pagination={state.pagination}/>
                            </div>
                            <div class="content-filter content-box">
                                <p class="content-box__title">Filter</p>
                                {/* <div class="content-filter__section">
                                <h3 class="content-filter__title">Choose Distribution</h3>
                                <div class="distributions-outer">
                                    <button class="button button__small button--confirm">MyEB Website</button>
                                    <button class="button button__small button--confirm">MyEB Development Site</button>
                                    <button class="button button__small button--idle">Example Landing Page</button>
                                </div>
                            </div> */}
                                <div class="content-filter__section">
                                    <h3 class="content-filter__title">Choose Content Type</h3>
                                    <div class="content-types">
                                        {state.contentTypes.length > 0 && state.contentTypes.map(({id, name,label, slug}, index) => {
                                            return <div
                                                key={index}
                                                className={"content-type " + (state.selectedContentTypes.includes(id) ? 'content-type--selected' : '')}
                                                onClick={() => this.selectContentType(id)}>{ this.labelOrName(label, name)}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


}

export default ContentSearch;

if (document.getElementById('content-search')) {
    ReactDOM.render(<ContentSearch/>, document.getElementById('content-search'));
}
