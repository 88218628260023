import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

export default (props) => {
    const { label, placeholder, fill, grow } = props;
    
    return (
        <LabelContainer label={label} placeholder={placeholder} fill={fill} grow={grow}>
            <ErrorContainer errors={props?.errors} fill={fill}>
                <textarea className="input-outer" { ...props }/>
            </ErrorContainer>
        </LabelContainer>
    )
}
