import {Component} from 'react';
import ImagesPopup from '../popups/images/images-popup';
import MediaHelper from "../../lib/helpers/media";

import { UploadButton } from '../buttons';

export default class Image extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: false,
            title: this.props.data,
            meta: {
                link: '',
                alt: '',
            }
        }
    }

    onChange(data, meta = {}) {
        const {config, sectionConfig, contentIndex, handleChange, fixed} = this.props;


        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: data !== null ? data : this.props.data,
                meta: Object.assign({}, this.props.meta, meta)
            },
            fixed: fixed,
        });
    }

    handleSelect(r) {
        let imageSizes = {};

        if (r.resource.image_variants) {
            r.resource.image_variants.forEach(iv => {
                imageSizes[iv.size] = iv.name;
            });
        }

        this.setState({popup: false});

        this.onChange(r.resource.file_name, {
            image_variants: imageSizes
        });
    }

    render() {
        const {data, meta} = this.props;


        return (
            <>
                {this.state.popup && <ImagesPopup onClose={() => this.setState({popup: false})}
                                                  onResourceSelect={this.handleSelect.bind(this)}
                                                  onlyShow={['browse', 'upload']}/>}

                <div className="editor-image">
                    <div className={`editor-image__img ${!data ? 'editor-image__img--empty' : ''}`}>
                        {
                            data &&
                            <img src={`${MediaHelper.formatPublicPath(`/images/${data}`)}`}/>

                        }
                        <div>Replace Image</div>
                    </div>
                    <div class="editor-image__content">
                        <div class="editor-image__row">
                            <UploadButton onClick={() => this.setState({ popup: true })}>Upload/Change</UploadButton>
                            <a href="#">Remove Image</a>
                        </div>
                        <div class="input-outer">
                            <label for="email">Image URL</label>
                            <input placeholder="" value={data ? data : ''} type="url"
                                   onChange={e => this.onChange(e.target.value)}/>
                        </div>
                        <div class="input-outer">
                            <label for="email">Hyperlink (destination when image clicked)</label>
                            <input placeholder="" type="link" value={meta.link ? meta.link : ''}
                                   onChange={e => this.onChange(null, {link: e.target.value})}/>
                        </div>
                        <div class="input-outer">
                            <label for="email">Alt Tag</label>
                            <input value={meta.alt ? meta.alt : ''}
                                   onChange={e => this.onChange(null, {alt: e.target.value})} placeholder=""
                                   type="alt"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
