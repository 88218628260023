import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { MinMaxLayout, SeventyThirtyLayout } from "../../../../containers/layout";
import PopupContext, { PopupProvider } from '../../../../context/context-popup';

import List from '../../../../components/list/list';

import InputsSelect from '../../../../components/inputs/inputs-select';


import ContentContainer from "../../../../components/layout/content-container";
import Container from '../../../../containers/container';

import { CreateButton } from '../../../../components/buttons';

import Sponsors from '../../../../components/sponsors';

import Navigation from '../../../grants/components/grants-application-navigation';
import CustomDropdown from '../../../../components/dropdowns/dropdown';

import SearchBarItem from '../../../../components/search/search-bar-item';

import ELearningPaths from '../../components/e-learning-path-list';
import ELearningView from '../../components/popups/e-learning-view';
import ELearningCreate from '../../components/popups/e-learning-create';
import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import ELearningFilters from '../../components/e-learning-path-filters';

import ResultsList from "../../../../components/results/results-list";
import ResultsListItem from "../../../../components/results/results-list-item";
import ResultsListItemMeta from '../../../../components/results/results-list-item-meta';
import ResultListItemDetails from "../../../../components/results/results-list-item-details";

import NavButton from '../../../../components/nav-button/nav-button';

import { stringToFriendlyDatetime } from '../../../../lib/helpers/datetime-helpers';
import Row from '../../../../utils/row';

import CompaniesApi from '../../../../lib/companies/companies-api';
import LabelContainer from '../../../../components/label-container';

import PopupsLearnerView from './popups/popups-learner-view';

const crumbs = [
    {
        name: 'E-Learning',
        link: ''
    }
]

class Learners extends Component {
    static contextType = PopupContext;

    constructor(props)
    {
        super(props);

        this.state = {
            learners: [],
            courses: [],
            meta: {},
            popup: null,
            loading: true,
            currentPage: 1,
            order_by: null,
            direction: null,
            filter: null,
            term: '',
            filteredCourse: null,
            filteredCompany: null,
        };
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let params = {
            term: this.state.term.length !== 0 ? this.state.term : null,
            per_page: 10,
            page: this.state.currentPage
        }

        if (this.state.order_by !== null) {
            params.order_by = this.state.order_by.value;
        }

        if (this.state.direction !== null) {
            params.direction = this.state.direction.value;
        }

        if (this.state.filteredCourse !== null) {
            params.course_id = this.state.filteredCourse.value;
        }

        if (this.state.filteredCompany !== null) {
            params.company_id = this.state.filteredCompany.value;
        }

        if (this.state.filter?.value === 'module_requiring_assessment_booking') {
            params.module_requiring_assessment_booking = 1
        }

        if (this.state.filter?.value === 'has_manual_assessment_bookings') {
            params.has_manual_assessment_bookings = 1
        }

        if (this.state.filter?.value === 'module_requiring_assessment') {
            params.module_requiring_assessment = 1
        }

        await new ELearningAPI().fetchLearners(params).then(({ data, meta }) => {
            this.setState({ learners: data, meta: meta, loading: false });
        });

        await new ELearningAPI().fetchCourses().then(({ data }) => this.setState({ courses: data }))
    }

    setPopup(popup) {
        this.setState({ popup: popup });
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.meta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    onSearchChange(e) {
        const { value } = e.target;

        this.setState({ term: value }, () => {
           this.fetch();
        });
    }

    onFilterBy(e) {
        this.setState({ direction: e }, () => this.fetch());
    }

    onSortBy(e) {
        this.setState({ order_by: e }, () => this.fetch());
    }

    onFilter(e) {
        this.setState({ filter: e }, () => this.fetch());
    }

    async loadCourses() {
        return new ELearningAPI().fetchCourses().then(({ data }) => {
            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))

            options.unshift({
                label: 'Reset',
                value: null,
            })

            return options;
        })
    }

    async loadCompanies() {
        return CompaniesApi.getCompanies().then(({ data }) => {
            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))

            options.unshift({
                label: 'Reset',
                value: null,
            })

            return options;
        })
    }

    learnerQuickView(learner) {
        const { setPopup } = this.context;

        setPopup(<PopupsLearnerView learner={learner} courses={this.state.courses}/>);
    }

    render() {
        return (
            <ContentContainer
                title={'Learners'}
                count={this.state.learners.length}
                icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                top={<div className="grant-index-top utils__rowSpaceBetween">
                    <SearchBarItem showButton={false} color="search-bar--white" onChange={this.onSearchChange.bind(this)}/>
                </div>}

                header={
                    <List gap="20px">
                        <Row gap="20px" wrap>
                            <CustomDropdown items={[
                                {
                                    label: 'Reset',
                                    value: null,
                                },
                                {
                                    label: 'Module requiring assessment booking',
                                    value: 'module_requiring_assessment_booking'
                                },
                                {
                                    label: 'Module requiring assessment',
                                    value: 'module_requiring_assessment',
                                },
                                {
                                    label: 'Has manual assessment bookings',
                                    value: 'has_manual_assessment_bookings',
                                },
                            ]} placeholder="Filter By" value={this.state.filter} icon="filter" onChange={this.onFilter.bind(this)}/>

                            <CustomDropdown items={[
                                {
                                    label: 'Reset',
                                    value: null,
                                },
                                {
                                    label: 'Created At',
                                    value: 'created_at'
                                },
                                {
                                    label: 'Updated At',
                                    value: 'updated_at'
                                },
                            ]} placeholder="Sort By" value={this.state.order_by} icon="filter" onChange={this.onSortBy.bind(this)}/>

                            <CustomDropdown items={[
                                {
                                    label: 'Reset',
                                    value: null,
                                },
                                {
                                    label: 'Ascending',
                                    value: 'asc'
                                },
                                {
                                    label: 'Descending',
                                    value: 'desc'
                                },
                            ]} placeholder="Sort Direction" value={this.state.direction} icon="filter" onChange={this.onFilterBy.bind(this)}/>
                        </Row>

                        <Row gap="20px" fill={true}>
                            <LabelContainer label="Course">
                                <InputsSelect
                                    defaultOptions
                                    value={this.state.filteredCourse}
                                    key={this.state}
                                    onChange={(item) => {
                                        if (item.label === 'reset') {
                                            item = null;
                                        }

                                        this.setState({ filteredCourse: item }, () => this.fetch());
                                    }}
                                    loadOptions={this.loadCourses.bind(this)}
                                />
                            </LabelContainer>

                            <LabelContainer label="Company">
                                <InputsSelect
                                    defaultOptions
                                    value={this.state.filteredCompany}
                                    key={this.state}
                                    onChange={(item) => {
                                        if (item.label === 'reset') {
                                            item = null;
                                        }

                                        this.setState({ filteredCompany: item }, () => this.fetch())
                                    }}
                                    loadOptions={this.loadCompanies.bind(this)}
                                />
                            </LabelContainer>
                        </Row>
                    </List>
                }

                bottom={<Navigation back={this.goBack.bind(this)}
                                    next={this.goNext.bind(this)}
                                    setPage={this.setPage.bind(this)}
                                    meta={this.state.meta}
                                    backDisabled={this.state.currentPage == 1}
                                    nextDisabled={this.state.currentPage == this.state.meta?.last_page}/>}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <ResultsList
                        titleOne={'Learner Name'}
                        titleTwo={''}
                        titleThree={''}>

                    { this.state.learners?.map((learner, index) => {
                        const approval = learner.course_works?.find(work => work.module_requiring_assessment) ? 'Awaiting Approval' : '';
                        
                        return (
                            <ResultsListItem
                                key={index}
                                details={<ResultListItemDetails title={learner?.name} subtitle={learner?.email}/>}
                                metaOne={<ResultsListItemMeta data={[`Last Edited: ${stringToFriendlyDatetime(learner?.updated_at)}`]}/>}
                                metaTwo={<ResultsListItemMeta data={[approval]} />}
                                actions={ <NavButton mobile={true} onClick={() => this.learnerQuickView(learner)}/>}
                            />
                        )
                    })}
                    </ResultsList>
                </div>
            </ContentContainer>
        );
    }
}

class Activity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: new Array(0).fill({
                message: 'Test',
            }),
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <ContentContainer title={'Activity'} icon={<img src="/assets/content-container/activity.svg" />}>
                
            </ContentContainer>
        );
    }
}

class Assessments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: new Array(0).fill({
                message: 'Test',
            }),
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <ContentContainer title={'Assessments awaiting review'} icon={<img src="/assets/content-container/assessments-awaiting-review.svg" />}>
                
            </ContentContainer>
        );
    }
}

const App = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'e-learning-index'}>
                <SeventyThirtyLayout
                    main={<Learners/>}
                    side={[
                        {
                            name: 'Activity',
                            component: <Activity/>
                        },
                        {
                            name: 'Assessments awaiting review',
                            component: <Assessments/>
                        }
                    ]}
                />
            </div>
        </PageLayout>

    );
}

const el = document.getElementById('learners');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <App/>
        </PopupProvider>    
    , el);
}
