import React, {Component} from 'react';
import Dropdown from "react-dropdown";

class Select extends Component {

    render() {
        const { name, errors = [],placeholder } = this.props;

        return (
            <div id={name} className="input-outer">
                <label htmlFor={name}>{placeholder}</label>
                <Dropdown
                    className="input input--idle"
                    {
                        ...this.props
                    }
                />
                {errors.length > 0 && <div className="input-outer__errors">{errors.map((err, index) => <p key={index}>{err}</p>)}</div>}

            </div>
        );
    }
}

export default Select;
