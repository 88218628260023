export default function List({ className, children, gap = '0px', style = {} }) {
    return (
        <div className={`list ${className}`} style={{
            display: 'flex',
            flexDirection: 'column',
            gap: gap,
            ...style,
        }}>
            { children }
        </div>
    )
}