import { useEffect } from "react";
import UpdateContentVersionState from "../../lib/content/update-content-version-state";

export default ({ addFreeContentItem, allowedContent, images }) => {
    useEffect(() => {
        console.log(allowedContent);
    }, [])
    
    return (
        <div class="free-content-buttons">
           { allowedContent.map((type, index) => {
               return (
                    <div key={index} class="free-content-button" onClick={() => addFreeContentItem(type)}>
                        <img src={images[type]}/>
                        <p>Add { type.toUpperCase() }</p>
                    </div>
               )
           })}
        </div>
    )
}
