export default function QuestionTypeContainer({ type, title, children }) {
    return (
        <div className="question-type">
            <div className="question-type__head">
                <img src={`/assets/question-types/question-type-${type}.svg`} />
                <p>{ title }</p>
            </div>
            <div className="question-type__content">
                { children }
            </div>
        </div>
    )
}