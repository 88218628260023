import { Component } from 'react';

import MobileSectionButtons from './mobile-section-buttons';
import MobileSectionHandler from './mobile-section-handler';

export default class SeventyThirtyLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSection: null,
        }
    }

    onSectionClick(section) {
        this.setState({ selectedSection: section });
    }

    onSectionClose() {
        this.setState({ selectedSection: null });
    }

    render() {
        const { main, side } = this.props;

        return (
            <div className="layout__inner layout__seventy-thirty">
                <MobileSectionButtons sections={side} onSectionClick={this.onSectionClick.bind(this)}/>

                <div className="layout__main-container">{ main }</div>

                <MobileSectionHandler
                    sections={side}
                    selectedSection={this.state.selectedSection}
                    onSectionClose={this.onSectionClose.bind(this)}
                />
            </div>
        )
    }
}
