import { Component, createRef } from 'react';
import { render } from 'react-dom';
import { Editor } from '@tinymce/tinymce-react'

export default class extends Component {
    constructor(props) {
        super(props);

        this.ref = createRef(null);
    }

    render() {
        const { config, sectionConfig, contentIndex, handleChange, fixed, data } = this.props;

        return <Editor
            onInit={(evt, editor) => this.ref.current = editor}
            value={data}
            onEditorChange={(value, editor) => {
                handleChange({
                    title: config.name,
                    contentItemConfig: config,
                    sectionConfig,
                    contentIndex,
                    data: {
                        body: value,
                        meta: {},
                    },
                    fixed
                })
            }}
            init={{
                height: 300,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }
            }
        />
    }
}
