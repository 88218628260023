import { Component } from 'react';

import Popup from '../../../components/popup';
import Container from '../../../containers/container';
import Row from '../../../utils/row';
import List from '../../../components/list/list';

import InputsText from '../../../components/inputs/inputs-text';
import InputsTextArea from '../../../components/inputs/inputs-text-area';
import InputsSelect from '../../../components/inputs/inputs-select';

import { ProceedButton, CancelButton } from '../../../components/buttons';

import MeApi from '../../../lib/users/me-api';
import AdminApi from '../../../lib/admin/admin-api';

import PopupContext from '../../../context/context-popup';

export default class extends Component {
    static contextType = PopupContext;

    constructor() {
        super();

        this.state = {
            params: {
                subject: null,
                body: null,
                admin_team_id: null,
                //to_id: null,
            },
            errors: [],
            groups: [],
            users: [],
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    confirm() {
        const params = { ...this.state.params };

        if (params.admin_team_id !== null) {
            params.admin_team_id = params.admin_team_id?.value;
        } else {
            delete params.admin_team_id;
        }

        if (params.to_id !== null) {
            params.to_id = params.to_id?.value;
        } else {
            delete params.to_id;
        }

        MeApi.createConversation(params, (err) => {
            console.log('Error')
            this.setState({ errors: err });
        }).then(() => {
            this.props.fetch();
            this.context.setPopup(null);
        });
    }

    async getUsers() {
        return AdminApi.getNonAdminUsers().then(({ data }) => {
            const options = data
                .filter(user => user.admin === 0 && user.super_admin === 0)
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }));

            this.setState({ users: options })

            return options;
        })
    }

    setParams(key, value) {
        this.setState({
            params: {
                ...this.state.params,
                [ key ]: value
            }
        })
    }

    render() {
        console.log(this.state.errors);
    
        return (
            <Popup type="manage-staff" onClose={() => this.context.setPopup(null)}>
                <div class="popup__inner">
                    <Container>
                        <Container.Header>
                            <Container.Title color="#151515" fontSize="30px">New Message</Container.Title>
                        </Container.Header>

                        <Container.Content>
                            <List gap="20px">
                                <InputsSelect
                                    defaultOptions
                                    value={this.state.params.to_id}
                                    key={null}
                                    onChange={e => this.setParams('to_id', e)}
                                    loadOptions={this.getUsers.bind(this)}
                                    errors={this.state.errors?.to_id}
                                />

                                <InputsText
                                    label="Subject of your message"
                                    value={this.state.params.subject}
                                    onChange={e => this.setParams('subject', e.target.value)}
                                    errors={this.state.errors?.subject}
                                />

                                <InputsTextArea
                                    label="Message"
                                    value={this.state.params.body}
                                    onChange={e => this.setParams('body', e.target.value)}
                                    errors={this.state.errors?.body}
                                />
                            </List>                          
                        </Container.Content>

                        <Container.Footer>
                            <Row gap="20px">
                                <ProceedButton responsive={false} onClick={this.confirm.bind(this)}>Confirm</ProceedButton>
                                <CancelButton responsive={false} onClick={() => this.context.setPopup(null)}/>
                            </Row>
                        </Container.Footer>
                    </Container>
                </div>
            </Popup>
        )
    }
}