import { Component, createRef } from 'react';

import {AddButton, DeleteButton} from '../buttons';
import {sanatizeString} from "../../lib/helpers/formatters";

export default class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnTitles: [],
            rows: [],
            pageload: true,
        }

        this.refs = createRef([]);
    }

    componentDidMount() {
        if (this.props.columns) {
            if (this.props.columns.length > 6) return;

            this.setState({ columnTitles: this.props.columns.map(column => column.title) });
        }
        const value =  this.props.value ? this.props.value : [];

        if (this.props.defaultRowCount) {
            const defaultCount = parseInt(this.props.defaultRowCount);
            if (defaultCount !== NaN) {
                const required = defaultCount - value.length;
                let additionalRows = [];
                for (let i = 0; i < required; i++) {
                    additionalRows.push({});
                }
                let rows = [...value, ...additionalRows];

                this.props.onChange({ value: rows });
                this.setState({ value: rows });
                return;
            }
        }

    }

    getRows() {
        let refIndex = 0;
        if (Array.isArray(this.state.value) === false) {
            return '';
        }
        return this.state.value.map((_, index) => {
            return (
                <tr key={index}>
                    { new Array(this.state.columnTitles.length).fill(0).map((data, rowValueIndex) => {
                        let text = this.getRowData(index, rowValueIndex);

                        if(this.props.readOnly === true) {
                            text = sanatizeString(text);
                        }

                        return (
                            <td key={rowValueIndex} data-label={this.state.columnTitles[rowValueIndex]}>
                                <div contentEditable={!this.props.readOnly}
                                    ref={ref => {
                                        if (ref !== null && text) {
                                            if (this.state.pageload) ref.innerText = text;
                                        }
                                    }}
                                    name={`${index}-${rowValueIndex}`}
                                    onInput={this.onRowValueChange.bind(this)}></div>
                            </td>
                        )
                    }) }
                </tr>
            )
        })
    }

    onRowValueChange(e) {
        const value = e.target.innerHTML;
        const name = e.target.attributes.name.value;

        const [ rowIndex, rowValueIndex ] = name.split('-');

        const val = this.state.value !== null ? this.state.value : []

        const rows = [ ...val ];

        rows[rowIndex] = {
            ...rows[rowIndex],
            [ this.state.columnTitles[rowValueIndex] ]: value,
        }

        if (value == '') {
            delete rows[rowIndex][this.state.columnTitles[rowValueIndex]];
        }

        if (this.state.pageload) this.setState({ pageload: false });
        this.setState({ value: rows });
        this.props.onChange({ value: rows });
    }

    getRowData(rowIndex, rowValueIndex) {
        const column = this.state.columnTitles[rowValueIndex];
        const rowData = this.state.value[rowIndex][column];

        return rowData || false;
    }

    addRow() {
        const val = this.state.value !== null ? this.state.value : []

        if (this.props.maxRowCount !== null) {
            if (val.length === parseInt(this.props.maxRowCount)) return;
        }

        const rows = [ ...val, {} ];

        this.setState({ value: rows });
        this.props.onChange({ value: rows });

    }

    removeRow() {


        const val = this.state.value !== null ? this.state.value : []

        if(val.length >=1) {
            let rows = [ ...val ];
            rows.splice(rows.length -1 , 1);
            this.setState({ value: rows });
            this.props.onChange({ value: rows });
        }


    }

    render() {
        return (
            <div className="table__outer">
                <table>
                    <thead>
                        <tr>
                            { this.state.columnTitles.length > 0 && this.state.columnTitles.map((v, index) => <th key={index}>{ v }</th>) }
                        </tr>
                    </thead>
                    <tbody>
                        { this.getRows() }
                    </tbody>
                </table>
                { (this.props.error || this.error) && <p className="table__error">{ this.props.error || this.error }</p>}
                { !this.props.readOnly && this.props.allowNewRows && <div className="utils__rowCentered">
                    <AddButton mobile={true} onClick={this.addRow.bind(this)} />
                    {
                        this.state.value?.length >=1 &&
                        <DeleteButton mobile={true} onClick={this.removeRow.bind(this)} />

                    }
                </div> }
            </div>
        )
    }
}
