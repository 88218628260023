import Dropdown from '../../grants/components/dropdown';

import Input from '../../../components/inputs/inputs-text';

import Row from '../../../utils/row';

import { ModifyButton, DeleteButton, DuplicateButton } from '../../../components/buttons';
import LabelContainer from '../../../components/label-container';

import SearchBarItem from '../../../components/search/search-bar-item';
import ELearningDropdownModule from './e-learning-dropdown-module';

import { SmallPill } from '../../../components/pills';

export default function ELearningDropdown({ index, setPopup, course, duplicateCourse, removeCourse }) {
    const { latest_course_versions } = course;
    const course_version = latest_course_versions.length > 0 ? latest_course_versions[0] : null;

    const Buttons = ({ }) => {
        return (
            <div className="utils__row utils__gap--20">
                <ModifyButton mobile={true} onClick={() => window.location.href = `/e-learning/courses/${course.id}`}/>
                <DuplicateButton mobile={true} onClick={() => duplicateCourse(course)}/>
                <DeleteButton mobile={true} onClick={() => removeCourse(course.id)}/>
            </div>
        )
    }

    return (
        <Dropdown title={course?.name} index={index} head={<Buttons/>} className="e-learning-page__dropdown">
            <LabelContainer label="Course Name" fill={true}>
                <p>{ course?.name }</p>
            </LabelContainer>

            <Row>
                <LabelContainer label="Host Name" fill={true}>
                    <p>{ course?.host_name }</p>
                </LabelContainer>

                <LabelContainer label="Difficulty" fill={true}>
                    <p style={{ textTransform : "capitalize"}}>{ course?.difficulty }</p>
                </LabelContainer>
            </Row>

            <Row>
                <LabelContainer label="Course Learning Path" fill={true}>
                    { !course_version ?
                        <p>No modules added. Click the ‘edit’ icon above to start adding modules to this course</p> :
                        <>
                            { course_version.modules.map((module, index) => {
                                return <ELearningDropdownModule
                                    key={index}
                                    index={index}
                                    modules={course_version.modules}
                                    module={Object.assign(module, {
                                        name: `Module: ${module.name}`
                                    })}
                                    />
                            }) }

                            <ELearningDropdownModule
                                key={course_version.modules.length}
                                index={course_version.modules.length}
                                modules={course_version.modules}
                                module={{
                                    name: 'Summary',
                                }}
                                />
                        </>
                    }
                </LabelContainer>

                <LabelContainer label="Courses required to gain access" fill={true}>
                    <Row wrap={true} gap="10px">
                        { course?.course_prerequisites?.map((c, index) => {
                            return <SmallPill key={index}>{ c?.name }</SmallPill>
                        })}
                    </Row>
                </LabelContainer>
            </Row>
        </Dropdown>
    )
}
