const userDropdownIcon = document.querySelectorAll(".topbar-menu__icon");
const userDropdown = document.querySelector(".user-dropdown");
const parent = document.querySelector(".website");

for (let dropdown of userDropdownIcon) {
    dropdown.onclick = (e) => {
        e.stopPropagation();
    
        console.log("clicked")
    
        const computed = getComputedStyle(userDropdown, null);
    
        if (computed.display == "none") {
            userDropdown.style.display = "flex";
            dropdown.classList.add("topbar-menu__icon--toggled");
        } else {
            userDropdown.style.display = "none";
            dropdown.classList.remove("topbar-menu__icon--toggled");
        }
    }
}

window.onclick = (event) => {
    if (userDropdown && !userDropdown.contains(event.target)) {
        userDropdown.style.display = "none";

        for (let dropdown of userDropdownIcon) {
            dropdown.classList.remove("topbar-menu__icon--toggled");
        }
    }
}
 