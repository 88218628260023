import { stringToFriendlyDatetime } from '../../lib/helpers/datetime-helpers';

export default function SentMessage({ message }) {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`} className="chatbox-message__outer chatbox-message__outer--sent">
            <div className="chatbox-message chatbox-message--sent">
                <p className="chatbox-message__message">{ message.body }</p>
                <p className="chatbox-message__sender">{ message.user.name }</p>
            </div>
            <p className="chatbox-message__date">{ message.read_by ? `Read By ${message.read_by.name} | ${CREATED_AT_DATE}` : CREATED_AT_DATE }</p>
        </div>
    )
}
