

import StickyContainer from '../../../components/layout/sticky-container';
import MediaQuery from 'react-responsive';

export function GrantStatusHeader({title, message, children, type = 'default'}) {
    return(

        <div className={`application-status__outer application-status__outer--${type}`}>
            <StickyContainer offset={60}>
                <div className={`application-status application-status--${type}`}>
                    <div className='application-status__head'>{title}</div>
                    <div className='application-status__inner'>
                        {/* <MediaQuery maxWidth={768}>
                            See More
                        </MediaQuery>
                        <MediaQuery minWidth={768}>
                            {message}
                        </MediaQuery> */}

                        { message }
                    </div>
                </div>
            </StickyContainer>

            <div className="application-status-bar">
                {children}
            </div>
        </div>
    );

}
