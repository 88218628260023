import { Component, createContext, cloneElement } from 'react';

import CourseOverview from './components/course-overview';
import CourseModule from './components/course-module';

import List from '../../../../components/list/list';
import TabBar from '../../../../components/tab-bar';
import PopupContext from '../../../../context/context-popup';

const ContentContext = createContext(null);

export default class CourseContent extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
        }
    }

    setTab = (tab) => this.setState({ selectedTab: tab });

    onModuleDelete = () => this.setTab(0);

    render() {
        const contentVersion = this.props.course.latest_course_versions?.[0];
        const modules = contentVersion !== undefined ? contentVersion.modules.map((_, index) => `Module ${index + 1}`) : [];
        const tabs = [...modules, 'Summary'];

        contentVersion.summary = { ...contentVersion.summary }

        return (
            <List gap="20px">
               <TabBar tabs={tabs} tab={this.state.selectedTab} setTab={this.setTab.bind(this)}/>

                { this.state.selectedTab === tabs.length - 1 && <CourseOverview course={this.props.course} 
                                                                summary={contentVersion.summary}
                                                                onChange={this.props.onChange}/> }

                { this.state.selectedTab !== tabs.length - 1 && <CourseModule course={this.props.course} 
                                                                save={this.props.save}
                                                                setPopup={this.props.setPopup}
                                                                onChange={this.props.onChange}
                                                                onModuleDelete={this.onModuleDelete.bind(this)}
                                                                moduleId={this.state.selectedTab}
                                                                module={contentVersion.modules[this.state.selectedTab]}/> }
            </List>
        )
    }
}   