import React, {Component} from 'react';
import Input from '../input-with-label';
import {
    stringCompensateForUTCOffset, stringLocalToUTC,
    stringToDatetimeLocal,
    stringToLocalFormat
} from "../../lib/helpers/datetime-helpers";
import Textarea from "../inputs/textarea";
import convert from "lodash/fp/convert";

class EventDetails extends Component {

    handleChange(e) {
        this.props.onChange(e.target.name, e.target.value);
    }


    getHours(durationMins) {
        const hours = Math.floor(durationMins / 60)


        return hours ? hours : 0;
    }

    getEventMinutes(durationMins) {
        const hours = Math.floor(durationMins / 60);
        const hoursInMins = hours * 60;

        return durationMins - hoursInMins;

    }

    render() {
        const {event, errors} = this.props;

        const start = event.start ?  stringCompensateForUTCOffset(event.start) : '';
        console.log('converted', start);
        return (
            <div className="companies-settings">
                <div className="companies-settings__section">
                    <div className="companies-settings__row">
                        <Input
                            label={'Name'}
                            placeholder={'Name'}
                            name={'name'}
                            value={event.name}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.name}
                        />
                        <Input
                            label={'Date & Time'}
                            placeholder={'Date & Time'}
                            type={'datetime-local'}
                            name={'start'}
                            value={start}
                            onChange={e => {
                                console.log('out', stringLocalToUTC(e.target.value));
                                this.handleChange({ target : {
                                    name : 'start',
                                    value  : stringLocalToUTC(e.target.value)
                                }})
                            }}
                            errors={errors.start}
                        />
                        <Input
                            label={'Duration Hours'}
                            placeholder={'Duration Hours'}
                            type={'number'}
                            name={'duration_mins'}
                            value={(() => {
                                const hours =  this.getHours(event.duration_mins);
                                if(hours > 0) {
                                    return hours.toString().replace(/^0+/, '');
                                }
                                return hours;
                            })()}
                            onChange={e => {
                                const value = e.target.value * 60;
                                const mins = this.getEventMinutes(event.duration_mins);
                                this.handleChange({target: {name: 'duration_mins', value: value + mins }})

                            }}
                            errors={errors.duration_mins}
                        />
                        <Input
                            label={'Duration Mins'}
                            placeholder={'Duration  Mins'}
                            type={'number'}
                            name={'duration_mins'}
                            max={59}
                            min={0}
                            value={(() => {
                                const mins =  this.getEventMinutes(event.duration_mins);
                                if(mins > 0) {
                                    return mins.toString().replace(/^0+/, '');
                                }
                                return 0;
                            })()}
                            onChange={e => {
                                const mins =  parseInt(e.target.value) ? parseInt(e.target.value) : 0;
                                const hours = this.getHours(event.duration_mins) ? this.getHours(event.duration_mins) : 0;

                                this.handleChange({target: {name: 'duration_mins', value:  ( hours * 60) + mins }})
                            }}
                            errors={errors.duration_mins}
                        />
                    </div>
                    <div className="companies-settings__row">
                        <Input
                            label={'Publish'}
                            placeholder={'Publish'}
                            type={'datetime-local'}
                            name={'publish_at'}
                            value={event.publish_at ? stringToDatetimeLocal(event.publish_at) : null}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.publish_at}
                        />
                        <Input
                            label={'External Link'}
                            placeholder={'External Link'}
                            name={'external_link'}
                            value={event.external_link}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.external_link}
                        />
                    </div>
                    <div className="companies-settings__row">
                        <Textarea
                            label={'Description'}
                            placeholder={'Description'}
                            name={'description'}
                            value={event.description}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.description}
                        />
                    </div>
                    <div className="companies-settings__row">
                        <Input
                            label={'Location'}
                            placeholder={'Location'}
                            name={'location_override'}
                            value={event.location_override}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.location_override}
                        />
                        <Input
                            label={'Location'}
                            placeholder={'Host'}
                            name={'host_override'}
                            value={event.host_override}
                            onChange={this.handleChange.bind(this)}
                            errors={errors.host_override}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default EventDetails;
