import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeCheckboxes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkboxes: [{
                option: '',
                option_required: false
            }],
            min_selection: false,
            max_selection: false
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.addCheckbox = this.addCheckbox.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    save() {
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
    }

    onInputChange(e) {
        const { name, value } = e.target;

        const hasNumber = /\d/;   

        let params = { [ name ]: value }

        if (hasNumber.test(name)) {
            const checkboxes = [ ...this.state.checkboxes ]
            const [ key, index ] = name.split('-');

            checkboxes[index][key] = value;

            params = { checkboxes }
        } 

        this.setState(params, () => this.save());
    }

    addCheckbox() {
        const checkboxes = [ ...this.state.checkboxes ];

        checkboxes.push({ option: '', option_required: false });

        this.setState({ checkboxes }, () => this.save());
    }

    deleteCheckbox(i) {
        const checkboxes = [ ...this.state.checkboxes].filter((_, index) => index !== i);

        this.setState({ checkboxes }, () => this.save());
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type='checkboxes' title="Checkboxes">
                    { this.state.checkboxes.map((option, index) => {
                        return (
                            <div key={index} className="utils__row utils__wrap utils__gap--20">
                                <Input value={option.option} 
                                        name={'option-' + index} 
                                        label={`Checkbox Option ${index + 1}`} 
                                        placeholder="Text Value" 
                                        onChange={this.onInputChange}/>

                                <Field title={`Option ${index + 1} required`}>
                                    <Checkbox checked={option.option_required} 
                                            name={'option_required-' + index} 
                                            text="Required" 
                                            onChange={this.onInputChange}/>
                                </Field>

                                <span className="column__delete" onClick={() => this.deleteCheckbox(index)}>X</span>
                            </div>
                        )
                    })}
                    <div className="utils__rowCentered">
                        <AddButton onClick={this.addCheckbox}>Add Checkbox Option</AddButton>
                    </div>
                </QuestionType>
                <QuestionType type='checkboxes' title="Checkbox Settings">
                    <QuestionTypeField title="Selection Criteria">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.min_selection} 
                                    name="min_selection" 
                                    label="Minimum Selection" 
                                    placeholder="Text Value" 
                                    type="number"
                                    onChange={this.onInputChange}/>

                            <Input value={this.state.max_selection} 
                                    name="max_selection" 
                                    label="Maximum Selection" 
                                    placeholder="Text Value" 
                                    type="number"
                                    onChange={this.onInputChange}/>
                        </div>
                    </QuestionTypeField>
                </QuestionType>
            </div>
        )
    }
}   