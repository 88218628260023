import ApiBase from "../api-base";
import axios from "axios";


class UsersApi extends ApiBase {
    async get(id) {
        return this.handleResponse(async () => {
            let response = await  axios.get(`/api/users/${id}`);
            return response.data.data;
        });
    }

    async resetPassword(id) {
        return this.handleResponse(async () => {
            let response = await  axios.post(`/api/users/${id}/reset-password`);
            return response.data.data;
        });
    }
}


export default new UsersApi();
