import React, {Component} from 'react';
import config from '../../lib/events/page-config';
import DynamicInputList from "../inputs/dynamic-input-list";


class EventContent extends Component {

    constructor(props) {
        super(props);
    }


    handleChange(sectionName, key, type, value) {

        let content = {...this.props.event.content};

        if (content.hasOwnProperty('sections') === false) {
            content.sections = [];
        }

        const si = _.findIndex(content.sections, s => {
            return s.name === sectionName;
        });
        if (si === -1) {
            content.sections.push({
                name: sectionName,
                content: {
                    [key]: {
                        value: value,
                        type: type
                    }
                }
            })
        } else {
            content.sections[si].content[key] = {
                value: value,
                type: type
            };
        }


        this.props.onChange('content', content);
    }


    getContentValue(sectionName, key) {
        const content = this.props.event.content;

        if (typeof content === 'undefined' || content === null) {
            return '';
        }

        if (content.hasOwnProperty('sections') === false) {
            return '';
        }
        const si = _.findIndex(content.sections, s => {
            return s.name === sectionName;
        });


        if (si === -1) {
            return '';
        }

        return content.sections[si].content.hasOwnProperty(key) ? content.sections[si].content[key].value : '';
    }

    render() {

        return (
            <div className="companies-settings">
                {
                    config.sections.map((section, index) => {
                        return (
                            <div key={index} className="companies-settings__section">
                                <div className="companies-settings__section-name">
                                    <h3>{section.name}</h3>
                                </div>
                                {
                                    <DynamicInputList getValue={field => this.getContentValue(section.name, field)}
                                                      config={section.fields}
                                                      onChange={(e, type) => {
                                                          this.handleChange(section.name, e.target.name, type, e.target.value)

                                                      }}/>
                                }
                            </div>
                        );
                    })
                }

            </div>
        );
    }
}

export default EventContent;
