import { useState, useContext } from 'react';

import Input from '../../../../../components/input-with-label';

import Popup from '../../../../../components/popup';

import { DeleteButton, CancelButton } from '../../../../../components/buttons';

import ELearningAPI from '../../../../../lib/e-learning/e-learning-api';

import PopupContext from '../../../../../context/context-popup';

export default function ELearningArchive({ path, fetch }) {
    const { setPopup } = useContext(PopupContext);
    
    const create = () => {
        new ELearningAPI().archiveLearningPath(path, (err) => {

        }).then(() => {
            fetch();
        });
    }

       return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Are you sure?</h1>
                <p>You are about to archive the learners path, this cannot be undone</p>

                <div class="popup__buttons">
                    <DeleteButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
