import { useState, useContext } from 'react';

import Input from '../../../../components/inputs/inputs-text';
import InputsTextArea from '../../../../components/inputs/inputs-text-area';

import Popup from '../../../../components/popup';

import { CreateButton, CancelButton } from '../../../../components/buttons';

import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import PopupContext from '../../../../context/context-popup';

import { Editor } from '@tinymce/tinymce-react';
import ErrorContainer from '../../../../components/error-container';
import LabelContainer from '../../../../components/label-container';

export default function ELearningCreate({ onNextClick }) {
    const { setPopup } = useContext(PopupContext);

    const [ name, setName ] = useState(null);
    const [ description, setDescription ] = useState(null);

    const [ errors, setErrors ] = useState({});

    const create = () => {
        new ELearningAPI().createLearningPath({
            name: name,
            description: description,
        }, (err) => {
            setErrors(err);
        }).then(({ id }) => {
            window.location.href = '/e-learning/learning-paths/' + id;
        });
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Add a new Learning Path</h1>
                <p>You can add new courses for learners to access on a learning path. First of, please give your learning path a name.</p>

                <Input label="Learning path name" placeholder="Enter a value" errors={errors?.name} value={name} onChange={(e) => setName(e.target.value)}/>

                <LabelContainer label="Description">
                    <ErrorContainer errors={errors?.description}>
                        <Editor
                            value={description}
                            onEditorChange={(value, editor) => {
                               setDescription(value);
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </ErrorContainer>
                </LabelContainer>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
