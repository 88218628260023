

export default ({ data }) => {
    const { title, type } = data;

    return (
        <div class="result-item">
            <div class="result-item__left">
                <div class="result-item__ball"></div>
                <p class="result-item__title">{ title }</p>
            </div>
            <div class="result-item__right">
                <div class="result-item__item">
                    <p>{ type }</p>
                </div>
                <div class="result-item__item">
                <a href={'/'} class="button button__fit button--alternate">Preview</a>
                </div>
            </div>
        </div>
    )
}