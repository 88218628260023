export default function ContainerTable({ columns = [], data = [], className, style = {}, rowStyle = {}, replaceColumn = [], insertBeforeCell = () => {} }) {
    let columnIndex = 0;

    const renderRow = (row, index) => {
        return (
            <div key={index} className="container-table__row" style={rowStyle}>
                { insertBeforeCell(index) }
                { row?.map((rowData, rowIndex) => {
                    const { width } = columns[rowIndex];

                    columnIndex++;

                    const isRowReplaceable = replaceColumn?.hasOwnProperty(rowIndex);

                    if (isRowReplaceable) {
                        return <div key={rowIndex} className="container-table-row__cell" style={{ width }}>{ replaceColumn[rowIndex](rowData, row) }</div>
                    }

                    return <div key={rowIndex} className="container-table-row__cell" style={{ width }}>{ rowData }</div>
                }) }
            </div>
        )
    }

    return (
        <div className={`container-table ${className}`} style={style}>
            <div className='container-table__headers'>
                { columns.map((column, index) => {
                    let style = {}

                    if (column?.width) style.width = column.width;

                    return <div key={index} className="container-table__header" style={style}>{ column?.field }</div>
                })}
            </div>

            <div className="container-table__rows">
                { data.map((row, index) => {
                    return renderRow(row, index)
                })}
            </div>
        </div>
    )
}

ContainerTable.DefaultProps = {
    columns: [],
    data: [],
    className: '',
    replaceColumn: {},
    style: {},
}
