function Container({ children, flexDirection = '', className = '', gap = '', style = {} }) {
    style = Object.assign({
        display: 'flex',
        flexDirection: 'column'
    }, style)
    return (
        <div className={`container ${className}`} style={{ flexDirection, gap, ...style }}>
            { children }
        </div>
    )
}

Container.Header = ({ children, style = {} }) => {
    return (
        <div className={`container__header`} style={{
            marginBottom: '20px',
            ...style,
        }}>
            { children }
        </div>
    )
}

Container.Footer = ({ children, style = {} }) => {
    return (
        <div className={'container__footer'} style={{
            marginTop: '20px',
            ...style,
        }}>
            { children }
        </div>
    )
}

Container.Content = ({ children, flexDirection = 'column', gap = '0px', style = {} }) => {
    return (
        <div className="container__content" style={{ display: 'flex', flexDirection, gap, ...style }}>
            { children }
        </div>
    )
}

Container.Title = ({ fontSize, fontWeight, color, children, style = {} }) => {
    const styles = {
        color,
        fontSize,
        fontWeight,
        ...style,
    }

    return <p className="container__title" style={styles}>{ children }</p>
}

Container.Text = ({ fontSize, fontWeight, color, children, style = {}, html = null }) => {
    const styles = {
        color,
        fontSize,
        fontWeight,
        ...style,
    }

    const props = {}

    if (html !== null) {
        props.dangerouslySetInnerHTML = { __html: html }
    }

    return <p style={styles} { ...props }>{ children }</p>
}

Container.Icon = ({ className, src, style = {} }) => {
    let Icon = null;

    if (typeof src === 'object') {
        Icon = src;
    }

    return Icon !== null ? <Icon className={className}/> : <img className={`container__icon ${className}`} src={src} style={style}/>
}

Container.Title.defaultProps = {
    fontSize: '14px',
    color: '#929BA8',
}

export default Container;
