import React, {Component} from 'react';
import config from '../../lib/companies/page-config';
import DynamicInputList from "../inputs/dynamic-input-list";

class CompanyPage extends Component {


    getPage() {
        const {company} = this.props;
        let page = {};

        if(company.company_profile) {
            page = company.company_profile.page ? company.company_profile.page : {};
        }

        return page;
    }


    getSectionIndex(page, sectionName) {
        return  _.findIndex(page.sections, s => s.name === sectionName);
    }

    getFieldValue(sectionName, field) {
        const page =  this.getPage();
        const index = this.getSectionIndex(page,sectionName);

        let section = {};
        if(index !== -1) {
            section = page.sections[index];
        }

        if(section.hasOwnProperty('content') === false) {
            return '';
        }
        return section.content.hasOwnProperty(field) ? section.content[field].value : '';
    }

    handleChange(sectionName, e, type) {
        console.log(type);
        let page = this.getPage();

        if(!page.sections) {
           page.sections = [];
        }

        const index = this.getSectionIndex(page, sectionName);

        if(index !== -1) {
            page.sections[index]['content'][e.target.name] = {
                value : e.target.value,
                type : type
            }
        }else {
            page.sections.push({
                name : sectionName,
                content : {
                    [e.target.name] : {
                        value: e.target.value,
                        type : type
                    }
                }
            })
        }

        this.props.onChange('page', page);

    }

    render() {

        return (
            <div className="companies-settings">
                {
                    config.sections.map((section, index) => {
                        return (
                            <div className="companies-settings__section" key={index}>
                                <div className="companies-settings__section-name">
                                    <h3>{section.name}</h3>
                                </div>
                                {
                                    <DynamicInputList getValue={field => this.getFieldValue(section.name, field)} config={section.fields} onChange={(e, type) => this.handleChange(section.name, e, type)} />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default CompanyPage;
