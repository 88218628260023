import React, {Component} from 'react';
import AdminUsersApi from '../../lib/users/admin-users-api';
import UserApi from '../../lib/users/users-api';
import AlertManager from '../../managers/alert/alert-manager';

import { ModifyButton } from '../buttons';

class UserRoles extends Component {


    constructor(props) {
        super(props);
        this.state = {
            roles : [],
            user : {},
            selected_roles: []
        };
    }


    componentDidMount() {
        this.getUser();
        this.getAvailableRoles();
    }

    getAvailableRoles() {
        AdminUsersApi.getRoles().then(roles => this.setState({roles : roles}));
    }

    getUser() {
        UserApi.get(this.props.userId).then(user => {
            this.setState({user: user, selected_roles : user.roles.map(r => r.id)}, ()=> console.log(this.state));
        });
    }

    findSelectedRoleIndex(id) {
        return _.findIndex(this.state.selected_roles, i => i === id);
    }

    handleChange(id) {
        let selectedRoles = [...this.state.selected_roles];
        const index = this.findSelectedRoleIndex(id);
        if(index !== -1) {
          selectedRoles.splice(index, 1);
        }else {
            selectedRoles.push(id);
        }

        this.setState({selected_roles : selectedRoles});
    }

    roleSelected(id) {
        return this.findSelectedRoleIndex(id) !== -1;
    }

    handleSubmit() {
        AdminUsersApi.syncUserRoles(this.state.user.id, {role_ids : this.state.selected_roles}).then(() => {
            AlertManager.success(`${this.state.user.name}'s roles updated`);
        });
    }
    render() {

        let {roles} = this.state;

        return (
            <div className="modify-member__checkboxes-outer">
                <p>Set the User Role</p>
                <div className="modify-member__checkboxes">
                    {roles.map(({id, name}, index) => {
                        return (
                            <div key={index} className="checkbox">
                                <input type="radio" checked={this.roleSelected(id)}   onClick={() => this.handleChange(id)}/>
                                <label htmlFor="huey">{name}</label>
                            </div>
                        )
                    })}
                </div>
                <div className="modify-member__checkboxes">
                    <ModifyButton onClick={this.handleSubmit.bind(this)}>Update Roles</ModifyButton>
                </div>

            </div>
        );
    }
}

export default UserRoles;
