import React, {Component} from 'react';
import ReactDom from "react-dom";
import ContentApi from '../../lib/content/content-api';


import Breadcrumb from "../../components/breadcrumb";
import Input from "../../components/input-with-label";
import CodeMirror from '@uiw/react-codemirror';
import {javascript} from '@codemirror/lang-javascript';
import AlertManager from "../../managers/alert/alert-manager";
import Popup from "../../components/popup";
import TabBar from "../../components/tab-bar";
import ContentTypeLabels from "./content-type-labels";

import { SaveButton, DeleteButton, CancelButton } from '../../components/buttons';


class ContentType extends Component {


    constructor(props) {
        super(props);

        this.tabs = [
            'Config',
            'Labels'
        ]

        this.state = {
            contentType: {},
            errors: {},
            popup: false,
            validJSON: true,
            configString: '',
            tab: 0
        };
    }


    componentDidMount() {
        this.getContentType();
    }


    getContentType() {
        ContentApi.getContentType(this.props.contentTypeId).then(contentType => {
            this.setState({contentType: contentType, configString: JSON.stringify(contentType.config, null, 2)});
        });
    }

    handleChange(e) {
        this.setState({
            contentType: Object.assign({}, this.state.contentType, {
                [e.target.name]: e.target.value
            })
        });
    }

    handleSave() {
        try {
            let params = this.state.contentType;

            if(this.state.tab === 0) {

                params.config = JSON.parse(this.state.configString);

            }

            ContentApi.updateContentType(this.props.contentTypeId, params, errors => this.setState({errors: errors})).then(success => {
                if (success) {
                    AlertManager.success('Content Type updated');
                }
            });

        } catch (e) {
            this.setState({validJSON: false});

            return;
        }


    }

    handleDelete() {
        ContentApi.deleteContentType(this.props.contentTypeId).then(() => {
            window.location = `/content-types`;
        });
    }

    setTab(id) {

        this.setState({tab: id, configString : ''});
    }


    render() {
        const {contentType, errors} = this.state;
        return (
            <>
                {
                    this.state.popup &&
                    <Popup type={'company-delete-popup'}>
                        <div className={'popup__inner'}>
                            <h1>Are you sure you want to delete {this.state.contentType.name}?</h1>
                            <div className="popup__content">
                                By clicking ‘Delete’, you will not be able to restore this event.
                            </div>
                            <div className="popup__buttons">
                                <DeleteButton responsive={false} onClick={this.handleDelete.bind(this)}>Delete</DeleteButton>
                                <CancelButton responsive={false} onClick={() => this.setState({popup: false})}>Cancel</CancelButton>
                            </div>
                        </div>
                    </Popup>
                }
                <div className={'account'}>

                    <div className="website__topbar">
                        <Breadcrumb crumbs={[{
                            name: 'Content Types',
                            link: '/content-types'
                        },
                            {
                                name: this.state.contentType.name,
                                link: '#'
                            }]}/>
                        <div className="website-row">
                            <SaveButton onClick={this.handleSave.bind(this)}>Save / Confirm</SaveButton>
                            <DeleteButton onClick={() => this.setState({popup: true})}>Delete</DeleteButton>
                        </div>
                    </div>

                    <div className="website__content">
                        <TabBar tabs={this.tabs} tab={this.state.tab} setTab={this.setTab.bind(this)}/>
                        {
                            this.state.tab === 1 &&
                            <ContentTypeLabels
                                contentType={this.state.contentType}
                                onChange={c => {
                                    this.setState({contentType: c})
                                }}
                            />

                        }

                        {
                            this.state.tab === 0 &&
                            <>
                                <div className="website__content">
                                    <div className="account__inputs">
                                        <div className="account__section">
                                            <div className="website-row">
                                                <Input name="name" value={contentType.name} placeholder="Name"
                                                       onChange={this.handleChange.bind(this)} errors={errors.name}/>
                                                <Input name="slug" value={contentType.slug} placeholder="Slug"
                                                       onChange={this.handleChange.bind(this)} errors={errors.slug}/>
                                                <Input name="label" value={contentType.label} placeholder="Label"
                                                       onChange={this.handleChange.bind(this)} errors={errors.label}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id={name} className="input-outer">
                                    <label htmlFor="">Config</label>
                                    {
                                        this.state.validJSON === false &&

                                        <div className="input-outer__errors"><p>Invalid JSON</p></div>
                                    }
                                </div>

                                {
                                    this.state.contentType &&
                                    <CodeMirror
                                        value={this.state.configString}
                                        height="800px"
                                        extensions={[javascript()]}
                                        onChange={e => {
                                            this.setState({configString: e, validJSON: true});
                                        }}
                                    />
                                }
                            </>
                        }


                    </div>
                </div>
            </>

        );
    }
}

export default ContentType;

const el = document.getElementById('content-type');

if (el) {
    ReactDom.render(<ContentType contentTypeId={el.getAttribute('data-content-type-id')}/>, el);
}
