import ResultListItemContainer from "./results-list-item-container";
import ResultListItemDetails from "./results-list-item-details";
import ResultListItemMeta from './results-list-item-meta';

import NavButton from '../nav-button/nav-button';

export default function ResultsListItem({ icon, metaOne = null, metaTwo = null, details = null, actions = null }) {

    const metasClass = 'results-list-item__metas';

    return (
        <ResultListItemContainer>
            <div className="results-list-item__main">
                {
                    icon ? icon : <img className="results-list-item__icon" src="/assets/document-icon.svg"/>
                }
                { details ? details : '' }
            </div>
            <div className={`${metasClass} ${metaOne ? metasClass + '--with-one' : ''} ${metaTwo ? metasClass + '--with-two' : ''}`}>
                { metaOne ? metaOne : '' }
                { metaTwo ? metaTwo : '' }
            </div>
            { actions ? <div className="results-list-item__actions">
                { actions }
            </div> : '' }
        </ResultListItemContainer>
    )
}
