import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

import Dropdown from '../dropdowns/dropdown';

export default function InputsDropdown(props) {
    const { label, placeholder, fill, items, value, onChange, onReset, reset, resetText, defaultSelected } = props;
    
    return (
        <LabelContainer label={label} fill={fill}>
            <ErrorContainer errors={props?.errors} fill={fill}>
                <Dropdown items={items} value={value} placeholder={placeholder} defaultSelected={defaultSelected} onChange={onChange} onReset={onReset} reset={reset} resetText={resetText} fit={fill}/>
            </ErrorContainer>
        </LabelContainer>
    )
}
