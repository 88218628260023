import Input from './input-with-label';

export default function InputCurrency(props) {
    const { fit, error, label, currencyType } = props;
    
    return (
        <div className="input-currency__outer" style={{ width: fit ? '100%' : 'unset'}}>
            { label && <p className="input-currency-outer__label">{ label }</p> }
            <div className="input-currency">
                <img className="input-currency__icon" src={`/assets/currency/${currencyType}.svg`} />
                <input type="number" { ...props } />
            </div>
            { error && error.length > 0 && <p className="input-currency-outer__error">{ error.map((err, index) => <p key={index}>{ err }</p> )}</p> }
        </div>
    )
}