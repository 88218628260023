import { useState, useContext } from 'react';

import Input from '../../../../components/inputs/inputs-text';
import InputsTextArea from '../../../../components/inputs/inputs-text-area';

import Popup from '../../../../components/popup';

import { DeleteButton, CancelButton } from '../../../../components/buttons';

import ELearningAPI from '../../../../lib/e-learning/e-learning-api';

import AdminAPI from '../../../../lib/admin/admin-api';
import AlertManager from '../../../../managers/alert/alert-manager';

import PopupContext from '../../../../context/context-popup';

export default function PopupsDeleteGroup({ group, fetchGroups }) {
    const { setPopup } = useContext(PopupContext);

    const confirm = () => {
        AdminAPI.deleteGroup(group.id, (err) => {
            AlertManager.error('There was an error deleting that group');
        }).then(({ id }) => {
            AlertManager.success('Successfully deleted the group');

            fetchGroups();
            setPopup(null);
        });
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Delete this group</h1>

                <div class="popup__buttons">
                    <DeleteButton responsive={false} onClick={confirm}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
