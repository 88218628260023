import { useState, useEffect, useRef } from 'react';

import Input from '../../../../../components/inputs/inputs-text';
import Dropdown from '../../../../../components/inputs/inputs-dropdown';
import InputFileSelect from '../../../../../components/inputs/inputs-file-select';

import { AddButton } from '../../../../../components/buttons';

import List from '../../../../../components/list/list';
import Row from '../../../../../utils/row';

import Divider from '../../../../../components/divider/divider';
import LabelContainer from '../../../../../components/label-container';

import ContainerGrid from '../../../../../containers/container-grid';
import Container from '../../../../../containers/container';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';

import { Editor } from '@tinymce/tinymce-react';

export default function CourseOverview({ onChange, summary }) {
    const [ courses, setCourses ] = useState([]);
    const editorRef = useRef(null);

    const courseList = summary?.additional_course_ids || [];

    const mappedCourses = () => {
        return courses?.map(course => {
            return {
                label: course.name,
                value: course.id,
            }
        })
    }

    const filteredCourses = () => {
        return mappedCourses().filter(({ value }) => {
            return !courseList?.includes(value);
        })
    }

    const onSummaryChange = (e) => {
        const { name, value } = e.target;

        summary[name] = value;
        
        onChange('summary', summary);
    }

    const addCourseDropdown = () => {
        let value = summary.hasOwnProperty('additional_course_ids') ? [ ...summary.additional_course_ids ] : []

        value.push(null);

        onSummaryChange({
            target: {
                name: 'additional_course_ids', 
                value: value
            }
        });
    }

    const onCourseAdd = (e, index) => {
        const { value } = e;

        let selectedCourses =  [ value ]

        if (courseList !== undefined) {
            selectedCourses =  [ ...summary.additional_course_ids ]

            if (value.length === 0) {
                selectedCourses.splice(index, 1);
            } else {
                selectedCourses[index] = value;
            }
        }

        onSummaryChange({
            target: {
                name: 'additional_course_ids', 
                value: selectedCourses
            }
        });
    }

    useEffect(() => {
        new ELearningApi().fetchCourses().then(({ data }) => setCourses(data));
    }, [])

    const mappedCourseList = mappedCourses();
    const filteredCoursesList = filteredCourses();

    return (
        <List gap="20px">
            <Row>
                <Input label="Success Banner Title" name="success_banner_title" onChange={onSummaryChange} value={summary?.success_banner_title} fill={true}/>
                <Input label="Success Banner Subtitle" name="success_banner_subtitle" onChange={onSummaryChange} value={summary?.success_banner_subtitle} fill={true}/>
            </Row>

            <Row>
                <LabelContainer label="Success Banner Description" fill={true}>
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={summary?.success_banner_description}
                        onEditorChange={(value, editor) => {
                            onSummaryChange({
                                target: {
                                    name: 'success_banner_description',
                                    value: value
                                }
                            });
                        }}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }
                        }
                    />
                </LabelContainer>
                <InputFileSelect label="Success Banner Image" name="success_banner_image" onChange={onSummaryChange} value={summary?.success_banner_image} fill={true}/>
            </Row>

            <Divider/>

            <Row>
                <Input label="CTA Banner Title" name="cta_banner_title" onChange={onSummaryChange} value={summary?.cta_banner_title} fill={true}/>
                <Input label="CTA Banner Subtitle" name="cta_banner_subtitle" onChange={onSummaryChange} value={summary?.cta_banner_subtitle} fill={true}/>
            </Row>

            <Row>
                <Input label="CTA Button 1 label" name="cta_button_one_label" onChange={onSummaryChange} value={summary?.cta_button_one_label} fill={true}/>
                <Input label="CTA Button 1 link" name="cta_button_one_link" onChange={onSummaryChange} value={summary?.cta_button_one_link} fill={true}/>
            </Row>

            <Row>
                <Input label="CTA Button 2 label" name="cta_button_two_label" onChange={onSummaryChange} value={summary?.cta_button_two_label} fill={true}/>
                <Input label="CTA Button 2 link" name="cta_button_two_link" onChange={onSummaryChange} value={summary?.cta_button_two_link} fill={true}/>
            </Row>

            <Divider/>

            <LabelContainer label="Other courses (these will show after the next courses on the learning path)">
                <ContainerGrid>
                    { courseList?.map((course, index) => {
                        return <Dropdown key={index} reset={true} label={`Course ${index + 1}`} fill={true} value={mappedCourseList.find(c => c.value === courseList?.[index])} items={filteredCoursesList} onChange={(e) => onCourseAdd(e, index)}/>
                    })}
                    <AddButton mobile={true} onClick={addCourseDropdown}/>
                </ContainerGrid>
            </LabelContainer>
        </List>
    )
}