import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../components/breadcrumb";
import SearchBox from "../../components/content-search/search-box";
import SearchResults from "../../components/content-search/search-results";
import { CreateButton } from '../../components/buttons';
import EventsApi from '../../lib/events/events-api';
import {nowUTCTimeStamp, stringToFriendlyDatetime} from "../../lib/helpers/datetime-helpers";
import NewEventPopup from "../../components/events/new-event-popup";

class Events extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            pagination: {},
            eventTypes:  [],
            page : 1,
            searchValue: '',
            meta: {},
            popup: false,
            upcoming : true
        };
    }


    componentDidMount() {
        this.getEvents();
        this.getEventTypes();
    }

    onChange(e) {
        this.setState({
            searchValue: e.target.value
        }, () => this.search());
    }

    getEventTypes() {
        EventsApi.types(1, 100).then(response => {
            this.setState({
                eventTypes : response.data
            });
        });
    }

    search() {

        if (this.state.searchValue.length >= 1) {

            EventsApi.search(this.state.searchValue, this.state.upcoming ?  nowUTCTimeStamp() : null).then(response => {
                this.setState({
                    results: response.data.map(item => {
                        return {
                            id: item.id,
                            type: stringToFriendlyDatetime(item.start),
                            tags: [],
                            title: item.name
                        }
                    })
                });
            });
        } else {
            this.getEvents();
        }

    }

    getEvents(page) {
        this.setState({page : page}, () => {
            EventsApi.getEvents(this.state.page, 20, this.state.upcoming ?  nowUTCTimeStamp() : null).then(response => {
                this.setState({
                    results: response.data.map(item => {
                        return {
                            id: item.id,
                            type: stringToFriendlyDatetime(item.start),
                            tags: [],
                            title: item.name
                        }
                    }),
                    meta: response.meta
                });
            });
        });

    }

    updateUpcoming() {
        this.setState({ upcoming : !this.state.upcoming}, () => {
            if(this.state.searchValue.length >= 1) {
                return this.search();
            }

            return this.getEvents();
        });
    }


    render() {
        const state = this.state;
        return (
            <>
                {this.state.popup && <NewEventPopup eventTypes={this.state.eventTypes} onClose={() => this.setState({popup : false})}/>}
                <div className="content-search">
                    <div className="website__topbar">
                        <Breadcrumb crumbs={[{
                            name: 'Events',
                            link: ''
                        }]}/>
                        <CreateButton onClick={() => this.setState({popup: true})}>Create New</CreateButton>

                    </div>
                    <div className="website__content">
                        <div className="content-search__inner">
                            <div className="content-searcher content-box">
                                <p className="content-box__title">Search your events</p>
                                <div className="content-searcher__search">
                                    <SearchBox onChange={this.onChange.bind(this)}/>
                                </div>
                                <SearchResults contentTypes={state.contentTypes} results={state.results}
                                               meta={state.meta} getItems={this.getEvents.bind(this)}
                                               indexScreen={state.indexScreen}
                                               formatLink={data => {
                                                   return `/events/${data.id}`;
                                               }}
                                               searchValue={state.searchValue} pagination={state.pagination}/>
                            </div>
                            <div className="content-filter content-box">
                                <p className="content-box__title">Filter</p>
                                <div className="content-filter__section">
                                    <h3 className="content-filter__title"></h3>
                                    <div className="content-types">
                                        <div
                                                className={"content-type " + (this.state.upcoming ? 'content-type--selected' : '')}
                                                onClick={this.updateUpcoming.bind(this)}>Upcoming</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Events;

const el = document.getElementById('events');

if (el) {
    ReactDOM
.

    render(

<Events/>,
    el
)
    ;
}
