import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import Settings from '../../js/components/content-distribution/settings';
import ScriptEditor from '../../js/components/content-distribution/script-editor';
import { CreateButton, CancelButton } from '../components/buttons';
import Breadcrumb from '../../js/components/breadcrumb';
import DistributionConfig from "./distributions/distribution-config";

import TabBar from '../../js/components/tab-bar';
import DistributionApi from '../lib/distributions/distribution-api';
import AlertManager from '../managers/alert/alert-manager';
import DistributionMenus from "./distributions/distribution-menus";
import NewMenuPopup from "./distributions/new-menu-popup";

const TABS = [
    'Settings',
    'Menus',
    'Script Editor',
    'Config'
];

class ContentDistributionsSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            distribution: {},
            configString: '',
            menuCreatePopup : false,
            errors: {},
            tab: 1,
            crumbs: [{
                name: 'Content | Distributions',
                link: '/content-distributions'
            },
                {
                    name: 'Name - Settings',
                    link: '#'
                }]
        };
    }

    componentDidMount() {
        this.getDistribution();
    }


    getDistribution() {
        let configString = '';
        DistributionApi.get(this.props.distributionId).then(data => {


            try {
                configString = JSON.stringify(data.config, null, 2);

            } catch (e) {
                AlertManager.error('Unable to process your config. Please check you code');
            }


            this.setState({
                configString: configString,
                distribution: data, crumbs: [{
                    name: 'Content | Distributions',
                    link: '/content-distributions'
                },
                    {
                        name: `${data.name} - ${TABS[this.state.tab]}`,
                        link: '#'
                    }]
            });
        });
    }

    goToDistributions() {
        window.location.href = '/content/distributions';
    }

    setTab(id) {
        this.setState((prevState) => {
            const crumbs = [...prevState.crumbs];

            crumbs[1].name = `${prevState.distribution.name} - ${TABS[id]}`;

            return {
                ...prevState,
                crumbs: crumbs,
                tab: id
            }
        })
    }

    handleSave() {

        let distribution = {...this.state.distribution};

        if (this.state.configString.length >= 1) {
            try {
                distribution.config = JSON.parse(this.state.configString);

            } catch (e) {
                console.error(e);
                AlertManager.error('Unable to save config. Please check you code');
            }
        }

        DistributionApi.update(this.state.distribution.id, distribution, errors => this.setState({errors})).then(response => {
            AlertManager.success('Distribution Saved');
        });
    }

    handleChange(key, value) {

        this.setState({distribution: Object.assign({}, this.state.distribution, {[key]: value})});
    }

    render() {
        return (
            <div class="content-distributions-settings">
                <div class="website__topbar">
                    <Breadcrumb crumbs={this.state.crumbs}/>
                    <div class="website-row">
                        {
                            this.state.tab === 1 &&
                            <CreateButton onClick={() => this.setState({menuCreatePopup : true})}>Create New Menu</CreateButton>

                        }
                        {
                            this.state.tab !== 1 &&
                            <CreateButton onClick={this.handleSave.bind(this)}>Save / Confirm</CreateButton>

                        }
                        <CancelButton onClick={this.goToDistributions}>Go Back</CancelButton>
                    </div>

                    {
                        this.state.menuCreatePopup &&
                        <NewMenuPopup distribution={this.state.distribution} onClose={() => this.setState({menuCreatePopup : false})} />

                    }
                </div>
                <div class="website__content">
                    <TabBar>
                        {TABS.map((name, index) => {
                            return <CreateButton key={index} onClick={() => this.setTab(index)}
                                           className={`${index == this.state.tab ? "button--alternate" : "button--white"} button__fit button__small`}>{name}</CreateButton>
                        })}
                    </TabBar>
                    {
                        this.state.tab == 0 &&
                        <Settings
                            distribution={this.state.distribution}
                            errors={this.state.errors}
                            onChange={this.handleChange.bind(this)}
                        />
                    }
                    {

                        (this.state.tab == 1 && this.state.distribution.id) &&
                        <DistributionMenus distribution={this.state.distribution} />
                    }
                    {

                        this.state.tab == 2 &&
                        <ScriptEditor
                            distribution={this.state.distribution}
                            errors={this.state.errors}
                            onChange={this.handleChange.bind(this)}
                        />
                    }
                    {
                        this.state.tab == 3 &&
                        <DistributionConfig
                            value={this.state.configString}
                            onChange={e => this.setState({configString: e})}/>

                    }
                </div>
            </div>
        )
    }
}

ContentDistributionsSettings.defaultProps = {
    distributionId: null
};


export default ContentDistributionsSettings;

const el = document.getElementById('content-distributions-settings');

if (el) {
    ReactDOM.render(<ContentDistributionsSettings distributionId={el.getAttribute('data-distribution-id')}/>, el);
}
