import React, {Component} from 'react';

class LoadingIcon extends Component {
    render() {
        return (
            <svg className={'loading-icon'} width="200px" height="200px" viewBox="0 0 100 100"
                 preserveAspectRatio="xMidYMid" {...this.props}>
                <circle cx="50" cy="50" fill="none" stroke="#c6c7d1" strokeWidth="3" r="35"
                        strokeDasharray="164.93361431346415 56.97787143782138">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                                      values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
        );
    }
}

export default LoadingIcon;
