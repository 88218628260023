export default function GrantQuestionTypes({ onQuestionType }) {
    const types = [
        {
            label: 'Text Input',
            type: 'text-input',
            icon: 'text',
        },
        {
            label: 'Table Input',
            type: 'table-input',
            icon: 'table'
        },
        {
            label: 'Checkboxes',
            type: 'checkboxes',
            icon: 'checkboxes'
        },
        {
            label: 'Radio Buttons',
            type: 'radio-buttons',
            icon: 'radio-buttons',
        },
        {
            label: 'Attach Docs',
            type: 'upload-documents',
            icon: 'upload-documents'
        },
        {
            label: 'Date Picker',
            type: 'date-picker',
            icon: 'date-picker'
        },
        {
            label: 'Dropdown',
            type: 'dropdown-selection',
            icon: 'dropdown-selection'
        },
        {
            label: 'Currency',
            type: 'currency-input',
            icon: 'currency-input'
        },
        {
            label: 'SIC Code',
            type: 'sic-code',
            icon: 'sic-code'
        }
    ]

    return (
        <div className="grant-question-types">
            { types.map(({ label, type, icon }, index) => {
                return (
                    <div key={index} className="grant-question-type" onClick={() => onQuestionType(type)}>
                        <img src={`/assets/question-types/question-type-${icon}.svg`} />
                        <p>{ label }</p>
                    </div>
                )
            })}
        </div>
    )
}