export default function Pill({ removable = false, onClick = () => {}, children, type = null }) {
    const clicked = (e) => {
        e.preventDefault();
        onClick();
    }

    return (
        <div className={`pill ${type !== null ? `pill--${type}` : ''}`} onClick={clicked}>
            { children }
            { removable ? <img src="/assets/x.svg" /> : '' }
        </div>
    )
}