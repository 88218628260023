import { Component } from 'react';

import SidePopup from '../../../../components/side-popup';
import SidePopupSection from '../../../../components/side-popup-section';
import { SaveButton } from '../../../../components/buttons';

import Input from '../../../../components/input-with-label';
import Dropdown from '../../../../components/dropdowns/dropdown';
import TextArea from '../../../../components/textarea-with-label';

import ApplicationApi from '../../../../lib/applications/application-api';
import AlertManager from "../../../../managers/alert/alert-manager";

import ProgressItem from '../../../../components/progress-item';

import Container from '../../../../containers/container';
import { stringToFriendlyDatetime } from '../../../../lib/helpers/datetime-helpers';

import List from '../../../../components/list/list';
import Row from '../../../../utils/row';

import Divider from '../../../../components/divider/divider';

export default class GrantApplicationFinalScoresBlock extends Component {
    constructor(props) {
        super(props);
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Grant scoring</p>
                    <span>></span>
                    <p>{ this.props.block?.title }</p>
                </div>
            </div>
        );
    }

    render() {
        const finalScore = this.props.scores?.block_scores?.filter(score => score.block_id === this.props.block_id);

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.props.setPopup(null)} content={
                <>
                    <List gap="20px">
                        <SidePopupSection title="Final Score">
                            { finalScore?.map((score, index) => {
                                return <ProgressItem 
                                    key={index} 
                                    left={
                                        <Row gap="15px">
                                            <img src="/assets/document-icon.svg"/>                                        
                                            <div className="progress-item__text">
                                                <p className="progress-item-text__title">{ score?.user?.name }</p>
                                                <p className="progress-item-text__subtitle">{ stringToFriendlyDatetime(score?.updated_at) }</p>
                                            </div>
                                        </Row>
                                    }
                                    right={
                                        <Container.Text fontSize="18px">Score: { score?.percentage_score }</Container.Text>
                                    }
                                    content={
                                        score?.comments !== null && <List gap="8px" style={{ marginLeft: '33px' }}>
                                            <Divider top="0px" bottom="0px"/>
                                            <Container.Text fontSize="14px" color="#151515">Comments</Container.Text>
                                            <Container.Text fontSize="12px" color="#C6C7D2">{ score?.comments }</Container.Text>
                                        </List>
                                    }
                                />
                            })}
                        </SidePopupSection>
                    </List>
                </>
            }/>
        )
    }
}
