import { useState } from 'react';

import { ProceedButton } from "../../../../../components/buttons"
import List from "../../../../../components/list/list"
import Container from "../../../../../containers/container"
import InputDate from '../../../../../components/input-date';

import ELearningApi from "../../../../../lib/e-learning/e-learning-api"
import ErrorContainer from '../../../../../components/error-container';

export default function BookingScreen({ fetchCourseWork, courseWork, module, question }) {
    const [ date, setDate ] = useState(null);
    const [ errors, setErrors ] = useState({});

    const obj = {
        course_work_id: courseWork.id,
        module_id: module.id,
        date: date,
    }

    const book = () => {
        new ELearningApi().bookManualAssessment(obj, (err) => {
            setErrors(err);
        }).then(fetchCourseWork);
    }

    return (
        <Container style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
        }}>
            <Container.Header style={{ marginBottom: '29px' }}>
                <Container.Text fontSize="30px" color="black">Manual Assessment Booking</Container.Text>
            </Container.Header>
            <Container.Content>
                <List gap="20px" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container.Text fontSize="18px" color="black" style={{ textAlign: 'center' }}>For you to proceed with this module you will have to book an in person exam</Container.Text>
                    <ErrorContainer errors={errors?.date} center={true}>
                        <InputDate
                            format={'YYYY-MM-DD HH:mm:ss'}
                            label={'Availability Window'}
                            value={date}
                            type="datetime-local"
                            onChange={(date) => setDate(date)}
                        />
                    </ErrorContainer>
                    { date !== null && <ProceedButton onClick={book}>Book Now</ProceedButton> }
                </List>
            </Container.Content>
        </Container>
    )
}