export default function LabelContainer({
    children,
    label,
    placeholder,
    className = '',
    grow = false,
    fill = false,
    fontSize = '16px' ,
    color = 'black',
    fontWeight = '400'
}) {
    return (
        <div className={`label-container ${className}`} style={{
            width: fill ? '100%' : 'inherit',
            flexGrow: grow ? grow : ''
        }}>
            { (label || placeholder) && <label style={{ fontSize, color, fontWeight }}>{ label || placeholder }</label> }

            { children }
        </div>
    )
}
