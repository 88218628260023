import { useEffect, useState } from 'react';

import Popup from '../../../../components/popup';

import GrantsApi from '../../../../lib/grants/grants-api';

import { DeleteButton, ProceedButton, CancelButton } from '../../../../components/buttons';


import Textarea from '../../../../components/textarea-with-label';

import React  from 'react';
import ApplicationApi from "../../../../lib/applications/application-api";

export default class GrantApplicationDecisionReject extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            rejection_message: '',
            errors: {}
        };

        this.api = new ApplicationApi();
    }


    handleSubmit() {
        this.api.reject(this.props.application.id, {
            total_application_score: this.props.totalApplicationScore,
            rejection_message: this.state.rejection_message
        }).then(() => {
            this.props.onRejection();
        });

    }

     onChange(e)  {
        const { value } = e.target;
        this.setState({ reason : value });
    }

    onChange(e) {
        const { name, value } = e.target;
        const state = { ...this.state }

        state[name] = value;
        
        this.setState(state);
    }

    render() {
        const { setPopup, grant } = this.props;
        return (
            <Popup type="application-decision">
                <div className="popup__inner">
                    <h1>You have chosen to reject application <span>{ grant.name }</span></h1>

                    <Textarea 
                        placeholder="Insert the reason here" 
                        label="Information for the applicant" 
                        name="rejection_message"
                        onChange={this.onChange.bind(this)} 
                        errors={this.state.errors}
                    />

                    <p>Once you confirm to proceed, the applicant will be notified of the application being rejected And this will not be reversible</p>

                    <div className="popup__buttons">
                        <ProceedButton responsive={false} onClick={this.handleSubmit.bind(this)}>Proceed</ProceedButton>
                        <CancelButton  responsive={false} onClick={() => setPopup(null)}>Cancel</CancelButton>
                    </div>
                </div>
            </Popup>
        );
    }


}
