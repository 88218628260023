import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [{
                title: '',
                type: '',
            }],
            default_row_count: false,
            min_rows_required : 0,
            allow_new_rows: false,
            max_row_count: false
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.addColumn = this.addColumn.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    save() {
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
    }

    onInputChange(e) {
        const { name, value } = e.target;

        const hasNumber = /\d/;

        let params = { [ name ]: value }

        if (hasNumber.test(name)) {
            const columns = [ ...this.state.columns ]
            const [ key, index ] = name.split('-');

            columns[index][key] = value;

            params = { columns }
        }

        this.setState(params, () => this.save());
    }

    addColumn() {
        const columns = [ ...this.state.columns ];

        columns.push({ title: '', type: '' });

        this.setState({ columns }, () => this.save());
    }


    deleteColumn(i) {
        const columns = [ ...this.state.columns ].filter((_, index) => index !== i);

        this.setState({ columns }, () => this.save());
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type='table' title="Table">
                    { this.state.columns.map((column, index) => {
                        return (
                            <div key={index} className="utils__row utils__wrap utils__gap--20">
                                <Input value={column.title}
                                        name={'title-' + index}
                                        label="Placeholder"
                                        placeholder="Text Value"
                                        onChange={this.onInputChange}/>


                                <span className="column__delete" onClick={() => this.deleteColumn(index)}>X</span>
                            </div>
                        )
                    })}
                    <div className="utils__rowCentered">
                        <AddButton onClick={this.addColumn}>Add Column</AddButton>
                    </div>
                </QuestionType>
                <QuestionType type='table' title="Table Settings">
                    <QuestionTypeField title="Row Count">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.min_rows_required}
                                   name="min_rows_required"
                                   min={1}
                                   label="Minimum of complete rows required (response required must be checked to take effect)"
                                   placeholder="0"
                                   type="number"
                                   onChange={this.onInputChange}/>
                        </div>
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.default_row_count}
                                    name="default_row_count"
                                    label="Default row count"
                                    placeholder="Text Value"
                                    type="number"
                                    min={1}
                                    onChange={this.onInputChange}/>
                        </div>
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Checkbox checked={this.state.allow_new_rows}
                                        name="allow_new_rows"
                                        text="Allow new rows to be added"
                                        onChange={this.onInputChange}/>
                        </div>
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.max_row_count}
                                    name="max_row_count"
                                    label="Maximum row count"
                                    placeholder="Text Value"
                                    type="number"
                                    min={1}
                                    onChange={this.onInputChange}/>
                        </div>
                    </QuestionTypeField>
                </QuestionType>
            </div>
        )
    }
}
