class MediaHelper {

    publicCDN;

    constructor(publicCDN) {
        this.publicCDN = publicCDN;
    }

    formatPublicPath(path) {
        return `${this.publicCDN}${path}`
    }

}


export default new MediaHelper(document.querySelector('meta[name="public-cdn-url"]').content);
