import { useState, useContext } from 'react';
import PopupContext from '../../../../../context/context-popup';

import Input from '../../../../../components/inputs/inputs-text';
import Checkbox from '../../../../../components/checkbox';

import Popup from '../../../../../components/popup';

import Row from '../../../../../utils/row';

import { ProceedButton, CancelButton } from '../../../../../components/buttons';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';
import LabelContainer from '../../../../../components/label-container';

import AlertManager from '../../../../../managers/alert/alert-manager';
import ErrorContainer from '../../../../../components/error-container';

export default function CourseModuleSave({ save, onSuccess }) {
    const { setPopup } = useContext(PopupContext);
    
    const [ errors, setErrors ] = useState(null);

    const clicked = () => {
        save().then(onSuccess).catch(err => {
            setErrors(err);
        });
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Confirm saving these changes</h1>
                <p>All changes saved will update on the live course, and learners will not be notified. If you require learners to restart the course, please ‘Publish’ a new version.</p>

                { errors !== null && <p style={{ color: 'rgb(255, 53, 86)', fontSize: '12px' }}>There is an error saving</p> }

                <div class="popup__buttons">
                    <ProceedButton responsive={false} onClick={clicked}>Confirm Changes</ProceedButton>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
