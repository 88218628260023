import React, {Component} from 'react';
import ContactsApi from '../../lib/contacts/contacts-api';
import {stringToFriendlyDatetime} from "../../lib/helpers/datetime-helpers";
import Pagination from "../pagination";

import { SendButton } from '../buttons';

class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            pagination: {},
            page: 1
        };
    }


    componentDidMount() {
        this.getMessages();
    }

    getMessages(page = this.state.page) {
        ContactsApi.getContactConversations(this.props.contact.id, page, 10).then(response => {
            console.log(response);
            this.setState({
                messages: response.data,
                pagination: response.meta
            })
        });

    }
    handlePaginationChange(p) {
        this.setState({page: p}, () => this.getMessages());
    }

    render() {
        return (
            <>
                <div className="account__block">
                    <div className="account__section account__section--full-width">
                        {
                            this.state.messages.map((m, i) => {
                                return(
                                    <div key={i} className={'account__information account__information--full-width'}>
                                        <p><strong>{m.subject}</strong> - ({stringToFriendlyDatetime(m.created_at)})</p>
                                        <p>{m.latest_messages[0].body}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>

                <div className="chatbox__input" style={{ opacity: 0.3, position: 'relative' }}>
                    <div style={{ position: 'absolute', left: '0px', top: '0px', width: '100%', height: '100%' }}></div>
                    <textarea placeholder="Enter message" disabled/>
                    <div className="chatbox-input__buttons">
                        <SendButton mobile={true} disabled/>
                    </div>
                </div>
                {/* <div className="account__block">


                {
                    this.state.messages.length >= 1 &&
                    <Pagination
                        pagination={this.state.pagination}
                        onChange={this.handlePaginationChange.bind(this)}
                    />
                }
                </div> */}
            </>

        );
    }
}

export default Messages;
