import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeUploadDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            radio_buttons: [{
                max_upload_amount: false
            }]
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    onInputChange(e) {
        const { name, value } = e.target;
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        let params = { [ name ]: value }

        this.setState(params, () => {
            this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
        });
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type='upload-documents' title="Upload Documents Settings">
                    <Field title="Upload Criteria">
                        <Input value={this.state.max_upload_amount} 
                            name="max_upload_amount" 
                            label="Maximum upload amount" 
                            placeholder="Text Value" 
                            type="number"
                            onChange={this.onInputChange}/>
                    </Field>
                </QuestionType>
            </div>
        )
    }
}   