import { Component } from 'react';
import ImagesPopup from '../popups/images/images-popup';
import MediaHelper from '../../lib/helpers/media';

export default class Gallery extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popup: false,
        }
    }

    onResourceSelect(r) {
        this.setState({ popup: false});
        let meta = Object.assign({}, this.props.meta);

        if (meta.hasOwnProperty('images')) {
            const imageInArray = meta.images.find(m => m.id === r.resource.id);

            if (!imageInArray) meta.images.push(r.resource);
        } else {
            meta.images = [ r.resource ]
        }

        this.onChange('', meta);
    }

    onChange(data, meta = {}) {
        const { config, sectionConfig, contentIndex, handleChange, fixed } = this.props;


        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: data !== null ? data : this.props.data,
                meta: Object.assign({}, this.props.meta, meta)
            },
            fixed: fixed,
        });
    }

    remove(id) {
        let meta = Object.assign({}, this.props.meta);

        if (meta.hasOwnProperty('images')) {
            const imageInArray = meta.images.find(m => m.id == id);

            if (imageInArray) {
                meta.images = meta.images.filter(i => i.id != id);
            }
        }

        this.onChange('', meta);
    }

    render() {
        const images = this.props.meta.images || [];

        return (
            <>
                { this.state.popup && <ImagesPopup onClose={() => this.setState({ popup : false })} onResourceSelect={this.onResourceSelect.bind(this)} onlyShow={['browse', 'upload']}/> }

                <div class="editor-gallery">
                    { images.map((image, index) => {
                        return (
                            <div key={index} style={{background : `url(${MediaHelper.formatPublicPath(`/images/${image.file_name}`)})`}} className="gallery-item" onClick={() => this.remove(image.id)}>

                                <div className="gallery-item__hover">X</div>
                                <div className="gallery-item__content">

                                </div>
                            </div>
                        )
                    })}
                    <div class="gallery-item gallery-item--dotted" onClick={() => this.setState({ popup : true})}>
                        <img src="/assets/plus.png"/>
                        <p>Add  More</p>
                    </div>
                </div>
            </>
        )
    }
}
