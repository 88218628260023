export default ({
                    message = 'Uh-oh!',
                    subtitle = 'It looks like there are no results for what you searched for.',
                    body = 'Please try refining your search or using the filters to find what you’re looking for.'
                }) => {
    return (
        <div class="no-data-error">
            <h1>{message}</h1>
            <b>{subtitle}</b>
            <p>{body}</p>
            <p>Need help? Contact Us</p>
        </div>
    )
}
