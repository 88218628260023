import { useEffect, useState, useContext } from 'react';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';

import Popup from '../../../../../components/popup';

import { CreateButton, CancelButton } from '../../../../../components/buttons';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';
import Divider from '../../../../../components/divider/divider';
import LabelContainer from '../../../../../components/label-container';

import Dropdown from "react-dropdown";
import PopupContext from '../../../../../context/context-popup';

export default function ELearningCreate({ learningPathId, learningPath = {}, onSuccess }) {
    const { setPopup } = useContext(PopupContext);
    
    const [ name, setName ] = useState(null);
    const [ host, setHost ] = useState(null);
    const [ course, setCourse ] = useState(null);

    const [ courses, setCourses ] = useState([]);

    const [ errors, setErrors ] = useState({});

    const addCourseToPath = async (id) => {
        return new ELearningApi().addCourseToLearningPath(learningPathId, { course_id: id });
    }

    const create = async () => {
       if (course !== null) {
        const action = await addCourseToPath(course.value);

        if (action) {
            onSuccess();
        }

        return;
       } 

       new ELearningApi().createCourse({ name, host_name: host }, (err) => {
        setErrors(err);
       }).then(async ({ id }) => {
        const action = await addCourseToPath(id);

        if (action) {
            onSuccess();
        }
       })
    }

    const loadCourses = async (value = '') => {
        return new ELearningApi().fetchCourses().then(({ data }) => {
            setCourses(data);

            let options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(key => !learningPath?.courses?.find(c => c.id === key.value))
                .filter(key => key.label.startsWith(value));

            return options;
        })
    }

    useEffect(() => {
        new ELearningApi().fetchCourses().then(({ data }) => setCourses(data));
    }, [])

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Add Course to Learning Path</h1>
                <p>Add a course to this learning path - you can then start building modules into the course for Learners to work through.</p>

                <Input label="Course Name" placeholder="Enter a value" value={name} onChange={(e) => setName(e.target.value)} errors={errors?.name}/>
                <Input label="Course Host" placeholder="Enter a value" value={host} onChange={(e) => setHost(e.target.value)} errors={errors?.host_name}/>

                <Divider/>

                <LabelContainer label="Add an existing course">
                    <Select
                        defaultOptions
                        value={course}
                        key={null}
                        onChange={(e) => setCourse(e)}
                        loadOptions={loadCourses}
                    />
                </LabelContainer>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
