import { Component } from 'react';

import SidePopup from '../../../components/side-popup';
import SidePopupSection from '../../../components/side-popup-section';
import { SaveButton } from '../../../components/buttons';

export default class GrantApplicationDetails extends Component {
    constructor(props) {
        super(props);

        this.head = (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Grant Details</p>
                    <span>></span>
                    <p>Grant Form Title</p>
                </div>
                <SaveButton></SaveButton>
            </div>
        )
    }

    render() {
        return (
            <SidePopup type="grant-details-popup" head={this.head} onClose={this.props.onClose} content={
                <>
                    <SidePopupSection title="General Details">

                    </SidePopupSection>

                    <SidePopupSection title="General Details">

                    </SidePopupSection>

                    <SidePopupSection title="General Details">

                    </SidePopupSection>

                    <SidePopupSection title="General Details">

                    </SidePopupSection>

                    <SidePopupSection title="General Details">

                    </SidePopupSection>
                </>   
            }/>
        )
    }
}