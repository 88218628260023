import { ExportButton, DocumentUploadButton } from '../../../components/buttons';

import Textarea from '../../../components/textarea-with-label';
import InformationSection from './grant-information-section';
import {GrantStatusHeader} from "./grant-status-header";

import React from 'react';

const STATUS_OFFER_REJECTED = 'offer rejected';

const STATUS_REJECTED = 'rejected';

const OPTIONS = {
    STATUS_OFFER_REJECTED: {
        value: STATUS_OFFER_REJECTED,
        label: 'Application Rejected: Offer rejected',
        message: 'This application is no longer accepted for claims processing. The grant manager should notify the applicant via email why this claim has been overturned to rejection.'
    },
    STATUS_REJECTED: {
        value: STATUS_REJECTED,
        label: 'Application Rejected',
        message: 'This application has been Rejected and can no longer be commented on or amended. If this was done in error, please contact an Admin. The applicant will see the comments input on the decision screen.'
    }
}
export default class GrantStatusRejected extends React.Component {
    constructor(props) {
        super(props);
    }

    getOption() {
        const option = _.find(OPTIONS, item => {
            return item.value === this.props.application.status;
        });

        return option === undefined ? null : option;
    }

    getMessage() {
        const option = this.getOption();

        return <p>{option ? option.message : `This application has been Rejected and can no longer be commented on or amended. If this was done in error, please contact an Admin. The applicant will see the comments input on the decision screen.`}</p>;
    }

    getTitle() {

        const option = this.getOption();

        return option !== null ? option.label : 'Application Rejected';
    }

    render() {
        const { application } = this.props;

        return (
            <GrantStatusHeader
                title={this.getTitle()}
                type={'rejected'}
                message={this.getMessage()}
            >
                <div className="utils__row utils__gap--20">
                    <InformationSection title="Information for the applicant">
                        <p>{application.rejection_message}</p>
                    </InformationSection>
                </div>
                <div className="utils__row utils__gap--20">
                    <InformationSection title="Application Output">
                        <ExportButton onClick={() => window.location = `/grants/applications/${this.props.application.id}/pdf`}>Export as PDF</ExportButton>
                    </InformationSection>
                    <InformationSection title="Information">
                        <DocumentUploadButton onClick={() => this.props.toggleDocumentUploads(true)}>Documents Uploads</DocumentUploadButton>
                    </InformationSection>
                </div>
            </GrantStatusHeader>
        );
    }

}
