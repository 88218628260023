import ApiBase from "../api-base";

export default class ApplicationApi extends ApiBase {

    async get(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}`);

            return response.data.data;
        });
    }

    async history() {
        const response = await axios.get(`/api/applications/history`);

        return response.data.data;
    }

    async applicationHistory(id, page, per_page) {
        const response = await axios.get(`/api/applications/${id}/history`, {params: {page, per_page}});

        return response.data;
    }

    async getApplications(params) {
        const response = await axios.get(`/api/applications`, { params });

        return response.data.data;
    }

    async getApplicationsWithMeta(params) {
        const response = await axios.get(`/api/applications`, { params });

        return response.data;
    }

    async getApplicationsByTerm(params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/search`, params);

            return response.data.data;
        })
    }

    async getComments(id, page = 1, per_page = 100, question_id = null) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/comments`, {params: {page, per_page, question_id}});

            return response.data;
        });
    }

    async postComments(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/comments`, params);

            return response.data.data;
        });
    }

    async markAsRead(id, ids = []) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/comments/mark-read`, {
                application_comment_ids: ids
            });

            return response.data.data;
        });
    }

    async makeAmmend(id, questionId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/amendments`, {
                question_id: questionId
            });

            return response.data.data;
        });
    }

    async getAmends(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/amendments`);

            return response.data.data;
        });
    }

    async archiveAmend(id, amendId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/amendments/${amendId}/archive`);

            return response.data.data;
        })
    }

    async addScorer(id, userId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/add-scorer`, {
                user_id: userId
            });

            return response.data.data;
        })
    }

    async removeScorer(id, userId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/remove-scorer`, {
                user_id: userId
            });

            return response.data.data;
        })
    }

    async getScorers(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/scorers`);

            return response.data.data;
        });
    }

    async getScores(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/scores`, {
                per_page: 100,
            });

            return response.data.data;
        });
    }

    async startScoring(id) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/scores/start`);

            return response.data.data;
        })
    }

    async scoreBySection(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/scores/by-section`);

            return response.data.data;
        })
    }

    async allScores(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/scores/all`);

            return response.data;
        })
    }
    
    async allFinalScores(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/applications/${id}/scores/final`);

            return response.data;
        })
    }

    async scoreQuestion(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/scores`, params);

            return response.data.data;
        })
    }

    async scoreBlock(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/scores/block`, params);

            return response.data.data;
        })
    }

    async scoreFinal(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/scores/final`, params);

            return response.data.data;
        })
    }

    async accept(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/accept`, params);

            return response.data.data;
        }, validationCallback)
    }

    async reject(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/reject`, params);

            return response.data.data;
        })
    }

    async setClaimStatus(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/applications/${id}/claims-status`, params);
            return response.data.data;
        })
    }

}
