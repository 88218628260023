import { Component } from 'react';

import SidePopup from '../../../../components/side-popup';
import SidePopupSection from '../../../../components/side-popup-section';
import { ViewButton, SaveButton } from '../../../../components/buttons';

import Input from '../../../../components/input-with-label';
import Dropdown from '../../../../components/dropdowns/dropdown';
import TextArea from '../../../../components/textarea-with-label';

import ApplicationApi from '../../../../lib/applications/application-api';
import AlertManager from "../../../../managers/alert/alert-manager";

import ResultsListItem from '../../../../components/results/results-list-item';
import ResultListItemDetails from '../../../../components/results/results-list-item-details';
import ResultsListItemMeta from '../../../../components/results/results-list-item-meta';
import NavButton from '../../../../components/nav-button/nav-button';

import { datetimeLocalToString } from '../../../../lib/helpers/datetime-helpers';

import Row from '../../../../utils/row';

import LabelContainer from '../../../../components/label-container';

import PopupContext from '../../../../context/context-popup';

export default class ELearningView extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>{ this.props.path?.name }</p>
                </div>
                <ViewButton onClick={() => window.location.href = '/e-learning/learning-paths/' + this.props.path?.id}>Manage</ViewButton>
            </div>
        );
    }

    render() {
        const { path } = this.props;
        const { setPopup } = this.context;

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => setPopup(null)} content={
                <>
                    <SidePopupSection>
                        <Row>
                            {/* <LabelContainer label="Created By" fill={true}>
                                <p>User name</p>
                            </LabelContainer> */}

                             <LabelContainer label="Date Created" fill={true}>
                                <p>{ datetimeLocalToString(path?.created_at) }</p>
                            </LabelContainer>
                        </Row>

                    </SidePopupSection>

                    <SidePopupSection title="Path Managers">
                        <p>{ path?.managers?.map(manager => manager?.name).join(',') }</p>
                    </SidePopupSection>

                    <SidePopupSection title="Overview">
                        <p dangerouslySetInnerHTML={{ __html: path?.description }}></p>
                    </SidePopupSection>x

                    <SidePopupSection title="Courses">
                        { path?.courses?.map((data, index) => {
                            const modules = data?.latest_course_versions?.[0]?.modules;

                            return (
                                <ResultsListItem
                                    key={index}
                                    icon={<img src="/assets/e-learning-course-icon.svg"/>}
                                    details={<ResultListItemDetails title={data?.name || ''} subtitle={`${modules?.length || 0} Modules`}/>}
                                    metaTwo={<ResultsListItemMeta data={['']}/>}
                                    metaOne={<ResultsListItemMeta data={['Estimated Time: ' + (data?.overview?.est_time || 'Not Set')]} />}
                                    actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/courses/${data.id}`}/>}/>
                            )
                        })}
                    </SidePopupSection>
                </>
            }/>
        )
    }
}
