import Dropdown from 'react-dropdown';
import ResultItem from './result-item';
import SearchResultsError from './search-results-error';

export default ({ results = [], meta, indexScreen = true, getItems, searchValue, formatLink = null}) => {
    const getOptions = () => {
        const items = meta.links.filter((_, index) => index > 0 && index < meta.links.length - 1);
        const newItems = items.map((item) => ({
            value: item.label,
            label: item.label,
            active: item.active,
        }));

        return newItems;
    }

    const paginationChange = (e) => {
        getItems(e.value);
    }


    return (
         results.length > 0 ?
            <>
                { !indexScreen && <p class="content-searcher__results">We’ve found { results.length } results for search term { searchValue }</p> }
                <div class="results-outer">
                    { results.map((data, index) => <ResultItem key={index} formatLink={formatLink} data={data}/>) }
                </div>
                { indexScreen && <div class="content-searcher__bottom">
                    <button disabled={meta.current_page == 1} class={"button button__fit " + (meta.current_page == 1 ? 'button--idle' : 'button--alternate')} onClick={() => getItems(meta.current_page - 1)}>Previous Page</button>
                    <Dropdown className="input input--idle" options={getOptions()} onChange={paginationChange} value={String(meta.current_page)}/>
                    <button disabled={meta.current_page == meta.last_page} class={"button button__fit " + (meta.current_page == meta.last_page ? 'button--idle' : 'button--alternate')} onClick={() => getItems(meta.current_page + 1)}>Next Page</button>
                </div> }
            </> :
                <SearchResultsError/>

    )
}
