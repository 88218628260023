import { Component } from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

export default class extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (value) => {
        const { config, sectionConfig, contentIndex, fixed} = this.props;


        this.props.handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {body : value, meta: {}},
            fixed : fixed
        });

    }

    render() {
        const { data } = this.props;

        return (
            <CodeMirror
                value={data}
                height="100%"
                extensions={[html()]}
                onChange={this.onChange.bind(this)}
            />
        )
    }
}
