import {useEffect, useState} from 'react';

import Popup from '../../../../components/popup';

import GrantsApi from '../../../../lib/grants/grants-api';

import {DeleteButton, ProceedButton} from '../../../../components/buttons';

import ApproveDecision from './grant-application-decision-approve';
import RejectDecision from './grant-application-decision-reject';

export default ({setPopup, grant, application, totalApplicationScore, onAcceptance, onRejection}) => {
    return (
        <Popup type="application-decision" onClose={setPopup}>
            <div class="popup__inner">
                <h1>Application <span>{grant.name}</span> Decision</h1>
                <p>Please select your decision for this application from the options below</p>

                <div class="popup__buttons">
                    <ProceedButton responsive={false}
                                   onClick={() => setPopup(<ApproveDecision
                                       application={application}
                                       onAcceptance={onAcceptance}
                                       totalApplicationScore={totalApplicationScore}
                                       grant={grant}
                                       setPopup={setPopup}/>)}
                                   color="green"> Accept Application</ProceedButton>
                    <DeleteButton responsive={false}
                                  onClick={() => setPopup(<RejectDecision grant={grant} application={application}
                                                                          onAcceptance={onAcceptance}
                                                                          onRejection={onRejection}
                                                                          totalApplicationScore={totalApplicationScore}
                                                                          setPopup={setPopup}/>)}>Reject
                        Application</DeleteButton>
                </div>
            </div>
        </Popup>
    )
}
