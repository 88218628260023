import { useState } from 'react';

import Module from "./module"

import Row from '../../../../../utils/row';
import List from "../../../../../components/list/list"
import TabBar from "../../../../../components/tab-bar"
import { BigPill, SmallPill } from '../../../../../components/pills';

export default function Modules({ resetManualState, nextModules = [], videoSessions, setManualState, manualState, tab, setTab, fetchCourseWork, modules = [], courseWork = {} }) {
    return (
        <List gap="20px">
            <TabBar>
                <Row gap="20px" alignItems="center">
                    { nextModules.map((moduleTab, key) => {
                        const index = modules.findIndex(m => m.id === moduleTab.id);

                        let Component = SmallPill;

                        if (tab === index) {
                            Component = BigPill;
                        }
                        
                        return (
                            <Component key={key} onClick={() => setTab(index)}>
                                <div className={'small-pill__inner'}>
                                    { courseWork?.module_requiring_assessment === moduleTab.id &&
                                    <div className="pill__spans">
                                        <div
                                            className={`small-pill__span-item small-pill__span-item--with-margin`}>
                                            <svg className={'small-pill__icon'} data-name="Group 1025"
                                                xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                viewBox="0 0 15 15">
                                                <path id="Path_379" data-name="Path 379" d="M0,0H15V15H0Z"
                                                    fill="none"/>
                                                <path id="Path_380" data-name="Path 380"
                                                    d="M11.929,5.121a3.168,3.168,0,0,0,1.889.224V11.45a.572.572,0,0,1-.591.55H2.591A.572.572,0,0,1,2,11.45V2.65a.572.572,0,0,1,.591-.55h7.741a2.564,2.564,0,0,0-.059.55,2.629,2.629,0,0,0,.7,1.783L7.945,6.876,4.155,3.881l-.765.838L7.952,8.324Zm1.3-.821a1.715,1.715,0,0,1-1.773-1.65A1.777,1.777,0,0,1,15,2.65,1.715,1.715,0,0,1,13.227,4.3Z"
                                                    fill="#fff"/>
                                            </svg>
                                            <div className={'small-pill__span-item-counter'}></div>

                                        </div>
                                    </div>
                                    }

                                    { courseWork.module_requiring_assessment_booking === moduleTab.id &&
                                    <div className="pill__spans">
                                        <div
                                            className={`small-pill__span-item small-pill__span-item--with-margin`}>
                                                <svg className={'small-pill__icon'} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                                                    <path id="Path_431" data-name="Path 431" d="M11,7h2V17H11Zm4,4h2v6H15ZM7,13H9v4H7Zm8-9H5V20H19V8H15ZM3,2.992A1,1,0,0,1,4,2H16l5,5V20.993A1,1,0,0,1,20.007,22H3.993A1,1,0,0,1,3,21.008Z" transform="translate(-3 -2)" fill="#FFFFFF"/>
                                                </svg>
                                            <div className={'small-pill__span-item-counter'}></div>

                                        </div>
                                    </div>
                                    }

                                    <div className={'small-pill__text'}>
                                        Module { index + 1 }
                                    </div>
                                    <div className={'small-pill__arrow'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.792" height="11.412" viewBox="0 0 6.792 11.412">
                                            <g id="Button_-_Arrow" data-name="Button - Arrow" transform="translate(0.706 0.706)">
                                                <path id="Path_2" data-name="Path 2" d="M11.5,45.5l-5,5.586L1.5,45.5" transform="translate(-45.5 11.5) rotate(-90)" fill="none" stroke="#fcf7ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            </g>
                                        </svg>

                                    </div>

                                </div>
                            </Component>
                        )
                    })}
                </Row>
            </TabBar>

            <Module 
                resetManualState={resetManualState}
                fetchCourseWork={fetchCourseWork} 
                module={modules[tab]} 
                courseWork={courseWork}
                manualState={manualState}
                setManualState={setManualState}
                videoSessions={videoSessions}
            />
        </List>
    )
}