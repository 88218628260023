import { useState, useEffect } from 'react';

import { UploadButton, DeleteButton, ChangeButton } from './buttons';

import ImagesPopup from "./popups/images/images-popup";

export default function FileUpload({ 
    onChange = (_, func) => { func() }, 
    value = null, 
    onView = () => {}, 
    onFileDelete = () => {},
    readOnly = false,
    onClick = () => {}
}) {
    const [ showFileUploader, setShowFileUploader ] = useState(false);

    return (
        <>
            { showFileUploader && <ImagesPopup
                onClose={() => setPopup(null)}
                onResourceSelect={r => {
                    setPopup(null);

                    onChange({
                        target: {
                            name: name,
                            value: r.resource.file_name
                        }
                    })
                }}
                onlyShow={['browse', 'upload']}
            /> }

            <div className="document-upload__outer" style={{ cursor: readOnly ? 'pointer' : 'unset' }}>
                <div className="document-upload__container" onClick={onClick}>
                    <div onClick={onView} className={`document-upload ${value !== null ? 'document-upload--successfull' : ''}`}>
                        <img src={"/assets/" + (value !== null ? 'document.svg': 'plus.svg')} />
                    </div>
                    { value !== null && <p className="document-upload__file">{value?.file?.name}</p> }
                </div>
                { !readOnly && <div className="document-upload__inner">
                    { value !== null ?
                        <>
                            <DeleteButton onClick={onFileDelete}/>
                        </> :
                        <UploadButton onClick={() => setShowFileUploader(true)}/>
                    }
                    <p className="document-upload-inner__text">Files must be below 5mb</p>
                </div> }
            </div>
        </>
    )
}
