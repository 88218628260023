import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";

import { MinMaxLayout } from "../../../../containers/layout";

import { CreateButton, SaveButton, DeleteButton, ProceedButton, AddButton } from "../../../../components/buttons";

import ContentContainer from "../../../../components/layout/content-container";

import ELearningApi from '../../../../lib/e-learning/e-learning-api';

import CourseRemove from '../../components/popups/course/course-remove';
import CourseEdit from '../../components/popups/course/course-edit';
import CourseModuleAdd from '../../components/popups/course/course-module-add';
import CoursePublish from '../../components/popups/course/course-publish';
import CourseSave from '../../components/popups/course/course-save';

import { stringToDatetimeLocal } from '../../../../lib/helpers/datetime-helpers';

import AlertManager from '../../../../managers/alert/alert-manager';

import CourseContent from './course-content';

import PopupContext, { PopupProvider } from '../../../../context/context-popup';

import Row from '../../../../utils/row';

class CoursePage extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            path: null,
            course: null,
            popup: null,
            modules: [],
            crumbs: [
                {
                    name: 'E-Learning',
                    link: ''
                },
                {
                    name: 'Learning Paths',
                    link: '',
                }
            ]
        }
    }

    componentDidMount() {
        this.fetchCourse();
    }

    async fetchPath() {
        const path = await new ELearningApi().getLearningPath(this.props.pathId);

        if (path) {
            this.setState({ path: path.data });
        }
    }

    async fetchCourse() {
        const course = await new ELearningApi().fetchCourse(this.props.courseId);

        if (course) {
            this.setState({ course: course.data });
        }
    }

    async save(course = null) {
        const courseVersions = this.state.course.latest_course_versions;
        const contentVersion = courseVersions[0];

        let modules = contentVersion?.modules;

        let params = {
            ...this.state.course,
            modules,
        };

        if (course !== null) {
            params = {
                ...course,
                modules,
            }
        }

        params.company_programme_eligibility = params.company_programme_eligibility.map(item => {
            if(typeof item === 'object') {
                return item.id;
            }

            if (typeof item === 'number') {
            return item;
            }
        });

        if (params.overview === null ||
            params.overview === undefined ||
            typeof params.overview === 'object' &&
            Object.keys(params.overview) === 0) {
                delete params.overview;
            }

        params.course_prerequisites = params?.course_prerequisites?.map(p => p.id);
        params.e_learning_tag_ids = params?.e_learning_tags?.map(p => p.id);

        return new Promise((resolve, reject) => {
            Promise.all([
                new ELearningApi().editCourse(this.state.course.id, params, (err) => reject(err)),
                new ELearningApi().syncManagers(this.state.course.id, {
                    user_ids: params?.course_managers?.map(p => p.id)
                }, (err) => reject(err)),
            ]).then((data) => {
                AlertManager.success('Sucessfully saved your course');
    
                this.setState({ course: data[0], modules: [] })

                resolve();
            });
        });
    }

    onChange(name, value) {
        this.setState({
            course: {
                ...this.state.course,
                [ name ] : value,
            }
        })
    }

    onModuleAdd(type) {
        const courseVersions = [ ...this.state.course.latest_course_versions ];
        const contentVersion = courseVersions[0];

        let modules = contentVersion?.modules;

        modules.push({
            type : type,
            name : "New module",
            sections : []
        });

        this.onChange('latest_course_versions', courseVersions);
    }

    addModule() {
        const { setPopup } = this.context;

        setPopup(
            <CourseModuleAdd
                course={this.state.course}
                onSuccess={(type) => {
                    this.onModuleAdd(type);
                    setPopup(null);
                }}
            />
        )
    }

    publishCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CoursePublish
                id={this.state.course.id}
                onSuccess={() => {
                    setPopup(null);
                    this.fetchCourse();
                }}
            />
        )
    }

    saveCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CourseSave
                save={this.save.bind(this)}
                onSuccess={() => {
                    setPopup(null);
                }}
            />
        )
    }

    editCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CourseEdit
                course={this.state.course}
                save={this.save.bind(this)}
                onSuccess={() => {
                    setPopup(null);
                }}
            />
        )
    }

    removeCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CourseRemove
                id={this.state.course.id}
                onSuccess={() => {
                    setPopup(null);
                    window.location.href = '/e-learning/' + this.state.path.id
                }}
            />
        )
    }

    render() {
        const crumbs = [
            {
                name: 'E-Learning',
                link: ''
            },
            {
                name: 'Learning Paths',
                link: '',
            },
            {
                name: this.state.course?.name,
                link: ''
            }
        ]

        if (this.state.course === null) return '';

        return (
            <PageLayout
                topLeft={<Breadcrumb crumbs={crumbs}/>}
                middle={
                    <Row justifyContent='space-between' wrap={true}>
                        <Row gap='20px'>
                            <CreateButton onClick={this.editCourse.bind(this)}>Course Details</CreateButton>
                            <AddButton onClick={this.addModule.bind(this)}>Add Module</AddButton>
                        </Row>

                        <Row gap='20px'>
                            <div className='grant-editor__published'>
                                Published Version: { this.state.course?.published_course_version_id || '...' }<br/>
                                Published: { stringToDatetimeLocal(this.state.course?.updated_at) }<br/>
                            </div>
                        </Row>

                        <Row gap='20px'>
                            <ProceedButton onClick={this.publishCourse.bind(this)}>Publish</ProceedButton>
                            <SaveButton mobile={true} onClick={this.saveCourse.bind(this)}/>
                            <DeleteButton onClick={this.removeCourse.bind(this)}/>
                        </Row>
                    </Row>}>
                    <MinMaxLayout
                        main={<CourseContent
                            save={this.save.bind(this)}
                            course={this.state.course}
                            onChange={this.onChange.bind(this)}
                        />}
                    />
            </PageLayout>
        );
    }
}

const el = document.getElementById('course-page');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <CoursePage courseId={el.getAttribute('courseId')}/>
        </PopupProvider>    
    , el);
}
