import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import ContactsApi from '../lib/contacts/contacts-api';
import Breadcrumb from "../components/breadcrumb";
import CompanyItem from "../components/companies/company-item";
import NoDataError from "../components/no-data-error";
import Pagination from "../components/pagination";

class Contacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crumbs: [{
                name: 'Contacts',
                link: '/contacts'
            },
                {
                    name: 'View All',
                    link: '#'
                }],
            contacts: [],
            pagination: {},
            page:  1
        };
    }


    componentDidMount() {
        this.getContacts();
    }

    getContacts(page = this.state.page) {
        ContactsApi.getContacts(page).then(response => {
            this.setState({
                pagination: response.meta,
                contacts: response.data
            });
        })
    }

    handlePaginationChange(e) {
        this.setState({page: e}, () => this.getContacts());
    }


    render() {
        return (
            <div className={'companies-outer'}>
                <div className="website__topbar">
                    <Breadcrumb crumbs={this.state.crumbs}/>
                </div>
                <div className="website__content">
                    <div className="companies__content">
                        {this.state.contacts.length > 0 ? this.state.contacts.map((c, index) => {
                                const params  = {
                                    key: index,
                                    title: `${c.first_name} ${c.last_name}`,
                                    infoOne : c.email,
                                    infoTwo : c.mobile_number
                                };

                                return (<CompanyItem
                                    {...params }
                                    onClick={() => window.location = `/contacts/${c.id}`} />);
                            }) :
                            <NoDataError message='No contacts found'/>}
                    </div>
                </div>
                {
                    this.state.contacts.length >= 1 &&
                    <Pagination
                        pagination={this.state.pagination}
                        onChange={this.handlePaginationChange.bind(this)}
                    />
                }
            </div>
        );
    }
}

export default Contacts;

const el = document.getElementById('contacts');

if (el) {
    ReactDOM.render(<Contacts/>, el);
}

