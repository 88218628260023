import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { MinMaxLayout, SeventyThirtyLayout } from "../../../../containers/layout";


import ContentContainer from "../../../../components/layout/content-container";
import Container from '../../../../containers/container';

import { CreateButton, ViewButton, ModifyButton, DeleteButton } from '../../../../components/buttons';

import Sponsors from '../../../../components/sponsors';

import Navigation from '../../../grants/components/grants-application-navigation';

import SearchBarItem from '../../../../components/search/search-bar-item';

import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import ELearningFilters from '../../components/e-learning-path-filters';

import ResultsList from "../../../../components/results/results-list";
import ResultsListItem from "../../../../components/results/results-list-item";
import ResultsListItemMeta from '../../../../components/results/results-list-item-meta';
import ResultListItemDetails from "../../../../components/results/results-list-item-details";

import NavButton from '../../../../components/nav-button/nav-button';

import Row from '../../../../utils/row';

import IndexCard from '../../../../components/index/index-card';

import { stringToFriendlyDatetime } from '../../../../lib/helpers/datetime-helpers';
import ContainerGrid from '../../../../containers/container-grid';

import PopupContext, { PopupProvider } from '../../../../context/context-popup';
import ELearningCourseCreate from '../../components/popups/course/course-create';

import CourseDelete from '../../components/popups/course/course-delete';

const crumbs = [
    {
        name: 'E-Learning',
        link: '/e-learning'
    },
    {
        name: 'Courses',
        link: '/e-learning/courses'
    }
]

class Courses extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            meta: {},
            popup: null,
            loading: true,
            currentPage: 1,
            order_by: null,
            direction: null,
            term: '',
        };
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let params = {
            term: this.state.term.length !== 0 ? this.state.term : null
        }

        if (this.state.order_by !== null) {
            params.order_by = this.state.order_by.value;
        }

        if (this.state.direction !== null) {
            params.direction = this.state.direction.value;
        }

        await new ELearningAPI().fetchCourses(params).then(({ data, meta }) => {
            this.setState({ courses: data, meta });
        });
    }

    createCourse() {
        const { setPopup } = this.context;

        setPopup(<ELearningCourseCreate onSuccess={() => {
            this.fetch();
            setPopup(null);
        }}/>);
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.meta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    onSearchChange(e) {
        const { value } = e.target;

        this.setState({ term: value }, () => {
           this.fetch();
        });
    }

    onFilterBy(e) {
        this.setState({ direction: e }, () => this.fetch());
    }

    onSortBy(e) {
        this.setState({ order_by: e }, () => this.fetch());
    }

    getStatus(courseWork) {
        if (courseWork.module_requiring_assessment?.length > 0) return <Container.Text color="#FF7612">Assessment requires marking</Container.Text>
        if (courseWork.module_requiring_assessment_booking?.length > 0) return <Container.Text color="#FF7612">Requires In person Assessment booking</Container.Text>

        if (courseWork.passed !== null) {
            return courseWork.passed === 1 ? <Container.Text color="#00C8C3">Passed</Container.Text> : <Container.Text color="#ED2855">Failed</Container.Text>;
        }

        return <Container.Text color="#FF7612">Awaiting Submission</Container.Text>;
    }

    deleteCourse(id) {
        this.context.setPopup(<CourseDelete id={id} onSuccess={() => {
            this.fetch();
            this.context.setPopup(null);
        }}/>)
    }

    render() {

        return (
            <ContentContainer
                title={'Course'}
                count={this.state.courses.length}
                icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                top={<div className="grant-index-top utils__rowSpaceBetween">
                    <SearchBarItem showButton={false} color="search-bar--white" onChange={this.onSearchChange.bind(this)}/>
                    <CreateButton onClick={this.createCourse.bind(this)}>Create Course</CreateButton>
                </div>}

                header={<><ELearningFilters
                    onFilterBy={this.onFilterBy.bind(this)}
                    onSortBy={this.onSortBy.bind(this)}
                    sortBy={this.state.order_by}
                    filterBy={this.state.direction}
                /></>}

                bottom={<Navigation back={this.goBack.bind(this)}
                                    next={this.goNext.bind(this)}
                                    setPage={this.setPage.bind(this)}
                                    meta={this.state.meta}
                                    backDisabled={this.state.currentPage == 1}
                                    nextDisabled={this.state.currentPage == this.state.meta?.last_page}/>}>
                <div className="grant-cards">
                    { this.state.courses.map((course, index) => {
                        return <IndexCard
                            key={index}
                            title={course?.name}
                            subtitle={`Difficulty: ${course?.difficulty || 'Not Set'}`}
                            meta={''}
                            info={`${course.latest_course_versions?.[0]?.modules?.length || '0'} modules`}
                            status={course?.archived ? <p className="color--red">Archived</p> : <p className="color--green">Active</p>}
                            buttons={
                                <Row gap="20px">
                                    <ViewButton onClick={() => window.location.href = '/e-learning/courses/' + course.id}>View</ViewButton>
                                    <DeleteButton mobile={true} onClick={() => this.deleteCourse(course.id)}/>
                                </Row>

                            }
                        />
                    })}
                </div>
            </ContentContainer>
        );
    }
}

const App = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'e-learning-index'}>
                <MinMaxLayout main={<Courses/>}/>
            </div>
        </PageLayout>

    );
}

const el = document.getElementById('courses');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <App/>
        </PopupProvider>    
    , el);
}
