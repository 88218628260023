import GrantCard from './grant-card';
import {ModifyButton, ProceedButton, ViewButton} from "../../../components/buttons";
import {stringToFriendlyDatetime} from "../../../lib/helpers/datetime-helpers";
import dayjs from "dayjs";

export default function GrantCards({grants = []}) {
    return (
        <div className="grant-cards">
            {grants.map((grant, index) => {
                let info = '';
                let status = 'Closed';

                if(grant.available_from || grant.available_to) {

                    if(grant.available_from) {
                        info += 'Available from ' + stringToFriendlyDatetime(grant.available_from);
                    }

                    if(dayjs(grant.available_from).isBefore(dayjs()) &&  dayjs().isBefore(dayjs(grant.available_to))) {
                        status = 'Open';
                    }


                    if(grant.available_to) {
                        info += ' to ' + stringToFriendlyDatetime(grant.available_to);
                    }
                }

                let meta = grant.published === null ? 'Editing' : 'Published';


                return <GrantCard
                    key={index}
                    title={grant.name}
                    subtitle={grant.funding_body}
                    meta={meta}
                    info={info}
                    status={status}
                    buttons={
                        <>
                            <ViewButton onClick={() => window.location = `/grants/view/${grant.id}`}/>
                            {/*<div className="utils__row">*/}
                            {/*    <ModifyButton mobile={true}/>*/}
                            {/*    <ProceedButton mobile={true}/>*/}
                            {/*</div>*/}
                        </>

                    }
                />
            })}
        </div>
    )
}
