import { useEffect, useState } from 'react';

import Popup from '../../../../components/popup';

import GrantsApi from '../../../../lib/grants/grants-api';

import { DeleteButton, ProceedButton } from '../../../../components/buttons';

import ApproveDecision from './grant-application-decision-approve';
import RejectDecision from './grant-application-decision-reject';

import AdminAPI from '../../../../lib/admin/admin-api';
import ApplicationAPI from '../../../../lib/applications/application-api';

import Select from 'react-select';
import auth from "../../../../lib/users/auth";

const AppAPI = new ApplicationAPI();

export default ({ setPopup, grant, application }) => {
    const { min_required_scorers = 3 } = grant;

    const [ options, setOptions ] = useState([]);
    const [ assessors, setAssessors ] = useState([])
    const [ users, setUsers ] = useState([]);

    const onChange = ({ value }) => {
        AppAPI.addScorer(application.id, value).then(() => updateScorersAndOptions());
    }

    const deleteAssessor = (id) => {
        AppAPI.removeScorer(application.id, id).then(() => updateScorersAndOptions());
    }

    const updateScorersAndOptions = () => {
        AppAPI.getScorers(application.id).then((d) => {
            setAssessors(d)
            setOptions(users.map(user => ({ value: user.id, label: user.name })).filter(user => !d.find(assessor => assessor.id == user.value)));
        });
    }

    useEffect(() => {
        AppAPI.getScorers(application.id).then((d) => {
            const assessorsList = d;

            setAssessors(assessorsList);

            AdminAPI.getUsers(1, 300).then(({ data }) => {
                data = [ ...data, auth.user ]
                setUsers(data);
                setOptions(data.map(user => ({ value: user.id, label: user.name })).filter(user => !assessorsList.find(assessor => assessor.id == user.value)))
            })

        });
    }, []);

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Please select the assessors for this application</h1>
                <p>If these users are changed once an application has commenced scoring, the user replaced will be required to re-score the application from the start</p>

                <div className="assessors__outer">
                    { new Array(min_required_scorers).fill(0).map((_, index) => {
                        const assessor = assessors[index] || null;

                        const name = assessor?.name || 'Choose a user';
                        const value = assessor?.id || 0;

                        return (
                            <div key={index} className="assessors-item">
                                <p>Assessor { index + 1 } - </p>
                                { assessor ? <p className="assessors-item__name">{ name }</p> : <Select value={{ label: name, value }} className='react-select-container' classNamePrefix="react-select" options={options} onChange={onChange}/> }
                                { assessor && <DeleteButton mobile={true} onClick={() => deleteAssessor(value)}/> }
                            </div>
                        )
                    }) }
                </div>
            </div>
        </Popup>
    )
}
