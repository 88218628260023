import { Component } from 'react';

import SidePopup from '../../../../components/side-popup';
import SidePopupSection from '../../../../components/side-popup-section';
import { SaveButton } from '../../../../components/buttons';

import Input from '../../../../components/input-with-label';
import Dropdown from '../../../../components/dropdowns/dropdown';
import TextArea from '../../../../components/textarea-with-label';

import ApplicationApi from '../../../../lib/applications/application-api';
import AlertManager from "../../../../managers/alert/alert-manager";

export default class GrantApplicationViewScoreGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            percentage_score: 0,
        }



        this.api = new ApplicationApi();
    }

    componentDidMount() {
        this.setScore();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.scores !== this.props.scores) {
            this.setScore();
        }
    }

    setScore() {

        const score = this.getScore();

        if(score !== null ) {
            this.setState({
                percentage_score : score.percentage_score,
                score : score,
                comments: score.comments
            })
        }
    }

    getScore() {

        const scores = this.props.scores.block_scores.filter(item => {
            return this.props.block_id === item.block_id;
        })

        return scores.length === 1 ? scores[0] : null;
    }

    save() {

        if(this.state.percentage_score === null) {
            return;
        }

        const params = {
            section_id: this.props.section_id,
            block_id: this.props.block_id,
            percentage_score:   this.state.percentage_score,
            comments : this.state.comments
        };

        this.api.scoreBlock(this.props.applicationId, params).then((data) => {
            AlertManager.success('Score updated');
        }).catch(e => {
            AlertManager.error('Sorry we could not update the scoring');
        });
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Grant scoring</p>
                    <span>></span>
                    <p>{this.props.block?.title}</p>
                </div>
                <SaveButton onClick={this.save.bind(this)}></SaveButton>
            </div>
        );
    }

    onChange(e) {
        const { name, value } = e.target;


        if(parseFloat(value) > parseFloat(this.props.block.scoring_weight_percentage)) {

            return;
        }

        if(parseFloat(value) < 0) {
            return;
        }

        this.setState({ [name]: value })
    }

    render() {

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.props.setPopup(null)} content={
                <>
                    <Input label={`Please input the percentage (%) score for this group of questions (max: ${this.props.block.scoring_weight_percentage}%)`}  type="number" value={this.state.percentage_score} name="percentage_score" onChange={this.onChange.bind(this)}/>
                    <TextArea value={this.state.comments} onChange={e => this.setState({comments : e.target.value})} label="Add Comments"/>
                </>
            }/>
        )
    }
}
