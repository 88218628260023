import React, {Component} from 'react';
import Input from "../input-with-label";

import Row from '../../utils/row';
import Container from '../../containers/container';
import { stringToFriendlyDatetime } from '../../lib/helpers/datetime-helpers';

class Details extends Component {
    render() {
        const { contact } = this.props;

        return (
            <Container gap="40px">
                <Row wrap={true} gap="30px 50px" style={{ flexGrow: '1' }}>
                    <Container style={{flex: '1 0 0'}}>
                        <Container.Header>
                            <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Email</Container.Title>
                        </Container.Header>
                        <Container.Content>
                            <Container.Text fontSize="16px" style={{ whiteSpace: 'nowrap' }}>{ contact?.email || '' }</Container.Text>
                        </Container.Content>
                    </Container>

                    <Container style={{flex: '1 0 0'}}>
                        <Container.Header>
                            <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">First Name</Container.Title>
                        </Container.Header>
                        <Container.Content>
                            <Container.Text fontSize="16px" style={{ whiteSpace: 'nowrap' }}>{ contact?.first_name || '' }</Container.Text>
                        </Container.Content>
                    </Container>

                    <Container style={{flex: '1 0 0'}}>
                        <Container.Header>
                            <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Last Name</Container.Title>
                        </Container.Header>
                        <Container.Content>
                            <Container.Text fontSize="16px" style={{ whiteSpace: 'nowrap' }}>{ contact?.last_name || '' }</Container.Text>
                        </Container.Content>
                    </Container>
                </Row>

                <Row wrap={true} gap="20px 50px" style={{ flexGrow: '1' }}>
                    <Container style={{flex: '1 0 0'}}>
                        <Container.Header>
                            <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Phone Number</Container.Title>
                        </Container.Header>
                        <Container.Content>
                            <Container.Text fontSize="16px" style={{ whiteSpace: 'nowrap' }}>{ contact?.phone_number || '' }</Container.Text>
                        </Container.Content>
                    </Container>
                </Row>
            </Container>
        );
    }
}


export default Details;
