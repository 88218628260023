import React, {Component} from 'react';
import AdminApi from '../../lib/admin/admin-api';
import SearchResults from './search-results';
import SearchBarItem from './search-bar-item';

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            value: '',
            open: false
        };

        this.container = null;
        this.time = null;
        this.handleClick = this.clickHandle.bind(this);
    }


    componentDidMount() {
        this.registerEvents();
    }


    searchCall() {

        if (this.state.value.length >= 1) {

            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                AdminApi.search(this.state.value).then(response => {

                    if (response !== false) {
                        this.setState({results: response.data, open: true});

                    }
                });

            }, 100);

        }
    }

    clickHandle(e) {


        if(this.container && !this.container.contains(e.target)) {
            this.setState({ open : false});
        }
    }

    registerEvents() {
        window.addEventListener('click', this.handleClick);
    }

    handleChange(e) {

        if (e.target.value.length === 0) {
            return this.setState({results: [], open: false});
        }

        this.setState({value: e.target.value}, () => {
            //this.searchCall();
        });
    }

    onChange(e) {
        this.handleChange(e);
    }

    onClick() {
        this.searchCall();
    }

    render() {
        const { results, value } = this.state;
        const toggled = results.length > 0;

        return (
            <div className="search-bar__outer" ref={r => this.container = r}>
                <SearchBarItem toggled={toggled} placeholder="Type here to start searching" onClick={this.onClick.bind(this)} onChange={this.onChange.bind(this)} />
                {
                    this.state.open && <SearchResults results={results} value={value}/>
                }
            </div>
        );
    }

}

export default SearchBar;
