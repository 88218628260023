import {Component} from 'react';

import SidePopup from '../../../../components/side-popup';
import SidePopupSection from '../../../../components/side-popup-section';
import {SaveButton} from '../../../../components/buttons';

import Input from '../../../../components/input-with-label';
import TextArea from '../../../../components/textarea-with-label';
import Dropdown from '../../../../components/dropdowns/dropdown';

import ApplicationApi from '../../../../lib/applications/application-api';
import {stringToFriendlyDatetime} from "../../../../lib/helpers/datetime-helpers";
import Alerts from '../../../../managers/alert/alert-manager';

import Auth from '../../../../lib/users/auth';
import AlertManager from "../../../../managers/alert/alert-manager";

export default class GrantApplicationViewScoreGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            score: null,
            final_score: null,
            comments: '',
            scoring_range: []
        }

        this.api = new ApplicationApi();
    }

    componentDidMount() {
        this.getScoringRange(() => {
            this.setUserScore();
            this.setFinalScore();
        });
    }

    getScoringRange(func = () => {
    }) {
        let scoring_range = this.props.question.hasOwnProperty('scoring_range') ? this.props.question.scoring_range.map(item => {
            return {value: item.value, label: `(${item.value}) ${item.label} `};
        }) : [];

        if (this.props.block?.override_questions_scoring_range) {
            if (this.props.block.hasOwnProperty('scoring_range')) {
                scoring_range = this.props.block.scoring_range.map(item => {
                    return {value: item.value, label: `(${item.value}) ${item.label} `};
                })
            }
        }



        this.setState({scoring_range}, () => func())
    }

    getScores() {

        return this.props.scores?.scores.filter(item => {

            if (item.user_id === Auth.user.id) {
                return false;
            }

            return item.question_id === this.props.question_id;
        });
    }

    save() {
        if(this.state.score?.value !== undefined) {
            let params = {
                section_id: this.props.section_id,
                block_id: this.props.block_id,
                question_id: this.props.question_id,
                score: this.state.score.value,
                comments: this.state.comments
            };

            this.api.scoreQuestion(this.props.applicationId, params).then((data) => {
                AlertManager.success('Score updated');
            }).catch(e => {
                AlertManager.error('Sorry we could not update the scoring');
            });
        }


        if (this.props.isManager) {
            if (this.state.final_score?.value == undefined) return;

           let  params = {
                section_id: this.props.section_id,
                block_id: this.props.block_id,
                question_id: this.props.question_id,
                score: this.state.final_score.value,

            }

            this.api.scoreFinal(this.props.applicationId, params).then(() => {
                Alerts.success('Final Score updated');
            });
        }
    }

    onChange(e) {
        const {name, value} = e.target;

        this.setState({[name]: value})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scores !== this.props.scores || this.props.question !== prevProps.question) {
            this.setUserScore();
            this.setFinalScore();
        }
    }

    setFinalScore() {
        const finalScore = this.getFinalScore();

        if (finalScore !== null) {
            this.setState({
                final_score: {
                    value: finalScore.score,
                    label: this.getScoreRangeLabel(finalScore.score)
                }
            });
        }

    }

    getFinalScore() {
        const scores = this.props.scores?.final_scores.filter(item => {
            return item.question_id === this.props.question_id;
        });

        return scores.length === 1 ? scores[0] : null;
    }

    setUserScore() {
        const userScore = this.getUserScore();
        if (userScore !== null) {
            console.log(this.getScoreRangeLabel(userScore.score))
            this.setState(
                {
                    score: {
                        label: this.getScoreRangeLabel(userScore.score),
                        value: userScore.score,
                    },
                    comments: userScore.comments
                }
            );
        }

    }

    getScoreRangeLabel(value) {
        const option = this.state.scoring_range.filter(item => {
            return item.value === value;
        })

        return option.length >= 1 ? option[0].label : '';
    }

    onScoreChange(item) {
        this.onChange({
            target: {
                name: 'score',
                value: item
            }
        })
    }

    onFinalScoreChange(item) {
        console.log(item)
        this.onChange({
            target: {
                name: 'final_score',
                value: item
            }
        })
    }


    getUserScore() {
        const scores = this.props.scores?.scores.filter((item) => {
            return (item.user_id === Auth.user.id && item.question_id === this.props.question_id);
        });

        console.log(this.props.scores, scores)

        return scores.length >= 1 ? scores[0] : null;
    }

    getHead() {
        return (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Grant Question</p>
                    <span>></span>
                    <p>{this.props.question.title}</p>
                </div>
                <SaveButton onClick={this.save.bind(this)}></SaveButton>
            </div>
        );
    }

    render() {

        const {isScorer} = this.props;

        return (
            <SidePopup type="grant-details-popup" head={this.getHead()} onClose={() => this.props.setPopup(null)}
                       content={
                           <>

                               <SidePopupSection
                                   title={this.props.question.scoring_criteria ? this.props.question.scoring_criteria : 'No scoring criteria given'}>
                                   {
                                       isScorer &&
                                       <>
                                           <Dropdown label="How do you score this question" value={this.state.score}
                                                     items={this.state.scoring_range.sort((a, b) => a.value - b.value)}
                                                     onChange={this.onScoreChange.bind(this)}
                                                        placeholder='Please Select'/>
                                           <Input label="Add comment" value={this.state.comments} name="comments"
                                                  onChange={this.onChange.bind(this)}/>
                                       </>
                                   }


                               </SidePopupSection>


                               <SidePopupSection title="Additional Scores Recorded">
                                   <div className="additional-scores__outer">
                                       {
                                           this.getScores().map((score, index) => {

                                               return (
                                                   <div key={index} className="additional-scores__item">
                                                       <div
                                                           className="additional-scores-item__row additional-scores-item__text">
                                                           <p>{score.user.name}</p>
                                                           <p>{stringToFriendlyDatetime(score.updated_at)}</p>
                                                           <p>Score: {score.score}</p>
                                                       </div>
                                                       <div className="additional-scores-item__row">
                                                           <p>{score.comments}</p>
                                                       </div>
                                                   </div>
                                               );
                                           })
                                       }


                                   </div>
                               </SidePopupSection>

                               {this.props.isManager && <SidePopupSection title="Average final score">
                                   <Dropdown
                                       label="This should be the average score, or agreed score, from all Assessors"
                                       value={this.state.final_score} items={this.state.scoring_range.sort((a, b) => a.value - b.value)}
                                       onChange={this.onFinalScoreChange.bind(this)}
                                       placeholder='Please Select'/>
                               </SidePopupSection>}
                           </>
                       }/>
        )
    }
}
