import ApiBase from "../api-base";
import axios from "axios";


class ImagesApi extends ApiBase {


    async deleteImage(id) {

        return this.handleResponse(async () => {
             return await axios.delete('/api/admin/images/' + id);
        });

    }

}

export default new ImagesApi();
