export default {
    sections : [
        {
            name:  'hero',
            fields : [
                {
                    field: 'background',
                    type : 'image'
                },
                {
                    field: 'title',
                }
            ]
        },
        {
            name:  'info',
            fields : [
                {
                    field: 'text one',
                }
            ]
        }
    ]
}
