import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeUploadDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currency: '',
            allow_decimals: false,
            min_end_range: false,
            max_end_range: false
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    onInputChange(e) {
        const { name, value } = e.target;
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        let params = { [ name ]: value }

        this.setState(params, () => {
            this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
        });
    }

    onCurrencyChange(item) {
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        let params = {
            currency: this.state.currency !== item ? item : '',
        }

        this.setState(params, () => {
            this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
        });
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type='currency-input' title="Currency Input Settings">
                    <Field title="Currency Selector">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Checkbox checked={this.state.currency === 'GBP'}
                                name='currency'
                                text="Pound Sterling (£)"
                                onChange={() => this.onCurrencyChange('GBP')}/>

                            <Checkbox checked={this.state.currency === 'EUR'}
                                name='currency'
                                text="Euro (€)"
                                onChange={() => this.onCurrencyChange('EUR')}/>
                        </div>
                    </Field>
                    <Field title="Decimalisation">
                        <Checkbox checked={this.state.allow_decimals}
                            name='allow_decimals'
                            text="Allow decimals in input"
                            onChange={this.onInputChange}/>
                    </Field>
                    <Field title="Currency Range">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.min_end_range}
                                name="min_end_range"
                                label='Minimum end of range'
                                placeholder="Value"
                                type="number"
                                onChange={this.onInputChange}/>

                            <Input value={this.state.max_end_range}
                                name='max_end_range'
                                label='Maximum end of range'
                                placeholder="Value"
                                type="number"
                                onChange={this.onInputChange}/>
                        </div>
                    </Field>
                </QuestionType>
            </div>
        )
    }
}
