import ApiBase from "../api-base";
import axios from "axios";

class MeApi extends ApiBase {


    async me() {
        return this.handleResponse(async  () => {
            let response = await axios.get('/api/users/me');
            return response.data.data;
        });
    }

    async takeTask(taskId) {
        return this.handleResponse(async  () => {
            let response = await axios.post(`/api/users/me/admin-tasks/${taskId}/self-assign`);
            return response.data;
        });
    }

    async myTasks(params) {
        return this.handleResponse(async  () => {
            let response = await axios.get('/api/users/me/admin-tasks', { params });
            return response.data;
        });
    }

    async notifications() {
        return this.handleResponse(async  () => {
            let response = await axios.get('/api/users/me/notifications');
            return response.data;
        });
    }

    async notificationsMarkAllRead() {
        return this.handleResponse(async  () => {
            let response = await axios.post('/api/users/me/notifications/mark-all-read');
            return response.data;
        });
    }

    async notificationCount() {
        return this.handleResponse(async  () => {
            let response =  await axios.get('/api/users/me/notifications/count')
            return response.data.data;
        });
    }

    async getConversations(params) {
        return this.handleResponse(async  () => {
            let response =  await axios.get('/api/users/me/conversations', {
                params
            })

            return response.data;
        });
    }

    async getConversation(id) {
        return this.handleResponse(async  () => {
            let response =  await axios.get(`/api/users/me/conversations/${id}`)

            return response.data.data;
        });
    }

    async getConversationMessages(id) {
        return this.handleResponse(async  () => {
            let response =  await axios.get(`/api/users/me/conversations/${id}/messages`)

            return response.data.data;
        });
    }


    async readConversationMessages(id) {
        return this.handleResponse(async  () => {
            let response = await axios.post(`/api/users/me/conversations/${id}/messages/mark-all-read`);

            return response.data;
        });
    }
    async createConversationMessage(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async  () => {
            let response = await axios.post(`/api/users/me/conversations/${id}/messages`, params);

            return response.data;
        }, validationCallback);
    }

    async createConversation(params, validationCallback = this.defaultValidationHandler) {
         return this.handleResponse(async  () => {
            let response = await axios.post(`/api/users/me/conversations`, params);

            return response.data;
        }, validationCallback);
    }
}

export default new MeApi();
