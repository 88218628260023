export default function notificationDescription(notification) {
    const MESSAGES = {
        'new_contact_message' : {
            title : 'New contact message',
            type : 'Message',
            description : 'You have a new message from a contact',
            link: '/contacts/messages?id=' + notification.meta.conversation_id
        },
        'new_contact' : {
            title : 'New contact',
            type : 'Contact',
            description : 'A new contact has been created',
            link: '/contacts/' + notification.meta.contact_id
        },
        'daily_new_companies' : {
            title : "Today's round-up of new companies joining myEB",
            type : 'View Companies',
            description: notification.body,
            link: `/companies`
        },
        'daily_new_application_submitted' : {
            title : "Today's round-up of new applications submitted for the grants you're managing",
            type : 'View Applications',
            description : notification.body,
            link : '/grants/applications'
        },
        'scoring_started' : {
            title : formatTitle(notification),
            type: 'View Application',
            description: notification.body,
            link: getApplicationLink(notification)
        },
        'scoring_re-started' : {
            title : formatTitle(notification),
            type: 'View Application',
            description: notification.body,
            link: getApplicationLink(notification)
        },
        'user_comments_application': {
            title : 'Comments made on application',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'amendments_submitted' : {
            title : 'Amends submitted on Application',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'approved_for_payment' : {
            title : 'Grant Claim approved for payment',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'claim_processed' : {
            title: 'Application Status Updated',
            type : 'View Application',
            description: notification.body,
            link: getApplicationLink(notification)
        },
        'application_completed' : {
            title : 'Grant Claim completed',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'documents_received' : {
            title : 'Grant Claim documents received',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'documents_requested' : {
            title : 'Grant Claim documents requested',
            type: 'View Application',
            description: notification.body,
            link:getApplicationLink(notification)
        },
        'offer_accepted' : {
            title : 'Grant Offer Accepted',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'offer_letter_sent' : {
            title : 'Grant Offer Sent',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'offer_rejected' : {
            title : 'Grant Offer Rejected',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'submitted_for_payment' : {
            title : 'Grant Claim sent for payment',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'application_accepted' : {
            title : 'Application Accepted',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        },
        'application_rejected' : {
            title : 'Application Rejected',
            type : 'View Application',
            description : notification.body,
            link: getApplicationLink(notification)
        }

    };

    return MESSAGES[notification.title] ? MESSAGES[notification.title] : {
        title : formatTitle(notification),
        description : notification.body
    } ;
}

function formatTitle(notification) {
    return notification.title.split('_').join(' ');
}

function getApplicationLink(notification) {
    if (notification.meta.id) {
        return `/grants/applications/${notification.meta.id}`;
    }
    else {
        return null;
    }
}
