import { Component, createRef } from 'react';

import { SendButton, AddButton } from '../buttons';

import MessageSent from './chatbox-sent-message';
import MessageReceived from './chatbox-received-message';

import FileUploader from '../popups/images/file-uploader-popup';

import FileTypes from '../../lib/files/file-types';

import ApplicationApi from '../../lib/applications/application-api';

import { stringToFriendlyDatetime } from '../../lib/helpers/datetime-helpers';

import SidePopup from '../side-popup';

const MessageFile = ({ message, sent }) => {

    // const goToFile = () => window.location.href = `/applications/${message.application_id}/files/` + message.application_file.file.name;

    return (
        <div id={`comment-${message.id}`} className={`chatbox-message__outer ${sent ? 'chatbox-message__outer--sent' : 'chatbox-message__outer--received'}`}  style={{ cursor: 'pointer' }}>
           <div className={`chatbox-message ${sent ? 'chatbox-message--sent' : 'chatbox-message--received'}`}>
               <a className={'chatbox-message__icon'} href={`/applications/${message.application_id}/files/` + message.application_file.file.name} target={'_blank'}><img src="/assets/document-icon.svg" /></a>
                <p className="chatbox-message__fileName">{ message.application_file.file.name }</p>
                <p className="chatbox-message__sender">{ message.user.name }</p>
            </div>
            <p className="chatbox-message__date">{ stringToFriendlyDatetime(message.created_at) }</p>
        </div>
    )
}

export default class Chatbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFileUploader: false,
            messages: [],
            message: '',
        }

        this.ref = createRef(null)
        this.messagesRef = createRef(null);

        this.origin = new URL(window.location).origin;
        this.API = new ApplicationApi();

        this.scrollTop = 0;
    }

    componentDidMount() {
        this.fetchComments(() => {
            const unreadComments = this.getUnreadComments().map(c => c.id);

            if (unreadComments.length > 0) {
                this.markAsRead(unreadComments);
            }
        });
    }

    fetchComments(callback = () => {}) {
        this.API.getComments(this.props.application.id, 1, 100, this.props.question.id).then(({ data }) => {
            this.setState({
                messages: [...data.reverse() ]
            }, () => {
                callback()
            });

            this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeight;
        })
    }

    setShowFileUploader(bool) {
        this.setState({
            showFileUploader: bool,
        })
    }

    onChange(e) {
        const { value } = e.target;

        this.setState({
            message: value
        })
    }

    onResourceSelect(file) {
        this.postMessage({
            body: 'Testing body for file',
            question_id: this.props.question.id,
            application_file_id: file.file_id,
        })
    }

    sendMessage() {
        console.log(this.props.question);
        if (this.state.message.length == 0) return;

        this.postMessage({
            body: this.state.message,
            question_id: this.props.question.id
        })

        this.setState({ message: '' })
    }

    postMessage(params) {
        this.API.postComments(this.props.application.id, params).then((data) => {
            this.setState({
                messages: [ ...this.state.messages, data ]
            });

            this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeight;
            console.log(this.props);
            this.props.onComment();

        })
    }

    markAsRead(unreadComments) {
        this.API.markAsRead(this.props.application.id, unreadComments).then(() => {
            this.fetchComments();
            this.props.fetchComments();
        })
    }

    messageEnterSubmit(e) {
        if (e.key === "Enter" && e.shiftKey == false) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    handleScroll(event) {
        const element = event.target;

        this.scrollTop = element.scrollTop;
    }

    isVisible(ele, container) {
        const { bottom, height, top } = ele.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }

    getUnreadComments() {
        return this.state.messages.filter(e => {
            const element = document.getElementById(`comment-${e.id}`);
            const container = this.messagesRef.current;

            //const visible = this.isVisible(element, container);

            return e.read_by == null && e.read_by_id == null && (e.user.admin == 0 || e.user.super_admin == 0)
        });
    }

    handleWheel() {
        const TOP_OF_ELEMENT = 0;

        if (this.scrollTop == TOP_OF_ELEMENT) {
            const newMessages = [];

            // API messages here

            const arr = [ ...this.state.messages ];
            arr.unshift(...newMessages);

            this.setState({
                messages: arr,
            })
        }
    }

    render() {
        return (
            <>
                <FileUploader toggled={this.state.showFileUploader}
                              onClose={() => this.setShowFileUploader(false)}
                              onResourceSelect={this.onResourceSelect.bind(this)}
                              fileTypes={[
                                FileTypes.TXT,
                                FileTypes.PDF,
                                FileTypes.DOC
                              ]}
                              endpoint={`${this.origin}/api/applications/${this.props.application.id}/files`}/>

                <SidePopup type='chatbox' onClose={() => this.props.setPopup(false)} replaceContent={
                    <>
                        <div className="chatbox__head">
                            <div className="chatbox-head__text">
                                <p className="chatbox-head-text__title">{ this.props.question.title }</p>
                                <p className="chatbox-head-text__logged">Comment logged: { this.state.messages.length > 0 && stringToFriendlyDatetime(this.state.messages[this.state.messages.length - 1].created_at) }</p>
                            </div>
                            <span className="chatbox__close" onClick={() => this.props.setPopup(false)}>X</span>
                        </div>
                        <div className="chatbox__chat">
                            <div className="chatbox__messages" onScroll={this.handleScroll.bind(this)} onWheel={this.handleWheel.bind(this)} ref={this.messagesRef}>
                                { this.state.messages.map((message, index) => {
                                    return message?.application_file !== null ?
                                        <MessageFile key={index} message={message} sent={window.user.id == message.user.id}/> :
                                        window.user.id == message.user.id ? <MessageSent key={index} message={message} /> : <MessageReceived key={index} message={message}/>
                                })}
                            </div>
                            {
                                (this.props.application.accepted === 0 && this.props.application.rejected === 0) &&
                                <div className="chatbox__input">
                                    <textarea placeholder="Enter message" value={this.state.message} onKeyPress={this.messageEnterSubmit.bind(this)} onChange={this.onChange.bind(this)} />
                                    <div className="chatbox-input__buttons">
                                        {/* <AddButton mobile={true} onClick={() => this.setShowFileUploader(true)}/> */}
                                        <SendButton mobile={true} onClick={this.sendMessage.bind(this)}/>
                                    </div>
                                </div>

                            }

                        </div>
                    </>
                }/>
            </>
        )
    }
}
