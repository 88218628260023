import TextInput from './question-type-text';
import TableInput from './question-type-table';
import Checkboxes from './question-type-checkboxes';
import RadioButtons from './question-type-radio-buttons';
import UploadDocuments from './question-type-upload-documents';
import DatePicker from './question-type-date-picker';
import DropdownSelection from './question-type-dropdown-selection';
import CurrencyInput from './question-type-currency-input';

export default {
    'text-input': TextInput,
    'table-input': TableInput,
    'checkboxes': Checkboxes,
    'radio-buttons': RadioButtons,
    'upload-documents': UploadDocuments,
    'date-picker': DatePicker,
    'dropdown-selection': DropdownSelection,
    'currency-input': CurrencyInput
}