export default ({ value, toShow = null }) => {
    const containsAnyLetter = (str) => {
        return /[a-zA-Z]/.test(str);
    }

    const format = (text) => {
        if (text?.includes("youtube")) {
            return {
                provider: 'youtube',
                id: text?.split('?v=')[1],
                value: text,
            }
        }

        if (text?.includes("vimeo")) {
            return {
                provider: 'vimeo',
                id: text?.split("com/")[1],
                value: text,
            }
        }

        if (containsAnyLetter(text)) {
            return {
                provider: 'youtube',
                id: text,
                value: text,
            }
        }

        if (text === null) {
            return {
                provider: '',
                id: '',
                value: '',
            }
        }

        return {
            provider: 'vimeo',
            id: text,
            value: text,
        }
    }

    const getEmbedUrl = (text = '') => {
        if (text === null) {
            return '';
        }
        
        if (text?.includes("youtube")) return "https://www.youtube.com/embed/" + text?.split('?v=')[1];
        if (text?.includes("vimeo")) return "https://player.vimeo.com/video/" + text?.split("com/")[1];

        if (containsAnyLetter(text)) return "https://www.youtube.com/embed/" + text;

        return "https://player.vimeo.com/video/" + text;
    }

    if (toShow !== null) {
        const notToShow = !toShow.some(type => getEmbedUrl(value).includes(type));

        if (notToShow) {
            return <p>Only available videos are { toShow.join(' ') }</p>
        }
    }

    return <iframe width="560" height="315" src={getEmbedUrl(value)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
}
