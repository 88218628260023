import List from '../list/list';

export default function QuestionTypeSection({ left, right }) {
    return (
        <div className="question-type-section">
            { left && <List gap="20px" style={{ flexGrow: 0.8, minWidth: '300px' }}>{ left }</List> }
            
            { right && <List gap="20px" style={{ flexGrow: 0.1 }}>{ right }</List> }
        </div>
    )
}