import { useState, useEffect } from 'react';

export default ({ data, meta, config, sectionConfig, contentIndex, handleChange, fixed }) => {
    const convert = (e) =>{
        let { id, provider, value } = format(e.target?.value);

        if (provider === null) provider = '';
        if (id === null)  id = '';
        if (value === null) value = '';

        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: id,
                meta: {
                    provider,
                    value,
                }
            },
            fixed
        })
    }

    const containsAnyLetter = (str) => {
        return /[a-zA-Z]/.test(str);
    }

    const format = (text) => {
        if (text?.includes("youtube")) {
            return {
                provider: 'youtube',
                id: text?.split('?v=')[1],
                value: text,
            }
        }

        if (text?.includes("vimeo")) {
            return {
                provider: 'vimeo',
                id: text?.split("com/")[1],
                value: text,
            }
        }

        if (containsAnyLetter(text)) {
            return {
                provider: 'youtube',
                id: text,
                value: text,
            }
        }

        if (text === null) {
            return {
                provider: '',
                id: '',
                value: '',
            }
        }

        return {
            provider: 'vimeo',
            id: text,
            value: text,
        }
    }

    const getEmbedUrl = (text = '') => {
        if (text === null) {
            return '';
        }
        
        if (text?.includes("youtube")) return "https://www.youtube.com/embed/" + text?.split('?v=')[1];
        if (text?.includes("vimeo")) return "https://player.vimeo.com/video/" + text?.split("com/")[1];

        if (containsAnyLetter(text)) return "https://www.youtube.com/embed/" + text;

        return "https://player.vimeo.com/video/" + text;
    }

    return (
        <div class="editor-video">
            <div class="input-outer input-outer__small">
                <input placeholder="Youtube Link or ID of video" value={meta?.value} onChange={convert}/>
            </div>
            
            <iframe width="560" height="315" src={getEmbedUrl(meta?.value)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
        </div>
    )
}
