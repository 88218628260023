import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import EventsApi from '../../lib/events/events-api';
import Popup from "../../components/popup";
import Breadcrumb from "../../components/breadcrumb";
import AlertManager from '../../managers/alert/alert-manager';
import TabBar from "../../components/tab-bar";
import EventDetails from "../../components/events/event-details";
import Button from '../../components/buttons/button';
import EventContent from "../../components/events/event-content";

import { SaveButton, DeleteButton, CancelButton } from '../../components/buttons';

class Event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            event: { content : {}},
            popup: false,
            errors: {},
            tab: 0
        };

        this.tabs = [
            'Details',
            'Content'
        ];
    }


    componentDidMount() {
        this.getEvent();
    }

    getEvent() {
        EventsApi.get(this.props.eventId).then(data => {
            this.setState({event: data});
        })
    }

    deleteEvent() {
        EventsApi.delete(this.state.event.id).then(() => window.location = '/events');
    }

    handleSave() {
        this.setState({errors: {}});
        EventsApi.update(this.state.event.id, this.state.event, errors => this.setState({errors: errors})).then(success => {
            if (success) {
                AlertManager.success('Event updated');

            }

        });
    }

    handleChange(key, value) {

        this.setState({
            event: Object.assign({}, this.state.event, {
                [key]: value
            })
        }, () => console.log(this.state));
    }

    render() {
        return (
            <div className="companies-outer">
                {
                    this.state.popup &&
                    <Popup type={'company-delete-popup'}>
                        <div className={'popup__inner'}>
                            <h1>Are you sure you want to delete {this.state.event.name}?</h1>
                            <div className="popup__content">
                                By clicking ‘Delete’, you will not be able to restore this event.
                            </div>
                            <div className="popup__buttons">
                                <DeleteButton responsive={false} onClick={this.deleteEvent.bind(this)}>Delete</DeleteButton>
                                <CancelButton responsive={false} onClick={() => this.setState({popup: false})}>Cancel</CancelButton>
                            </div>
                        </div>
                    </Popup>
                }

                <div className="website__topbar">
                    <Breadcrumb crumbs={[{
                        name: 'Events',
                        link: '/events'
                    },
                        {
                            name: this.state.event.name,
                            link: '#'
                        }]}/>

                    <div className="website-row">
                        <SaveButton onClick={this.handleSave.bind(this)}>Save / Confirm</SaveButton>
                        <DeleteButton onClick={() => this.setState({popup: true})}>Delete</DeleteButton>
                    </div>
                </div>
                <div className="website__content">
                    <TabBar tabs={this.tabs} tab={this.state.tab} setTab={tab => this.setState({tab: tab})}/>
                    {
                        this.state.tab === 0 &&
                        <EventDetails errors={this.state.errors} event={this.state.event}
                                      onChange={this.handleChange.bind(this)}/>
                    }
                    {
                        this.state.tab === 1 &&
                        <EventContent event={this.state.event} onChange={this.handleChange.bind(this)}/>
                    }
                </div>


            </div>
        );
    }
}

export default Event;

const el = document.getElementById('event');

if (el) {
    ReactDOM.render(
        <Event eventId={el.getAttribute('data-event-id')}/>,
        el
    );
}
