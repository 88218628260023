import ApiBase from "../api-base";

class EventsApi extends ApiBase {


    storeEvent(data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/events`, data);
            return response.data.data;
        }, validationCallback);
    }

    getEvents(page = 1, perPage = 10, from = null) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/events`, {params : {page: page, per_page : perPage, from : from}});
            return response.data;
        });
    }

    search(term, from = null) {
        let params  = {term : term};

        if(from) {
            params.from = from;
        }
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/events/search`, params);
            return response.data;
        });
    }

    get(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/events/${id}`);
            return response.data.data;
        });
    }

    update(id, data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/events/${id}`, data);
            return response.data.data;
        }, validationCallback);
    }

    delete(id) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/events/${id}`);
            return response.data;
        });
    }

    types(page = 1, perPage = 100) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/event-types`, {params : {page: page, per_page : perPage}});
            return response.data;
        });
    }

}


export default new EventsApi();
