import CustomDropdown from '../../../components/dropdowns/dropdown';

export default function ELearningCoursesFilters({ filterBy, onFilterBy = () => {}, sortBy, onSortBy = () => {} }) {
    return (
        <div className="content-container__filters">
            <CustomDropdown items={[
                {
                    label: 'Reset',
                    value: null,
                },
                {
                    label: 'A - Z',
                    value: 'desc'
                },
                {
                    label: 'Z - A',
                    value: 'asc'
                },
                {
                    label: 'Category',
                    value: 'created_at'
                },
                {
                    label: 'Date Created',
                    value: 'created_at'
                }
            ]} placeholder="Sort results by" value={sortBy} icon="sort" onChange={onSortBy}/>

            <CustomDropdown items={[
                {
                    label: 'Reset',
                    value: null,
                },
                {
                    label: 'Public',
                    value: 'asc'
                },
                {
                    label: 'Private',
                    value: 'desc'
                },
                {
                    label: 'Active',
                    value: 'desc'
                },
                {
                    label: 'Inactive',
                    value: 'desc'
                }
            ]} placeholder="Filter by status" icon="filter" value={filterBy} onChange={onFilterBy}/>
    
            {/* <CustomDropdown items={[]} reset={true} placeholder="Catagories" icon="filter" value={acceptedRejected} onChange={onAcceptedRejected}/> */}
        </div>
    )
}