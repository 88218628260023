import ContentLoader from 'react-content-loader';

export default () => {  
    return (
        <div class="myteam__members">
            { new Array(20).fill(0).map((_, index) => {
                return (
                    <ContentLoader key={index} viewBox="0 0 300 300"> 
                        <rect x="0" y="0" rx="20" ry="20" width="300" height="300" />
                    </ContentLoader>
                )
            }) }
        </div>
    )
}