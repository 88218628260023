import React, {Component} from 'react';

import { UploadButton } from '../components/buttons';

class ProfileImageUpload extends Component {
    handleChange(e) {
        let formData = new FormData();
        let file = e.target.files[0];
        formData.append(
            "file",
            file,
            file.name

        );

        axios.post('/media/images/profile-image', formData).then(response => {
            this.props.onChange();
        })
    }

    delete() {
        axios.delete('/media/images/profile-image').then(response => {
            this.props.onChange();
        })
    }


    render() {
        const img = this.props.user.user_profile ? this.props.user.user_profile.profile_img : null;

        return (
            <>
                <img className="account__user-icon" src={img ? `/media/private/${img}` : '/assets/user-icon.svg'}/>
                <div className="account__column">
                    <UploadButton onClick={() => this.inputEl.click()}>Upload Profile Photo</UploadButton>

                    <a onClick={this.delete.bind(this)} href="#">Remove Profile Photo</a>
                    <input accept={'.jpeg, .jpg'} ref={r => this.inputEl = r} style={{ display : 'none'}} type={'file'} onChange={this.handleChange.bind(this)}/>
                </div>
            </>
        );
    }
}

ProfileImageUpload.defaultProps = {
    user : {},
    onChange : () => console.log('Profile image update')
};

export default ProfileImageUpload;
