import { useState } from 'react';

import { ProceedButton } from "../../../../../components/buttons"
import List from "../../../../../components/list/list"
import Row from '../../../../../utils/row';
import Container from "../../../../../containers/container"
import InputDate from '../../../../../components/input-date';

import ELearningApi from "../../../../../lib/e-learning/e-learning-api"

import { stringToFriendlyDatetime } from '../../../../../lib/helpers/datetime-helpers';

export default function NoSubmissionsScreen() {
  return (
        <Container style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
        }}>
            <Container.Header style={{ marginBottom: '29px' }}>
                <Container.Text fontSize="30px" color="black">Nothing submitted</Container.Text>
            </Container.Header>
            <Container.Content>
                <Row gap="10px" alignItems="center">
                    <Container.Text fontSize="20px" color="black">Nothing to show as the user has not submitted anything</Container.Text>
                </Row>
            </Container.Content>
        </Container>
    )
}