import { useEffect } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';
import ContainerGrid from '../../../../../../containers/container-grid';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { AddButton, CancelButton } from '../../../../../../components/buttons';

export default function QuestionTypeCheckbox({ 
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({ 
            target: { 
                name: 'config', 
                value: config
            }
        });
    }

    const onCheckboxChange = (index, e) => {
        const body = question?.body !== null ? [ ...question?.body ] : [];
        const { name, value } = e.target;

        if (body[index] === undefined) {
            body[index] = {
                label: null,
                value: null,
            }
        } 

        body[index][name] = value;

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const addCheckbox = () => {
        const body = question?.body !== null ? [ ...question?.body ] : [];
        const index = body.length;

        body[index] = {
            label: null,
            value: null,
        }

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const removeCheckbox = (index) => {
        const body = question?.body !== null ? [ ...question?.body ] : [];

        body.splice(index, 1);

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const renderCheckboxes = () => {
        const arr = question?.body || [];

        return arr.map((_, index) => {
            const button = question?.body?.[index];

            return (
                <LabelContainer key={index} label={`Checkbox ${index + 1}`} grow={1}>
                    <List gap="20px">
                        <Row>
                            <Input fill={true} value={button?.value} name="value" onChange={(e) => onCheckboxChange(index, e)}/>
                            <CancelButton mobile={true} onClick={() => removeCheckbox(index)}/>
                        </Row>
                    </List>
                </LabelContainer>
            )
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <CodeMirror
                            height="300px"
                            value={question?.description || ''}
                            onChange={e => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: e,
                                    }
                                })
                            }}
                            extensions={[html()]}
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <AddButton onClick={addCheckbox}>Add Checkbox</AddButton>
                    </Row>

                    <Dropdown label="Next Question" value={getNextQuestion()} items={getMappedQuestions()}
                        onChange={(item) => {
                            onConfigChange({
                                target: {
                                    name: 'next_question',
                                    value: item.value,
                                }
                            })
                        }}/>
                </>
            }/>

            <List gap="20px">
                <ContainerGrid>
                    { renderCheckboxes() }
                </ContainerGrid>
            </List>
        </QuestionType>
    )
}