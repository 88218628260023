import React, {Component} from 'react';

class StickyContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sticky: false
        };

        this.ref = null;

        this.contentContainer = document.getElementById('content');
        this.resizer = this.handleResize.bind(this);
        this.scrollHandler = this.handleScroll.bind(this);
        this.stickyOffset = null;
        this.spacerContainer = null;
        this.wrapper = null;

        this.offsetFrom = null;
        this.offsetTo = null;
    }


    componentDidMount() {
        if (!this.contentContainer) {
            this.contentContainer = document.getElementById('content');
        }

        console.log('Mounted', this.contentContainer)

        this.contentContainer.addEventListener('scroll', this.scrollHandler, false);
        window.addEventListener('resize', this.resizer);
    }

    componentWillUnmount() {
        this.contentContainer.removeEventListener('scroll', this.scrollHandler, false);
        window.removeEventListener('resize', this.resizer);
    }


    handleResize() {
        this.setState({width: this.getSpacerWidth()});
    }

    getSpacerWidth() {
        return this.spacerContainer?.offsetWidth;
    }

    handleScroll() {
        if (this.props.offsetFrom !== null) {
            this.offsetFrom = document.querySelector(`.sticky-container__inner ${this.props.offsetFrom}`);
        }

        if (this.props.offsetTo !== null) {
            this.offsetTo = document.querySelector(`.sticky-container__inner ${this.props.offsetTo}`);
        }

        let containerOffset = this.contentContainer.scrollTop + (20 + this.props.offset ? this.props.offset : 0 ) ;

        if (this.stickyOffset === null) {
            this.stickyOffset = this.ref.offsetTop;
        }

        if (this.offsetFrom !== null) {
            this.stickyOffset = this.ref.offsetTop - (this.offsetFrom.getBoundingClientRect().height + this.props.offset);
            containerOffset = this.contentContainer.scrollTop;
        }

        if (containerOffset >= this.stickyOffset && this.state.sticky === false) {
            this.setState({width : this.ref.offsetWidth}, () => {
                this.setState({sticky: true});
            });
        }

        if (containerOffset < this.stickyOffset && this.state.sticky === true) {
            this.setState({sticky: false});

        }

    }

    render() {
        let offset = Number(this.props.offset);

        if (this.props.offsetFrom !== null) {
            this.offsetFrom = document.querySelector(`.sticky-container__inner ${this.props.offsetFrom}`);
        }

        if (this.props.offsetTo !== null) {
            this.offsetTo = document.querySelector(`.sticky-container__inner ${this.props.offsetTo}`);
        }

        if (this.offsetFrom !== null) {
            let { height, y } = this.offsetFrom.getBoundingClientRect();

            offset = height + y + this.props.offset;
        }

        return (
            <div className={`sticky-container ${this.state.sticky ? 'sticky' : ''} ${offset ? 'with-offset' : ''}`} ref={r => this.ref = r}>

                {this.state.sticky &&

                <>
                    <div className="sticky-container__spacer" ref={r => this.spacerContainer = r}>
                        {this.props.children}
                    </div>
                    <div className="sticky-container__inner"
                         style={{width: this.state.width, top: offset ? offset + 'px' : 0}}>
                        {this.props.children}

                    </div>
                </>

                }

                    {
                        this.state.sticky === false &&
                        this.props.children
                    }


            </div>
        );
    }

}

export default StickyContainer;
