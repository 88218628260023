import React, {Component} from 'react';
import CustomSwitch from "../ui/custom-switch";
import Input from "../input-with-label";


const CustomLabel = ({slug, contentTypeSlug = null}) => {

    const style = { color: contentTypeSlug !== null ? '#00C8C3' : '#EA1557', fontSize: "12px" };

    return(
       <>Slug: http://example.com/{ contentTypeSlug !== null &&  <span style={style}>{ contentTypeSlug }/</span> }{slug} </>
    );
}

class DomainMapping extends Component {

    render() {
        const props = this.props;
        const { content } = this.props;
        const contentType = content.hasOwnProperty('content_type') ? content.content_type: {};

        return (
            <Input { ...props } label={<CustomLabel contentTypeSlug={content.slug_override === false ? contentType.slug : null} slug={content.slug} />} value={content.slug} onChange={props.onSlugChange} switchToggled={content.slug_override}>
                <div className="slug__content-type">
                    <p>Include <strong>Content Type</strong></p>
                    <CustomSwitch onChange={() => this.props.onSwitchChange(!content.slug_override)} checked={!content.slug_override} />

                </div>
            </Input>
        );
    }
}

DomainMapping.defaultProps = {
    slugOverride : false
};


export default DomainMapping;


