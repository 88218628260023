import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import ActivityList from "../components/cards/activity-list";
import AdminUsersApi from '../lib/users/admin-users-api';
import AdminApi from '../lib/admin/admin-api';
import NewContent from '../components/content-search/create-new';
import actionParams from "../lib/users/actions";
import meApi from '../lib/users/me-api';

import { CreateButton, DeleteButton, ViewButton, UploadButton, ProceedButton, ModifyButton, SendButton, CancelButton, SaveButton, MessageButton, PasswordButton, ImageButton } from '../../js/components/buttons';

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actions: [],
            systemMessages:  [],
            newContentPopup: false
        };
    }

    componentDidMount() {
        this.getActions();
        this.getSystemMessages();
    }

    getActions() {
        AdminUsersApi.getActions(1, 20).then(response => {
            this.setState({actions: response.data});
        });
    }

    getSystemMessages() {
        AdminApi.systemMessages(1, 10).then(response => {
           this.setState({ systemMessages : response.data});
        });
    }


    render() {
        return (
            <>
                {this.state.newContentPopup && <NewContent setPopup={() => this.setState({newContentPopup: false})}/>}
                <div className={'dashboard'}>
                    <div className="dashboard__top-bar">
                        <div className="dashboard__row-title">
                            Quick Links
                        </div>
                        <ImageButton
                            src={'/assets/doc.svg'}
                            onClick={() => window.location = 'https://awesomedev.atlassian.net/servicedesk/customer/portal/3'}
                        >
                            Support Guides
                        </ImageButton>


                    </div>
                    <div className="dashboard__main">
                        <div className="dashboard__col">
                            <div className="dashboard__col-top">
                                <div className="dashboard__col-title">
                                    Recent user activity
                                </div>

                            </div>
                            <div className="dashboard__col-body">
                                <ActivityList actions={this.state.actions.map(a => {
                                    return  actionParams(a);
                                })}/>
                            </div>
                        </div>
                        <div className="dashboard__col">


                        </div>
                        <div className="dashboard__col">
                            {/* <div className="dashboard__col-top">
                                <div className="dashboard__col-title">
                                    Support Messages
                                </div>
                            </div>

                            <div className="dashboard__col-body">
                                <ActivityList height={'200px'} actions={this.state.systemMessages.map(a => {
                                    return {
                                        subtitle : 'System Message',
                                        title: a.title,
                                        body : a.body,

                                    }
                                })}/>
                            </div> */}

                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default Dashboard;

const el = document.getElementById('dashboard');

if (el) {
    ReactDOM.render(<Dashboard/>, el);
}
