import { useEffect , useState, useCallback } from 'react'
import { debounce } from "underscore";
import Dropdown from 'react-dropdown';
import MediaHelper from  '../../../lib/helpers/media';
import AlertsManager from "../../../managers/alert/alert-manager";

import SearchBox from '../../content-search/search-box';

import '@uppy/image-editor/dist/style.css'

import Display from './browse/display';
import Filter from './browse/filter';
import ViewDetails from './browse/view-details';
import _ from 'lodash';

export default ({onResourceSelect}) => {
    const [ selected, setSelected ] = useState(null);
    const [ images, setImages ] = useState([]);
    const [ viewDetails, setViewDetails ] = useState(false);
    const [ searchValue, setSearchValue ] = useState('');
    const [ pagination, setPagination ] = useState(1);
    const [ indexScreen, setIndexScreen ] = useState(true);
    const [ meta, setMeta ] = useState(null);
    const [ selectedFilter, setSelectedFilter ] = useState(null);

    const [ filteredImages, setFilteredImages ] = useState([]);

    const filterOptions = [
        'Normal',
        'Newest first',
        'Oldest first',
    ]

    const per_page = 20;

    const onClick = (item) => {
        if (selected != null && selected.id == item.id) return setSelected(null);

        setSelected(item);
    }

    const onFilterChange = (e) => {
        if (e.value == 'Newest first') {
            setFilteredImages(images.sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
            setSelectedFilter(filterOptions.findIndex(s => s == e.value));

            return;
        }

        if (e.value == 'Oldest first') {
            setFilteredImages(images.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()));
            setSelectedFilter(filterOptions.findIndex(s => s == e.value));

            return;
        }

        return setFilteredImages(images);
    }

    const onChange = (evt) => {
        const searchValue = evt.target.value;

        setSearchValue(searchValue);

        if (searchValue.length == 0) {
            return fetchImages(pagination);
        }

        debounceSearch(searchValue);
    }

    const onRename = () => {
        axios.get('/api/admin/images/' + selected.id).then(({ data: { data }}) => {
            setSelected(data);
        });
    }

    const paginationChange = (e) => {
        fetchImages(e.value);
    }

    const getOptions = () => {
        const items = meta.links.filter((_, index) => index > 0 && index < meta.links.length - 1);
        const newItems = items.map((item) => ({
            value: item.label,
            label: item.label,
            active: item.active,
        }));

        return newItems;
    }

    const debounceSearch = useCallback(debounce(search =>
        axios.post('/api/admin/images/search', { term: search }).then(({ data }) => {
            setImages(data);
            setFilteredImages(data);
            setIndexScreen(false);
    }), 500), [])

    const viewDetailsClick = () => {
        setViewDetails(!viewDetails);
    }

    const fetchImages = (pageNumber = 1) => {
        axios.get(`/api/admin/images?page=${pageNumber}&per_page=${per_page}`).then(({ data: { data, meta }}) => {
            setImages(data);
            setFilteredImages(data);
            setIndexScreen(true);
            setPagination(meta.current_page);
            setMeta(meta);
        });
    }

    const handleUseResource = () => {
       onResourceSelect({
           type: 'image',
           resource : selected
       })
    }

    const handleDelete = () => {
        setViewDetails(false);
        setSelected(null);
        fetchImages(pagination);
        AlertsManager.success('Image Deleted');
    }


    useEffect(() => {
        fetchImages();
    }, []);

    const getImagePath = (path) => {
        return MediaHelper.formatPublicPath(`/images/${path}`);
    }

    return (
        <div class="images-browse">
            { viewDetails ? <ViewDetails onDelete={handleDelete} onUseResource={handleUseResource} onRename={onRename} viewDetailsClick={viewDetailsClick} id={selected.id}/> :
            <>
                <div class="images-browse__section">
                    <div className="images-browse__outer">
                        <div class="images-browse__row">
                            <SearchBox onChange={onChange}/>
                            <Dropdown className="input input--idle" options={filterOptions} onChange={onFilterChange} value={filterOptions[selectedFilter]} placeholder="Sort By" />
                        </div>
                        <div class="images-browse__list">
                            { filteredImages.length > 0 && filteredImages.map((item, index) => {
                                const className = "images-item " + (item.id == selected?.id ? 'images-item--selected' : '')

                                return (
                                    <div key={index} class={className} style={{ background: `url(${getImagePath( item.file_name)}` }} onClick={() => onClick(item)}>
                                        <div class="images-item__select">
                                            { item.id == selected?.id ? 'Selected' : 'Select Image' }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    { indexScreen && meta != null && <div class="content-searcher__bottom">
                        <button disabled={meta.current_page == 1} class={"button button__fit " + (meta.current_page == 1 ? 'button--idle' : 'button--alternate')} onClick={() => fetchImages(meta.current_page - 1)}>Previous Page</button>
                        <Dropdown className="input input--idle" options={getOptions()} onChange={paginationChange} value={String(meta.current_page)}/>
                        <button disabled={meta.current_page == meta.last_page} class={"button button__fit " + (meta.current_page == meta.last_page ? 'button--idle' : 'button--alternate')} onClick={() => fetchImages(meta.current_page + 1)}>Next Page</button>
                    </div> }
                </div>
                <div class="images-browse__box">
                    { selected != null ? <Display onDelete={() => {
                        setSelected(null);
                        fetchImages(pagination);
                    }} onUseResource={handleUseResource} img={selected} viewDetailsClick={viewDetailsClick}/> : <Filter/> }
                </div>
            </>
        }
        </div>
    )
}

