import { useState } from 'react';

import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

import ImagesPopup from "../popups/images/images-popup";

export default (props) => {
    const { label, placeholder, fill, name, onChange } = props;

    const [ popup, setPopup ] = useState(null);
    
    return (
        <>
             {
                popup &&
                <ImagesPopup
                    onClose={() => setPopup(null)}
                    onResourceSelect={r => {
                        setPopup(null);

                        onChange({
                            target: {
                                name: name,
                                value: r.resource.file_name
                            }
                        })
                    }}
                    onlyShow={['browse', 'upload']}
                />
            }

            <LabelContainer label={label} placeholder={placeholder} fill={fill}>
                <ErrorContainer errors={props?.errors} fill={fill}>
                    <div class="input-outer__select">
                        <input className="inputs-text" { ...props }/>
                        <button onClick={() => setPopup(true)}>Select</button>
                    </div>
                </ErrorContainer>
            </LabelContainer>
        </>
    )
}
