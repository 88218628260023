import { Component } from 'react';

import Dropdown from './dropdown';

import SidePopupSection from '../../../components/side-popup-section';

import Input from '../../../components/input-with-label';
import Checkbox from '../../../components/checkbox';
import Textarea from '../../../components/textarea-with-label';

import FileUploader from '../../../components/popups/images/file-uploader-popup';
import FileTypes from '../../../lib/files/file-types';

import { AddButton, DeleteButton, UploadButton } from '../../../components/buttons';

export default class GrantModifyEligibility extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFileUploader: false,
            selectedConfigId: null,
        }
    }

    componentDidMount() {}

    addConfig() {
        const eligibility_config = Array.isArray(this.props.config) ?  [ ...this.props.config ] : [];

        eligibility_config.push({
            title : '',
            description : '',
            questions: [],
            files: []
        })

        this.props.onChange(eligibility_config);

    }

    removeConfig(index) {
        let eligibility_config = [ ...this.props.config ];

        eligibility_config = eligibility_config.filter((_, i) => i !== index);

        this.props.onChange(eligibility_config);
    }

    changeConfigValue(key, value, index) {
        let eligibility_config = [ ...this.props.config ];

        eligibility_config[index][key] = value;

        this.props.onChange(eligibility_config);
    }

    onConfigInput(e, index) {
        const { name, value } = e.target;

        this.changeConfigValue(name, value, index);
    }

    onConfigDescription(e, index) {
        const { value } = e.target;

        this.changeConfigValue('description', value, index);
    }

    addFileToConfig(file) {
        const eligibility_config = [ ...this.props.config ];
        const files = eligibility_config[this.state.selectedConfigId].files;

        files.push(file);
      
        this.setState({  selectedConfigId: null }, () => {
            this.closeFileUploader();
            this.props.onChange(eligibility_config);
        });
    }

    removeFileFromConfig(index, fileIndex) {
        const eligibility_config = [ ...this.props.config ];
        const files = eligibility_config[index].files;

        eligibility_config[index].files = files.filter((_, fi) => fi !== fileIndex);

        this.props.onChange(eligibility_config);
    }

    addQuestion(index) {
        const eligibility_config = [ ...this.props.config ];
        const questions = eligibility_config[index].questions;

        questions.push({
            label: '',
            required: false,
        })

        this.props.onChange(eligibility_config);
    }

    removeQuestion(index, questionIndex) {
        const eligibility_config = [ ...this.props.config ];
        let questions = eligibility_config[index].questions;

        eligibility_config[index].questions = questions.filter((_, qi) => qi !== questionIndex);

        this.props.onChange(eligibility_config);
    }

    changeQuestionValue(key, value, index, questionIndex) {
        const eligibility_config = [ ...this.props.config ];
        let questions = eligibility_config[index].questions;

        questions[questionIndex][key] = value;

        this.props.onChange(eligibility_config);
    }

    onQuestionInput(e, index, questionIndex) {
        const { value } = e.target;

       this.changeQuestionValue('label', value, index, questionIndex);
    }

    onQuestionRequired(e, index, questionIndex) {

        const { value } = e.target;

        this.changeQuestionValue('required', value, index, questionIndex);
    }

    showFileUploader(configId) {
        this.setState({ showFileUploader: true, selectedConfigId: configId  });
    }

    closeFileUploader() {
        this.setState({ showFileUploader: false });
    }

    render() {
        const fileTypes = [
            FileTypes.PDF,
            FileTypes.TXT,
            FileTypes.DOC,
            FileTypes.DOCX,
            FileTypes.XLSX,
            FileTypes.CSV,
            'image/jpeg',
            'image/png'
        ]


        return (
            <>
                <FileUploader toggled={this.state.showFileUploader}
                        onClose={this.closeFileUploader.bind(this)}
                        onlyShow={['browse', 'upload']}
                        fileTypes={fileTypes}
                        path={`/api/grants/${this.props.grant.id}/files`}
                        endpoint={`/api/grants/${this.props.grant.id}/files`}
                        onResourceSelect={this.addFileToConfig.bind(this)}/>

                <SidePopupSection title="Add Guidance and Eligibility Questions">
                    {
                        this.props.config !== null &&
                        this.props.config.map((config, index) => {
                        return (
                            <Dropdown key={index} head={<DeleteButton mobile={true} onClick={() => this.removeConfig(index)}/>} title={`Item ${index + 1}`} index={index + 1} className="eligibility-question">
                                <div style={{ padding: '0px 21px 21px 21px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <Input placeholder="Enter title" name="title" value={config.title} onChange={(e) => this.onConfigInput(e, index)}/>
                                        <Textarea placeholder="Enter additional description (optional)" name="description" value={config.description} onChange={(e) => this.onConfigInput(e, index)}/>
                                    </div>

                                    <div className="eligibility-question__checkboxes">
                                        { config.questions.length > 0 ? config.questions.map((question, questionIndex) => {
                                            return (
                                                <div key={questionIndex} className="eligibility-question-checkbox utils__row utils__gap--20">
                                                    <Input placeholder="Enter checkbox text" value={question.label} onChange={(e) => this.onQuestionInput(e, index, questionIndex)}/>
                                                    <Checkbox checked={question.required} onChange={(e) => this.onQuestionRequired(e, index, questionIndex)} text="Required"/>
                                                    <span className="column__delete" onClick={() => this.removeQuestion(index, questionIndex)}>X</span>
                                                </div>
                                            )
                                        }) : <p>No checkboxes added</p>}
                                    </div>

                                    <div className="eligibility-question__buttons">
                                        <AddButton onClick={() => this.addQuestion(index)}>Add Checkbox</AddButton>
                                        <UploadButton onClick={() => this.showFileUploader(index)}>Document</UploadButton>
                                    </div>

                                    { config.files.length > 0 && <div className="eligibility-question__documents">
                                        { config.files.map((file, fileIndex) => {
                                            return (
                                                <div key={fileIndex} className="eligibility-question-file">
                                                    <div className="eligibility-question-file__icon">
                                                        <img src="/assets/document-icon.svg" />
                                                    </div>
                                                    <div className="eligibility-question-file__text">
                                                        <p>{ file.name }</p>
                                                        <p className="eligibility-question-file__delete" onClick={() => this.removeFileFromConfig(index, fileIndex)}>Remove</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div> }
                                </div>
                            </Dropdown>
                        )
                    }) }
                    <AddButton onClick={this.addConfig.bind(this)}>Add Item</AddButton>
                </SidePopupSection>
            </>
        )
    }
}
