import React, {Component} from 'react';
import ImageInput from "./image-input";
import Textarea from "./inputs-textarea";
import Input from "./inputs-text";
import {RichText} from "../content-editor";

class DynamicInputList extends Component {

    getValue(field) {
        return this.props.getValue ? this.props.getValue(field) : '';
    }

    render() {
        const {config, onChange, chunkSize = 2} = this.props;
        return (
            <>
                {
                    _.chunk(config, chunkSize).map((c, i) => {
                        return (
                            <div key={i} className="companies-settings__row">

                                {
                                    c.map((item, index) => {
                                        let params = {
                                            key: index,
                                            name: item.field,
                                            value: this.getValue(item.field),
                                            placeholder: _.startCase(item.label ? item.label : item.field),
                                            onChange: e => {
                                                onChange(e, item.type)
                                            }
                                        };
                                        switch (item.type) {
                                            case('image') : {
                                                return (
                                                    <ImageInput {...params} />
                                                );
                                            }
                                            case('textarea') : {
                                                return (<Textarea {...params} />);
                                            }
                                            case('wysiwyg') : {
                                                params.handleChange = ({data: {body}}) => {
                                                    onChange({target: {name: item.field, value: body}}, item.type);
                                                }
                                                params.config = {
                                                    name: item.field,
                                                };
                                                params.data  = this.getValue(item.field);
                                                return (<RichText {...params} />);
                                            }
                                            default:
                                                return (
                                                    <Input {...params} />
                                                );
                                        }
                                    })
                                }
                            </div>
                        )
                    })
                }
            </>
        );
    }
}

export default DynamicInputList;
