import {Component} from 'react';

import SidePopup from '../../../components/side-popup';
import SidePopupSection from '../../../components/side-popup-section';

import Input from '../../../components/input-with-label';
import InputDate from '../../../components/input-date';
import TextArea from '../../../components/textarea-with-label';

import GrantsApi from '../../../lib/grants/grants-api';

import {SaveButton, AddButton} from '../../../components/buttons';

import AlertManager from '../../../managers/alert/alert-manager';

import Eligibility from './grant-modify-eligibility';
import EligibilityQuestions from './grant-modify-eligibility-questions';

import Dropdown from "../../../components/dropdowns/dropdown";
import {stringToDatetimeLocal, stringToUTCDateTime} from "../../../lib/helpers/datetime-helpers";

import Select from 'react-select'

import PrefixPopup from '../components/popups/grant-prefixes';

import AdminAPI from '../../../lib/admin/admin-api';
import GrantsAPI from '../../../lib/grants/grants-api';

import {SmallPill} from '../../../components/pills';

export default class GrantModify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                meta: {},
                eligibility_config: []
            },
            users: [],
            options: [],
            managers: [1],
            errors: {},
            prefixPopup: false,
            prefixes: [],
        }
    }

    componentDidMount() {
        this.setState({
            managers: this.props.grant.managers,
            values: Object.assign({}, this.state.values, this.props.grant)
        }, () => console.log(this.state, 'sdsd'));

        this.fetchUsers();
        this.fetchPrefixes();
    }

    fetchPrefixes() {
        GrantsAPI.getGrantPrefixes().then(({data}) => {
            this.setState({prefixes: data})
        })
    }

    fetchUsers() {
        AdminAPI.getUsers(1, 200).then(({data}) => {
            data = [...data, window.user]

            this.setState({
                users: data,
                options: data.map(user => ({
                    value: user.id,
                    label: user.name
                })).filter(user => !this.state.managers.find(manager => manager.id == user.value))
            });
        })
    }

    updatePrefixes(prefix) {

        this.setState({
            values: Object.assign({}, this.state.values, {
                grant_prefix_id: prefix.id
            }),
            prefixes: [
                ...this.state.prefixes,
                prefix
            ]
        })
    }

    setPrefixPopup(bool) {
        this.setState({prefixPopup: bool})
    }

    setExcludedIndustries(values) {
        this.onMetaChange({
            target: {
                name: 'excluded_company_industries',
                value: values,
            }
        })
    }

    onManagerChange({value}) {
        const hasManager = this.state.managers.find(manager => manager.id == value);

        if (hasManager) return;

        GrantsApi.addManager(this.props.grant.id, value).then(data => this.setManagers(data));
    }

    removeManager(value) {
        const hasManager = this.state.managers.find(manager => manager.id == value);

        if (!hasManager) return;

        GrantsApi.removeManager(this.props.grant.id, value).then(data => this.setManagers(data));
    }

    setManagers(data) {
        const filteredUsers = this.state.users.map(user => ({value: user.id, label: user.name}))
            .filter(user => !data.managers.find(manager => manager.id == user.value))

        this.setState({
            managers: data.managers,
            options: filteredUsers
        })
    }

    handleSave() {
        let values = { ...this.state.values };

        delete values?.latest_grant_versions;
        delete values?.grant_versions;

        GrantsApi.update(this.props.grant.id, values, (errors) => {

            this.setState({errors: errors});
            AlertManager.error('There are some errors with your input');
        }).then(() => {
            this.props.refreshGrant();
            AlertManager.success('Grant Saved');
            this.props.onClose();
        });
    }

    onChange(e) {
        let {name, value} = e.target;

        if (name == 'grant_value') {
            value = Number(value);
        }

        this.setState({values: {...this.state.values, [name]: value}});
    }

    onDateChange(e) {
        const [startDate, endDate] = e;

        this.setState({
            values: {
                ...this.state.values,
                available_from: startDate,
                available_to: endDate,
            }
        });
    }

    onPrefixChange(prefix) {
        const {value} = prefix;

        this.onChange({target: {name: 'grant_prefix_id', value: value}});
    }

    onMetaChange(e) {

        this.setState({
            values: Object.assign({}, this.state.values, {
                meta: Object.assign({}, this.state.values.meta, {
                    [e.target.name]: e.target.value
                })
            })
        }, () => console.log(this.state.values));
    }

    getMetaValue(name) {
        return this.state.values.meta ? this.state.values.meta[name] : '';
    }

    head() {
        return (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Grant Details</p>
                    <span>></span>
                    <p>{this.state.values.name}</p>
                </div>
                <SaveButton onClick={this.handleSave.bind(this)}></SaveButton>
            </div>
        );
    }

    render() {
        const prefixes = this.state.prefixes.map(p => ({label: p.name.toUpperCase(), value: p.id}));
        const prefixValue = prefixes.find(p => p.value == this.state.values?.grant_prefix_id);


        return (
            <>
                {this.state.prefixPopup && <PrefixPopup setPopup={this.setPrefixPopup.bind(this)}
                                                        updatePrefixes={this.updatePrefixes.bind(this)}/>}

                <SidePopup type="grant-details-popup" head={this.head()} onClose={this.props.onClose} content={
                    <>
                        <SidePopupSection title="General Details">
                            <Input name="name"
                                   label="Name of Grant (this is what the form will be called)"
                                   placeholder="Name of grant"
                                   errors={this.state.errors.name}
                                   value={this.state.values.name} onChange={this.onChange.bind(this)}/>

                            <Input name="funding_body"
                                   label="Funding body"
                                   placeholder="Funding body"
                                   errors={this.state.errors}
                                   value={this.state.values.funding_body} onChange={this.onChange.bind(this)}/>


                            <div className="input-outer">
                                <label>Add Prefix (E.g BMBC-DIG, no spacing)</label>
                                <div className="utils__row utils__gap--20">
                                    <Dropdown error={this.state.errors.grant_prefix_id} value={prefixValue}
                                              items={prefixes} onChange={this.onPrefixChange.bind(this)}/>
                                    <AddButton mobile={true} onClick={() => this.setPrefixPopup(true)}/>
                                </div>
                            </div>

                            <Input name="prefix_count_start_from"
                                   type={'prefix_count_start_from'}
                                   label={`Prefix Start From (e.g. if 1, the next application will be named #${this.state.values.prefix ? this.state.values.prefix.toUpperCase() : 'prefix'}-0001)`}
                                   placeholder={''}
                                   value={this.state.values.prefix_count_start_from}
                                   onChange={this.onChange.bind(this)}/>

                            <TextArea name="description"
                                      label="Description"
                                      placeholder="Description"
                                      value={this.state.values.description} onChange={this.onChange.bind(this)}/>

                            <Input name="grant_value_description"
                                   label="Grant value description"
                                   placeholder="e.g. 50% grant funding for projects at £1,500 to £5,000 "
                                   value={this.getMetaValue('grant_value_description')}
                                   onChange={this.onMetaChange.bind(this)}/>

                            <div className="utils__row utils__gap--20 utils__wrap">
                                <InputDate
                                    label={'Availability Window'}
                                    allowDateRange={true}
                                    value={[this.state.values.available_from, this.state.values.available_to]}
                                    type="datetime-local"
                                    onChange={this.onDateChange.bind(this)}/>

                                <Input name="grant_value"
                                       label="Grant Value"
                                       placeholder="Max. Grant Value"
                                       value={this.state.values.grant_value} onChange={this.onChange.bind(this)}/>
                            </div>
                        </SidePopupSection>

                        <EligibilityQuestions
                            grant={this.props.grant}
                            onChange={config => this.onChange({target: {name: 'eligibility_config', value: config}})}
                            config={this.state.values.eligibility_config}
                        />

                        <Eligibility grant={this.props.grant}
                                     setExcludedIndustries={this.setExcludedIndustries.bind(this)}
                                     getMetaValue={this.getMetaValue.bind(this)}
                                     onChange={this.onMetaChange.bind(this)}/>

                        <SidePopupSection title="Mandatory Requirements">
                            <TextArea name="mandatory_requirement_notes"
                                      label="Guidance notes for applicants"
                                      placeholder=""
                                      value={this.getMetaValue('mandatory_requirement_notes')}
                                      onChange={this.onMetaChange.bind(this)}/>

                        </SidePopupSection>

                        <SidePopupSection title="Application Managers">
                            <Select value='' className='react-select-container' classNamePrefix="react-select"
                                    options={this.state.options} onChange={this.onManagerChange.bind(this)}/>
                            {this.state.managers.length > 0 &&
                            <div className="utils__row utils__gap--20 utils__wrap">
                                {this.state.managers.map((manager, index) => {
                                    return <SmallPill key={index}
                                                      removable={true}
                                                      onClick={() => this.removeManager(manager.id)}>{manager.name}</SmallPill>
                                })}
                            </div>
                            }
                        </SidePopupSection>

                        <SidePopupSection title="Application Scoring">
                            <div className="text-area__row">
                                <label htmlFor="">Please confirm how many people are required to score this
                                    application</label>
                            </div>
                            <Dropdown
                                value={this.state.values.min_required_scorers ? {
                                    label: this.state.values.min_required_scorers,
                                    value: this.state.values.min_required_scorers
                                } : null}
                                items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                                    return {value: item, label: item};
                                })} onChange={e => this.onChange({
                                target: {
                                    name: 'min_required_scorers',
                                    value: e.value
                                }
                            })}/>

                        </SidePopupSection>
                    </>
                }/>
            </>
        )
    }
}
