export default ({ value, onChange = () => {}, placeholder = 'Search Field', results }) => {
    return (
        <div class="search-box__outer">
            <div class={"search-box " + (results ? "search-box--toggled" : "")}>
                <input placeholder={placeholder} value={value} onChange={onChange}/>
                <div class="content-search__button"><img src='/assets/SVG/awesomefunction-search.svg'/></div>
            </div>
             { results && <div class="search-box__content">
                <div class="search-item">Your search item</div>
                <div class="search-item">Your search item</div>
                <div class="search-item">Your search item</div>
                <div class="search-item">Your search item</div>
                <div class="search-item">Your search item</div>
            </div> }
        </div>
    )
}