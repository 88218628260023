import { useState, useEffect, useContext } from "react";


import MeApi from '../lib/users/me-api';
import ReactDOM from "react-dom";

import TopBarResponsive from './topbar-responsive';

import NotificationsMenu from "./notifications-menu";
import NotificationsMenuResponsive from "./notifications-menu-responsive";

import UserDropdown from "./user-dropdown"

import NotificationsPopup from './notifications-popup';
import MessagesPopup from './messages-popup';

import {authUserId} from "../lib/helpers/user";
import SearchBar from "./search/search-bar";
import Row from "../utils/row";

import { BiTask } from 'react-icons/bi';

import { useTasksStore } from "../context/context-tasks";

const TopBar = () => {
    window.Echo.private('admin-notification').listen('NewNotification',  () => {
        getNotificationCount(true);
    });

    window.Echo.private(`user-notification-${authUserId()}`).listen('NewNotification',  () => {
        getNotificationCount();
    }).listen('UserUpdated', () => {
        getUser();
    });

    const [ showDropdown, setShowDropdown ] = useState(false);

    const [ showNotifications, setShowNotifications ] = useState(false);
    const [ showMessages, setShowMessages ] = useState(false);

    const [ notificationCount, setNotificationCount ] = useState({ total : 0, unread : 0});
    const [ user, setUser ] = useState({ user_profile : {}});
    const [ unread, setUnread ] = useState(0);

    const { tasks } = useTasksStore();

    useEffect(() => {
        getUser();
        getNotificationCount();
        getConversations();
    }, []);

    const getConversations = () => {
        MeApi.getConversations({ with_unread_messages: 1 }).then(({ meta }) => {
            setUnread(meta.total);
        })
    }


    const getUser = () => {
        MeApi.me().then(data => {
            setUser(data);
        });
    }

    const getNotificationCount = (animate) => {
            MeApi.notificationCount().then(count => {
                setNotificationCount(count);

            });
    }
    const dropdownClickHandler = () => setShowDropdown(!showDropdown);

    let img = '/assets/user-icon.svg';
    if(user.user_profile) {
        img  = user.user_profile.profile_img ? `/media/private/${user.user_profile.profile_img}` : "/assets/user-icon.svg";
    }

    return (
        <div class="topbar">
            { showNotifications && <NotificationsPopup
                notificationCount={notificationCount}
                set={setShowNotifications}
                onNotificationsUpdate={() => getNotificationCount()}
            /> }
            { showMessages && <MessagesPopup set={setShowMessages}/> }

            <TopBarResponsive img={img} onClick={dropdownClickHandler}/>

            <NotificationsMenuResponsive
                notificationCount={notificationCount}
                setShowNotifications={setShowNotifications}
                setShowMessages={setShowMessages}
                showNotifications={showNotifications}
            />

            <div class="topbar__content">
                <div class="topbar__head">
                    <img className="topbar__logo" src="/assets/logo.png" alt="EB Logo"/>
                </div>
                <div class="topbar__left">
                    <SearchBar />
                </div>
                <div class="topbar__right">

                    <Row gap="0px">
                        <div class="notifications__menu" onClick={() => window.location.href="/tasks" }>
                            <div className={`notification-item`}>
                                <BiTask style={{ width: '25px', height: '25px' }}/>
                                { tasks > 0 && <span class="notification-item__icon">{ tasks }</span> }
                            </div>
                        </div>

                        <div class="notifications__menu" onClick={() => window.location.href="/messages" }>
                            <div className={`notification-item`}>
                                <svg class="icon__notication" xmlns="http://www.w3.org/2000/svg" width="21" height="20.5" viewBox="0 0 21 20.5">
                                    <path id="Path_303" data-name="Path 303" d="M5.455,15,1,18.5V3A1,1,0,0,1,2,2H17a1,1,0,0,1,1,1V15Zm-.692-2H16V4H3V14.385ZM8,17H18.237L20,18.385V8h1a1,1,0,0,1,1,1V22.5L17.545,19H9a1,1,0,0,1-1-1Z" transform="translate(-1 -2)" fill="#000000"/>
                                </svg>
                                { unread > 0 && <span class="notification-item__icon">{ unread }</span> }
                            </div>
                        </div>

                        <NotificationsMenu
                            notificationCount={notificationCount}
                            setShowNotifications={setShowNotifications}
                            setShowMessages={setShowMessages}
                            showNotifications={showNotifications}
                        />
                    </Row>

                    <div class="topbar__inner">
                        <div class="user-info">
                            <img src={img}/>
                            <div class="user-info__inner">
                                <h4>{ user.name }</h4>
                                <p>{ user.super_admin ? 'Super Admin' : 'Admin'}</p>
                            </div>
                        </div>
                        <div class="topbar-menu__icon" onClick={dropdownClickHandler}>
                            <img src="/assets/downArrow.svg"/>
                        </div>
                    </div>
                </div>
            </div>
            { showDropdown && <UserDropdown user={user} close={dropdownClickHandler}/> }
        </div>
    )
}

export default TopBar;

if (document.getElementById('topbar')) {
    ReactDOM.render(<TopBar/>, document.getElementById('topbar'));
}
