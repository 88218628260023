import Row from "../../utils/row"
import List from '../../components/list/list';

export default function ProgressItem({ accepted, title, subtitle, content = null, left = null, right = null }) {
    return (
        <div className={`progress-item ${accepted ? 'progress-item--toggled' : ''}`}>
            <List gap="20px" style={{ width: '100%' }}>
                <Row alignItems="center" justifyContent="space-between" fill>
                    { left ? left : <Row gap="15px">
                        <img src="/assets/document-icon.svg"/>
                        <div className="progress-item__text">
                            <p className="progress-item-text__title">{ title }</p>
                            <p className="progress-item-text__subtitle">{ subtitle }</p>
                        </div>
                    </Row> }

                    { right && right }
                </Row>
                { content && content }
            </List>
        </div>
    )
}