import ContainerGrid from './container-grid';

import ButtonPrevious from '../components/buttons-navigation/button-previous';
import ButtonNext from '../components/buttons-navigation/button-next';

function ContainerIndex({ children }) {
    return (
        <div className="container-index">
            { children }
        </div>
    )
}

ContainerIndex.Filters = ({ children }) => {
    return (
        <div className="container-index__filters">
            { children }
        </div>
    )
}

ContainerIndex.Content = ({ children, className = '' }) => {
    return (
        <div className="container-index__content">
            <ContainerGrid className={className}>
                { children }
            </ContainerGrid>
        </div>
    )
}

ContainerIndex.Pagination = ({ 
    currentPage = 0,
    maxPages = 0,
    onPrevious = () => {},
    onNext = () => {},
}) => {
    return (
        <div className="container-index__pagination">
            <ButtonPrevious onClick={onPrevious} disabled={currentPage === 1}>Prev</ButtonPrevious>
            <p>{ currentPage } of { maxPages }</p>
            <ButtonNext onClick={onNext} disabled={currentPage === maxPages}>Next</ButtonNext>
        </div>
    )
}

export default ContainerIndex;