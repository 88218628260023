export default ({ content, selectedIndex, reset, resetText, onSelect, onReset, icons, icon }) => {
    const sort = icon == 'sort' ? <img src={icons.sort2} alt="Dropdown item icon"/> : '';

    return (
        <div className="select-dropdown__content">
            { reset && <div className="select-dropdown__item" onClick={onReset}>{ resetText }</div> }
            
            { content.map((item, index) => {
                return (
                    <div key={index} className={`select-dropdown__item ${selectedIndex == index ? 'select-dropdown__item--selected' : ''}`} onClick={() => onSelect(item, index)}>
                        { sort }
                        { item.label }
                    </div>
                )
            })}
        </div>
    )
}