import { PasswordButton} from '../../../components/buttons';

import Input from '../../../components/input-with-label';

import { Component, createRef } from 'react';

import Row from '../../../utils/row';
import { CancelButton, AddButton, SendButton } from '../../../components/buttons';
import Divider from '../../../components/divider/divider';
import List from '../../../components/list/list';

import Container from '../../../containers/container';
import { stringToFriendlyDatetime } from '../../../lib/helpers/datetime-helpers';

import FileUploader from '../../../components/popups/images/file-uploader-popup';
import FileTypes from '../../../lib/files/file-types';

import MeApi from '../../../lib/users/me-api';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

function MessageSent({ message }) {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`}  className="chatbox-message__outer chatbox-message__outer--sent">
            <div className="chatbox-message chatbox-message--sent" style={{
                maxWidth: '70%',
                gap: '40px'
            }}>
                <p className="chatbox-message__message">{ message.body }</p>
                <Row gap="10px" alignItems='center'>
                    <Container.Icon style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%'
                    }} src={message?.from?.user_profile?.profile_img || '/assets/user-icon.svg'}/>
                    <p className="chatbox-message__sender">{ message?.from?.name }</p>
                </Row>
            </div>
            <Row gap="10px" alignItems='center'>
                <Row gap="5px" alignItems='center'>
                    { message.read_by?.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Container.Icon className={`sent-read-by-${index}`} style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%'
                                }} src={item?.user_profile?.profile_img || '/assets/user-icon.svg'}/>

                                <Tooltip
                                    anchorSelect={`.sent-read-by-${index}`}
                                    content={`${item?.name} - ${stringToFriendlyDatetime(item?.created_at)}`}
                                />
                            </Row>
                        )
                    }) }
                </Row>
                <p className="chatbox-message__date">{ CREATED_AT_DATE }</p>
            </Row>
        </div>
    )
}

function MessageReceived({ message }) {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`} className="chatbox-message__outer chatbox-message__outer--received">
            <div className="chatbox-message chatbox-message--received" style={{
                maxWidth: '70%',
                gap: '40px'
            }}>
                <p className="chatbox-message__message">{ message.body }</p>
                <Row gap="10px" alignItems='center'>
                    <Container.Icon style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%'
                    }} src={message?.from?.user_profile?.profile_img || '/assets/user-icon.svg'}/>
                    <p className="chatbox-message__sender">{ message?.from?.name }</p>
                </Row>
            </div>
            <Row gap="10px" alignItems='center'>
                <p className="chatbox-message__date">{ CREATED_AT_DATE }</p>
                <Row gap="5px" alignItems='center'>
                    { message.read_by?.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Container.Icon className={`recieved-read-by-${index}`} style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%'
                                }} src={item?.user_profile?.profile_img || '/assets/user-icon.svg'}/>

                                <Tooltip
                                    anchorSelect={`.recieved-read-by-${index}`}
                                    content={`${item?.name} - ${stringToFriendlyDatetime(item?.created_at)}`}
                                />
                            </Row>
                        )
                    }) }
                </Row>
            </Row>
        </div>
    )
}

export default class AccountMessagesMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFileUploader: false,
            conversation: null,
            messages: [],
            message: '',
        };

        this.messagesRef = createRef(null);
    }


    componentDidMount() {
        this.fetch();
        this.fetchMessages();
        this.readMessages();
    }

    fetch() {
        MeApi.getConversation(this.props.id).then((data) => {
            this.setState({ conversation: data });
        });
    }

    async fetchMessages() {
        return MeApi.getConversationMessages(this.props.id).then((data) => {
            const messages = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            this.setState({ messages });
            this.scrollTop();
        })
    }

    readMessages() {
        return MeApi.readConversationMessages(this.props.id);
    }

    setShowFileUploader(bool) {
        this.setState({
            showFileUploader: bool,
        })
    }

    scrollTop() {
        document.querySelector('#content').scrollTop = document.querySelector('#content').scrollHeight;
    }

    sendMessage() {
        if (this.state.message.length == 0) return;

        this.postMessage({
            body: this.state.message,
        })

        this.setState({ message: '' })
    }

    postMessage(params, callback = () => {}) {
        MeApi.createConversationMessage(this.state.conversation.id, params).then(async () => {
            await this.fetchMessages();
            callback();

            this.scrollTop();
        })
    }

    onChange(e) {
        const { value } = e.target;

        this.setState({ message: value })
    }


    messageEnterSubmit(e) {
        if (e.key === "Enter" && e.shiftKey == false) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    render() {
        if (this.state.conversation === null) return null;

        return (
            <Container>
                <Container.Header>
                    <List gap="40px">
                        <CancelButton onClick={this.props.return} style={{
                            width: 'fit-content'
                        }}>Return</CancelButton>

                        <List gap="15px">
                            <Container.Title color="#151515" fontSize="20px">Messages</Container.Title>
                            <Row gap='50px'>
                                <Container.Text color="#1577EA" fontSize="16px">Created: { stringToFriendlyDatetime(this.state.conversation.created_at) } - Updated: { stringToFriendlyDatetime(this.state.conversation.updated_at) }</Container.Text>
                                <Container.Text color="#1577EA" fontSize="16px">Type: { this.state.conversation.subject }</Container.Text>
                            </Row>
                        </List>
                    </List>
                    <Divider/>
                </Container.Header>
                <Container.Content>


                    <List gap="40px">
                            <div className="chatbox__messages" ref={this.messagesRef}>
                                { this.state?.messages?.map(message => {

                                    if (window.user.id == message?.from?.id) {
                                        return <MessageSent message={message}/>
                                    } else {
                                        return <MessageReceived message={message}/>
                                    }
                                })}
                            </div>

                            <div className="chatbox__input">
                                <textarea placeholder="Enter message" value={this.state.message} onKeyPress={this.messageEnterSubmit.bind(this)} onChange={this.onChange.bind(this)} />
                                <div className="chatbox-input__buttons">
                                    <SendButton mobile={true} onClick={this.sendMessage.bind(this)}/>
                                </div>
                            </div>
                    </List>
                </Container.Content>
            </Container>
        );
    }
}

const MessageFile = ({ message, sent }) => {
    const CREATED_AT_DATE = stringToFriendlyDatetime(message.created_at);

    return (
        <div id={`comment-${message.id}`} className={`chatbox-message__outer ${sent ? 'chatbox-message__outer--sent' : 'chatbox-message__outer--received'}`}>
           <div className={`chatbox-message ${sent ? 'chatbox-message--sent' : 'chatbox-message--received'}`}>
                <img src="/assets/document-icon.svg" />
                <p className="chatbox-message__fileName">{ message?.application_file?.file?.name }</p>
                <p className="chatbox-message__sender">{ message.user.name }</p>
            </div>
            <p className="chatbox-message__date">{ message.read_by ? `Read By ${message.read_by.name} | ${CREATED_AT_DATE}` : CREATED_AT_DATE }</p>
        </div>
    )
}

