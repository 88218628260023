import { useEffect } from 'react'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import XHRUpload from '@uppy/xhr-upload'

import '@uppy/image-editor/dist/style.css'

export default () => {
    useEffect(() => {
        const uppy = new Uppy({
            meta: { type: 'avatar' },
            restrictions: { maxNumberOfFiles: 1 },
        });

        uppy.use(Dashboard, {
            id: 'Dashboard',
            inline: true,
            target: '#uppy-upload',
            debug: true,
            autoProceed: false,
            metaFields: [
                { id: 'name', name: 'Name', placeholder: 'file name' },
                { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
            ],
        })

        uppy.use(XHRUpload, {
            endpoint: '/media/images'
        });

        uppy.use(ImageEditor, {
            id: 'ImageEditor',
            quality: 0.8,
            target: Dashboard,
            cropperOptions: {
              viewMode: 1,
              background: false,
              autoCropArea: 1,
              responsive: true,
            },
        })

        uppy.on('file-editor:start', (file) => {
            console.log(file)
        })
    }, []);

    return (
        <div class="images-upload">
            <div id="uppy-upload"></div>
        </div>
    )
}

