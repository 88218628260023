import ApiBase from "../api-base";

class GrantsApi extends ApiBase {


    async getGrants(page = 1, perPage = 2000) {
        return this.handleResponse(async () => {
            const response = await axios.get(`/api/grants`, {params : { page : page, per_page : perPage}});
            return response.data;
        });
    }

    async getGrantPrefixes() {
        return this.handleResponse(async () => {
            const response = await axios.get(`/api/grant-prefixes`);
            return response.data;
        });
    }

    async createPrefix(prefix = '', validationHandler = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            const response = await axios.post(`/api/grant-prefixes`, {
                name: prefix
            });
            return response.data;
        }, validationHandler);
    }

    //Required params : name

    async create(data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants`, data);
            return response.data.data;
        }, validationCallback);
    }

    async update(id, data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${id}`, data);
            return response.data.data;
        }, validationCallback);
    }

    async get(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/grants/${id}`);
            return response.data.data;
        });
    }

    async delete(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/grants/${id}`);
            return response.data.data;
        }, validationCallback);
    }



    async checkPreview(grantId, data, validationCallback = this.defaultValidationHandler) {

        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/check-preview`, data);
            return response.data.data;
        }, validationCallback);
    }

    async generatePreview(grantId, data, validationCallback = this.defaultValidationHandler) {

        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/generate-preview`, data);
            return response.data.data;
        }, validationCallback);
    }

    //Required params : name

    async createVersion(grantId, data, validationCallback = this.defaultValidationHandler) {

        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/versions`, data);
            return response.data.data;
        }, validationCallback);
    }

    async publish(grantId, grantVersionId, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/publish`, { grant_version_id : grantVersionId});
            return response.data.data;
        }, validationCallback);
    }


    async addManager(grantId, userId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/add-manager`, {
                user_id: userId
            });
            return response.data.data;
        });
    }

    async removeManager(grantId, userId) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/grants/${grantId}/remove-manager`, {
                user_id: userId
            });
            return response.data.data;
        });
    }
}


export default new GrantsApi();
