import { useEffect } from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import QuestionType from "../../../../../../components/question/question-type-container";

export default function QuestionTypeHtml({ 
    question,
    index,
    onQuestionChange,
}) {
    return (
        <QuestionType title={question.title} type={question.type}>
            <CodeMirror
                height="300px"
                extensions={[html()]}
                value={question?.body || ''}
                onChange={(e) => onQuestionChange({ target: {
                    name: 'body',
                    value: e
                }})}
            />
        </QuestionType>
    )
}