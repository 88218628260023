import ApiBase from "../api-base";


class AdminApi extends ApiBase {

    constructor() {
        super();
        this.searchCancel = null;
    }


    async systemMessages(page = 1, perPage = 10) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin/system-messages`, {params : { per_page : perPage, page: page}});
            return response.data;
        });
    }

    async getTeams() {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin-teams`);
            return response.data;
        });
    }

    async addMemberToGroup(teamId, ids, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/admin-teams/${teamId}/sync-members`, {
                user_ids: ids,
            });

            return response.data.data;
        }, validationCallback);
    }

    async getGroupMembers(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin-teams/${id}/members`);
            return response.data;
        });
    }

    async editGroup(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/admin-teams/${id}`, params);

            return response.data.data;
        }, validationCallback);
    }

    async createGroup(name, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/admin-teams`, {
                name
            });

            return response.data.data;
        }, validationCallback);
    }

    async deleteGroup(id) {
        return this.handleResponse(async () => {
            return await axios.delete(`/api/admin-teams/${id}`)
        });
    }

    async getUsers(page = 1, perPage = 10) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin/users`, {params : { per_page : perPage, page: page}});
            return response.data;
        });
    }

    async getNonAdminUsers(page = 1, perPage = 10) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/users`, {params : { per_page : perPage, page: page}});
            return response.data;
        });
    }

    async search(term = '') {

        return this.handleResponse(async () => {
            if (this.searchCancel !== null) {
                this.searchCancel.abort();
                this.searchCancel = null;
                return false;
            }

            this.searchCancel = new AbortController();

            let response = await axios.post(`/api/admin/search`, {term: term}, {
                signal: this.searchCancel.signal
            });
            this.searchCancel = null;
            return response.data;
        })

    }

}

export default new AdminApi();
