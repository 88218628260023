
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import SidePopup from './side-popup';
import UserRoles from "./users/user-roles";
import Button from "./buttons/button";

import { ViewButton, DeleteButton, PasswordButton } from '../components/buttons';

export default ({ data, setModifyMember, modifyMember, user }) => {
    const { email } = data;

    const userProfile = data.user_profile || {}

    const firstName = userProfile.first_name || 'No name';
    const lastName = userProfile.last_name || 'No name';
    const jobTitle = userProfile.job_title || 'No title';
    const telNo = userProfile.tel_no || 'No Telephone'

    const [ updatedRoles, setUpdatedRoles ] = useState(null);

    const [ rolesList, setRolesList ] = useState([]);
    const [ roleError, setRoleError ] = useState([]);

    const confirm = () => {
        // Roles that are new to the user

        const newRoles = rolesList.filter(({ id, checked }) => checked && !updatedRoles.some(role => id == role.id));

        // Roles to delete are ones the user have but are unchecked

        const deleteRoles = rolesList.filter(({ id, checked }) => !checked && updatedRoles.some(role => role.id == id));

        if (newRoles.length > 0) {
            axios.post('/api/admin/users/' + data.id + '/add-roles', { role_ids: newRoles.map(({ id }) => id)}).then();
        }

        if (deleteRoles.length > 0) {
            axios.post('/api/admin/users/' + data.id + '/remove-roles', { role_ids: deleteRoles.map(({ id }) => id) }).then();
        }
    }

    const changeRole = (name, checked) => {
        setRolesList(
            rolesList.map((data) => {
                const role = data.name;

                if (name == role) {
                    return {
                        ...data,
                        checked: !checked
                    };
                }

                return data;
            })
          );
    }

    useEffect(() => {
        axios.get('/api/admin/users/' + data.id).then(({ data: { data } }) => {
            setUpdatedRoles(data.roles);

            axios.get('/api/admin/roles').then((roles) => {
                let apiRoles = roles.data.data;

                const newRoles = apiRoles.map(d => ({
                    ...d,
                    checked: data.roles.some(({ name }) => name == d.name),
                }));

                setRolesList(newRoles);
            });
        });
    }, [ modifyMember ])

    const superAdmin = user.super_admin === 1;

    return (
        <SidePopup type="modify-member" onClose={setModifyMember} back={true}>
            <h1 class="side-popup__title">{ firstName + ' ' + lastName  + ' | Manage ' }</h1>
            <div class="modify-member__info">
                <p class="modify-member__job-role">{ jobTitle }</p>
                <p>{ email }</p>
                <p>{ telNo }</p>
            </div>
            {
                superAdmin &&
                <UserRoles userId={data.id} />
            }
            {
                (!superAdmin && data.roles.length >= 1 ) &&
                    <>
                    <div className="modify-member__info">
                        <p>User Roles</p>
                    </div>
                        <div className="account__roles">
                            {
                                data.roles &&
                                data.roles.map((role, index) => {
                                    return (
                                        <div key={index} className="account-role">
                                            <h2>{role.name}</h2>
                                            <p>{role.description}</p>
                                            <b>{role.permissions.map(p => p.name).join('/')}</b>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>

            }

            <div class="modify-member__buttons-outer">
                <div class="modify-member__buttons">
                    <PasswordButton>Reset Password</PasswordButton>
                    {
                        superAdmin && <DeleteButton onClick={() => setPopup(false)}>Remove Account</DeleteButton>
                    }
                </div>
                <ViewButton onClick={() => window.location = `/users/${data.id}`}>View Details</ViewButton>
            </div>
        </SidePopup>
    )
}
