import {stringToFriendlyDatetime} from "../helpers/datetime-helpers";

export default function actionParams(action, defaultParams = null) {
    switch (action.type) {
        default :
            return defaultParams || modelAction(action)
    }
}

function modelAction(action) {

    return {
        subtitle: getModelTypeSting(action),
        title: `${getModelName(action)} was ${modelTypeParts(action)[1]}`,
        body: `${action.user.name} - ${stringToFriendlyDatetime(action.created_at)}`,
        link: getModelLink(action)
    }

}

function modelTypeParts(action) {
    return  action.type.split('_');
}

function getModelLink(action) {
    const parts = modelTypeParts(action);
    const model = parts[0];
    const type = parts[1];

    switch (model) {
        case ('company') :
            if (modelTypeViewable(type)) {
                return `/companies/${action.meta.id}`
            }
            return null;
        case ('content') :
            if (modelTypeViewable(type)) {
                return `/content/${action.meta.id}/edit`
            }
            return null;
        case ('distribution') :
            if (modelTypeViewable(type)) {
                return `/content/distributions/${action.meta.id}`
            }
            return null;
    }
}

function modelTypeViewable(type) {
    if (type === 'updated') {
        return type;
    }

    if (type === 'created') {
        return type;
    }
}

function getModelTypeSting(action) {

    return action.type.split('_').join(' ');
}

function getModeType(action) {
    return action.type.split('_')[0];
}

function getModelName(action) {
    const withTitle = ['content'];
    const type = getModeType(action);

    if(_.findIndex(withTitle, t => t === type) !== -1) {
        return action.meta.title;
    }
    return action.meta.name;
}
