import ApiBase from "../api-base";


class TasksApi extends ApiBase {

    constructor() {
        super();
        this.searchCancel = null;
    }


    async adminTasks(page = 1, perPage = 10, params = {}) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin/tasks`, {params : { per_page : perPage, page: page, ...params}});
            return response.data;
        });
    }

    async getTask(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/admin/tasks/${id}`);
            return response.data;
        });
    }

    async completeTask(id, validationHandler = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            const response = await axios.post(`/api/admin/tasks/${id}/complete`);
            return response.data;
        }, validationHandler);
    }

    async createNote(id, body, validationHandler = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            const response = await axios.post(`/api/admin/tasks/${id}/add-note`, {
                note: body
            });
            return response.data;
        }, validationHandler);
    }
}

export default new TasksApi();
