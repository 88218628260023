import { SmallPill } from '../../../components/pills';

export default function GrantApplicationsPills({ statuses = [], statusRemove }) {
    return (
        <div className="pills-container">
            { statuses.map((status, index) => {
                return <SmallPill key={index} removable={true} onClick={() => statusRemove(index)}>{ status }</SmallPill>
            })}
        </div>
    )
}