import Checkbox from '../../components/checkbox';

export default function GrantApplicationReview({ application, grant }) {
    const { eligibility_responses } = application;

    return (
        <div className="application-view__section">
            <div className="application-view-segment application-view__spacing">
                <p className="application-view-segment__title">Eligibility Overview</p>
                <p className="application-view-segment__description">{ grant?.meta?.eligibility_notes }</p>
            </div>
            { eligibility_responses.map(({ title, description, responses }, index) => {
                return (
                    <div className="application-view-segment" key={index}>
                        <p className="application-view-segment__title">{ index + 1}. { title }</p>
                        <p className="application-view-segment__description" dangerouslySetInnerHTML={{ __html: description }}/>
                        <p className="application-view-segment__content">
                            <div className="utils__row utils__gap--20">
                                { responses.map((response, key) => {
                                    return <p key={key} dangerouslySetInnerHTML={{ __html: response }}/>

                                    //<Checkbox checked={true} text={response} />
                                })}
                            </div>
                        </p>
                    </div>
                )  
            })}
        </div>
    )
}