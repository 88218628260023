import {Component} from 'react';
import CompanyItem from '../../js/components/companies/company-item';
import ReactDOM from 'react-dom';
import CompaniesApi from '../lib/companies/companies-api';
import NoDataError from '../../js/components/no-data-error';
import Breadcrumb from '../../js/components/breadcrumb';
import Pagination from "../components/pagination";
import Popup from '../components/popup';
import Input from "../components/input-with-label";

import { CreateButton, CancelButton } from '../components/buttons';

class Companies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            crumbs: [{
                name: 'Companies',
                link: '/companies'
            },
                {
                    name: 'View All',
                    link: '#'
                }],
            companies: [],
            page: 1,
            pagination: {},
            popup: false,
            new_company_name: '',
            errors : {}
        }
    }

    componentDidMount() {
        this.getCompanies();
    }

    getCompanies() {
        CompaniesApi.getCompanies(this.state.page, 15).then(results => {
            this.setState({
                pagination: results.meta,
                companies: results.data
            })
        })
    }

    handlePaginationChange(e) {
        this.setState({page: e}, () => this.getCompanies());
    }

    createNew() {
        CompaniesApi.create(
            {name : this.state.new_company_name},
                errors => this.setState({errors : errors})
        ).then(data => {
            window.location = `/companies/${data.id}`;
        });
    }

    render() {
        return (
            <div className="companies-outer">
                {
                    this.state.popup &&
                    <Popup type={'new-company'}>
                        <div className="popup__inner">
                            <h1>Create new company</h1>
                            <Input name="name"
                                   placeholder="Name"
                                   value={this.state.new_company_name}
                                   onChange={e => this.setState({new_company_name: e.target.value})}
                                   errors={this.state.errors.name}
                            />
                            <div className="popup__buttons">
                                <CreateButton responsive={false} onClick={this.createNew.bind(this)}/>
                                <CancelButton responsive={false} onClick={() => this.setState({popup: false, new_company_name: false})}/>
                            </div>
                        </div>
                    </Popup>
                }
                <div class="website__topbar">
                    <Breadcrumb crumbs={this.state.crumbs}/>
                    <CreateButton onClick={() => this.setState({popup: true})}>Create New Company</CreateButton>
                </div>
                <div class="website__content">
                    <div class="companies__content">
                        {this.state.companies.length > 0 ? this.state.companies.map((company, index) => {
                                const profile = company?.company_profile?.profile || {}
                               
                                const params = {
                                    key: index,
                                    title : 'MyEB Tenant',
                                    name : company.name,
                                    infoOne : profile.email,
                                    infoTwo : profile.tel_no
                                };

                                return(
                                    <CompanyItem
                                        {...params}
                                        onClick={() => window.location = `/companies/${company.id}`}
                                    />
                                );

                            }) : <NoDataError message='No companies found'/>}
                    </div>
                </div>
                {
                    this.state.companies.length >= 1 &&
                    <Pagination
                        pagination={this.state.pagination}
                        onChange={this.handlePaginationChange.bind(this)}
                    />
                }

            </div>
        )
    }
}

export default Companies;

let el = document.getElementById('companies');

if (el) {
    ReactDOM.render(<Companies/>, el);
}
