import { ViewButton } from '../components/buttons';

export default ({ data, onClick }) => {
    const { roles, email } = data;

    const userProfile = data.user_profile || {}

    const firstName = userProfile.first_name || 'No last name';
    const lastName = userProfile.last_name || 'No first name';
    const jobTitle = userProfile.job_title || 'N/A';
    const telNo = userProfile.tel_no || 'No Telephone'
    let  profileImage =  '/assets/user-icon.svg';
    if(userProfile.hasOwnProperty('profile_img')) {
        if(userProfile.profile_img != null) {
            profileImage =     `/media/private/${userProfile.profile_img}`;
        }
    }
    return (
        <div class="team-member">
            <div class="team-member__header">
                <img src={profileImage} />
                <div>
                    <p class="user-info__role">{ data.name }</p>
                    <p>{ firstName }</p>
                    <p>{ lastName }</p>
                </div>
            </div>
            <div class="team-member__info">
                <p class="user-info__jobrole">{ jobTitle }</p>
                <p>{ email }</p>
                <p>{ telNo }</p>
            </div>
            {/*<button class="button button--inactive"><img src="/assets/email.svg"/>Message<img src="/assets/rightArrow.svg"/></button>*/}
            <div className="team-member__buttons">
                {/*<button class="button">Remove</button>*/}
                <ViewButton responsive={false} onClick={onClick} />
            </div>
        </div>
    )
}
