window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: 'eu',
    forceTLS: true
});




/**
 * Bugsnag for error reporting
 */

import Bugsnag from '@bugsnag/js'

Bugsnag.start({ apiKey: process.env.MIX_BUGSNAG_API_KEY });
Bugsnag.releaseStage = process.env.MIX_APP_ENV;
Bugsnag.notifyReleaseStages = ['production', 'dev'];

window.Bugsnag = Bugsnag;

import Auth from './lib/users/auth';

window.auth = Auth;

