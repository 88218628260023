
import { SendButton, CancelButton } from '../components/buttons';
import Popup from './popup';

export default ({ setPopup }) => {
    return (
        <Popup type="forgot-password">
            <div class="popup__inner">
                <h1>Forgot your password?</h1>
                <div class="input-outer">
                    <label for="email">Please re-enter the email address for the account that you are trying to access:</label>
                    <input placeholder="Enter your email address" />
                </div>
                <div class="popup__buttons">
                    <SendButton responsive={false}>Send Reminder</SendButton>
                    <CancelButton responsive={false} onClick={() => setPopup(false)}>Cancel</CancelButton>
                </div>
            </div>
        </Popup>
    )
}