const searchOuter = document.querySelector(".search-outer");
const searchIcon = document.getElementById("search-icon");

if (searchIcon) {
    searchIcon.onclick = () => {
        const computed = getComputedStyle(searchOuter, null);
    
        console.log(computed.display)
    
        if (computed.display == "none") {
            searchOuter.style.display = "flex";
    
            // searchOuter.classList.remove("animate__fadeOutUp");
            // searchOuter.classList.add("animate__fadeInDown");
        } else {
            // searchOuter.classList.remove("animate__fadeInDown");
            // searchOuter.classList.add("animate__fadeOutUp");
    
            searchOuter.style.display = "none";
        }
    }
}