import ApiBase from "../api-base";


class ContactsApi extends ApiBase {

    getContacts(page = 1, perPage = 20) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/contacts`, {params: {page: page, per_page: perPage}});
            return response.data;
        });
    }

    get(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/contacts/${id}`);
            return response.data.data;
        });
    }

    getContactConversations(id, page = 1, perPage = 20, order_by, order_direction) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/contacts/${id}/conversations`, {params : {page: page, per_page: perPage, order_by, order_direction }});
            return response.data;
        });
    }

    getConversations(params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/contacts/conversations`, { params });
            return response.data;
        });
    }

    getConversation(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/contacts/conversations/${id}`);
            return response.data;
        });
    }
}


export default new ContactsApi();
