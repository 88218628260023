import { useEffect, useState } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';
import ContainerGrid from '../../../../../../containers/container-grid';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { AddButton, CancelButton } from '../../../../../../components/buttons';

export default function QuestionTypeTextMatching({
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({
            target: {
                name: 'config',
                value: config
            }
        });
    }

    const onButtonChange = (list, index, e) => {
        const body = question?.body !== null ? { ...question?.body } : {};
        const { value } = e.target;

        let correct_answers = question?.correct_answers || [];

        if (list === 0) {
            body.list_a[index] = value;
        }

        if (list === 1) {
            body.list_b[index] = value;
        }

        correct_answers[index] = {
            [ body.list_a[index] ]:  body.list_b[index]
        }

        onQuestionChange({
            target: {
                name: 'correct_answers',
                value: correct_answers,
            }
        })

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const addButton = () => {
        const body = question?.body !== null ? { ...question?.body } : {};
        
        if (!body.hasOwnProperty('list_a')) {
            body.list_a = [ null ];
            body.list_b = [ null ];
        } else {
            body.list_a.push(null);
            body.list_b.push(null);
        }
        
        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const removeButton = (index) => {
        const body = question?.body !== null ? { ...question?.body } : {};

        let correct_answers = question?.correct_answers || [];

        body.list_a.splice(index, 1);
        body.list_b.splice(index, 1);

        correct_answers = correct_answers.filter((_, i) => i !== index);

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const renderButtons = () => {
        const arr = question?.body?.list_a || [];

        return arr.map((_, index) => {
            return (
                <LabelContainer key={index} grow={1}>
                    <List gap="20px">
                        <Row>
                            <Row fill={true}>
                                <Input label={`Word ${index + 1} - A`} fill={true} value={question?.body?.list_a?.[index]} onChange={(e) => onButtonChange(0, index, e)}/>
                                <Input label={`Word ${index + 1} - B`} fill={true} value={question?.body?.list_b?.[index]} onChange={(e) => onButtonChange(1, index, e)}/>
                            </Row>
                            <CancelButton mobile={true} onClick={() => removeButton(index)}/>
                        </Row>
                    </List>
                </LabelContainer>
            )
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <CodeMirror
                            height="300px"
                            value={question?.description || ''}
                            onChange={e => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: e,
                                    }
                                })
                            }}
                            extensions={[html()]}
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <AddButton onClick={addButton}>Add Item</AddButton>
                    </Row>

                    <LabelContainer label="Randomize List">
                        <Checkbox 
                            text="List A"
                            checked={question?.config?.randomize_list_a}
                            onChange={() => {
                                onConfigChange({
                                    target: {
                                        name: 'randomize_list_a',
                                        value: !question?.config?.randomize_list_a,
                                    }
                                })
                            }}
                        />

                        <Checkbox 
                            text="List B"
                            checked={question?.config?.randomize_list_b}
                            onChange={() => {
                                onConfigChange({
                                    target: {
                                        name: 'randomize_list_b',
                                        value: !question?.config?.randomize_list_b,
                                    }
                                })
                            }}
                        />
                    </LabelContainer>
                </>
            }/>

            <List gap="20px">
                { renderButtons() }
            </List>

            <Input 
                label="Correct Answer Response" 
                placeholder="Input Value"
                value={question?.config?.correct_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'correct_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
            <Input 
                label="Incorrect Answer Response"
                placeholder="Input Value"
                value={question?.config?.incorrect_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'incorrect_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
        </QuestionType>
    )
}
