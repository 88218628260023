import { useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

export default ({ ref = useRef(null), children, type, onClose = () => console.log('closing'), back = false, head, replaceContent = null, content = null, outsideClickClose = true }) => {
    const isTablet = useMediaQuery({ query: '(min-width: 992px)' })

    return (
        <div className="side-popup__outer">
            <div class={"side-popup " + ( type ? type : "" )} ref={ref}>
                { replaceContent == null ?
                    <>
                        <div className="side-popup__head">
                            <p className="side-popup__close" onClick={() => onClose()}>Close</p>
                            { head && head }
                        </div>
                        { (content !== null || children.length > 0) && <div className="side-popup__content">{ content } { children }</div> }    
                    </>
                    :
                    replaceContent
                }
            </div>
        </div>
    )
}
