import CustomDropdown from '../../../components/dropdowns/dropdown';

export default function GrantApplicationsFilters({ onStatusChange, onAcceptedRejected, onDirectionChange, onOrderBy, onGrant, grants = [], selectedGrant, orderBy, statuses = [], status, direction, acceptedRejected }) {
    return (
        <div className="content-container__filters">
            <CustomDropdown items={grants.map(g => ({ label: g.name, value: g.id }))} reset={true} value={selectedGrant} placeholder="Filter by Grant" icon="filter" onChange={onGrant}/>
            <CustomDropdown items={[
                {
                    label: 'Sort by Status',
                    value: 'status'
                },
                {
                    label: 'Sort by Updated at',
                    value: 'updated_at'
                },
                {
                    label: 'Sort by Created at',
                    value: 'created_at'
                }
            ]} placeholder="Order by" value={orderBy} icon="sort" onChange={onOrderBy}/>
            <CustomDropdown items={[
                {
                    label: 'Sort by Ascending',
                    value: 'asc'
                },
                {
                    label: 'Sort by Descending',
                    value: 'desc'
                }
            ]} placeholder="Order direction" icon="sort" value={direction} onChange={onDirectionChange}/>
            <CustomDropdown items={statuses.map(status => ({ label: status, value: status }))} reset={true} value={status} placeholder="Filter by Status" icon="filter" onChange={onStatusChange}/>
            <CustomDropdown items={[
                {
                    label: 'Filter by Accepted',
                    value: 'accepted',
                },
                {
                    label: 'Filter by Rejected',
                    value: 'rejected'
                }
            ]} reset={true} placeholder="Filter by Accepted / Rejected" icon="sort" value={acceptedRejected} onChange={onAcceptedRejected}/>
        </div>
    )
}