import Popup from './popup';

import React from "react";
import axios from "axios";
import AdminUsersApi from '../lib/users/admin-users-api';
import Input from '../components/input-with-label';
import AlertManager from '../managers/alert/alert-manager';

import { SendButton, CancelButton } from '../components/buttons';

export default class NewUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            selected_roles: {},
            first_name: '',
            last_name: '',
            email: '',
            super_admin: false,
            errors: {}
        };
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles() {
        axios.get('/api/admin/roles').then(r => this.setState({roles: r.data.data}));
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit() {

        let data =  {
            first_name : this.state.first_name,
            last_name:  this.state.last_name,
            email : this.state.email,
            super_admin : this.state.super_admin,
            role_ids : _.map(this.state.selected_roles, sr => sr)
        }

        AdminUsersApi.invite(data, errors => {
            this.setState({errors});
        }).then(() => {
            AlertManager.success('User invited');
            this.props.setPopup(false);
        });
    }

    render() {
        const {setPopup} = this.props;
        const {errors} = this.state;
        return (
            <Popup type="new-user" onClose={setPopup}>
                <div class="popup__inner">
                    <h1>Invite a New user to your MyEB Team</h1>
                    <div class="input-outer">
                        <Input errors={errors.first_name}
                               name={'first_name'}
                               value={this.state.first_name}
                               onChange={this.handleChange.bind(this)}
                               placeholder="Enter the first name"
                        />
                    </div>
                    <div class="input-outer">
                        <Input errors={errors.last_name} name={'last_name'} value={this.state.last_name} onChange={this.handleChange.bind(this)}
                               placeholder="Enter the last name"/>
                    </div>
                    <div class="input-outer">
                        <Input errors={errors.email} type={'email'} name={'email'} value={this.state.email}
                               onChange={this.handleChange.bind(this)} placeholder="Enter the email address"/>
                    </div>

                    <div className="user-roles__outer">
                        <p>Is user a Super Admin?s</p>
                        <div className="user-roles">
                            <div className="checkbox">
                                <input name={'super_admin'} onChange={() => {
                                    this.setState({super_admin: !this.state.super_admin})
                                }} value={this.state.super_admin} type={'checkbox'}/>
                                <label>Super Admin</label>
                            </div>
                        </div>
                    </div>

                    <div class="user-roles__outer">
                        <p>Set the User Role</p>
                        <div class="user-roles">
                            {
                                this.state.roles.map((r, i) => {
                                    return (
                                        <div key={i} className="checkbox">
                                            <input type="checkbox" name="role[]" onChange={e => {
                                                let roles = this.state.selected_roles;
                                                if (roles[r.id]) {
                                                    delete roles[r.id];
                                                } else {
                                                    roles[r.id] = r.id;
                                                }

                                                this.setState({selected_roles: roles});

                                            }} checked={this.state.selected_roles[r.id]}/>
                                            <label htmlFor="huey">{r.name}</label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div class="popup__buttons">
                        <SendButton responsive={false} onClick={this.handleSubmit.bind(this)}>Send Invitation</SendButton>
                        <CancelButton responsive={false} onClick={() => setPopup(false)}/>
                    </div>
                </div>
            </Popup>);
    }


}
