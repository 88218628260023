import { useEffect, useState } from 'react';
import AlertsManager from "../../../../managers/alert/alert-manager";
import MediaHelper from '../../../../lib/helpers/media';
import ImagesApi from '../../../../lib/media/images-api';

import { ProceedButton, CancelButton, DeleteButton, SaveButton } from '../../../buttons';

import axios from "axios";

export default ({ id, viewDetailsClick, onRename, onUseResource, onDelete = ( ) => console.log('Image Deleted') }) => {
    const [ image, setImage ] = useState({});
    const [ shouldRename, setShouldRename ] = useState(false);
    const [ renamed, setRenamed ] = useState(image?.name);

    useEffect(() => {
        getImage();
    }, [])

    const getImage = () => {
        axios.get('/api/admin/images/' + id).then(({ data: { data }}) => {
            setImage(data)
            setRenamed(data?.name)
        });
    }

    const deleteImage = () => {

            ImagesApi.deleteImage(image.id).then(() => {
                onDelete();
            }).catch(() => {
                AlertsManager.error('Image could not be deleted');
            });
    }

    const rename = () => {
        if (shouldRename) {
            if (renamed.length > 0) {
                axios.post('/api/admin/images/' + image.id, { name: renamed }).then(({ data: { data }}) => getImage());
                onRename();
            }
        }

        setShouldRename(!shouldRename)
    }


    return (
        <div class="images-browse__viewDetails">
            <div class="viewDetails__bar">
                <CancelButton className="viewDetails__back" onClick={viewDetailsClick}>Back</CancelButton>
                <ProceedButton onClick={onUseResource}>Use Image</ProceedButton>
            </div>
            <div class="viewDetails__top">
                <div style={{ backgroundImage: `url(${MediaHelper.formatPublicPath(`/images/${image.file_name}`)})` }}></div>
            </div>
            <div class="viewDetails__bottom">
                { shouldRename ? <div className="input-outer">
                    <input class="viewDetails__rename" placeholder="New name" value={renamed} onChange={(e) => setRenamed(e.target.value)}/>
                </div> : <div class="viewDetails__info">
                    <div class="viewDetails__info-section">
                        <div class="viewDetails__info-item">
                            <p>FILE NAME</p>
                            <p>{ image?.name }</p>
                        </div>
                        <div class="viewDetails__info-item">
                            <p>FILE SIZE</p>
                            <p>{ image?.file?.size_kb }kb</p>
                        </div>
                    </div>
                    <div class="viewDetails__info-section">
                        <div class="viewDetails__info-item">
                            <p>UPLOAD DATE</p>
                            <p>{ image?.updated_at }</p>
                        </div>
                        <div class="viewDetails__info-item">
                            <p>UPLOADED BY</p>
                            <p>Test</p>
                        </div>
                    </div>
                    <div class="viewDetails__info-section">
                        <div class="viewDetails__info-item">
                            <p>DIMENSIONS</p>
                            <p>{ image?.width } / { image?.height }</p>
                        </div>
                    </div>
                </div> }
                <div class="viewDetails__buttons">
                    <SaveButton onClick={rename}>{ shouldRename ? 'Save' : 'Rename' }</SaveButton>
                    <DeleteButton onClick={deleteImage}>Delete Image</DeleteButton>
                </div>
            </div>
        </div>
    )
}
