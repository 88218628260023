import React, {Component} from 'react';
import ReactDom from 'react-dom';
import ContentApi from '../../lib/content/content-api';

import Breadcrumb from "../../components/breadcrumb";
import CompanyItem from "../../components/companies/company-item";
import NoDataError from "../../components/no-data-error";
import Pagination from "../../components/pagination";
import NewContentTypePopup from "../../components/content-types/new-content-type-popup";
import TabBar from "../../components/tab-bar";

import { CreateButton, CancelButton } from '../../components/buttons';

class ContentTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contentTypes : [],
            pagination : {},
            page : 1,
            popup : false,
        };
    }


    componentDidMount() {
        this.getContentTypes();
    }

    getContentTypes(page = this.state.page, perPage = 20) {
        ContentApi.getContentTypes(page, perPage).then(response => {
            this.setState({
                pagination: response.meta,
                contentTypes: response.data
            });
        })
    }

    handlePaginationChange(e) {
        this.setState({page: e}, () => this.getContentTypes());

    }

    render() {
        return (
            <div className={'companies-outer'}>
                { this.state.popup && <NewContentTypePopup onClose={() => this.setState({ popup : false})} />}
                <div className="website__topbar">
                    <Breadcrumb crumbs={ [{
                        name: 'Content Types',
                        link: '/content-types'
                    },
                        {
                            name: 'View All',
                            link: '#'
                        }]}/>

                    <CreateButton onClick={() => this.setState({popup: true})}>Create New</CreateButton>
                </div>
                <div className="website__content">

                    <div className="companies__content">
                        {this.state.contentTypes.length > 0 ? this.state.contentTypes.map((c, index) => {
                                const params  = {
                                    key: index,
                                    title: `${c.name}`,
                                    infoOne : c.slug,
                                    infoTwo : ''
                                };

                                return (<CompanyItem
                                    {...params }
                                    onClick={() => window.location = `/content-types/${c.id}`} />);
                            }) :
                            <NoDataError message='No Content Types found'/>}
                    </div>
                </div>
                {
                    this.state.contentTypes.length >= 1 &&
                    <Pagination
                        pagination={this.state.pagination}
                        onChange={this.handlePaginationChange.bind(this)}
                    />
                }
            </div>
        );
    }


}

export default ContentTypes;

const el = document.getElementById('content-types');

if(el) {
    ReactDom.render(<ContentTypes />, el);
}
