import AsyncSelect from 'react-select/async';

import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

export default function InputsSelect(props) {
    const { label, placeholder, fill } = props;

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(props.filter(inputValue));
        }, 1000);
    };

    return (
        <LabelContainer label={label} placeholder={placeholder} fill={fill}>
            <ErrorContainer errors={props?.errors}>
                <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions { ...props }/>
            </ErrorContainer>
        </LabelContainer>
    )
}
