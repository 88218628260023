import React, {Component} from 'react';
import ReactDOM from "react-dom";
import ContactsApi from '../lib/contacts/contacts-api';
import Breadcrumb from "../components/breadcrumb";
import {stringToFriendlyDatetime} from "../lib/helpers/datetime-helpers";
import Input from "../components/input-with-label";
import ProfileImageUpload from "../components/profile-image-upload";
import TabBar from "../components/tab-bar";
import Details from "../components/contacts/details";
import Messages from "../components/contacts/messages";

import { Layout, MinMaxLayout } from '../containers/layout';
import List from '../components/list/list';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: {id : null},
            tab: 0
        };

        this.tabs = [
            'Details',
            'Messages'
        ];
    }

    componentDidMount() {
        this.getContact();
    }

    getContact() {
        ContactsApi.get(this.props.contactId).then(contact => {
            this.setState({contact: contact})
        });
    }

    render() {
        const contact = this.state.contact || {};

        const contactName = `${contact.first_name} ${contact.last_name}`;

        return (

                    <List className={'account'} gap="40px">
                        <div className="website__topbar">
                            <Breadcrumb crumbs={[{
                                name: 'Contacts',
                                link: '/contacts'
                            },
                                {
                                    name: contactName,
                                    link: '#'
                                }]}/>
                        </div>

                        <List gap="20px">
                            <TabBar tabs={this.tabs} tab={this.state.tab} setTab={t => this.setState({tab: t})}/>
                            <div className="account__user">
                                <img className="account__user-icon" src={'/assets/user-icon.svg'}/>
                                <div className="account__column">
                                    <h1>{contactName}</h1>
                                    <p>Created: {stringToFriendlyDatetime(contact.created_at)}</p>
                                    <h3><a href={`mailto:${contact.email}`}>{contact.email}</a></h3>
                                </div>
                            </div>
                            {
                                contact.id !== null &&
                                    <>
                                        {
                                            this.state.tab === 0 &&
                                            <Details
                                                contact={contact}
                                            />
                                        }
                                        {
                                            this.state.tab === 1 &&
                                            <Messages contact={contact}/>
                                        }
                                    </>
                            }
                        </List>
                    </List>


        );
    }
}

export default Contact;


const el = document.getElementById('contact');

if (el) {
    ReactDOM.render(<Contact contactId={el.getAttribute('data-contact-id')}/>, el);
}
