import ApiBase from "../api-base";
import axios from "axios";

class AdminUsersApi extends ApiBase {

    async resetPassword(id) {
        return this.handleResponse(async () => {
            let response = await  axios.post(`/api/admin/users/${id}/reset-password`);
            return response.data.data;
        });
    }

    async invite(data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await  axios.post(`/api/admin/users/invite`, data);
            return response.data.data;
        }, validationCallback);
    }

    async getRoles() {
        return this.handleResponse(async () => {
            let response = await axios.get('/api/admin/roles', {params : {per_page: 100}});
            return response.data.data;
        })
    }
    async getActions(page = 1, perPage= 10) {
        return this.handleResponse(async () => {
            let response = await axios.get('/api/admin/users/actions', {params : {page: page,per_page: perPage}});
            return response.data;
        })
    }


    async syncUserRoles(id, data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await  axios.post(`/api/admin/users/${id}/sync-roles`, data);
            return response.data.data;
        }, validationCallback);
    }


}


export default new AdminUsersApi();
