export default ({ file }) => {
    const name = file?.name.split('.');
    const fileName = name[0].slice(0, 12) + '.' + name[name.length - 1] || 'No file name';

    return (
        <div className="document-file">
            <a target={'_blank'} rel={'noreferrer'}
                href={file.path}
                className="document-file__icon">
                <img src="/assets/document-icon.svg" />
                <a className="document-file__view-overlay">
                    <svg id="Group_449" data-name="Group 449"
                            xmlns="http://www.w3.org/2000/svg" width="37.297"
                            height="37.297" viewBox="0 0 37.297 37.297">
                        <path id="Path_141" data-name="Path 141" d="M0,0H37.3V37.3H0Z"
                                fill="none"/>
                        <path id="Path_142" data-name="Path 142"
                                d="M1.181,16.986a17.1,17.1,0,0,1,33.627,0,17.1,17.1,0,0,1-33.627,0Zm16.813,7.77a7.77,7.77,0,1,0-7.77-7.77A7.77,7.77,0,0,0,17.994,24.757Zm0-3.108a4.662,4.662,0,1,1,4.662-4.662A4.662,4.662,0,0,1,17.994,21.649Z"
                                transform="translate(0.654 1.662)" fill="#fff"/>
                    </svg>

                </a>
            </a>
            <p className="document-file__name">{ fileName }</p>
        </div>
    )
}
