const DEFAULT_TIMEOUT = 3000;

import Alerts from './alert-types';

class AlertManager {
    alerts = [];
    container = document.getElementById('alert-container');
    timer;

    addAlertToDom({ type, title = null, message, classes = '' }) {
        let typeName = title !== null ? title : type;

        this.container.innerHTML = `
            <div class='alert-item alert-item--${type} animate__animated animate__fadeInUp ${classes}'>
                <div class='alert-item__head'>${typeName}</div>
                <div class='alert-item__inner'>
                    <span id="alert-item__exit">X</span>
                    <b>${message}</b>
                </div>
            </div>
        `;

        const exit = document.getElementById('alert-item__exit');

        exit.onclick = () => {
            if (this.timer != null) clearTimeout(this.timer);

            this.nextAlert();
        }
    };

    success(message, timeout = DEFAULT_TIMEOUT) {
        this.addAlert({
            type: Alerts.SUCCESS ,
            message,
            timeout
        });
    }
    error(message, timeout = DEFAULT_TIMEOUT) {
        this.addAlert({
            type : Alerts.ERROR,
            message,
            timeout
        })
    }

    message(type, title, message, timeout = DEFAULT_TIMEOUT) {
        this.addAlert({
            title,
            type : type,
            message,
            timeout
        })
    }

    bigError(title, message) {
        this.addAlert({
            title,
            type : Alerts.ERROR,
            message,
            timeout : 100000,
            classes : 'alert-item--big'
        })
    }

    addAlert({ type, title = null, message, timeout = DEFAULT_TIMEOUT, classes = '' }) {
        // Alert gets added
        // - If no alert before then show it and fade out
        // - If there is an alert already keep showing it until it fades out then show next one

        const alert = { type, message, timeout, id: this.alerts.length, title, classes };

        this.alerts.push(alert);

        this.timer = setTimeout(() => this.nextAlert(), timeout);

        this.addAlertToDom(alert);
    }

    nextAlert() {
        const alert = document.querySelector('.alert-item');
        alert.classList.add('fadeOut');

        this.alerts = this.alerts.filter(({ id }) => id != this.alerts[0].id);

        if (this.alerts.length == 0) {
            if (this.timer != null) clearTimeout(this.timer);

            setTimeout(() => this.container.innerHTML = '', 400);
        } else {
            const alert = this.alerts[0];

            this.timer = setTimeout(() => this.nextAlert(), alert.timeout);
            this.addAlertToDom(alert);
        }
    }
}

export default new AlertManager;
