import React, {Component} from 'react';
import Dropdown from 'react-dropdown';
import Input from '../input-with-label';
import ContentApi from '../../lib/content/content-api';
import CustomCreatableSelect from "../inputs/custom-creatable-select";
import CUSTOM_SELECT_STYLING from "../inputs/custom-creatable-select-styling";

class FetchedContent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            tags: []
        };
    }


    componentDidMount() {

        if (this.isTaggable()) {
            this.getTags();
        }

    }

    getTags() {
        ContentApi.getTags(1, 1000).then(response => {
            this.setState({tags: response.data});
        })
    }


    getOptions() {
        let options = [{value: '', lable: 'Please Select'}];
        this.props.contentTypes.forEach(type => {
            options.push({
                label: type.name,
                value: type.id
            });
        });

        return options;
    }

    getContentTypeValue() {

        const defaultType = this.getDefault();


        return defaultType !== null ? defaultType.name : '';
    }

    getDefault() {
        let defaultType = null;

        if (this.props.config.hasOwnProperty('default')) {
            let typeIndex = _.findIndex(this.props.contentTypes, item => {
                return item.id === this.props.config.default;
            });

            if (typeIndex !== -1) {
                defaultType = this.props.contentTypes[typeIndex];
            }
        }

        return defaultType;
    }

    updateMeta(key, value) {
        const {config, sectionConfig, contentIndex, handleChange, fixed} = this.props;

        let meta = {...this.props.meta};

        meta[key] = value;


        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: this.getDefault().id,
                meta: Object.assign({}, this.props.meta, meta)
            },
            fixed: fixed,
        });

    }

    getMeta(key) {

        return this.props.meta.hasOwnProperty(key) ? this.props.meta[key] : '';
    }

    isTaggable() {

        const {config} = this.props;

        if (!config) {
            return false;
        }

        if (config.hasOwnProperty('taggable')) {
            return config.taggable === true;
        }

        return false;
    }

    getTagValues() {
        const { meta} = this.props;

        if(meta.hasOwnProperty('tags')) {
            return meta.tags.map(item => {
                return {
                    value : item.id,
                    label: item.name
                }
            });
        }

        return [];
    }

    async handleTagsChange(tags) {

        let newTags = [];

        let existingTags = [];

        tags.forEach((item) => {
            if(item.__isNew__ === true) {
                newTags.push(item.value)
            }else {
                existingTags.push({
                    name:  item.label,
                    id : item.value
                });
            }
        });

        if(newTags.length >=1) {
            let response = await ContentApi.storeTags(newTags);

            response.data.forEach(item => {
                existingTags.push({
                    id: item.id,
                    name : item.name
                })
            })
        }
        this.getTags();

        this.updateMeta('tags', existingTags);
    }

    getTagOptions() {
        return this.state.tags.map(item => {
            return {
                value: item.id,
                label : item.name
            };
        });
    }


    render() {

        return (
            <div className={'content-editor__items'}>

                <div className="content-editor__item">

                    <div className="content-editor__item">
                        <Input
                            placeholder={'Content Type'}
                            value={this.getContentTypeValue()}
                            readonly={true}
                            style={{textTransform: 'capitalize'}}
                        />
                    </div>

                </div>
                <div className="content-editor__item">
                    <div className="input-outer">
                        <label htmlFor="">Order By</label>
                        <Dropdown
                            className="input input--idle"
                            options={[
                                {
                                    label: "Published",
                                    value: "published"
                                },
                                {
                                    label: "Title",
                                    value: "title"
                                }
                            ]}
                            onChange={e => this.updateMeta('order', e.value)}
                            value={this.getMeta('order')}
                            placeholder="None"
                        />
                    </div>
                </div>
                <div className="content-editor__item">
                    <div className="input-outer">
                        <label htmlFor="">Direction</label>
                        <Dropdown
                            className="input input--idle"
                            options={[
                                {
                                    label: "Ascending",
                                    value: "asc"
                                },
                                {
                                    label: "Descending",
                                    value: "desc"
                                }
                            ]}
                            onChange={e => this.updateMeta('direction', e.value)}
                            value={this.getMeta('direction')}
                            placeholder="None"
                        />
                    </div>
                </div>


                <div className="content-editor__item">
                    <Input
                        type={'number'}
                        placeholder={'Limit'}
                        onChange={e => this.updateMeta('limit', e.target.value)}
                        value={this.getMeta('limit')}
                    />
                </div>

                {
                    this.isTaggable() &&
                    <div className="content-editor__item">
                        <CustomCreatableSelect
                            classNamePrefix={'tags-input'}
                            styles={CUSTOM_SELECT_STYLING}
                            name={'Tags'}
                            placeholder={'Tags'}
                            isMulti
                            onChange={this.handleTagsChange.bind(this)}
                            options={this.getTagOptions()}
                            value={this.getTagValues()}

                        />
                    </div>

                }

            </div>
        );
    }
}

FetchedContent.defaultProps = {
    config: {}
};


export default FetchedContent;
