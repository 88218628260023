import { useEffect } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';
import ContainerGrid from '../../../../../../containers/container-grid';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { AddButton, CancelButton } from '../../../../../../components/buttons';

export default function QuestionTypeAttachDocs({ 
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({ 
            target: { 
                name: 'config', 
                value: config
            }
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <CodeMirror
                            height="300px"
                            value={question?.description || ''}
                            onChange={e => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: e,
                                    }
                                })
                            }}
                            extensions={[html()]}
                        />
                    </LabelContainer>
                </>
            } right={
                <List gap='20px'>
                    <p style={{ maxWidth: '300px' }}>Please note: file types are set by your system admin and are not able to be amended through this dialog.</p>
                    
                    <Input label="Maximum upload amount" name='maximum_upload_amount' value={question?.config?.maximum_upload_amount} onChange={onConfigChange}/>

                    <Dropdown label="Next Question" value={getNextQuestion()} items={getMappedQuestions()}
                        onChange={(item) => {
                            onConfigChange({
                                target: {
                                    name: 'next_question',
                                    value: item.value,
                                }
                            })
                        }}/>
                </List>
            }/>
        </QuestionType>
    )
}