import { useState, useEffect } from 'react';

import Input from '../input-with-label';
import profileConfig from '../../lib/companies/profile-config';
import Textarea from "../inputs/textarea";
import DynamicInputList from "../inputs/dynamic-input-list";
import Dropdown from "react-dropdown";
import Select from "../inputs/select";
import InputsSelect from '../inputs/inputs-select';
import LabelContainer from '../label-container';
import { SmallPill } from '../pills';
import Row from '../../utils/row';
import CompaniesApi from '../../lib/companies/companies-api';

export default ({ onProgrammeAdd, onProgrammeRemove, company, industries, onChange}) => {
    let profile = {};

    if(company.company_profile) {
        profile = company.company_profile.profile ? company.company_profile.profile : {};
    }

    const handleCompanyChange = (e) => {
        onChange(e.target.name, e.target.value);
    }

    const handleProfileChange = (e) => {
        onChange('profile', Object.assign({}, profile, {
            [e.target.name] : e.target.value
        }))
    }


    const config = profileConfig[1];


    const getProfileValue = (key) => {
            return profile[key] || '';
    }

    const getOptionValue = () => {
        if (company.company_industry_id === null) {
            return null;
        }

        const industry =  _.find(industries, (i) => {
            return i.id === company.company_industry_id;
        });

        return industry ? { value : industry.id, label : industry.name} : null;

    }
    const getOptions = (value, callback) => {
        return industries.map(i => {
            return {value: i.id, label : i.name, className : 'ucfirst'};
        });
    }

    const getProgrammeOptions = () => {
        return CompaniesApi.fetchProgrammes().then(data => {
            const options = data.map((key) => ({
                label: key.name,
                value: key.id
            }));

            return options;
        })
    }

    return (
        <div class="companies-settings">


            <div class="companies-settings__section">
                <div class="companies-settings__row">
                    <Input name="name" value={company.name} placeholder="Company Name" onChange={handleCompanyChange}/>
                    <Select
                        name={'company_industry_id'}
                        placeholder={'Industry'}
                        options={getOptions()}
                        value={getOptionValue()}
                        onChange={(o) => handleCompanyChange({ target : { name : 'company_industry_id', value: o.value}})}
                    />

                </div>
                <div className="companies-settings__row">
                    <Textarea name="description" value={company.description} placeholder="Description"
                           onChange={handleCompanyChange}/>
                </div>
            </div>
            <div className="companies-settings__section">
                {
                    <DynamicInputList
                        config={config}
                        onChange={handleProfileChange}
                        getValue={getProfileValue}
                    />
                }
            </div>
            <div class="companies-settings__section">
                <Row gap="20px">
                    <InputsSelect
                        defaultOptions
                        loadOptions={getProgrammeOptions}
                        onChange={(val) => onProgrammeAdd(val.value)}
                        placeholder="Select Programme"
                        label="Which programme does this company belong to?"
                    />

                    <LabelContainer label="Selected Programmes">
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                        }}>
                            { company?.company_programmes?.map((key, index) => {
                                return <SmallPill key={index} removable={true} onClick={() => onProgrammeRemove(key.id)}>{ key.name }</SmallPill>
                            })}
                        </div>
                    </LabelContainer>
                </Row>
            </div>
        </div>
    )
}
