import React from 'react';

export function home() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z"
            fill="#c6c7d2"/>
        </svg>

    );
}

export function content() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M13 21v2h-2v-2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6a3.99 3.99 0 0 1 3 1.354A3.99 3.99 0 0 1 15 3h6a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8zm7-2V5h-5a2 2 0 0 0-2 2v12h7zm-9 0V7a2 2 0 0 0-2-2H4v14h7z"
             fill="#c6c7d2"/>
        </svg>

    );
}

export function events() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
             fill="#c6c7d2"/>
        </svg>

    );
}

export function grants() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3-7H8v-2h1v-1a3.5 3.5 0 0 1 6.746-1.311l-1.986.496A1.499 1.499 0 0 0 11 10v1h3v2h-3v2h5v2H8v-2h1v-2z"
            fill="#c6c7d2"/>
        </svg>

    );
}


export function eLearning() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
            <path id="Path_271" data-name="Path 271" d="M4,5V16H20V5ZM2,4.007A1.007,1.007,0,0,1,2.992,3H21.008A1,1,0,0,1,22,4.007V18H2ZM1,19H23v2H1Z" transform="translate(-1 -3)" fill="#c6c7d2"/>
        </svg>
    )
}

export function learningPaths() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M13 8v8a3 3 0 0 1-3 3H7.83a3.001 3.001 0 1 1 0-2H10a1 1 0 0 0 1-1V8a3 3 0 0 1 3-3h3V2l5 4-5 4V7h-3a1 1 0 0 0-1 1zM5 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill="#000"/>
        </svg>
    )
}

export function learners() {
    return (
        <svg className={'default-icon'} id="Group_1322" data-name="Group 1322" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_425" data-name="Path 425" d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22ZM7.013,18.256a8,8,0,0,0,10.154-.148,7,7,0,0,0-10.154.148Zm-1.4-1.436a9,9,0,0,1,12.906-.186,8,8,0,1,0-12.906.187ZM12,13a4,4,0,1,1,4-4A4,4,0,0,1,12,13Zm0-2a2,2,0,1,0-2-2A2,2,0,0,0,12,11Z" fill="#61707f"/>
        </svg>
    )
}

export function courses() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
            <path id="Path_271" data-name="Path 271" d="M4,5V16H20V5ZM2,4.007A1.007,1.007,0,0,1,2.992,3H21.008A1,1,0,0,1,22,4.007V18H2ZM1,19H23v2H1Z" transform="translate(-1 -3)" fill="#c6c7d2"/>
        </svg>
    )
}

export function courseWorks() {
    return (
        <svg className={'default-icon'} xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
            <path id="Path_271" data-name="Path 271" d="M4,5V16H20V5ZM2,4.007A1.007,1.007,0,0,1,2.992,3H21.008A1,1,0,0,1,22,4.007V18H2ZM1,19H23v2H1Z" transform="translate(-1 -3)" fill="#c6c7d2"/>
        </svg>)
}

export function contacts() {
    return (
        <svg class="default-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_255" data-name="Path 255" d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22ZM7.013,18.256a8,8,0,0,0,10.154-.148,7,7,0,0,0-10.154.148Zm-1.4-1.436a9,9,0,0,1,12.906-.186,8,8,0,1,0-12.906.187ZM12,13a4,4,0,1,1,4-4A4,4,0,0,1,12,13Zm0-2a2,2,0,1,0-2-2A2,2,0,0,0,12,11Z" transform="translate(-2 -2)" fill="#c6c7d2"></path></svg>
    )
}


export function search() {
    return (
        <svg class="default-icon" id="Group_477" data-name="Group 477" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_163" data-name="Path 163" d="M18.031,16.617,22.314,20.9,20.9,22.314l-4.282-4.283a9,9,0,1,1,1.414-1.414Zm-2.006-.742a7,7,0,1,0-.15.15l.15-.15Z" fill="#c6c7d2"></path></svg>
    )
}

export function messages() {
    return (
        <svg class="default-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18"><path id="Path_243" data-name="Path 243" d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3ZM20,7.238l-7.928,7.1L4,7.216V19H20ZM4.511,5l7.55,6.662L19.5,5Z" transform="translate(-2 -3)" fill="#c6c7d2"></path></svg>
    )
}

export function getIcon(name) {
    switch (name) {
        case 'home' : {
            return home();
        }
        case 'content' : {
            return content();
        }
        case 'events' : {
            return events();
        }
        case 'grants' : {
            return grants();
        }
        case 'e-learning': {
            return eLearning();
        }
        case 'learning-paths': {
            return learningPaths();
        }
        case 'learners': {
            return learners();
        }
        case 'courses':
            return courses();
        case 'course-works':
            return courseWorks();
        case 'search' : {
            return search();
        }
        case 'contacts' : {
            return contacts();
        }
        case 'messages' : {
            return messages();
        }
        default : {
            return (<home />)
        }
    }

}
