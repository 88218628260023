/**
 * Handle the updating of state of fixed and free content for the Content Editor
 */

 class UpdateContentVersionState {
    constructor(sections) {
        this.sections = [...sections];
        this.onStateChange = () => console.log('State Handled');
    }


    handleUpdate(contentItemConfig, sectionConfig, contentIndex, data, fixed) {
        // console.log(contentItemConfig.name, data, 'data in');
        if (fixed === true) {
            this.handleFixedContentChange(contentItemConfig, sectionConfig, data);
        } else {
            this.handleFreeContent(contentItemConfig, sectionConfig, data, contentIndex);
        }
    }

    handleDisplayed(sectionName, state) {
        let index = this.getSectionIndexByName(sectionName);

        if(index !== -1) {
            this.sections[index].displayed = state;
            return this.fireStateChange();
        }

        this.makeNewSection(sectionName, state);

        this.fireStateChange();
    }

    makeNewSection(sectionName, displayed = true) {
        let section =  {
            name: sectionName,
            order: 0,
            displayed: displayed,
            contents: []
        };

        this.sections.push(section);

        return section;
    }

    fireStateChange() {
        this.onStateChange(this.sections);
    }

    handleFixedContentChange(contentItemConfig, sectionConfig, data) {

        let section = this.getSectionByName(sectionConfig.name);

        // Section doesn't exist. Lets create it.
        if (section === null) {
            section = this.makeNewSection(sectionConfig.name);
        }

        let contentItemIndex = _.findIndex(section.contents, c => {
            return c.fixed === true && c.title === contentItemConfig.name;
        });



        // Content has not been created yet. Lets create it
        if (contentItemIndex === -1) {
            section.contents.push({
                title: contentItemConfig.name,
                type: contentItemConfig.type,
                fixed: true,
                order: 0,
                body: data.body,
                meta: data.meta
            });
        } else {
            const meta = data.hasOwnProperty("meta");
            const body = data.hasOwnProperty("body");

            if (meta !== null) section.contents[contentItemIndex].meta = data.meta;
            if (body !== null) section.contents[contentItemIndex].body = data.body;

        }

        if(this.checkSectionForDuplications(section) === false) {
            return;
        }

        this.fireStateChange();
    }

    checkSectionForDuplications({contents}) {
        let checked = {};
        for (let i = 0; i < contents.length; i ++) {
            if(checked.hasOwnProperty(contents[i].title)) {
                console.error('Duplication Found', contents, i);
                return false;
            }
            checked[contents[i].title] = true;
        }

        // console.log('No dupes', checked);
        return;
    }

    handleFreeContent(contentItemConfig, sectionConfig, data, contentIndex) {
        let section = this.getSectionByName(sectionConfig.name);

        if (section === null) {
            section = this.makeNewSection(sectionConfig.name);
        }

        if (contentIndex == null) {
            section.contents.push({
                type: contentItemConfig.type,
                fixed: false,
                order: contentItemConfig.order,
                body: data.body,
                meta : data.meta ? data.meta: {},
                title : ''
            });
        } else {
            section.contents[contentIndex].body = data.body;
            section.contents[contentIndex].meta = data.meta;
        }

        this.fireStateChange();
    }

    deleteFreeItem(sectionConfig, freeContentIndex) {
        let section = this.getSectionByName(sectionConfig.name);
        let freeContent = [...section.contents].map((c, index) => {
            c.contentIndex = index;
            return c;
        }).filter(c => c.fixed === false).sort((a, b) => a.order - b.order);

        section.contents.splice(freeContent[freeContentIndex].contentIndex, 1);
        this.fireStateChange();
    }

    handleFreeContentOrder(sectionConfig, contents) {
        let section = this.getSectionByName(sectionConfig.name);

        if (section != null) {
            contents.forEach(content => {
                if(section.contents[content.oldIndex]) {

                    section.contents[content.oldIndex].order = content.order;
                }
            });
        }

        this.fireStateChange();
    }

    handleFreeContentTitle(contentItemConfig, sectionConfig, contentIndex, title) {
        let section = this.getSectionByName(sectionConfig.name);



        if (section != null) {
            if (contentIndex != -1) {
                // console.log(section.contents[contentIndex])
                section.contents[contentIndex].title = title;
            }
        }

        this.fireStateChange();
    }

    getSectionIndexByName(name) {
       return _.findIndex(this.sections, s => s.name === name);
    }

    getSectionByName(name) {
        let index = this.getSectionIndexByName(name);
        return index !== -1 ? {...this.sections[index]} : null;
    }

}


export default UpdateContentVersionState;
