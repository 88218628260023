import Dropdown from '../../grants/components/dropdown';

import Input from '../../../components/inputs/inputs-text';

import Row from '../../../utils/row';

import { ModifyButton, DeleteButton, DuplicateButton } from '../../../components/buttons';
import LabelContainer from '../../../components/label-container';

import SearchBarItem from '../../../components/search/search-bar-item';

const Option = ({ previous = '', next = '' }) => {
    return (
        <div className="e-learning-page__option">
            <svg xmlns="http://www.w3.org/2000/svg" width="18.003" height="18.002" viewBox="0 0 18.003 18.002">
                <path id="Path_385" data-name="Path 385" d="M7.105,15.21A3,3,0,1,1,5,15.17V8.83a3,3,0,1,1,2,0V12a4.976,4.976,0,0,1,3-1h4a3,3,0,0,0,2.9-2.21,3,3,0,1,1,2.032.064A5,5,0,0,1,14,13H10a3,3,0,0,0-2.9,2.21ZM6,17a1,1,0,1,0,1,1A1,1,0,0,0,6,17ZM6,5A1,1,0,1,0,7,6,1,1,0,0,0,6,5ZM18,5a1,1,0,1,0,1,1A1,1,0,0,0,18,5Z" transform="translate(-2.998 -2.999)" fill="#929ba8"/>
            </svg>
            <p>{ previous }</p>
            <b>></b>
            <p>{ next }</p>
        </div>
    )
}

export default function ELearningDropdownModule({ index, modulesInOrder = [], modules = [], module }) {
    const pass_module = modules.find(m => m.id === module?.config?.pass_module); 
    const fail_module = modules.find(m => m.id === module?.config?.fail_module);
    const next_module = modules.find(m => m.id === module?.config?.next_module);

    return (
        <Dropdown title={module?.name} canMove={false} open={true} className="e-learning-page__dropdown-module">
            { module.hasOwnProperty('id') && <div className="e-learning-page__options">
                { next_module && <Option previous='Complete' next={next_module?.name}/> }
                { pass_module && <Option previous='Pass' next={pass_module?.name}/> }
                { fail_module && <Option previous='Fail' next={fail_module?.name}/> }

                { !next_module && !pass_module && !fail_module && <Option previous='Complete' next='Summary'/> }
            </div> }
        </Dropdown>
    )
}
