import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);


const TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm';

export function datetimeLocalToString(datetimeLocal) {
    if(datetimeLocal.length === 0) {
        return null;
    }
    return (new dayjs(datetimeLocal)).format(TIMESTAMP_FORMAT);
}

export function stringCompensateForUTCOffset(string)
{

    const date = new Date(string);

    const offset = Math.abs(date.getTimezoneOffset());
    const sub =  string.substring( 0, 19);
    let comp = new dayjs(sub).add(offset, 'minute');



    return comp.format(LOCAL_FORMAT);
}

export function stringLocalToUTC(string)
{
    const date = new Date(string);
    const offset = date.getTimezoneOffset();
    let comp = new dayjs(date).subtract(Math.abs(offset), 'minute');
    return comp.format(TIMESTAMP_FORMAT);

}

export function stringToLocalFormat(string) {
    return (new dayjs(string)).utc().format(string);
}

export function stringToDatetimeLocal(string) {
    return (new dayjs(string)).tz('Europe/London').format(LOCAL_FORMAT)
}

export function stringToDateLocal(string) {
    return (new dayjs(string).utc().format('YYYY-MM-DD'))
}

export function stringToUTCDateTime(string) {
    if (string) {
        return (new dayjs(string)).utc().toISOString();
    }
}


export function nowUTCTimeStamp() {
    return (new dayjs()).utc().format(TIMESTAMP_FORMAT);
}


export function stringToFriendlyDatetime(string) {
    return (new dayjs(string)).tz('Europe/London').format('HH:mm D/M/YYYY');

}
