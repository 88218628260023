
import Popup from '../../../../components/popup';
import Input from '../../../../components/input-with-label';


import { CreateButton, CancelButton } from '../../../../components/buttons';
import GrantsAPI from "../../../../lib/grants/grants-api";

import React from 'react';

export default class GrantPrefixes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prefix: '',
            errors: {}
        }
    }

    createPrefix() {
        GrantsAPI.createPrefix(this.state.prefix.toLowerCase(),  errors => {
            this.setState({errors : errors})
        }).then(({ data }) => {
            this.props.updatePrefixes(data);

            this.props.setPopup(false);
        });
    }

    onPrefixChange(e) {
        let { value } = e.target;
        value = value.replace(/\s/g, '');
        this.setState({ prefix: value, errors: {} });
    }

    render() {
        return (
            <Popup type="grant-prefixes" onClose={() => this.props.setPopup(false)}>
                <div class="popup__inner">
                    <h1>Create a new grant prefix</h1>
                    <p>Input the prefix you want to add to the grant you are creating</p>

                    <Input errors={this.state.errors.name} value={this.state.prefix.toUpperCase()} onChange={this.onPrefixChange.bind(this)} />

                    <div class="popup__buttons">
                        <CreateButton responsive={false} onClick={this.createPrefix.bind(this)}>Create</CreateButton>
                        <CancelButton responsive={false} onClick={() => this.props.setPopup(false)}>Cancel</CancelButton>
                    </div>
                </div>
            </Popup>
        );
    }


}
