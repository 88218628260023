import { Component } from 'react';

export default class MinMaxLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { main } = this.props;
        return (
            <div className="layout__inner layout__min-max">
                <div className="layout__main-container">{ main }</div>
            </div>
        )
    }
}
