import React from 'react';

class NotificationsMenu extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            animate : false
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.notificationCount.unread >= 1) {
            if(prevProps.notificationCount !== this.props.notificationCount) {
                this.setState({ animate : true}, () => {
                    setTimeout(()=> this.setState({animate: false}), 1000);
                });
            }
        }

    }

    render( ) {
        let {setShowNotifications, setShowMessages, showNotifications, notificationCount} = this.props;

        return (
            <>
                <div class="notifications__menu">
                    <div className={`notification-item ${this.state.animate ? 'scale' : ''}`} onClick={() => setShowNotifications(!showNotifications)}>
                        <img class="icon__notication" src="/assets/notification.svg"/>
                        { notificationCount.unread !== 0 ? <span class="notification-item__icon">{ notificationCount.unread }</span> : '' }
                    </div>
                    {/*<div class="notification-item" onClick={() => setShowMessages(true)}>*/}
                    {/*    <img class="icon__messages" src="/assets/email.svg"/>*/}
                    {/*    <span class="notification-item__icon notification-item--toggled">100</span>*/}
                    {/*</div>*/}
                </div>
            </>
        );
    }

}

export default NotificationsMenu;
