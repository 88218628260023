import { useState } from 'react';

import { ProceedButton, DeleteButton } from "../../../../../components/buttons"
import List from "../../../../../components/list/list"
import Row from '../../../../../utils/row';
import Container from "../../../../../containers/container"
import InputDate from '../../../../../components/input-date';

import ELearningApi from "../../../../../lib/e-learning/e-learning-api"

import { stringToFriendlyDatetime } from '../../../../../lib/helpers/datetime-helpers';
import AlertManager from '../../../../../managers/alert/alert-manager';

export default function BookedScreen({ module, booking }) {
    const removeBooking = () => {
        new ELearningApi().removeManualAssessmentBooking(booking.id).then(() => {
            AlertManager.success('Removed the booking');
        });
    }

    return (
        <Container style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
        }}>
            <Container.Header style={{ marginBottom: '29px' }}>
                <Container.Text fontSize="30px" color="black">In Person Assessment Booking</Container.Text>
            </Container.Header>
            <Container.Content>
                <List gap="20px" style={{
                    alignItems: 'center'
                }}>
                    <Row gap="10px" alignItems="center">
                        <Container.Text fontSize="20px" color="black">Booked for</Container.Text>
                        <Container.Text fontSize="22px" color="black">{ stringToFriendlyDatetime(booking?.date) }</Container.Text>
                    </Row>
                    <DeleteButton onClick={removeBooking}>Remove Booking</DeleteButton>
                </List>
            </Container.Content>
        </Container>
    )
}