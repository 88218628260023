import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeRadioButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            radio_buttons: [{
                option: ''
            }]
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.add = this.add.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    save() {
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
    }

    onInputChange(e) {
        const { name, value } = e.target;

        const hasNumber = /\d/;   

        let params = { [ name ]: value }

        if (hasNumber.test(name)) {
            const radio_buttons = [ ...this.state.radio_buttons ]
            const [ key, index ] = name.split('-');

            radio_buttons[index][key] = value;

            params = { radio_buttons }
        } 

        this.setState(params, () => this.save());
    }

    add() {
        const radio_buttons = [ ...this.state.radio_buttons ];

        radio_buttons.push({ option: '' });

        this.setState({ radio_buttons }, () => this.save());
    }

    delete(i) {
        const radio_buttons = [ ...this.state.radio_buttons ].filter((_, index) => index !== i);

        this.setState({ radio_buttons }, () => this.save());
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type="radio-buttons" title="Radio Buttons">
                    { this.state.radio_buttons.map((option, index) => {
                        return (
                            <div key={index} className="utils__row utils__wrap utils__gap--20">
                                <Input value={option.option} 
                                        name={'option-' + index} 
                                        label={`Radio Button Option ${index + 1}`} 
                                        placeholder="Text Value" 
                                        onChange={this.onInputChange}/>

                                <span className="column__delete" onClick={() => this.delete(index)}>X</span>
                            </div>
                        )
                    })}
                    <div className="utils__rowCentered">
                        <AddButton onClick={this.add}>Add Radio Button</AddButton>
                    </div>
                </QuestionType>
            </div>
        )
    }
}   