class GrantEditStateHandler {
    constructor() {
        this.onStateUpdate = grant => {
            console.log(grant);
        }
    }

    setOnStateUpdate(callback) {
        this.onStateUpdate = callback;
    }

    addSection(state, section = this.makeSection()) {
        let sections = [];

        if (state.config.hasOwnProperty('sections') === false) {
            sections = [section];

        } else {
            sections = [...state.config.sections, section];
        }

        state = Object.assign({}, state, {
            config: Object.assign({}, state.config, {
                sections: sections
            })
        });


        this.onStateUpdate(state);
    }

    fireComplete(state) {
        this.onStateUpdate(state);

    }

    updateSection(state, sectionIndex, params) {

        if (state.config.sections[sectionIndex]) {
            let sections = [...state.config.sections];

            sections[sectionIndex] = Object.assign({}, state.config.sections[sectionIndex], params);

            state = Object.assign({}, state, {
                config: Object.assign({}, state.config, {
                    sections: sections
                })
            });

            this.fireComplete(state);
        }
    }

    removeSection(state, sectionIndex) {
        if (state.config.sections[sectionIndex]) {
            let sections = [...state.config.sections];
            sections.splice(sectionIndex, 1);
            state = Object.assign({}, state, {
                config: Object.assign({}, state.config, {
                    sections: sections
                })
            });

            this.fireComplete(state);
        }
    }

    addBlock(state, sectionIndex, block = this.makeBlock()) {

        let section = this.getSection(state, sectionIndex);

        if (section !== null) {
            if (section.hasOwnProperty('blocks') === false) {
                section.blocks = [block];
            } else {
                section.blocks.push(block);
            }
            this.updateSection(state, sectionIndex, section);
        }

    }

    getSection(state, sectionIndex) {
        if(state.config.sections[sectionIndex]) {
            return  {...state.config.sections[sectionIndex]};
        }

        return null;
    }

    updateBlock(state, sectionIndex, blockIndex, params) {

        let section = this.getSection(state, sectionIndex);

        if (section !== null) {

            if (section.hasOwnProperty('blocks') === true) {
                let blocks  = [...section.blocks];
                if (blocks[blockIndex]) {
                    blocks[blockIndex] = Object.assign({}, blocks[blockIndex], params);
                    section = Object.assign({}, section, { blocks : blocks});
                    this.updateSection(state, sectionIndex, section);
                }
            }
        }
    }


    removeBlock(state, sectionIndex, blockIndex) {
        if (state.config.sections[sectionIndex]) {
            let section = {...state.config.sections[sectionIndex]};
            if (section.hasOwnProperty('blocks') === true) {
                if (section.blocks[blockIndex]) {
                    let blocks = [...section.blocks];
                    blocks.splice(blockIndex, 1);
                    this.updateSection(state, sectionIndex, { blocks : blocks});
                }
            }
        }
    }

    addQuestion(state, sectionIndex, blockIndex, question = this.makeQuestion()) {

        let section = this.getSection(state, sectionIndex);

        if(section !== null) {
            if (section.hasOwnProperty('blocks') === true) {
                let blocks = [...section.blocks];
                if (blocks[blockIndex]) {
                    let block = {...blocks[blockIndex]};
                    if (block.hasOwnProperty('questions') === false) {
                        block.questions = [question];
                    } else {
                        block.questions.push(question);
                    }

                    blocks[blockIndex] = block;

                    this.updateSection(state, sectionIndex, { blocks: blocks});
                }
            }
        }




    }

    updateQuestion(state, sectionIndex, blockIndex, questionIndex, params) {

        let section = this.getSection(state, sectionIndex);

        if(section !== null) {
            if (section.hasOwnProperty('blocks') === true) {
                let blocks = [...section.blocks];
                if (blocks[blockIndex]) {
                    let block = {...blocks[blockIndex]};
                    if (block.hasOwnProperty('questions') === true) {
                        let questions = [...block.questions];
                        if(questions[questionIndex]) {
                            console.log(questionIndex);
                            questions[questionIndex] = Object.assign({}, questions[questionIndex], params);
                            block.questions = questions;

                            blocks[blockIndex] = Object.assign({}, block[blockIndex], block);
                            this.updateSection(state, sectionIndex, { blocks: blocks});
                        }


                    }
                }
            }
        }
    }

    removeQuestion(state, sectionIndex, blockIndex, questionIndex) {
        let section = this.getSection(state, sectionIndex);

        if(section !== null) {
            if (section.hasOwnProperty('blocks') === true) {
                let blocks = [...section.blocks];
                if (blocks[blockIndex]) {
                    let block = {...blocks[blockIndex]};
                    if (block.hasOwnProperty('questions') === true) {
                        let questions = [...block.questions];
                        if(questions[questionIndex]) {
                            questions.splice(questionIndex, 1);
                            block.questions = questions;

                            blocks[blockIndex] = Object.assign({}, block[blockIndex], block);
                            this.updateSection(state, sectionIndex, { blocks: blocks});
                        }


                    }
                }
            }
        }
    }

    makeSection(params = {}) {
        return Object.assign({}, {name: '', description: '', blocks: []}, params);
    }

    makeBlock(params = {}) {
        return Object.assign({}, {
            title: '',
            description: '',
            questions: [],
            show_section_number: false,
            show_question_decimal_number: false,
            mobile_popup: false,
            scoring_weight_percentage: null,
            override_questions_scoring_range: false,
            scoring_range: []
        }, params);
    }

    makeQuestion(type, params = {}) {
        switch (type) {
            case('text-input') :
            {
                return Object.assign({}, {
                    placeholder: '',
                    small_input: false,
                    large_input: false,
                    set_a_word_count: false,
                    min_word_count: null,
                    max_word_count: null,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('table-input') :
            {
                return Object.assign({}, {
                    columns: [{
                        title: '',
                        type: '',
                    }],
                    default_row_count: 1,
                    allow_new_rows: false,
                    min_rows_required : 0,
                    max_row_count: null,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('checkboxes') :
            {
                return Object.assign({}, {
                    checkboxes: [{
                        option: '',
                        option_required: false
                    }],
                    min_selection: null,
                    max_selection: null,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('radio-buttons') :
            {
                return Object.assign({}, {
                    radio_buttons: [{
                        option: ''
                    }],
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('upload-documents') :
            {
                return Object.assign({}, {
                    max_upload_amount: null,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('date-picker') :
            {
                return Object.assign({}, {
                    allow_date_range: false,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('dropdown-selection') :
            {
                return Object.assign({}, {
                    dropdown: [{
                        item: ''
                    }],
                    dropdown_title: '',
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            case('currency-input') :
            {
                return Object.assign({}, {
                    currency: '',
                    allow_decimals: false,
                    min_end_range: null,
                    max_end_range: null,
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
            default : {
                return Object.assign({}, {
                    title: '',
                    description: '',
                    scoring_range: [],
                    scoring_criteria: false,
                    comments_enabled: false,
                }, params);
            }
        }
    }

}


export default GrantEditStateHandler;
