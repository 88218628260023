import ResultListItemDetails from "../results/results-list-item-details";

import ResultsListItem from "../results/results-list-item";
import React from "react";
import {ResultListItemMetaInfo} from "../results/result-list-item-meta-info";
import {stringToFriendlyDatetime} from "../../lib/helpers/datetime-helpers";

export default function ActivityItem({ item }) {
    return (
        <ResultsListItem
            details={<ResultListItemDetails title={item?.message} subtitle={handleApplicationName(item)} />}
            metaOne={
                <ResultListItemMetaInfo
                    itemOne={<p>{ stringToFriendlyDatetime(item?.updated_at) }</p>}
                    itemTwo={<p>{ item?.created_by?.name}</p>}
                />
            }
        />
    )
}

function handleApplicationName(item) {

    if (item?.application) {
        if(item.application.name === null) {
            return item.application.grant !== null ? item.application.grant.name.toUpperCase() : 'Application No Longer exists'
        }

        return `#${item.application.name.toUpperCase()}`;

    }
    else {
        return 'Application No Longer exists';
    }
}
