export default ({ square = true, checked = false, text = 'default text', disabled = false, onChange = () => {}, error, name }) => {

    return (
       <div className="checkbox__outer">
            <div className="checkbox__inner">
                <div className={`checkbox-round ${square ? 'checkbox-square' : ''} ${disabled ? 'checkbox--disabled' : ''}`} onClick={() => onChange({ target: { name, option: text, value: !checked } })}>
                    <input type="checkbox" checked={checked} onChange={() => {}} />
                    <label for="checkbox"></label>
                </div>
                <p>{ text }</p>
            </div>
            { error && <p className="checkbox__error">{ error }</p> }
       </div>
    )
}
