import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

import Dropdown from '../../../../components/dropdowns/dropdown';

import { AddButton } from '../../../../components/buttons';

function Field({ title, children }) {
    return (
        <div className="field">
            <p className="field__label">{ title }</p>
            { children }
        </div>
    )
}

export default class QuestionTypeDropdownSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdown: [{
                item: ''
            }],
            dropdown_title: ''
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.add = this.add.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    save() {
        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
    }

    onInputChange(e) {
        const { name, value } = e.target;

        const hasNumber = /\d/;   

        let params = { [ name ]: value }

        if (hasNumber.test(name)) {
            const dropdown = [ ...this.state.dropdown ]
            const [ key, index ] = name.split('-');

            dropdown[index][key] = value;

            params = { dropdown }
        } 

        this.setState(params, () => this.save());
    }

    add() {
        const dropdown = [ ...this.state.dropdown ];

        dropdown.push({ item: '' });

        this.setState({ dropdown }, () => this.save());
    }

    delete(i) {
        const dropdown = [ ...this.state.dropdown ].filter((_, index) => index !== i);

        this.setState({ dropdown }, () => this.save());
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type="dropdown-selection" title="Dropdown Selection">
                    <Input value={this.state.dropdown_title} 
                        name='dropdown_title'
                        label='Dropdown Selection Title'
                        placeholder="Text Value" 
                        onChange={this.onInputChange}/>

                    { this.state.dropdown.map((option, index) => {
                        return (
                            <div key={index} className="utils__row utils__wrap utils__gap--20">
                                <Input value={option.item} 
                                        name={'item-' + index} 
                                        label={`Dropdown Selection Item ${index + 1}`} 
                                        placeholder="Text Value" 
                                        onChange={this.onInputChange}/>
                                        
                                <span className="column__delete" onClick={() => this.delete(index)}>X</span>
                            </div>
                        )
                    })}
                    <div className="utils__rowCentered">
                        <AddButton onClick={this.add}>Add Dropdown Item</AddButton>
                    </div>
                </QuestionType>
            </div>
        )
    }
}   