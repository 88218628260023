
import { Component } from 'react';
import ReactDOM from 'react-dom';

import MeApi from "../../lib/users/me-api";
import AlertManager from "../../managers/alert/alert-manager";
import Row from '../../utils/row';
import CreateButton from '../../components/buttons/create-button';
import Divider from '../../components/divider/divider';
import List from '../../components/list/list';

import ContactsApi from '../../lib/contacts/contacts-api';

import Container from '../../containers/container';
import ContainerTable from '../../containers/container-table';
import ButtonNext from '../../components/buttons-navigation/button-next';
import { stringToFriendlyDatetime } from '../../lib/helpers/datetime-helpers';
import ContainerIndex from '../../containers/container-index';

import AccountMessagesMessage from './components/account-messages-message';


import PopupsNewMessage from './popups/popups-new-message';

import PageLayout from "../../containers/layout/page-layout";
import { MinMaxLayout } from "../../containers/layout";

import PopupContext, { PopupProvider } from '../../context/context-popup';

import adminApi from '../../lib/admin/admin-api';

import Dropdown from '../../components/inputs/inputs-dropdown';

import { SmallPill } from '../../components/pills';
import { useTasksStore } from '../../context/context-tasks';

const Unread = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
            <path id="Path_441" data-name="Path 441" d="M18.8,8.493A5.023,5.023,0,0,0,22,8.9V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3H16.1a5.01,5.01,0,0,0,1.093,4.241l-5.132,4.442L5.647,6.238,4.353,7.762l7.72,6.555ZM21,7a3,3,0,1,1,3-3A3,3,0,0,1,21,7Z" transform="translate(-2 -1)" fill="#fff"/>
        </svg>
    )
}

const Read = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.833" viewBox="0 0 20 19.833">
            <path id="Path_443" data-name="Path 443" d="M2.243,6.854,11.49,1.31a1,1,0,0,1,1.029,0l9.238,5.545A.5.5,0,0,1,22,7.284V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V7.283a.5.5,0,0,1,.243-.429ZM4,8.133V19H20V8.132L17.209,6.456,12,3.332l-8,4.8ZM12.06,13.7l5.3-4.463,1.288,1.53L12.074,16.3l-6.71-5.53L6.636,9.228,12.06,13.7Z" transform="translate(-2 -1.167)" fill="#354859"/>
        </svg>
    )
}

const Wrapper = () => {
    const tasksContext = useTasksStore();

    return <Messages tasksContext={tasksContext}/>
}

export default class Messages extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            popup: null,
            selectedMessage: null,
            messages: [],
            currentPage: 1,
            adminTeams: [],
            meta: {},
            sortMessages: {
                label: 'Sort by updated at',
                value: 'updated_at'
            },
            adminTeam: null,
            filterUnread: null,
            direction: {
                label: 'Sort by Descending',
                value: 'desc'
            }
        };
    }

    componentDidMount() {
        this.fetch().then(() => {
            const id = new URLSearchParams(window.location.search).get('id');

            if (id !== null) {
                this.selectMessage(Number(id));
            }
        });

        this.fetchAdminTeams();
        this.getMyTeams();
    }

    fetchAdminTeams() {
        adminApi.getTeams().then(({ data }) => {
            this.setState({ adminTeams: data });
        });
    }

    getMyTeams() {
        MeApi.me().then(({ data }) => {
            console.log(data);
        });
    }

    getAdminTeam(team) {
        if (typeof team === 'object') return team?.name;

        return this.state.adminTeams.find(t => t.id === team)?.name;
    }

    fetch() {
        const data = {
            per_page: 50,
            page: this.state.currentPage,
            order_by: this.state.sortMessages?.value,
            order_direction: this.state.direction?.value,
        }

        if (this.state.adminTeam !== null) {
            data.admin_team_ids = [ this.state.adminTeam.value ];
        }

        if (this.state.filterUnread !== null) {
            data.with_unread_messages = this.state.filterUnread?.value;
        }

        return new Promise((resolve, reject) => {
            ContactsApi.getConversations(data).then(response => {
                this.setState({
                    meta: response.meta,
                    messages: response.data
                })

                resolve();
            });
        })
    }

    handleChange(e) {
        this.setState({[e.target.name] : e.target.value});
    }

    selectMessage(msg) {
        this.setState({ selectedMessage: msg });
    }

    openNewMessagePopup() {
        const { setPopup } = this.context;

        setPopup(<PopupsNewMessage fetch={this.fetch.bind(this)}/>);
    }

    renderContent() {
        if (this.state.selectedMessage !== null) {

            return <AccountMessagesMessage id={this.state.selectedMessage} tasksContext={this.props.tasksContext} data={this.state.messages.find(({ id }) => id === this.state.selectedMessage)} return={() => {
                this.selectMessage(null);
                this.fetch();
            }}/>
        }

        let messages = [];

        this.state?.messages?.forEach(msg => {
            messages.push([
                msg?.user_unread_messages?.length > 0,
                msg.subject,
                this.getAdminTeam(msg?.admin_team_id),
                msg.created_by,
                stringToFriendlyDatetime(msg.updated_at),
                msg?.admin_tasks?.filter(task => task.completed_at === null).length,
                msg.id,
            ])
        })

        return (
            <Container>
                <Container.Header>
                    <Row justifyContent='space-between' alignItems='center'>
                        <Container.Title color="#354859" fontSize="40px">Messages</Container.Title>
                    </Row>
                    <Divider/>
                    <List gap="20px">
                        <Row gap="40px" wrap={true}>
                            <Dropdown
                                label="Sort"
                                items={
                                    [
                                        {
                                            label: 'Sort by updated at',
                                            value: 'updated_at'
                                        },
                                        {
                                            label: 'Sort by created at',
                                            value: 'created_at'
                                        }
                                    ]
                                }
                                value={this.state.sortMessages}
                                onChange={val => this.setState({ sortMessages: val }, () => this.fetch())}
                            />
                            <Dropdown
                                label="Direction"
                                items={
                                    [
                                        {
                                            label: 'Sort by Ascending',
                                            value: 'asc'
                                        },
                                        {
                                            label: 'Sort by Descending',
                                            value: 'desc'
                                        },
                                    ]
                                }
                                value={this.state.direction}
                                onChange={val => this.setState({ direction: val }, () => this.fetch())}
                            />
                            <Dropdown
                                label="Unread / Read"
                                reset
                                resetText="All"
                                defaultSelected={true}
                                items={
                                    [
                                        {
                                            label: 'Filter by Unread',
                                            value: 1
                                        },
                                        {
                                            label: 'Filter by Read',
                                            value: 0
                                        },
                                    ]
                                }
                                value={this.state.filterUnread}
                                onChange={val => this.setState({ filterUnread: val }, () => this.fetch())}
                                onReset={() => this.setState({ filterUnread: null }, () => this.fetch())}
                            />
                             <Dropdown
                                label="Filter by team"
                                reset
                                resetText="All"
                                defaultSelected={true}
                                items={
                                    this.state.adminTeams.map((data) => {
                                        return {
                                            label: data?.name,
                                            value: data.id,
                                        }
                                    })
                                }
                                value={this.state.adminTeam}
                                onChange={val => this.setState({ adminTeam: val }, () => this.fetch())}
                                onReset={() => this.setState({ adminTeam: null })}
                            />
                            {/* <Row gap="20px">
                                <LabelContainer label="User">
                                    <div style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%'
                                    }}></div>
                                </LabelContainer>
                                <LabelContainer label="Contact">
                                    <div style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: 'blue',
                                        borderRadius: '50%'
                                    }}></div>
                                </LabelContainer>
                            </Row> */}
                        </Row>
                    </List>
                    <Divider/>
                </Container.Header>
                <Container.Content>
                    <List gap='40px'>
                    <ContainerTable
                            className="dashboard-progress"
                            columns={[
                                { field: 'STATUS', width: '10%' },
                                { field: 'SUBJECT', width: '30.8196721%' },
                                { field: 'TEAM', width: '10%' },
                                { field: 'CONTACT', width: '20%' },
                                { field: 'LAST UPDATE', width: '10%' },
                                { field: 'TASKS', width: '10%' },
                                { field: '', width: '9.1803279%' }
                            ]} data={[
                                ...messages
                            ]}
                            style={{
                                backgroundColor: 'transparent',
                                padding: '0px',
                            }}
                            rowStyle={{
                                backgroundColor: '#FCF7FF',
                            }}
                            replaceColumn={{
                                0: (data) => {
                                    return (
                                        <div style={{
                                            backgroundColor: data === true ? '#FF7612' : '#F4EAFA',
                                            width: '100%',
                                            height: '100%',
                                            padding: '25px 0px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '10px 0px 0px 10px'
                                        }}>
                                            { data === true ? <Unread/> : <Read/> }
                                        </div>
                                    )
                                },
                                1: (data) => {
                                    return <Container.Text className="truncate">{ data }</Container.Text>
                                },
                                2: (data) => {
                                    return data !== undefined ? <SmallPill>{ data }</SmallPill> : ''
                                },
                                3: (data) => {
                                    return <Container.Text className="truncate">
                                        <a href={`/contacts/${data.id}`}>{ data.first_name ? `${data.first_name} ${data.last_name || ''}` : data.email }</a>
                                    </Container.Text>
                                },
                                6: (data) => {
                                    return (
                                        <Row style={{
                                            width: '100%',
                                            paddingRight: '25px'
                                        }} justifyContent='flex-end'>
                                            <ButtonNext onClick={() => this.selectMessage(data)}>View</ButtonNext>
                                        </Row>
                                    )
                                }
                            }}
                        ></ContainerTable>
                        <ContainerIndex.Pagination
                            currentPage={this.state.currentPage}
                            maxPages={this.state.meta?.last_page}
                            onPrevious={() => this.setState({ currentPage: this.state.currentPage - 1 }, () => this.fetch())}
                            onNext={() => this.setState({ currentPage: this.state.currentPage + 1 }, () => this.fetch())}
                        />
                    </List>
                </Container.Content>
            </Container>
        )
    }

    render() {
        return <MinMaxLayout main={this.renderContent()}/>
    }

}

const el = document.getElementById('contact-messages');

if (el) {
    ReactDOM.render(<>
        <PageLayout>
            <Wrapper/>
        </PageLayout>
    </>, el);
}
