import { ModifyButton } from "../buttons";

export default ({ name, title, subtitle, infoOne, infoTwo, onClick}) => {

    return (
        <div class="company-item">
            <div class="company-item__row">
                <p>{ title }</p>
                <p>{ subtitle }</p>
            </div>
            <p class="company-item__name">{ name }</p>
            <div class="company-item__info">
                <p>{infoOne}</p>
                <p>{ infoTwo }</p>
            </div>
            <div className="company-item__buttons">
                <ModifyButton responsive={false} onClick={onClick}>View/Modify</ModifyButton>
                {/*<button class="button button--idle">Remove Company <img src="/assets/rightArrow.svg"/></button>*/}
            </div>
        </div>
    )
}
