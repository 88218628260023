import React, {Component} from 'react';
import Switch from "react-switch";

class CustomSwitch extends Component {
    render() {
        return (
            <div className={'custom-switch'}>
                <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor="#00C8C3"
                    offHandleColor="#EA1557"
                    {
                        ...this.props
                    }
                />
            </div>

        );
    }
}

export default CustomSwitch;
