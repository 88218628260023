import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { SeventyThirtyLayout } from "../../../../containers/layout";
import ActivityItem from '../../../../components/activity/activity-item';
import ResultsList from "../../../../components/results/results-list";

import { Empty } from '../../../../components/state';

import { CreateButton, SaveButton, DeleteButton, ProceedButton, AddButton } from "../../../../components/buttons";

import ContentContainer from "../../../../components/layout/content-container";

import ELearningDropdown from '../../components/e-learning-dropdown';

import ELearningApi from '../../../../lib/e-learning/e-learning-api';

import CourseCreate from '../../components/popups/course/course-add';
import CourseRemove from '../../components/popups/course/course-remove';

import LearningPathDetails from '../../components/popups/path/path-edit';
import LearningPathArchive from '../../components/popups/path/path-archive';
import LearningPathDelete from '../../components/popups/path/path-delete';

import AlertManager from '../../../../managers/alert/alert-manager';

import Sponsors from '../../../../components/sponsors';
import LabelContainer from '../../../../components/label-container';
import PopupContext, { PopupProvider } from '../../../../context/context-popup';

class Activity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: new Array(0).fill({
                message: 'Test',
            }),
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <ContentContainer title={'Activity'} icon={<img src="/assets/content-container/activity.svg" />}>
                <ResultsList>
                    {
                        this.state.activity.map((item, index) =>{
                            return(
                                <ActivityItem item={item} key={index} />
                            );
                        })
                    }
                </ResultsList>
            </ContentContainer>
        );
    }
}

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        const courses = this.props?.page?.courses;

        if (courses === undefined || courses === null || courses?.length === 0) return (
            <Empty>
                No courses added to learning path
            </Empty>
        )

        return (
            <div className='e-learning-page__content'>
                { courses.map((course, index) => 
                        <ELearningDropdown 
                                key={index}
                                course={course} 
                                removeCourse={this.props.removeCourse} 
                                duplicateCourse={this.props.duplicateCourse}
                                index={index + 1}/> )}
            </div>
        )
    }
}   

class LearningPath extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            page: {},
            popup: null,
            crumbs: [
                {
                    name: 'E-Learning',
                    link: ''
                },
                {
                    name: 'Learning Paths',
                    link: '',
                }
            ]
        }
    }

    componentDidMount() {
        this.fetchLearningPath();
    }

    async save() {
        const params = Object.assign(this.state.page, {
            managers: this.state.page.managers.map(({ id }) => id)
        });

        const saved = await new ELearningApi().updateLearningPath(this.props.id, params); 

        if (saved) {
            AlertManager.success('Sucessfully saved your learning path');

            this.fetchLearningPath();
        } else {
            AlertManager.error(saved);
        }
    }

    async publish() {
        const params = Object.assign(this.state.page, {
            published: this.state.page?.published === null ? 1 : (this.state.page?.published === 1 ? 0 : this.state.page?.published === 0 ? 1 : null),
        });

        const saved = await new ELearningApi().updateLearningPath(this.props.id, params); 

        if (saved) {
            AlertManager.success('Sucessfully published learning path');

            this.fetchLearningPath();
        } else {
            AlertManager.error(saved);
        }
    }

    fetchLearningPath() {
        new ELearningApi().getLearningPath(this.props?.id).then(({ data }) => {
            this.setState({ page: data });
        });
    }

    createCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CourseCreate
                learningPathId={this.props?.id}
                learningPath={this.state.page}
                onSuccess={() => {
                    setPopup(null);
                    this.fetchLearningPath();
                }}
            />
        );
    }

    async duplicateCourse(course) {
        const courseCreate = await new ELearningApi().createCourse({ 
            name: course.name, 
            host_name: course.host_name, 
        });

        if (courseCreate) {
            const addCourseToPath = await new ELearningApi().addCourseToLearningPath(this.props.id, { 
                course_id: courseCreate.id 
            });

            if (addCourseToPath) {
                this.fetchLearningPath();
            }
        }
    }

    removeCourse(id) {
        const { setPopup } = this.context;

        setPopup(
            <CourseRemove
                id={id}
                learningPathId={this.props?.id}
                onSuccess={() => {
                    setPopup(null);
                    this.fetchLearningPath();
                }}
            />
        );
    }

    deletePath() {
        const { setPopup } = this.context;

        setPopup(
            <LearningPathDelete 
                path={this.props?.id} 
                onSuccess={() => window.location.reload()}
            />
        );
    }

    viewDetails() {
        const { setPopup } = this.context;

        setPopup(
            <LearningPathDetails 
                path={this.state.page} 
                fetchLearningPath={this.fetchLearningPath.bind(this)}
                onChange={this.onChange.bind(this)}
            />
        );
    }

    onChange(name, value) {
        this.setState({
            page: {
                ...this.state.page,
                [ name ] : value,
            }
        }, () => console.log(this.state))
    }

    render() {
        const crumbs = [
            {
                name: 'E-Learning',
                link: ''
            },
            {
                name: 'Learning Paths',
                link: '',
            },
            {
                name: this.state.page?.name,
                link: ''
            }
        ]
        
        return (
            <PageLayout
                topLeft={<Breadcrumb crumbs={crumbs}/>}
                middle={
                    <div className='grant-editor__middle utils__gap--20'>
                        <div className="utils__rowSpaceBetween utils__gap--20">
                            <div className="utils__row utils__gap--20">
                                <CreateButton onClick={this.viewDetails.bind(this)}>Path Details</CreateButton>
                                <AddButton onClick={this.createCourse.bind(this)}>Add Course</AddButton>
                                <LabelContainer label="Visibility">
                                    <p>{ this.state.page.visible === 1 ? 'Visible' : 'Hidden'}</p>
                                </LabelContainer>
                            </div>

                            <div className="utils__row utils__gap--20">
                                <div className='grant-editor__published'>
                                    Published Version: ...<br/>
                                    Published: ...<br/>
                                </div>
                            </div>

                            <div className="utils__row utils__gap--20">                              
                                { this.state.page?.published === 0 ? 
                                <ProceedButton onClick={this.publish.bind(this)}>Publish</ProceedButton> :
                                <DeleteButton onClick={this.publish.bind(this)}>Unublish</DeleteButton>
                                }
                                <SaveButton mobile={true} onClick={this.save.bind(this)}/>
                                <DeleteButton onClick={this.deletePath.bind(this)}/>
                            </div>
                        </div>
                    </div>}>
                    <SeventyThirtyLayout
                        main={<Page page={this.state.page} 
                                    removeCourse={this.removeCourse.bind(this)}
                                    duplicateCourse={this.duplicateCourse.bind(this)}
                                    onChange={this.onChange.bind(this)}/>}
                        side={[
                            {
                                name: 'Activity',
                                component: <Activity/>
                            }
                        ]}
                    />
            </PageLayout>
        );
    }
}

const el = document.getElementById('learning-path');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <LearningPath id={el.getAttribute('pageId')}/>
        </PopupProvider>    
    , el);
}
