import { useState } from 'react';

import Container from '../../../containers/container';
import Row from '../../../utils/row';

import InputFileSelect from '../../../components/inputs/inputs-file-select';

const videoTypes = [
    {
        label: 'YouTube',
        value: 'youtube',
    },
    {
        label: 'Vimeo',
        value: 'vimeo',
    }
]

export default function EmbedVideo({ onChange, value }) {
    const change = (e) => {
        const { value } = e.target;

        onChange({
            target: {
                name: 'featured_image',
                value: value
            }
        });
    }

    return (
        <div className="embed-video">
            <Container>
                <Container.Header>
                    <Container.Title>Embed Image</Container.Title>
                </Container.Header>

                <Container.Content>
                    <Row>
                        <InputFileSelect label="Image" fill={true} value={value} onChange={change}/>
                    </Row>
                </Container.Content>
            </Container>
        </div>
    )
}