import {Component} from 'react';

export default class TextBox extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {data, config, sectionConfig, contentIndex, handleChange, fixed,} = this.props;

        return (

            <div className="input-outer input-outer__small" >
                <textarea placeholder="Title" value={data} onChange={(evt) => handleChange({
                    title: config.name,
                    contentItemConfig: config,
                    sectionConfig,
                    contentIndex,
                    data: {body : evt.target.value, meta: {}},
                    fixed : fixed
                })}/>
            </div>
        )
    }
}
