import React from 'react';
import ContentLoader from 'react-content-loader';
import MeApi from '../lib/users/me-api';

import SidePopup from "./side-popup"
import dayjs from "dayjs";
import notificationDescription from "../lib/helpers/notification-descriptions";
import {stringToFriendlyDatetime} from "../lib/helpers/datetime-helpers";


const Skeleton = () => {
    return (
        <>
            {new Array(20).fill(0).map((_, index) => {
                return (
                    <ContentLoader key={index} viewBox="0 0 300 125">
                        <rect x="0" y="0" rx="20" ry="20" width="300" height="125"/>
                    </ContentLoader>
                )
            })}
        </>
    )
}

export default class NotificationsPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        };
    }


    getNotifications() {
        MeApi.notifications().then(data => {
            this.setState({
                notifications: data.data
            });
        });

    }

    componentDidMount() {
        this.getNotifications();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.notificationCount !== this.props.notificationCount) {
            this.getNotifications();
        }
    }


    handleRead() {
        MeApi.notificationsMarkAllRead().then(() => {
            this.props.onNotificationsUpdate();

        });
    }

    handleClick(e, text) {
        e.preventDefault();
        window.location.href= text.link;
    }

    render() {
        const content = (
            <>
            <div class="notifications-popup__head">
                    <div class="notifications-popup__start">
                        <h1 class="side-popup__title">Notifications</h1>
                        <div class="notifications-popup__icon">{this.props.notificationCount.unread}</div>
                    </div>
                    <div class="notifications-popup__links">
                        <p className={'notifications-popup__link'} onClick={this.handleRead.bind(this)}>Mark all as Read</p>
                    </div>
                </div>
                <div class="notifications-popup__content">
                    {this.state.notifications.length > 0 ? this.state.notifications.map((n, index) => {
                        const text = notificationDescription(n);
                        
                        return (
                            <div key={index}
                                 className={`notifications-item ${n.read_by.length >= 1 ? 'notification-item--read' : ''}`}>
                                <img src="/assets/user-icon.svg"/>
                                <div class="notifications-item__info">
                                    <p class="notifications-item__date">{stringToFriendlyDatetime(n.created_at)}</p>
                                    <div class="notifications-item__bar">
                                        <p class="notifications-item__name">{text.title}</p>
                                        {text.link ? <p className="notifications-item__type">{text.type}</p> : ''}
                                    </div>
                                    <div class="notifications-item__bar">
                                        <p class="notifications-item__description">{text.description}</p>
                                        {text.link ? <button className="notifications-item__button" onClick={(e) => {
                                            this.handleClick(e, text)
                                        }}>></button> : ''}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <Skeleton/>}
                </div>
            </>
        )
        return (
            <SidePopup type="notifications-popup" onClose={this.props.set} content={content}/>
        )
    }


}
