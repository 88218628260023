export default function SearchBarItem({ color, toggled = false, placeholder = 'Start searching', onClick = () => {}, onChange = () => {}, showButton = true }) {
    const classN= `search-bar ${toggled ? "search-bar--toggled" : ""}`;

    let clearOut = null;

    const handleChange = (e) => {
        clearTimeout(clearOut);

        clearOut = setTimeout(() => {
            onChange(e);
        },500)

    }

    return (
        <div className={`search-bar__inner ${color}`}>
            <img className="search-bar__icon" src="/assets/SVG/awesomefunction-search.svg" />
            <input className={classN} placeholder={placeholder} onChange={handleChange}/>
            {
                showButton &&
                <button onClick={onClick}>Search <img src="/assets/SVG/awesomeui-chevron-right-small.svg" /></button>

            }
        </div>
    )
}
