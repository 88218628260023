import Dropdown from './dropdown';

import Input from '../../../components/input-with-label';
import TextArea from '../../../components/textarea-with-label';
import Checkbox from '../../../components/checkbox';

import GrantScore from './grant-score';
import GrantScoreGroup from './grant-score-group';

import CustomSwitch from '../../../components/ui/custom-switch';

import {AddButton, DeleteButton, DuplicateButton, ScoreButton} from '../../../components/buttons';

import QuestionTypes from './grant-question-types';
import QuestionType from './question-types';

export default function GrantQuestionBlock({
                                               setPopup,
                                               title,
                                               index,
                                               data,
                                               grantVersion,
                                               onQuestionType,
                                               sectionIndex,
                                               blockIndex,
                                               grantStateHandler,
                                               addQuestionToBlock,
                                               removeQuestion,
                                               removeQuestionBlock,
                                               blockNumber
                                           }) {
    const duplicateBlock = () => {
        grantStateHandler.addBlock(grantVersion, sectionIndex, {...data});
    }

    const onChange = (e) => {
        const {name, value} = e.target;

        grantStateHandler.updateBlock(grantVersion, sectionIndex, blockIndex, {[name]: value});
    }

    const onChangeQuestion = (e, questionIndex) => {
        const {name, value} = e.target;

        grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, {[name]: value});
    }

    const onMobilePopup = () => {
        grantStateHandler.updateBlock(grantVersion, sectionIndex, blockIndex, {mobile_popup: !data.mobile_popup});
    }

    const QuestionBlockButtons = () => {
        const popup = () => setCurrentPopup(GrantScoreGroup, {block : data, breadcrumb: `${blockNumber}) ${data.title ? data.title : ' Question Group'}`}, data)

        return (
            <div className="utils__row utils__gap--20">
                <ScoreButton onClick={popup}>Edit Scoring</ScoreButton>
                <DuplicateButton mobile={true} onClick={duplicateBlock}/>
                <DeleteButton mobile={true} onClick={removeQuestionBlock}/>
            </div>
        )
    }

    const QuestionButtons = ({questionIndex, question}) => {
        const popup = () => setCurrentPopup(GrantScore, {
            question : question,
            breadcrumb: `${blockNumber}.${questionIndex + 1}) ${question.title ? question.title : 'Question Scoring'}`,
            questionIndex
        }, question)

        return (
            <div className="utils__row utils__gap--20">
                <ScoreButton onClick={popup}/>
                <DuplicateButton
                    onClick={() => grantStateHandler.addQuestion(grantVersion, sectionIndex, blockIndex, {...question})}
                    mobile={true}/>
                <DeleteButton mobile={true} onClick={() => removeQuestion(questionIndex)}/>
            </div>
        )
    }

    const setCurrentPopup = (popup, params) => {
        const Popup = popup;

        setPopup(<Popup onClose={() => setPopup(null)} grantVersion={grantVersion} grantStateHandler={grantStateHandler}
                        sectionIndex={sectionIndex} block={data} blockIndex={blockIndex}  {...params}/>);
    }

    const getQuestionByType = (question, questionIndex) => {
        const {type} = question;
        const QuestionTypeComponent = QuestionType[type];

        if (QuestionTypeComponent) {
            return <QuestionTypeComponent
                key={questionIndex}
                question={question}
                grantVersion={grantVersion}
                questionIndex={questionIndex}
                sectionIndex={sectionIndex}
                blockIndex={blockIndex}
                grantStateHandler={grantStateHandler}/>
        } else {
            return <p>No question component</p>
        }
    }

    return (
        <Dropdown title={`${blockNumber} ${data.title ? ` - ${data.title}` : ''}`} index={index}
                  head={<QuestionBlockButtons/>} className="grant-question-block">
            <div className="grant-question-block__top">
                <div style={{display: 'flex', flexDirection: 'column'}} className="utils__gap--20 grant-question-block__top-item">
                    <Input name="title" value={data.title} label="Block Title" placeholder="Input value"
                           onChange={onChange}/>
                    <TextArea name="description" value={data.description} label="Block Description"
                              placeholder="Input value" onChange={onChange}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', paddingTop: '40px'}}
                     className="utils__gap--20 grant-question-block__top-item2">
                    <Checkbox name="show_section_number" text="Show section number" checked={data.show_section_number}
                              onChange={onChange}/>
                    <Checkbox name="show_question_decimal_number" text="Show question decimal number"
                              checked={data.show_question_decimal_number} onChange={onChange}/>
                    <div className="utils__row utils__gap--20 grant-questions-block__block-mobile">
                        <CustomSwitch name="mobile_popup" checked={data.mobile_popup} onChange={onMobilePopup}/>
                        <p>Block popup on mobile</p>
                    </div>
                </div>
            </div>
            <div className="grant-question-block__content utils__gap--20">
                {data.questions &&
                <div className="grant-question-block__questions">
                    {data.questions.map((question, i) => {

                        return (
                            <Dropdown key={i} title={`${blockNumber}.${i +1} ${question.title ? ` - ${question.title}` : ''}`} index={i + 1}
                                      head={<QuestionButtons questionIndex={i} question={question}/>}
                                      className="grant-question">
                                <div className="grant-question-block__top" style={{paddingTop: '20px'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}
                                         className="utils__gap--20 grant-question-block__top-item">
                                        <Input name="title" value={question.title} label="Question Title"
                                               placeholder="Input value" onChange={e => onChangeQuestion(e, i)}/>
                                        <TextArea name="description" value={question.description}
                                                  label="Question Description" placeholder="Input value"
                                                  onChange={e => onChangeQuestion(e, i)}/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', paddingTop: '40px'}}
                                         className="utils__gap--20 grant-question-block__top-item2">
                                        <Checkbox name="response_required" text="Response Required"
                                                  checked={question.response_required}
                                                  onChange={e => onChangeQuestion(e, i)}/>
                                    </div>
                                </div>
                                <div className="grant-question-block__content utils__gap--20">
                                    {getQuestionByType(question, i)}
                                </div>
                            </Dropdown>
                        )
                    })}
                </div>}

                <QuestionTypes onQuestionType={onQuestionType}/>

                {/* <div className="utils__rowCentered">
                    <AddButton onClick={() => addQuestionToBlock(data.id)}>Add Question</AddButton>
                </div> */}
            </div>
        </Dropdown>
    )
}
