import { Component } from 'react';
import ReactDOM from 'react-dom';

import DistributionItem from '../../js/components/content-distribution/distribution-item';
import Breadcrumb from '../../js/components/breadcrumb';

import DistributionApi from '../lib/distributions/distribution-api';

class ContentDistributions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            distributions : []
        };
    }


    componentDidMount() {
        this.getDistributions();
    }

    getDistributions() {
        DistributionApi.getDistributions().then(data => this.setState({distributions : data}))
    }

    render() {
        return (
            <div class="content-distributions">
                <div class="website__topbar">
                    <Breadcrumb crumbs={[{
                        name: 'Content | Distributions',
                        link: '/content/distributions'
                    }]} />
                </div>
                <div class="website__content">
                    <div class="distributions-outer">
                        { this.state.distributions.map((d, i) => <DistributionItem key={i} distribution={d} />) }
                    </div>
                </div>
            </div>
        )
    }
}

export default ContentDistributions;

if (document.getElementById('content-distributions')) {
    ReactDOM.render(<ContentDistributions/>, document.getElementById('content-distributions'));
}
