import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';

import Input from '../../js/components/input-with-label';
import { CreateButton, SaveButton, CancelButton } from '../components/buttons';

import Breadcrumb from '../../js/components/breadcrumb';
import ProfileImageUpload from "../components/profile-image-upload";
import dayjs from "dayjs";

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            errors: [],
            crumbs: [{
                name: 'Account',
                link: '/account'
            },
                {
                    name: 'First Name',
                    link: '#'
                }]
        };
    }


    handleChange(evt) {
        this.setState({
            user: Object.assign({}, this.state.user, {
                [evt.target.name]: evt.target.value
            })
        });
    }

    handleUpdate() {
        // Prevents sending any data like password thats not been touched so it doesnt make password empty
        axios.post('/api/users/me', this.state.user).then(() => window.location.reload()).catch(({response}) => {

            if (response) {
                this.setState({errors: response.data.errors});

                if (response.data.errors.hasOwnProperty("password"))  {
                    this.passwordRef.scrollIntoView();
                }

            }
        });
    }

    componentDidMount() {
        this.getUser();
    }


    getUser() {
        axios.get('/api/users/me').then(({data: {data}}) => {
            data.user_profile = data.user_profile ? data.user_profile : {};
            this.setState({
                user: Object.assign({}, data, {...data.user_profile})
            })

        });
    }


    updateProfileImage() {
        axios.get('/api/users/me').then(response => {

            this.setState({
                user: Object.assign({}, this.state.user, {
                    user_profile: Object.assign({}, this.state.user.user_profile, {
                        profile_img: response.data.data.user_profile.profile_img
                    })
                })
            }, () => console.log(this.state));
        });
    }

    goBack() {
        const link = document.referrer.split('/')[1] == 'account' ? '/dashboard' : document.referrer;

        window.location.href = link;
    }

    render() {
        const {errors} = this.state;
        const {user} = this.state;


        return (
            <div class="account">
                <div class="website__topbar">
                    <Breadcrumb crumbs={[{
                        name: 'Account',
                        link: '/account'
                    },
                        {
                            name: user.name,
                            link: '#'
                        }]}/>
                    <div class="website-row">
                        <SaveButton onClick={this.handleUpdate.bind(this)}>Save / Confirm</SaveButton>
                        <CancelButton onClick={this.goBack}>Go Back</CancelButton>
                    </div>
                </div>
                <div class="website__content">
                    <div class="account__user">
                        <ProfileImageUpload
                            user={this.state.user}
                            onChange={() => this.updateProfileImage()}
                        />
                    </div>
                    <div class="account__block">
                        <div class="account__inputs">
                            <div class="account__section">
                                <div className="website-row">
                                    <Input name="name" value={user.name} placeholder="Username"
                                           onChange={this.handleChange.bind(this)} errors={errors.name}/>
                                </div>
                                <div class="website-row">
                                    <Input name="first_name" value={user.first_name} placeholder="First name"
                                           onChange={this.handleChange.bind(this)} errors={errors.first_name}/>
                                    <Input name="last_name" value={user.last_name} placeholder="Last name"
                                           onChange={this.handleChange.bind(this)} errors={errors.last_name}/>
                                </div>
                                <div class="website-row">
                                    <Input name="email" value={user.email} placeholder="Email address"
                                           onChange={this.handleChange.bind(this)} errors={errors.email}/>
                                    <Input name="tel_no" value={user.tel_no} placeholder="Telephone number"
                                           onChange={this.handleChange.bind(this)} errors={errors.tel_no}/>
                                </div>
                                <div class="website-row">
                                    <Input name="job_title" value={user.job_title} placeholder="Job title"
                                           onChange={this.handleChange.bind(this)} errors={errors.job_title}/>
                                </div>
                            </div>
                            <div class="account__section">
                                <p>Reset Password</p>
                                <div class="website-row">
                                    <Input type="password" name="password" value={user.password}
                                           placeholder="New password" onChange={this.handleChange.bind(this)}/>
                                    <Input type="password" name="password_confirmation"
                                           value={user.password_confirmation} placeholder="Confirm Password"
                                           onChange={this.handleChange.bind(this)}/>
                                </div>
                            </div>
                            <div ref={r => this.passwordRef = r}
                                 className="input-outer__errors">{errors.password && errors.password.map((err, index) =>
                                <p className={'input-outer__error'} key={index} >{err}</p>)}</div>
                        </div>

                        <div class="account__information">
                            <p style={{marginBottom: "23px"}}>Date Joined:</p>
                            <b>{ dayjs(user.created_at).format('DD/MM/YY') }</b>
                            <div style={{ height: '43px'}}></div>
                            <p style={{marginBottom: "23px"}}>Admin Role:</p>
                            <b > { user.super_admin === 1 ? 'Super Admin'  : 'Admin'} </b>
                            <p style={{marginTop: "43px"}}>Roles & Permissions</p>


                            <div class="account__roles">
                                {
                                    user.roles &&
                                    user.roles.map((role, index) => {
                                        return(
                                            <div key={index} className="account-role">
                                                <h2>{ role.name }</h2>
                                                <p>{ role.description }</p>
                                                <b>{ role.permissions.map(p => p.name).join('/')}</b>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {/*<button class="button button--idle">Close Account <img src="/assets/rightArrow.svg"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Account;

if (document.getElementById('account')) {
    ReactDOM.render(<Account/>, document.getElementById('account'));
}
