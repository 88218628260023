export default function File({ file, onRemove  = () => {} }) {
    return (
        <div className="eligibility-question-file">
            <div className="eligibility-question-file__icon">
                <img src="/assets/document-icon.svg" />
            </div>
            <div className="eligibility-question-file__text">
                <p>{ file?.name }</p>
                <p className="eligibility-question-file__delete" onClick={() => onRemove(file)}>Remove</p>
            </div>
        </div>
    )
}