export function ResultListItemMetaInfo({itemOne, itemTwo}) {

    return (
        <div className={'results-list-item-meta-info'}>
            <div className="results-list-item-meta-info__item">
                {itemOne}
            </div>
            <div className="results-list-item-meta-info__item">
                {itemTwo}
            </div>
        </div>
    );

}
