import React, {Component} from 'react';
import ContentApi from "../../lib/content/content-api";
import Popup from "../popup";
import Input from "../input-with-label";

import { CreateButton, CancelButton } from '../buttons';

class NewContentTypePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            slug: '',
            errors: {},
        };
    }


    createNew() {
        const {name, slug} = this.state;
        ContentApi.createNewContentType({
            name, slug, config : {}
        }, errors => this.setState({errors: errors})).then(data => {
            window.location = `/content-types/${data.id}`;
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

    }

    render() {
        return (
            <Popup type={'new-event'}>
                <div className="popup__inner">
                    <h1>Create a new Content Type</h1>
                    <Input
                        placeholder={'Name'}
                        name={'name'}
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        errors={this.state.errors.name}
                    />
                    <Input
                        placeholder={'Slug'}
                        name={'slug'}
                        value={this.state.slug}
                        onChange={this.handleChange.bind(this)}
                        errors={this.state.errors.slug}
                    />


                    <div className="popup__buttons">
                        <CreateButton responsive={false} onClick={this.createNew.bind(this)}/>
                        <CancelButton responsive={false} onClick={this.props.onClose}/>
                    </div>
                </div>
            </Popup>);
    }
}

export default NewContentTypePopup;
