import Dropdown from 'react-dropdown';
import { useState } from 'react';
import Popup from '../popup';

import { SaveButton, DeleteButton, CancelButton } from '../buttons';

export default ({ onChange, confirm, dropdownOptions, currentContentVersion, onDelete, content }) => {

    const [deletePopup, setDeletePopup] = useState(false);

    let options = dropdownOptions.map(({ id, name }) => {
        if (name !== null) return {
            id: id,
            value: id,
            label: name,
        };

        return {
            id: id,
            value: id,
            label: id,
        }
    });

    options.unshift({
        value: 'Create New Version',
        label: 'Create New Version',
    });


    return (
        <div class="website-row">

            { deletePopup &&
                <Popup type={'content-delete-popup'} >
                    <div className={'popup__inner'}>
                        <h1>Are you sure you want to delete {content.title}?</h1>
                        <div className="popup__content">
                            By clicking ‘Delete Content’, you will not be able to restore this content. Any instances that
                            this Content has been used will no longer be available on your distribution (e.g. website).
                        </div>
                        <div className="popup__buttons">
                            <DeleteButton responsive={false} onClick={onDelete}/>
                            <CancelButton responsive={false} onClick={() => setDeletePopup(false)}/>
                        </div>
                    </div>
                </Popup>
            }

            <Dropdown className="input input--idle" options={options} onChange={onChange} value={currentContentVersion ? currentContentVersion : 'Create New Versions'} placeholder="Select an option"/>
            {
                auth.hasPermission('content_edit') && <SaveButton onClick={confirm}/>

            }
            {
                auth.hasPermission('content_delete') && <DeleteButton onClick={() => setDeletePopup(true)}/>
            }
        </div>
    )
}
