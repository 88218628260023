import Input from '../input-with-label';
import React, {Component} from "react";
import {datetimeLocalToString, stringToDatetimeLocal} from "../../lib/helpers/datetime-helpers";
import {slugFormatter} from "../../lib/helpers/formatters";
import ImagesPopup from '../popups/images/images-popup';
import InputWithToggle from '../input-with-toggle';

import ContentApi from '../../lib/content/content-api';
import CustomCreatableSelect from "../inputs/custom-creatable-select";
import DomainMapping from "./domain-mapping";

import CUSTOM_SELECT_STYLING from '../inputs/custom-creatable-select-styling';


export default class DetailsMain extends Component {


    constructor(props) {
        super(props);
        this.state = {
            imagePopup : false,
            currentImageField : null,
            tags : [],
            toggled: {
                contentType: false,
            }
        };
    }


    getTags() {
        ContentApi.getTags().then(response => {
            this.setState({tags : response.data});
        });
    }

    componentDidMount() {
        this.getTags();
    }

    onSlugChange(e) {

        let slug  = slugFormatter(e.target.value);

        this.props.onChange('slug', slug);
    }


    handleMetaChange(e) {
        this.props.onChange('meta', { [e.target.name] : e.target.value});
    }

    handleResourceSelect(r) {
        this.handleMetaChange({
            target : {
                name: this.state.currentImageField,
                value : r.resource.file_name
            }
        })
        this.setState({
            imagePopup: false,
            currentImageField : null
        });
    }

    handleTagsChange(tags) {

        this.props.onChange('tags', tags.map(item => {

            if(item.__isNew__ === true) {
                return {
                    name : item.value
                }
            }

            return {
                name:  item.label,
                id : item.value
            };
        }));
    }

    getTagOptions() {
        return this.state.tags.map(item => {
            return {
                value : item.id,
                label : item.name
            };
        });
    }

    handleSlugOverride = (e) => {

        this.props.onChange('slug_override', e);
    }

    render() {

        let {content, errors, contentType } = this.props;
        let meta =  content.meta ? content.meta : {};

        const tagValues = content.tags ? content.tags.map(tag => {
            return {value: tag.id, label: tag.name};
        }) : []

        return (
            <div class="content-editor__inner">
                {this.state.imagePopup && <ImagesPopup onClose={() => this.setState({ imagePopup : false, currentImageField : null})} onResourceSelect={this.handleResourceSelect.bind(this)} onlyShow={['browse', 'upload']}/>}
                <p class="content-editor__title">Content Details</p>
                <div class="content-editor__items">
                    <div class="content-editor__item">
                        <Input errors={errors.title ? errors.title : []} name="title" value={content.title} placeholder="Title" onChange={e => this.props.onChange('title',e.target.value)}/>

                        <Input
                            name="social_image"
                            value={meta.social_image ? meta.social_image: '' }
                            placeholder="Social image URL"
                            onChange={this.handleMetaChange.bind(this)}
                            onSelect={() => this.setState({imagePopup : true, currentImageField:  'social_image'})}
                            select={true}
                        />



                    </div>
                    <div className="content-editor__item">
                        <Input name="preview_description" value={meta.preview_description ?  meta.preview_description : ''} placeholder="Preview Description" onChange={this.handleMetaChange.bind(this)}/>

                        <Input
                            name="preview_image"
                            value={meta.preview_image ? meta.preview_image: '' }
                            placeholder="Preview image URL"
                            onChange={this.handleMetaChange.bind(this)}
                            select={true}
                            onSelect={() => this.setState({imagePopup : true, currentImageField:  'preview_image'})}
                        />
                    </div>
                    <div class="content-editor__item">
                        <Input errors={errors.published ? errors.published : []}
                               name="published"
                               value={content.published ? stringToDatetimeLocal(content.published) : ''}
                               type="datetime-local"
                               placeholder="Publish Date"
                               onChange={e => this.props.onChange('published', datetimeLocalToString(e.target.value))}
                        />

                        <CustomCreatableSelect
                            classNamePrefix={'tags-input'}
                            styles={CUSTOM_SELECT_STYLING}
                            name={'Tags'}
                            placeholder={'Tags'}
                            isMulti
                            onChange={this.handleTagsChange.bind(this)}
                            options={this.getTagOptions()}
                            value={tagValues}

                        />
                    </div>
                    <div class="content-editor__item">
                        <Input name="seo_title" value={meta.seo_title ? meta.seo_title : ''} placeholder="SEO Content Title" onChange={this.handleMetaChange.bind(this)}/>

                        <Input name="seo_description" value={meta.seo_description ?  meta.seo_description : ''} placeholder="SEO Description" onChange={this.handleMetaChange.bind(this)}/>
                    </div>
                    <div class="content-editor__item">
                        <DomainMapping
                            errors={errors.slug}
                            content={content}
                            contentType={contentType}
                            onSlugChange={this.onSlugChange.bind(this)}
                            onSwitchChange={this.handleSlugOverride.bind(this)}
                        />

                        <Input name="seo_keywords" value={meta.seo_keywords  ? meta.seo_keywords : ''} placeholder="SEO Keywords" onChange={this.handleMetaChange.bind(this)}/>
                    </div>
                </div>
            </div>
        );
    }

}
