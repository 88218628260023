import React, {Component} from 'react';
import ReactDom from "react-dom";
import ContentApi from '../../lib/content/content-api';
import AlertsManager from '../../managers/alert/alert-manager';


class Helper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contentTypes: [],
            selected: {},
            available: {}
        };
    }

    componentDidMount() {
        this.getContentTypes();
    }

    getContentTypes() {
        ContentApi.getContentTypes(1, 1000).then(data => {
            this.setState({
                contentTypes: data.data
            }, () => {
                this.setAvailable();
            });
        });
    }

    setAvailable() {
        let a = {};
        this.state.contentTypes.forEach(item => {
            console.log(item);
            if (item.config.hasOwnProperty('sections')) {
                item.config.sections.forEach(section => {

                    if(a.hasOwnProperty(section.name)) {

                        if(JSON.stringify(section) !== JSON.stringify(a[section.name])) {
                            a[`${section.name} (${item.name})`] = section;
                        }
                    }else {
                        a[`${section.name}`] = section;

                    }

                });
            }
        });
        this.setState({available: a});
    }

    copy(code) {
        navigator.clipboard.writeText(code);
        AlertsManager.success('Code Copied');
    }


    render() {
        return (
            <div style={{ paddingTop : '50px'}}>
                <div style={{padding: '20px', background: "white", position : 'fixed', width: '100%', top: '50px'}}>
                    { _.map(this.state.available, (a, key) => {
                        return(
                            <a key={key} style={{ marginRight: '20px'}} href={`#${key}`}>{key}</a>
                        );
                    })}
                </div>

                <div className="account__block">
                    <div className="account__section account__section--full-width">
                {
                    _.map(this.state.available, (a, key) => {
                        const codeString = JSON.stringify(a, null, 4);
                        return(
                            <div id={key} key={key} className={'account__information account__information--full-width'}>
                                <p><strong>{key}</strong> <button onClick={() => this.copy(codeString)}>Copy</button> </p>
                                <p>  <pre>
                                                                    {codeString}

                                </pre>
                                </p>
                            </div>

                        )
                    })
                }
                    </div>
                </div>
            </div>
        );
    }
}

export default Helper;

const el = document.getElementById('content-type-helper');

if (el) {
    ReactDom.render(<Helper/>, el);
}
