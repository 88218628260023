const NotificationsMenuResponsive = ({ setShowNotifications, setShowMessages, showNotifications, notificationCount }) => {
    const notificationClick = () => setShowNotifications(!showNotifications);
    const messagesClick = () => setShowMessages(true);

    return (
        <div class="notifications-responsive">
             <div class="notification-item" onClick={notificationClick}>
                    <img class="icon__notication" src="/assets/notification.svg"/>
                    <span class="notification-item__icon">{ notificationCount.unread}</span>
                </div>
            {/*<div class="notification-item" onClick={messagesClick}>*/}
            {/*    <img class="icon__messages" src="/assets/email.svg"/>*/}
            {/*    <span class="notification-item__icon notification-item--toggled">100</span>*/}
            {/*</div>*/}
        </div>
    )
}

export default NotificationsMenuResponsive;
