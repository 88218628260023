import Input from '../input-with-label';
import React, {useState} from "react";
import ImagesPopup from '../popups/images/images-popup';
import DynamicInputList from "../inputs/dynamic-input-list";


const ADDITIONAL_SETTINGS = [
    {
        name: 'CTA Content',
        fields:  [
            {
                label : 'CTA title',
                field: 'cta_title'
            },
            {
                label : 'CTA description',
                field: 'cta_description',
                type: 'textarea'

            },
            {
                label : 'CTA background text',
                field: 'cta_background_text',
                type: 'textarea'

            }
        ]
    }
];

export default ({distribution = {}, onChange, errors = {}}) => {
    const d = distribution;
    const s = distribution.settings || {};
    const config = distribution.config ? distribution.config : [];
    // const config = [];
    const handleSettingsChange = (e) => {
        onChange('settings', Object.assign({}, s, {[e.target.name]: e.target.value}));
    }

    const handleChange = (e) => {
        onChange(e.target.name, e.target.value);
    }

    const getFieldValue = field => {
        return s[field] ? s[field] : '';
    }

    const [popup, setPopup] = useState(false);

    return (
        <div class="distributions-settings">
            {popup && <ImagesPopup
                onClose={() => setPopup(false)}
                onResourceSelect={r => {
                    setPopup(false);

                    handleSettingsChange({
                        target: {
                            name: 'distribution_image',
                            value: r.resource.file_name
                        }
                    })
                }}
                onlyShow={['browse', 'upload']}
            />}
            <div class="distributions-settings__section">
                <div class="distribution-settings__row">
                    <Input errors={errors.name} name="name" onChange={handleChange} value={d.name}
                           placeholder="Distribution Name"/>
                    <Input errors={errors.domain} name="domain" onChange={handleChange} value={d.domain}
                           placeholder="Assigned Domain"/>
                </div>
                <div className="distribution-settings__row">
                    <Input errors={errors.description} name="description" onChange={handleChange} value={d.description}
                           placeholder="Description"/>
                    <Input
                        name="social_image"
                        value={s.distribution_image ? s.distribution_image : ''}
                        placeholder="Social image URL"
                        onChange={handleSettingsChange}
                        onSelect={() => setPopup(true)}
                        select={true}
                    />

                </div>
            </div>
            {
                config.map((section, key) => {
                        return(
                            <div key={key} className="distributions-settings__section">
                                <p className="distributions-settings__title"> { section.name} </p>

                                    <DynamicInputList chunkSize={3} getValue={field => getFieldValue(field)} config={section.fields} onChange={e => handleSettingsChange(e)} />
                            </div>
                        );
                })
            }

            <div class="distributions-settings__section">
                <p class="distributions-settings__title">General Information: This information may be populated in
                    different instances across your distribution. </p>
                <div class="distribution-settings__row">
                    <Input name="email_one" value={s.email_one} placeholder="Email 1" onChange={handleSettingsChange}/>
                    <Input name="email_two" value={s.email_two} placeholder="Email 2" onChange={handleSettingsChange}/>
                    <Input name="telephone" value={s.telephone} placeholder="Telephone"
                           onChange={handleSettingsChange}/>
                </div>
                <div class="distribution-settings__row">
                    <Input name="address_one" value={s.address_one} placeholder="Address Line 1"
                           onChange={handleSettingsChange}/>
                    <Input name="address_two" value={s.address_two} placeholder="Address Line 2"
                           onChange={handleSettingsChange}/>
                    <Input name="address_three" value={s.address_three} placeholder="Address Line 3"
                           onChange={handleSettingsChange}/>
                </div>
                <div class="distribution-settings__row">
                    <Input name="address_city" value={s.address_city} placeholder="City"
                           onChange={handleSettingsChange}/>
                    <Input name="address_postcode" value={s.address_postcode} placeholder="Post Code"
                           onChange={handleSettingsChange}/>
                    <Input name="address_country" value={s.address_country} placeholder="Country"
                           onChange={handleSettingsChange}/>
                </div>
            </div>
            <div class="distributions-settings__section">
                <p class="distributions-settings__title">Social Links</p>
                <div class="distribution-settings__row">
                    <Input name="social_fb" value={s.social_fb} placeholder="Facebook" onChange={handleSettingsChange}/>
                    <Input name="social_inst" value={s.social_inst} placeholder="Instagram"
                           onChange={handleSettingsChange}/>
                    <Input name="social_twitter" value={s.social_twitter} placeholder="Twitter"
                           onChange={handleSettingsChange}/>
                </div>
                <div class="distribution-settings__row">
                    <Input name="social_linkedin" value={s.social_linkedin} placeholder="LinkedIn"
                           onChange={handleSettingsChange}/>
                    <Input name="social_youtube" value={s.social_youtube} placeholder="YouTube"
                           onChange={handleSettingsChange}/>
                    <Input name="social_vimeo" value={s.social_vimeo} placeholder="Vimeo"
                           onChange={handleSettingsChange}/>
                </div>
                <div class="distribution-settings__row">
                    <Input name="social_tiktok" value={s.social_tiktok} placeholder="TikTok"
                           onChange={handleSettingsChange}/>
                </div>
            </div>
            <div class="distributions-settings__section">
                <p class="distributions-settings__title">Policies</p>
                <div class="distribution-settings__row">
                    <Input name="links_privacy" value={s.links_privacy} placeholder="Privacy"
                           onChange={handleSettingsChange}/>
                    <Input name="links_terms" value={s.links_terms} placeholder="Terms & Conditions"
                           onChange={handleSettingsChange}/>
                    <Input name="links_cookie" value={s.links_cookie} placeholder="Cookie"
                           onChange={handleSettingsChange}/>
                </div>
                <div class="distribution-settings__row">
                    <Input name="copy_cookie" value={s.copy_cookie} placeholder="Cookie Bar Statement"
                           onChange={handleSettingsChange}/>
                </div>
            </div>
            {/*<div class="distributions-settings__section">*/}
            {/*    <p class="distributions-settings__title">Analytics</p>*/}
            {/*    <div class="distribution-settings__row">*/}
            {/*        <Input name="slug" value={""} placeholder="Header Code" onChange={() => {}}/>*/}
            {/*    </div>*/}
            {/*    <div class="distribution-settings__row">*/}
            {/*        <Input name="slug" value={""} placeholder="Tracking Code" onChange={() => {}}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
