import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Input from '../../components/input-with-label';
import { CreateButton, SaveButton, CancelButton } from '../../components/buttons';

import { AiFillDelete } from 'react-icons/ai';

import Breadcrumb from '../../components/breadcrumb';
import ProfileImageUpload from "../../components/profile-image-upload";
import dayjs from "dayjs";
import Row from '../../utils/row';

import InputsText from '../../components/inputs/inputs-text';
import InputsSelect from '../../components/inputs/inputs-select';
import LabelContainer from '../../components/label-container';

import CreatableSelect from '../../components/inputs/inputs-creatable';

import CompaniesApi from '../../lib/companies/companies-api';

import { SmallPill } from '../../components/pills';
import Container from '../../containers/container';
import Divider from '../../components/divider/divider';
import List from '../../components/list/list';

import AdminAPI from '../../lib/admin/admin-api';
import { AddButton, ProceedButton, DeleteButton } from '../../components/buttons';

import PopupsCreateGroup from './components/popups/popups-create-group';
import PopupsDeleteGroup from './components/popups/popups-delete-group';
import alertManager from '../../managers/alert/alert-manager';

import ELearningApi from '../../lib/e-learning/e-learning-api';

import PopupContext, { PopupProvider } from '../../context/context-popup';

const Group = ({ group, fetchGroups }) => {
    const [ members, setMembers ] = useState(null);
    const [ memberAdded, setMemberAdded ] = useState(null);
    const [ name, setName ] = useState(group.name);
    const { setPopup } = useContext(PopupContext);

    const addToGroup = (item) => {
        let mappedMembers = members.map(member => member.id);

        mappedMembers.push(item.value);

        AdminAPI.addMemberToGroup(group.id, mappedMembers).then(async () => {
            await getMembers();
            setMemberAdded(Math.random());
        });
    }

    const deleteFromGroup = (member) => {
        let mappedMembers = members.map(member => member.id);

        mappedMembers = mappedMembers.filter(m => m !== member.id);

        AdminAPI.addMemberToGroup(group.id, mappedMembers).then(async () => {
            await getMembers();
            setMemberAdded(Math.random());
        });
    }

    const getMembers = async () => {
        return AdminAPI.getGroupMembers(group.id).then(({ data }) => setMembers(data));
    }

    const fetchUsers = async () => {
        const mappedMembers = members.map(member => member.id);

        return AdminAPI.getUsers(1, 500).then(({ data }) => {
            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(({ value }) => !mappedMembers.includes(value));

            if (!mappedMembers.includes(window.user.id)) {
                options.push({
                    label: window.user.name,
                    value: window.user.id,
                });
            }

            return options;
        })
    }

    const saveGroup = () => {
        AdminAPI.editGroup(group.id, {
            name: name,
        }).then(() => {
            alertManager.success('Successfully saved the group');
        })
    }

    useEffect(() => {
        getMembers();
    }, []);

    if (members === null) return '';

    return (
        <Container style={{
            padding: '30px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            border: '1px solid #929BA8'
        }}>
            <Container.Header style={{
                marginBottom: '30px'
            }}>
                <Row justifyContent='space-between' alignItems="center">
                    <Container.Title color="#354859" fontSize="30px">{ name }</Container.Title>

                    <Row gap='20px'>
                        <SaveButton mobile={true} onClick={saveGroup}/>
                        <DeleteButton mobile={true} onClick={() => setPopup(<PopupsDeleteGroup fetchGroups={fetchGroups} group={group}/>)}/>
                    </Row>
                </Row>
            </Container.Header>

            <Container.Content >
                <List gap="40px">
                    <List gap="20px">
                        <InputsText
                            label="Group Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />

                        <InputsSelect
                            defaultOptions
                            label="Select a member"
                            value={null}
                            key={memberAdded}
                            onChange={addToGroup}
                            loadOptions={fetchUsers}
                        />
                    </List>

                    <LabelContainer label="Selected Users">
                        <List gap="10px">
                            { members.map((member, index) => {
                                return <div key={index} style={{
                                    padding: '15px',
                                    background: '#F5F8FA',
                                    borderRadius: '3px',
                                    border: '1px solid #929BA8',
                                    boxSizing: 'border-box',
                                }}>
                                    <Row justifyContent='space-between' alignItems='center'>
                                        <p style={{ fontSize: '18px' }}>{ member?.name }</p>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: 'rgb(242 209 209)',
                                            cursor: 'pointer',
                                        }} onClick={() => deleteFromGroup(member)}>
                                            <AiFillDelete style={{ color: 'red' }}/>
                                        </div>
                                    </Row>
                                </div>
                            })}
                        </List>
                    </LabelContainer>
                </List>
            </Container.Content>
        </Container>
    )
}

const Tags = () => {
    const [ tags, setTags ] = useState([]);

    const onTagCreate = (item) => {
        new ELearningApi().createTag({
            name: item
        }).then(getTags);
    }

    const onTagDelete = (id) => {
        new ELearningApi().deleteTag(id).then(getTags);
    }

    const getTags = () => {
        new ELearningApi().getTags().then(({ data }) => {
            setTags(data);
        })
    }

    useEffect(() => {
        getTags();
    }, []);

    return (
        <Container>
            <Container.Header style={{
                marginBottom: '20px'
            }}>
                <List gap="20px">
                    <Container.Title color="#354859" fontSize="30px">(Course / Learning Path) Tags</Container.Title>
                </List>
            </Container.Header>
            <Container.Content>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                }}>
                    <LabelContainer label="Tags">
                        <LabelContainer fontSize="12px" label="Tag description">
                            <CreatableSelect
                                defaultOptions
                                value={null}
                                key={tags}
                                onCreateOption={onTagCreate}
                            />
                        </LabelContainer>
                    </LabelContainer>

                    <LabelContainer label="Tag Manager">
                        <LabelContainer fontSize="12px" label="Here is a list of the tags that are currently setup. If you need to remove these, please be aware of the instances that they occur across different features of myEB. These will be automatically removed in all instances and may change access for users.">
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                            }}>
                                { tags.map(({ id, name }, index) => {
                                    return <SmallPill key={index} removable={true} onClick={() => onTagDelete(id)}>{ name }</SmallPill>
                                })}
                            </div>
                        </LabelContainer>
                    </LabelContainer>
                </div>
            </Container.Content>
        </Container>
    )
}

const Groups = () => {
    const [ groups, setGroups ] = useState([]);
    const { setPopup } = useContext(PopupContext);

    const fetchGroups = () => {
        AdminAPI.getTeams().then(({ data }) => {
            setGroups(data);
        });
    }

    useEffect(() => {
       fetchGroups();
    }, []);

    return (
        <Container>
            <Container.Header style={{
                marginBottom: '40px'
            }}>
                <List gap="20px">
                    <Container.Title color="#354859" fontSize="30px">Groups</Container.Title>
                    <Container.Text color="#929BA8" fontSize="12px">User groups can be created to add EB staff to and push updates such as messages and notifications to a specific group of users.</Container.Text>
                    <AddButton onClick={() => setPopup(<PopupsCreateGroup fetchGroups={fetchGroups}/>)}>Create</AddButton>
                </List>
            </Container.Header>

            <Container.Content>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                }}>
                    { groups.map((group, index) => <Group key={index} group={group} fetchGroups={fetchGroups}/>) }
                </div>
            </Container.Content>
        </Container>
    )
}

const EligibilityTags = ({
    onProgrammeCreate,
    programmeValue,
    programmes,
    onProgrammeDelete,
}) => {
    return (
        <Container>
            <Container.Header style={{
                marginBottom: '20px'
            }}>
                <List gap="20px">
                    <Container.Title color="#354859" fontSize="30px">Programmes</Container.Title>
                </List>
            </Container.Header>
            <Container.Content>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                }}>
                    <LabelContainer label="Eligibility Tags">
                        <LabelContainer fontSize="12px" label="Programme tags are used on a business’ account to inform the customer of the programmes that they have access to. This may unlock hidden content that general public users do not have access to (such as exclusive Launchpad eLearning programmes)">
                            <CreatableSelect
                                onCreateOption={onProgrammeCreate}
                                value={programmeValue}
                            />
                        </LabelContainer>
                    </LabelContainer>

                    <LabelContainer label="Tag Manager">
                        <LabelContainer fontSize="12px" label="Here is a list of the tags that are currently setup. If you need to remove these, please be aware of the instances that they occur across different features of myEB. These will be automatically removed in all instances and may change access for users.">
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                            }}>
                                { programmes.map(({ id, name }, index) => {
                                    return <SmallPill key={index} removable={true} onClick={() => onProgrammeDelete(id)}>{ name }</SmallPill>
                                })}
                            </div>
                        </LabelContainer>
                    </LabelContainer>
                </div>
            </Container.Content>
        </Container>
    )
}

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            errors: [],
            crumbs: [{
                name: 'Settings',
                link: '/'
            }],
            popup: null,
            programmes: [],
            programmeValue: '',
        };
    }

    componentDidMount() {
        this.fetchProgrammes();
    }

    onProgrammeCreate(programme) {
        CompaniesApi.addProgramme(programme).then((data) => {
            this.fetchProgrammes();
        });

        this.setState({ programmeValue: '' })
    }

    onProgrammeDelete(id) {
        CompaniesApi.deleteProgramme(id).then(this.fetchProgrammes.bind(this));
    }

    fetchProgrammes() {
        CompaniesApi.fetchProgrammes().then((data) => {
            this.setState({ programmes: data });
        });
    }

    handleChange(evt) {
        this.setState({
            user: Object.assign({}, this.state.user, {
                [evt.target.name]: evt.target.value
            })
        });
    }

    handleUpdate() {
        // Prevents sending any data like password thats not been touched so it doesnt make password empty
        axios.post('/api/users/me', this.state.user).then(() => window.location.reload()).catch(({response}) => {

            if (response) {
                this.setState({errors: response.data.errors});

                if (response.data.errors.hasOwnProperty("password"))  {
                    this.passwordRef.scrollIntoView();
                }

            }
        });
    }

    goBack() {
        const link = document.referrer.split('/')[1] == 'settings' ? '/dashboard' : document.referrer;

        window.location.href = link;
    }

    render() {
        return (
            <Container className="account">
                <Container.Header style={{
                    marginBottom: '20px'
                }}>
                    <Row justifyContent='space-between'>
                        <Breadcrumb crumbs={[{
                            name: 'Settings',
                            link: '/'
                        }]}/>
                        <Row gap="20px">
                            <SaveButton onClick={this.handleUpdate.bind(this)}>Save / Confirm</SaveButton>
                            <CancelButton onClick={this.goBack}>Go Back</CancelButton>
                        </Row>
                    </Row>
                    <Divider/>
                </Container.Header>
                <Container.Content>
                    <List gap="100px">
                        <EligibilityTags
                            programmeValue={this.state.programmeValue}
                            programmes={this.state.programmes}
                            onProgrammeCreate={this.onProgrammeCreate.bind(this)}
                            onProgrammeDelete={this.onProgrammeDelete.bind(this)}
                        />

                        <Tags/>

                        <Groups/>
                    </List>
                </Container.Content>
            </Container>
        )
    }

}

export default Settings;

if (document.getElementById('settings')) {
    ReactDOM.render(
        <PopupProvider>
            <Settings/>
        </PopupProvider>
    , document.getElementById('settings'));
}
