import ReactDOM from "react-dom";
import React, {Component} from 'react';
import dayjs from "dayjs";
import UsersApi from '../lib/users/users-api';
import AdminUsersApi from '../lib/users/admin-users-api';
import MeApi from '../lib/users/me-api';
import Breadcrumb from "../components/breadcrumb";
import AlertManager from '../managers/alert/alert-manager';
import UserRoles from "../components/users/user-roles";

import { DeleteButton, PasswordButton} from '../components/buttons';

class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            authUser: {}
        };
    }

    getUser() {
        UsersApi.get(this.props.user.id).then(user => this.setState({user: user}));
    }

    getAuthUser() {
        MeApi.me().then(authUser => this.setState({authUser}));
    }

    componentDidMount() {
        this.getUser();
        this.getAuthUser();
    }

    resetPassword() {
        if (this.state.user.admin === 1 || this.state.user.super_admin === 1) {

            AdminUsersApi.resetPassword(this.state.user.id).then(() => {

                AlertManager.success('Password Reset Sent');

            });
        }
    }

    render() {
        const {user, authUser} = this.state;
        const img = user.user_profile ? user.user_profile.profile_img : null;
        const profile = user.user_profile || {};
        const superAdmin = authUser.super_admin === 1;

        return (
            <div>
                <div className="website__topbar">
                    <Breadcrumb crumbs={[{
                        name: 'Dashboard',
                        link: '/dashboard'
                    },
                        {
                            name: 'User',
                            link: '#'
                        }]}/>
                    <div className="website-row">
                        {/*<button className="button button--confirm" >Save /*/}
                        {/*    Confirm <img src="/assets/rightArrow.svg"/></button>*/}
                            <PasswordButton onClick={this.resetPassword.bind(this)}>Reset Password</PasswordButton>
                        {
                            superAdmin && <DeleteButton>Close Account</DeleteButton>
                        }
                    </div>
                </div>
                <div className="website__content">
                    <div className="account__user">
                        <img className="account__user-icon"
                             src={img ? `/media/private/${img}` : '/assets/user-icon.svg'}/>
                        <div className="account__column">
                            <h1>{user.name}</h1>
                            <h3>{profile.first_name} {profile.last_name}</h3>
                            <h3>{profile.job_title}</h3>
                            <a href={`mailto:${user.email}`}>{user.email}</a>
                        </div>
                    </div>
                    <div className="account__block">
                        <div className="account__inputs">
                            <div className="account__section">
                                <div className="website-row">

                                </div>
                                <div className="website-row">

                                </div>
                                <div className="website-row">

                                </div>
                                <div className="website-row">

                                </div>
                            </div>
                            <div className="account__section">

                            </div>

                        </div>

                        <div className="account__information">
                            <p style={{marginBottom: "23px"}}>Date Joined:</p>
                            <b>{dayjs(user.created_at).format('DD/MM/YY')}</b>
                            <div style={{height: '43px'}}></div>
                            <p style={{marginBottom: "23px"}}>Admin Role:</p>
                            <b> {user.super_admin === 1 ? 'Super Admin' : 'Admin'} </b>
                            <p style={{marginTop: "43px"}}>Roles & Permissions</p>


                            {this.state.authUser.super_admin === 1 &&
                            <UserRoles userId={this.props.user.id}/>
                            }
                            {
                                this.state.authUser.super_admin !== 1 &&
                                    <>
                                        <div className="account__roles">
                                            {
                                                this.state.user.roles &&
                                                this.state.user.roles.map((role, index) => {
                                                    return (
                                                        <div key={index} className="account-role">
                                                            <h2>{role.name}</h2>
                                                            <p>{role.description}</p>
                                                            <b>{role.permissions.map(p => p.name).join('/')}</b>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </>

                            }


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default User;


let el = document.getElementById('user');

if (el) {
    ReactDOM.render(<User user={JSON.parse(el.getAttribute("data-user"))}/>, el);
}
