import React, {Component} from 'react';
import ImagePopup from '../popups/images/images-popup';
import ImagesPopup from "../popups/images/images-popup";
import Image from "../content-editor/Image";
import Input from "../input-with-label";

class ImageInput extends Component {

    constructor(props) {
        super(props);
        this.state  = {
            popup : false
        };
    }


    render() {
        return (
            <>
                {
                    this.state.popup &&
                    <ImagesPopup
                        onClose={() => this.setState({popup : false})}
                        onResourceSelect={r => {
                            this.setState({popup : false})
                            this.props.onChange({
                                target: {
                                    name: this.props.name,
                                    value: r.resource.file_name
                                }
                            })
                        }}
                        onlyShow={['browse', 'upload']}
                    />
                }

                <Input
                    onSelect={() => this.setState({popup: true})}
                    select={true}
                    {
                        ...this.props
                    }
                />
            </>
        );
    }
}

ImageInput.defaultProps  = {
    onChange :e => console.log(e, 'Image change'),
    value : '',
    name : ''
};

export default ImageInput;
