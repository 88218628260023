export default function ButtonNext({ hide = false, background = false, onClick = () => {}, disabled = false, children }) {
    return (
        <div className={`state-pages__navgiation state-pages__next ${background ? background && disabled ? 'state-pages__navgiation--backgroundDisabled' : 'state-pages__navgiation--background' : ''} 
                                                                    ${disabled && !background ? 'state-pages__navgiation--disabled' : ''} 
                                                                    ${hide ? 'state-pages__navgiation--hidden' : ''}`} 
                                                                    onClick={(hide || disabled )? () => {} : onClick}>

            { children }  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.556" viewBox="0 0 16 15.556">
                <path id="Path_170" data-name="Path 170" d="M16.172,11,10.808,5.636l1.414-1.414L20,12l-7.778,7.778-1.414-1.414L16.172,13H4V11Z" transform="translate(-4 -4.222)" fill="#929ba8"/>
            </svg>


        </div>
    )
}
