import React, {Component} from 'react';
import ListCard from '../cards/list-card';
import {List} from "react-content-loader";
import actionParams from "../../lib/users/actions";

class ActivityList extends Component {
    render() {
        return (
            <div className={`activity-list`}  >
                <div className="activity-list__inner" style={{ height: `${this.props.height ? this.props.height : 'initial'}`}}>
                    {
                        this.props.actions.map((a,i) => {

                            return(
                                <ListCard
                                    key={i}
                                    {
                                        ... a
                                    }
                                />
                            );
                        })
                    }
                </div>

                <div className="activity-list__overflow">

                </div>
            </div>
        );
    }
}

ActivityList.defaultProps = {
    actions : [],
    height : null
};

export default ActivityList;
