export default function ContentContainer(props) {
    const {
        title = '',
        count = null,
        action = null,
        children,
        icon = null,
        top = null,
        header = null,
        bottom = null,
    } = props;

    const hasMultipleChildren = children && Array.isArray(children) && children.length > 1;

    return (
        <div className="content-container">
            <div className="content-container__head">
                <div className="content-container__row">
                    {
                        icon ? icon : <img src="/assets/document-icon.svg"/>
                    }

                    <p>{title}</p>
                    {count && <span>{count}</span>}
                </div>
                {action && action}
            </div>

            { top ? <div className='content-container__top content-container__child'>{ top }</div> : '' }

            { header ? <div className='content-container__header content-container__child'>{ header }</div> : '' }

            { children ? <div className='content-container__content content-container__child'>{
                hasMultipleChildren &&
                children.map((child, index) => {
                    return (
                        <div key={index}
                            className={`content-container__child content-container__child-split`}>
                            {
                                child
                            }
                        </div>
                    )
                })
            }

            {
                !hasMultipleChildren &&
                <div className={'content-container__child'}>{children}</div>

            } </div> : '' }

            { bottom ? <div className='content-container__bottom content-container__child'>{ bottom }</div> : '' }

        </div>
    )
}
