import React, {Component} from 'react';
import Popup from "../../components/popup";
import Input from "../../components/input-with-label";

import { CreateButton, CancelButton } from '../../components/buttons';

import DistributionApi from  '../../lib/distributions/distribution-api';

class NewMenuPopup extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name : '',
            errors : {}
        };
    }


    createNew() {
        DistributionApi.storeMenu(this.props.distribution.id, {name : this.state.name}, errors => this.setState({errors:  errors})).then(data => {
           window.location = `/content/distributions/${this.props.distribution.id}/menus/${data.id}`;
        });
    }

    render() {
        return (
            <Popup type={'new-event'}>
                <div className="popup__inner">
                    <h1>Create a new Menu</h1>
                    <Input
                        placeholder={'Name'}
                        name={'name'}
                        value={this.state.name}
                        onChange={e => this.setState({name : e.target.value})}
                        errors={this.state.errors.name}
                    />

                    <div className="popup__buttons">
                        <CreateButton responsive={false} onClick={this.createNew.bind(this)}/>
                        <CancelButton responsive={false} onClick={this.props.onClose}/>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default NewMenuPopup;
