export default function Sponsors() {
    return (
        <div className="sponsors__outer">
            <p>This grants portal is 
            funded in part by:</p>
            <img src="/assets/sponsors/eu.svg" />
            <img src="/assets/sponsors/dig.svg" />
            <img src="/assets/sponsors/northern.svg" />
        </div>
    )
}