import React, { Component } from 'react'

const PopupContext = React.createContext()

class PopupProvider extends Component {
  // Context state
  state = {
    popup: null,
    overlap: false,
  }

  // Method to update state
  setPopup = (popup, overlap = false) => {
    this.setState((prevState) => ({ popup, overlap }))
  }

  closePopup = () => {
    this.setPopup(null);
  }

  render() {
    const { children } = this.props
    const { popup, overlap } = this.state
    const { setPopup, closePopup } = this

    return (
      <PopupContext.Provider
        value={{
          popup,
          setPopup,
          closePopup,
          overlap,
        }}
      >
        { overlap === false && popup && popup }
        { children }
      </PopupContext.Provider>
    )
  }
}

export default PopupContext

export { PopupProvider }