import { useRef, useEffect } from 'react';

import Auth from '../lib/users/auth';


export default ({ close, user }) => {
    const ref = useRef(null);

    useEffect(() => {
        window.onclick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) close();
        }
    }, []);

    return (
        <div class="user-dropdown" ref={ref}>
            <div class="user-dropdown__item">
                <h3>{user.name}</h3>
                <p>{ user.admin === 1 ? 'Admin' : 'Super Admin'}</p>
            </div>
            <a href="/account" class="user-dropdown__item">
                My Account
                <img src="/assets/userDropdownArrow.svg"/>
            </a>
            <a href="/myteam" class="user-dropdown__item">
                My Team
                <img src="/assets/userDropdownArrow.svg"/>
            </a>
            {
                Auth.isSuperAdmin() &&
                <a href="/settings" className="user-dropdown__item">
                    Settings
                    <img src="/assets/userDropdownArrow.svg"/>
                </a>
            }

            <div class="user-dropdown__item">
                <a href="/log-out">Logout</a>
                <img src="/assets/userDropdownArrow.svg"/>
            </div>
        </div>
    )
}
