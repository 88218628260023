import { useContext } from 'react';

import PopupContext from '../../../../../context/context-popup';

import Popup from '../../../../../components/popup';

import { DeleteButton, CancelButton } from '../../../../../components/buttons';

import ELearningAPI from '../../../../../lib/e-learning/e-learning-api';

export default function CourseDelete({ id, onSuccess }) {
    const { setPopup } = useContext(PopupContext);

    const clicked = () => {
        new ELearningAPI().deleteCourse(id).then(onSuccess);
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Are you sure?</h1>

                <div class="popup__buttons">
                    <DeleteButton responsive={false} onClick={clicked}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
