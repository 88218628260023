import {Component, useContext} from 'react';
import ReactDOM from 'react-dom';

import MeApi from "../../lib/users/me-api";
import AlertManager from "../../managers/alert/alert-manager";
import Row from '../../utils/row';
import CreateButton from '../../components/buttons/create-button';
import Divider from '../../components/divider/divider';
import List from '../../components/list/list';

import Container from '../../containers/container';
import ContainerTable from '../../containers/container-table';
import ButtonNext from '../../components/buttons-navigation/button-next';
import {stringToFriendlyDatetime} from '../../lib/helpers/datetime-helpers';
import ContainerIndex from '../../containers/container-index';

import PageLayout from "../../containers/layout/page-layout";
import {MinMaxLayout} from "../../containers/layout";

import PopupContext, {PopupProvider} from '../../context/context-popup';

import TasksApi from '../../lib/tasks';

import ClipLoader from "react-spinners/ClipLoader";

import {TiTick} from 'react-icons/ti';
import TasksView from './popups/tasks-view';
import InputsDropdown from '../../components/inputs/inputs-dropdown';
import TasksContext from '../../context/context-tasks';

import {useTasksStore} from '../../context/context-tasks';

Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};

const Wrapper = () => {
    const popupContext = useContext(PopupContext);
    const tasksContext = useTasksStore();

    return <Tasks popupContext={popupContext} tasksContext={tasksContext}/>
}

import SearchResultsError from '../../components/content-search/search-results-error';
import Auth from "../../lib/users/auth";

export default class Tasks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            tasks: [],
            currentPage: 1,
            meta: {},
            loading: [],
            activeTask: null,
            sort: {
                label: 'Sort by created at',
                value: 'created_at'
            },
            direction: {
                label: 'Sort by Descending',
                value: 'desc'
            },
            completed: {
                label: 'Incomplete',
                value: 0
            },
            deleting: []
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        const params = {
            order_by: this.state.sort?.value,
            order_direction: this.state.direction?.value,
        }

        if (this.state.completed !== null) {
            params.completed = this.state.completed?.value;
        }

        return new Promise((resolve, reject) => {
            MeApi.myTasks(params).then(({data, meta}) => {
                let tasks = data;

                // if (this.state.deleting.length > 0) {
                //     this.state.deleting.forEach(item => {
                //         tasks.insert(item.index, item.task);
                //     });
                // }

                this.setState({tasks, meta}, resolve())
            });
        });
    }

    addToLoading(id) {
        this.setState({
            loading: {
                ...this.state.loading,
                [id]: true,
            }
        });
    }

    removeFromLoading(id) {
        const loading = {...this.state.loading}

        delete loading[id];

        this.setState({loading});
    }

    isLoading(id) {
        return this.state.loading[id];
    }

    addToDeleting(id) {
        const deleting = [...this.state.deleting]

        const task = {
            task: this.state.tasks.find(t => t.id === id),
            index: this.state.tasks.findIndex(t => t.id === id)
        }

        deleting.push(task);

        this.setState({deleting});
    }

    completeTask(id) {
        this.addToLoading(id);

        TasksApi.completeTask(id).then(() => {
            this.removeFromLoading(id);
            this.setCompletedTask(id);
            this.addToDeleting(id);

            this.fetch().then(() => {
                this.props.popupContext.closePopup();
            });

            setTimeout(() => {
                this.props.tasksContext.refreshTasks();

                if (this.state.completed !== null) {
                    this.removeTaskFromState(id);
                }
            }, 2000);
        });
    }

    setCompletedTask(id) {
        let tasks = [...this.state.tasks]

        let task = tasks.find(t => t.id === id);
        task.completed = true;

        this.setState({tasks})
    }

    removeTaskFromState(id) {
        let tasks = [...this.state.tasks]

        tasks = tasks.filter(t => t.id !== id);

        this.setState({tasks})
    }

    showTask(data) {
        const {setPopup, closePopup} = this.props.popupContext;

        this.setState({activeTask: data.id})

        setPopup(<TasksView task={data} onComplete={(task) => this.completeTask(task)} onClose={() => {
            this.setState({activeTask: null});
            closePopup();
        }}/>, true);
    }

    renderCompletion(data, completed) {
        if (this.isLoading(data.id)) {
            return <ClipLoader
                color={'white'}
                loading={true}
                size={30}
            />
        }

        if (completed === false) {
            return (
                <div style={{
                    width: '30px',
                    height: '30px',
                    background: 'white',
                    borderRadius: '50%',
                    cursor: 'pointer'
                }} onClick={() => {
                    if (completed === false) this.completeTask(data.id);
                }}></div>
            )
        }

        return <TiTick style={{fontSize: '30px', color: 'white'}}/>;
    }

    getRelationUrl(task) {
        const split = task.related_to?.split(`\\`);
        const relation = split !== undefined ? split[split?.length - 1] : '';

        if (task.related === null) return null;

        switch (relation) {
            case 'Conversation':
                return '/contacts/messages?id=' + task.related.id;
        }

        return null;
    }

    renderTable() {
        const map = {
            0: "You have completed all your tasks. To view completed tasks, toggle the sort field to 'completed'",
            1: "There is no completed tasks. To view incompleted tasks, toggle the sort field to 'incompleted'",
            null: "There is no tasks here, try refine your search by choosing another sort field"
        }

        if (this.state.tasks?.length === 0) return <SearchResultsError
            message='You have no tasks'
            body={<>
                <b>{map[this.state.completed?.value || null]}</b>
            </>}
        />

        let tasks = [];

        this.state?.tasks?.forEach((task, index) => {
            tasks.push([
                task,
                task.name,
                task.description,
                task,
                stringToFriendlyDatetime(task.created_at),
                this.getRelationUrl(task),
                task.admin_task_notes.length,
                task.id,
            ])
        })


        return (
            <List gap='40px'>
                <ContainerTable
                    className="dashboard-progress"
                    columns={[
                        {field: 'COMPLETED', width: '10%'},
                        {field: 'NAME', width: '20%'},
                        {field: 'DESCRIPTION', width: '20%'},
                        {field: 'ASSIGNED TO', width: '20%'},
                        {field: 'CREATED', width: '20%'},
                        {field: 'RELATION', width: '15%'},
                        {field: 'NOTES', width: '5%'},
                        {field: '', width: '10%'}
                    ]}
                    data={
                        [
                            ...tasks
                        ]
                    }
                    style={{
                        backgroundColor: 'transparent',
                        padding: '0px',
                    }}
                    rowStyle={{
                        backgroundColor: '#FCF7FF',
                    }}
                    replaceColumn={{
                        0: (data) => {

                            const completed = data.completed_at !== null;
                            return (
                                <div style={{
                                    backgroundColor: completed === true ? '#00C8C3' : '#ff3556',
                                    width: '100%',
                                    height: '70px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '10px 0px 0px 10px'
                                }}>
                                    {this.renderCompletion(data, completed)}
                                </div>
                            )
                        },
                        3: (data) => {
                            const completed = data.completed_at !== null;

                            return <div>
                                {
                                    data.assigned_to &&
                                    data.assigned_to.name
                                }
                                {
                                    (completed === false && Auth.user.id !== data.assigned_to?.id) &&
                                    <>
                                        &nbsp;<a href={'#'} onClick={async e => {
                                        e.preventDefault();
                                        await MeApi.takeTask(data.id);
                                        this.fetch();

                                    }}>Take task</a>
                                    </>
                                }

                            </div>;
                        },
                        5: (data, row) => {
                            return data !== null ? <a href={data}>View Conversation</a> : '';
                        },
                        7: (data) => {
                            const task = this.state.tasks.find(t => t.id === data);

                            return (
                                <Row style={{
                                    width: '100%',
                                    paddingRight: '25px'
                                }} justifyContent='flex-end'>
                                    <ButtonNext onClick={() => this.showTask(task)}>View</ButtonNext>
                                </Row>
                            )
                        }
                    }}
                />
                <ContainerIndex.Pagination
                    currentPage={this.state.currentPage}
                    maxPages={this.state.meta?.last_page}
                    onPrevious={() => this.setState({currentPage: this.state.currentPage - 1}, () => this.fetch())}
                    onNext={() => this.setState({currentPage: this.state.currentPage + 1}, () => this.fetch())}
                />
            </List>
        )
    }

    renderContent() {
        return (
            <>
                <Container>
                    <Container.Header>
                        <Row justifyContent='space-between' alignItems='center'>
                            <Container.Title color="#354859" fontSize="40px">Tasks</Container.Title>
                        </Row>
                        <Divider/>
                        <Row gap="20px">
                            <InputsDropdown
                                label="Completed / Incomplete"
                                defaultSelected={true}
                                reset
                                resetText="All"
                                items={
                                    [
                                        {
                                            label: 'Sort by Completed',
                                            value: 1
                                        },
                                        {
                                            label: 'Sort by Incomplete',
                                            value: 0
                                        }
                                    ]
                                }
                                value={this.state.completed}
                                onChange={val => this.setState({completed: val}, () => this.fetch())}
                                onReset={() => this.setState({completed: null})}
                            />
                            <InputsDropdown
                                label="Sort"
                                items={
                                    [
                                        {
                                            label: 'Sort by updated at',
                                            value: 'updated_at'
                                        },
                                        {
                                            label: 'Sort by created at',
                                            value: 'created_at'
                                        }
                                    ]
                                }
                                value={this.state.sort}
                                onChange={val => this.setState({sort: val}, () => this.fetch())}
                            />
                            <InputsDropdown
                                label="Direction"
                                items={
                                    [
                                        {
                                            label: 'Sort by Ascending',
                                            value: 'asc'
                                        },
                                        {
                                            label: 'Sort by Descending',
                                            value: 'desc'
                                        },
                                    ]
                                }
                                value={this.state.direction}
                                onChange={val => this.setState({direction: val}, () => this.fetch())}
                            />
                        </Row>
                        <Divider/>
                    </Container.Header>
                    <Container.Content>
                        {this.renderTable()}
                    </Container.Content>
                </Container>
            </>
        )
    }

    render() {
        const {popup, overlap} = this.props.popupContext;

        return (
            <PageLayout>
                {overlap === true && popup}

                <MinMaxLayout main={this.renderContent()}/>
            </PageLayout>
        );
    }

}

const el = document.getElementById('tasks');

if (el) {
    ReactDOM.render(<>
        <PageLayout>
            <Wrapper/>
        </PageLayout>
    </>, el);
}
