import { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import ContentApi from '../lib/content/content-api';

import AlertManager from '../managers/alert/alert-manager';
import AlertTypes from '../managers/alert/alert-types';

import Input from '../components/input-with-label';

import Popup from './popup';

import { CreateButton, CancelButton } from '../components/buttons';

export default ({ setPopup, contentId, onSuccess }) => {
    const [ versions, setVersions ] = useState([]);

    const [ state, setState ] = useState({
        versionId: null,
        name: '',
    });

    const [ errors, setErrors ] = useState({});

    const confirm = async () => {

        let version = null;


        if(state.versionId !== null) {
            version = await ContentApi.getVersion(contentId, state.versionId);
        }

        try {
            let response = await ContentApi.createNewVersion(contentId, {
                name: state.name ? state.name : '',
                body: version ? version.body : {
                    sections: []
                },
            });

            onSuccess(response.data.data.id);
            AlertManager.addAlert({ type: AlertTypes.SUCCESS, message: "Successfully created a new version" });

        } catch(error) {
            const err = error.response.data.errors;
            const message = err.hasOwnProperty("errors") ? err.errors[0][0].message : err.body[0];

            if (message == 'Version name must unique for this content' ) {
                return setErrors({ name: [ message ] });
            }

            return AlertManager.addAlert({ type: AlertTypes.ERROR, message: 'We could not create your version' });
        }
    }

    useEffect(async () => {
        const versions = await ContentApi.getContentVersions(contentId);

        let options = versions.map(({ id, name }) => {
            if (name != null) return {
                id: id,
                value: id,
                label: name,
            };

            return {
                id: id,
                value: id,
                label: id,
            }
        });

        setVersions(options);
    }, []);

    const onChange = (e) => {

        setState(prev => ({
            ...prev,
            versionId: e.value,
        }));
    }

    const onName = (e) => {
        setState(prev => ({
            ...prev,
            name: e.target.value,
        }));
    }

    return (
        <Popup type="new-version">
            <div class="popup__inner">
                <h1>Create a new version of “Content Name”</h1>

                <div class="input-outer">
                    <label for="email">Choose existing version to work from</label>
                    <Dropdown className="input input--idle" options={versions} onChange={onChange} value={""} placeholder="Select an option"/>
                </div>

                <Input name="name" value={state.name} placeholder="Please name your version" onChange={onName} errors={errors.name}/>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={confirm}/>
                    <CancelButton responsive={false} onClick={() => setPopup(false)}/>
                </div>
            </div>
        </Popup>
    )
}
