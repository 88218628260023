import { useState, useContext } from 'react';

import Input from '../../../../../components/inputs/inputs-text';
import Checkbox from '../../../../../components/checkbox';

import Popup from '../../../../../components/popup';

import Row from '../../../../../utils/row';

import { CreateButton, CancelButton } from '../../../../../components/buttons';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';
import LabelContainer from '../../../../../components/label-container';

import AlertManager from '../../../../../managers/alert/alert-manager';

import PopupContext from '../../../../../context/context-popup';

export default function CourseModuleAdd({ course, onSuccess }) {
    const { setPopup } = useContext(PopupContext);
    
    const [ type, setType ] = useState(null);

    const [ errors, setErrors ] = useState({});

    const create = () => {
        if (type === null) {
            return setErrors({
                type: ['Needs to have a type']
            })
        }

       onSuccess(type);
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Add a new Module to Course</h1>
                <p>Please select the type of module that you would like to add:</p>

                <LabelContainer label="Information modules will allow you to add content types and forms to engage with the learner and teach them about the topics on this course.">
                    <Checkbox square={false} text="Information" name="information" checked={type === 'information'} onChange={e => setType(e.target.name)}/>
                </LabelContainer>

                <LabelContainer label="Assessment modules will allow the learner to test knowledge and either be dynamically or manually scored on their answers to the questions set out. This type of module can enable you to branch the learners path to different modules if required.">
                    <Row>
                        <Checkbox square={false} text="Dynamic Assessment" name="dynamic" checked={type === 'dynamic'} onChange={e => setType(e.target.name)}/>
                        <Checkbox square={false} text="Manual Assessment" name="manual" checked={type === 'manual'} onChange={e => setType(e.target.name)}/>
                    </Row>
                </LabelContainer>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
