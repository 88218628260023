import { Component } from 'react';

import SidePopupSection from '../../../components/side-popup-section';
import TextArea from '../../../components/textarea-with-label';
import Dropdown from '../../../components/dropdowns/dropdown';
import { SmallPill } from '../../../components/pills';

import CompaniesApi from '../../../lib/companies/companies-api';

export default class GrantModifyEligibility extends Component {
    constructor(props) {
        super(props);

        this.state = {
            industries: [],
            industriesSelected: []
        }
    }

    async componentDidMount() {
        const { data } = await CompaniesApi.getIndustries(1, 10000);

        const excluded = this.props.grant.meta?.excluded_company_industries || [];
        const industriesSelected = excluded.map(id => {
            return {
                label: data.find(i => i.id == id).name,
                value: id,
            }
        })

        this.setState({ industries: data, industriesSelected })
    }

    industryAdd(item) {
        const industriesSelected = [ ...this.state.industriesSelected ]
        const industry = industriesSelected.find(i => i.value == item.value);

        if (industry) return;

        industriesSelected.push(item);

        this.setState({ industriesSelected });
        this.props.setExcludedIndustries(industriesSelected.map(i => i.value));
    }

    removeIndustry(ind) {
        let industriesSelected = [ ...this.state.industriesSelected ]
        const industry = industriesSelected.find(i => i.value == ind.value);

        if (industry) {
            industriesSelected = industriesSelected.filter(i => i.value !== ind.value);
        }

        this.setState({ industriesSelected });
        this.props.setExcludedIndustries(industriesSelected.map(i => i.value));
    }

    render() {
        return (
            <SidePopupSection title="Eligibility">
                <Dropdown items={this.state.industries.map(i => ({ label: i.name, value: i.id }))} onChange={this.industryAdd.bind(this)}/>

                <div className="utils__row utils__gap--20 utils__wrap">
                    { this.state.industriesSelected.map((industry, index) => {
                        return <SmallPill key={index} onClick={() => this.removeIndustry(industry)} removable={true}>{ industry.label }</SmallPill>
                    })}
                </div>

                <TextArea name="eligibility_notes"
                            label="Guidance notes for applicants"
                            placeholder=""
                            value={this.props.getMetaValue('eligibility_notes')} onChange={this.props.onChange} />
            </SidePopupSection>
        )
    }
}
