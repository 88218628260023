export default ({ onClick, img }) => {
    return (
        <div class="topbar-responsive">
            <div class="topbar-responsive__inner">
                <img class="topbar-responsive__icon" src="/assets/hamburger.svg" id="sidebar-icon"/>
                {/*<img class="topbar-responsive__icon" src="/assets/search-icon.svg" id="search-icon"/>*/}
            </div>
            <img className="topbar__logo" src="/assets/logo.png" alt="EB Logo"/>
            <div class="topbar-responsive__inner">
                <img class="topbar-responsive__icon" src={img ? img : '/assets/user-icon.svg'}/>
                <div class="topbar-responsive__icon topbar-menu__icon" onClick={onClick}>
                    <img src="/assets/downArrow.svg"/>
                </div>
            </div>
        </div>
    )
}
