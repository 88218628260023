import { Component } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { ViewButton, SaveButton } from '../../../../../components/buttons';

import Row from '../../../../../utils/row';

import LabelContainer from '../../../../../components/label-container';

import ResultsList from '../../../../../components/results/results-list';
import ResultsListItem from '../../../../../components/results/results-list-item';
import ResultsListItemMeta from '../../../../../components/results/results-list-item-meta';
import ResultListItemDetails from '../../../../../components/results/results-list-item-details';
import NavButton from '../../../../../components/nav-button/nav-button';
import List from '../../../../../components/list/list';

import { stringToFriendlyDatetime } from '../../../../../lib/helpers/datetime-helpers';

import PopupContext from '../../../../../context/context-popup';

export default class PopupsLearnerView extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>{ this.props.learner.name }</p>
                </div>
            </div>
        );
    }

    render() {
        const { learner, courses = [] } = this.props;

        console.log(courses)

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <List gap="20px">
                    <SidePopupSection>
                        <Row>
                            <LabelContainer label="Last Activity" fill={true}>
                                <p>{ stringToFriendlyDatetime(learner?.updated_at) }</p>
                            </LabelContainer>
                        </Row>
                    </SidePopupSection>

                    <List gap="20px">
                        { learner.course_works?.map((courseWork, index) => {
                            const course = courses?.find(course => course.id === courseWork.course_id);

                            const status = () => {
                                if (courseWork.passed === null) return <p style={{ color: '#FF7612' }}>Awaiting Review</p>
                                if (courseWork.passed === 1) return <p style={{ color: '#00C8C3' }}>Completed</p>
                                if (courseWork.passed === 0) return <p style={{ color: '#ED2855' }}>Failed</p>
                            }

                            return (
                                <ResultsListItem
                                    key={index}
                                    icon={<img src="/assets/e-learning-course-icon.svg"/>}
                                    details={<ResultListItemDetails title={course?.name || ''} subtitle={status()}/>}
                                    metaTwo={<ResultsListItemMeta data={['']}/>}
                                    metaOne={<ResultsListItemMeta data={['Estimated Time: ' + (course?.overview?.est_time || 'Not Set')]} />}
                                    actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/course-works/${courseWork.id}`}/>}/>
                            )
                        })}
                    </List>
                </List>
            }/>
        )
    }
}
