import { useState, useContext } from 'react';

import Input from '../../../../components/inputs/inputs-text';
import InputsTextArea from '../../../../components/inputs/inputs-text-area';

import Popup from '../../../../components/popup';

import { CreateButton, CancelButton } from '../../../../components/buttons';

import ELearningAPI from '../../../../lib/e-learning/e-learning-api';

import AdminAPI from '../../../../lib/admin/admin-api';
import AlertManager from '../../../../managers/alert/alert-manager';

import PopupContext from '../../../../context/context-popup';

export default function PopupsCreateGroup({ fetchGroups }) {
    const [ name, setName ] = useState(null);
    const [ description, setDescription ] = useState(null);
    const { setPopup } = useContext(PopupContext);

    const [ errors, setErrors ] = useState({});

    const create = () => {
        AdminAPI.createGroup(name, (err) => {
            setErrors(err);
        }).then(({ id }) => {
            AlertManager.success('Successfully created a group');

            fetchGroups();
            setPopup(null);
        });
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Add a new group</h1>

                <Input label="Group Name" placeholder="Enter a name" errors={errors?.name} value={name} onChange={(e) => setName(e.target.value)}/>

                <div class="popup__buttons">
                    <CreateButton responsive={false} onClick={create}/>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
