import React, {Component} from 'react';
import Dropdown from "react-dropdown";
import SmallButton from "./buttons/small-button";

class Pagination extends Component {

    getOptions() {
        if(!this.props.pagination) {
            return;
        }
        const items = this.props.pagination.links.filter((_, index) => index > 0 && index < this.props.pagination.links.length - 1);
        return items.map((item) => ({
            value: item.label,
            label: item.label,
            active: item.active,
        }));
    }

    render() {
        const meta = this.props.pagination;
        const {onChange} = this.props;
        return (
            <div className="pagination">
                <SmallButton disabled={meta.current_page == 1} className={meta.current_page == 1 ? 'button--idle' : 'button--alternate'} onClick={() => onChange(meta.current_page - 1)}>Previous Page</SmallButton>

                <Dropdown className="input input--idle" options={this.getOptions()} onChange={onChange} value={String(meta.current_page)}/>

                <SmallButton disabled={meta.current_page == meta.last_page}
                        className={meta.current_page == meta.last_page ? 'button--idle' : 'button--alternate'}
                        onClick={() => onChange(meta.current_page + 1)}>Next Page
                </SmallButton>
            </div>
        );
    }
}

Pagination.defaultProps = {
    pagination: {},
    onChange: page => console.log(page, 'Pagination Change'),
};

export default Pagination;
