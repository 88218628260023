
import ResultListItemDetails from '../../../components/results/results-list-item-details';

import React from "react";
import ApplicationApi from "../../../lib/applications/application-api";
import {stringToFriendlyDatetime} from "../../../lib/helpers/datetime-helpers";

function ProgressItem({accepted, title, subtitle}) {
    return (
        <div className={`progress-item ${accepted ? 'progress-item--toggled' : ''}`}>
            <img src="/assets/document-icon.svg"/>
            <div className="progress-item__text">
                <p className="progress-item-text__title">{title}</p>
                <p className="progress-item-text__subtitle">{subtitle}</p>
            </div>
        </div>
    )
}

export default class GrantApplicationProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.ref = null;

        this.api = new ApplicationApi();
    }


    clicked() {
        this.props.setPopup(null);
    }


    componentDidMount() {
        this.getHistory();
    }

    getHistory() {
        this.api.applicationHistory(this.props.application.id, 1, 500).then(data => {
            this.setState({
                items : data.data
            });
        });
    }

    render() {
        const { application } = this.props;
        return (
            <div className="side-popup__outer">
                <div className="progress-popup side-popup" ref={r=> this.ref = r}>
                    <div className="utils__row utils__rowSpaceBetween">
                        <h1>Progress Checker</h1>
                        <span className="side-popup__close" onClick={this.clicked.bind(this)}>
                       <img src="/assets/close.svg"/>
                   </span>
                    </div>
                    <div className="progress__content">
                        <div className="progress__head">
                            <img src="/assets/document-icon.svg"/>
                            <p>{ application.name !== null ? "#" + application.name.toUpperCase() : 'No Name' }</p>
                        </div>
                        <p className="progress__title">Event History</p>
                        <div className="progress__inner">
                            {this.state.items.map((item, index) => {

                                return <ProgressItem key={index} {...{ title : item.message, subtitle : `${stringToFriendlyDatetime(item.created_at)} - ${item.created_by.name}`}} />
                            })}
                        </div>
                    </div>
                </div>
            </div>);
    }


}
