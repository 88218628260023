import create from 'zustand';
import MeApi from '../lib/users/me-api';

const fetch = async (set) => {
    try {
      const { meta } = await MeApi.myTasks({ completed: 0 });
      set({ tasks: meta?.total });
    } catch (error) {
      console.error('Error refreshing tasks:', error);
    }
}

export const useTasksStore = create((set) => ({
  tasks: 0,
  refreshTasks: () => fetch(set),
}));

MeApi.myTasks({ completed: 0 }).then(({ meta }) => useTasksStore.setState({ tasks: meta.total }));