const SLUG_REGEX =  /[^a-zA-Z0-9-]+/g;


export function slugFormatter(string) {
    return string.replace(" ", "-").replace(SLUG_REGEX, "").toLowerCase();
}


export function sanatizeString(string) {
    if(string === null) {
        return string;
    }


    if(typeof  string !== 'string') {
        return string;
    }


    return string.replace( /(<([^>]+)>)/ig, '');
}
