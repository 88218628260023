import ApiBase from "../api-base";

 class DistributionApi extends ApiBase {

    async getDistributions() {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/content/distributions`);
            return response.data.data;
        });
    }


     async get(id) {
         return this.handleResponse(async () => {
             let response = await axios.get(`/api/content/distributions/${id}`);
             return response.data.data;
         });
     }

     async update(id, data, validationCallback = this.defaultValidationHandler) {
         return this.handleResponse(async () => {
             let response = await axios.post(`/api/content/distributions/${id}`, data);
             return response.data.data;
         }, validationCallback);
     }

     async getMenus(distributionId, page = 1, perPage = 20) {
         return this.handleResponse(async () => {
             let response = await axios.get(`/api/content/distributions/${distributionId}/menus`, {
                 params : {
                     page: page,
                     per_page : perPage
                 }
             });
             return response.data;
         });
     }

     async storeMenu(distributionId, data, validationCallback = this.defaultValidationHandler) {
         return this.handleResponse(async () => {
             let response = await axios.post(`/api/content/distributions/${distributionId}/menus`, data);
             return response.data.data;
         }, validationCallback);
     }

     async getMenu(distributionId, id) {
         return this.handleResponse(async () => {
             let response = await axios.get(`/api/content/distributions/${distributionId}/menus/${id}`);
             return response.data.data;
         });
     }

     async updateMenu(distributionId,id,  data, validationCallback = this.defaultValidationHandler) {
         return this.handleResponse(async () => {
             let response = await axios.post(`/api/content/distributions/${distributionId}/menus/${id}`, data);
             return response.data.data;
         }, validationCallback);
     }

     async deleteMenu(distributionId,id) {
         return this.handleResponse(async () => {
             let response = await axios.delete(`/api/content/distributions/${distributionId}/menus/${id}`);
             return response.data.data;
         });
     }



 }



export default new DistributionApi();
