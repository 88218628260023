import {Component} from 'react';

import CodeMirror from '@uiw/react-codemirror';
import {html} from '@codemirror/lang-html';

export default ({distribution, onChange, errors}) => {


    const handleChange = (value) => {
        onChange('settings', Object.assign({}, distribution.settings, {
            header_code : value
        }))
    }

    const getData = () => {
        return distribution.settings ? distribution.settings.header_code : ''
    }


    return (
        <div className="distributions-settings">
            <div className="distributions-settings__section">
                <div className="distribution-settings__row">
                    <p>This code will be inserted into the header of your distribution if applicable. This can include Google Analytics and other tracking code.</p>
                </div>
            </div>
            <div className="distributions-settings__section">
                <div className="distribution-settings__row">
                    <div className="input-outer">
                        <CodeMirror
                            value={getData()}
                            height="500px"
                            width={'100%'}
                            extensions={[html()]}
                            onChange={handleChange}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}
