import { useEffect, useRef } from 'react';

import QuestionType from "../../../../../../components/question/question-type-container";

import { Editor } from '@tinymce/tinymce-react';

export default function QuestionTypeRichText({ 
    question,
    index,
    onQuestionChange,
}) {
    const editorRef = useRef(null);

    return (
        <QuestionType title={question.title} type={question.type}>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                value={question?.body}
                onEditorChange={(value, editor) => {
                    onQuestionChange({
                        target: {
                            name: 'body', 
                            value: value
                        }
                    })
                }}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }
                }
            />
        </QuestionType>
    )
}