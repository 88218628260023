
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import ForgotPassword from '../../js/components/forgot-password';

const Forgot = () => {
    const [ popup, setPopup ] = useState(false);

    return (
        <>
            { popup && <ForgotPassword setPopup={setPopup}/> }
            <a onClick={() => setPopup(true)} href="#">Forgot your password?</a>
        </>
    )
}

export default Forgot;

if (document.getElementById('forgot-password__container')) {
    ReactDOM.render(<Forgot />, document.getElementById('forgot-password__container'));
}