export default function SearchResults({ results, value }) {
    const getLink = (item) => {
        switch (item.type) {
            case 'company': 
                return `/companies/${item.type_id}`
            case 'user':
                return `/users/${item.type_id}`
            default:
                return null;
        }
    }

    const onClick = () => {
        const link = getLink(result);
        
        if(link) window.location = link;
    }

    return (
        <div className="search-bar__results">
            <div className="search-bar__results">
                <div className="search-bar__results-head">
                    <p>{ results.length } results found</p>
                </div>
                <div className="search-bar__results-body">
                    {
                        results.map((result, index) => {
                            return (
                                <div key={index} className="search-result" onClick={onClick}><p>{ result.title }</p><b>{ result.type }</b></div>
                            );
                        })

                    }
                </div>
                {
                    results.length >= 5 &&
                    <div className="search-bar__results-bottom"
                        onClick={() => window.location.href = "/search-results?term=" + value}>View all {results.length} results
                    </div>
                }
            </div>
        </div>
    )
}