import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {getIcon } from '../icons/default-icons';
import NavButton from '../components/nav-button/nav-button';
import Auth from '../lib/users/auth';

const MENUS = [
    {
        icon : 'home',
        label : 'Home',
        url : '/dashboard'
    },
    {
        label : 'Content',
        icon : 'content',
        children : [
            {
                label : 'Search',
                url : '/content/search',
                wild: false,
            },
            {
                label : 'Distributions',
                url : '/content/distributions',
                wild :true
            },
            {
                label : 'Content Types',
                url : '/content-types',
                wild :true
            }
        ]
    },
    {
        label : 'Contacts',
        icon : 'contacts',
        children : [
            {
                label : 'View All',
                url : '/contacts',
                wild: false,
                icon: 'search'
            },
            {
                label : 'Messages',
                url : '/contacts/messages',
                wild: false,
                icon: 'messages'
            }
        ]
    },
    {
        icon : 'events',
        label : 'Events',
        url : '/events'
    },
    {
        label : 'Grants',
        icon : 'grants',
        permissions_required:  ['grant_view', 'application_view'],
        children : [
            {
                label : 'Grants Dashboard',
                url : '/grants',
                wild: false,
                permissions_required:  'grant_view',

            },
            {
                label : 'Applications',
                url : '/grants/applications',
                wild :true,
                permissions_required:  'application_view',

            }
        ]
    },
    {
        label : 'E-Learning',
        icon : 'e-learning',
        permissions_required:  ['e_learning_view'],
        children : [
            {
                label : 'eLearning',
                icon : 'e-learning',
                url : '/e-learning/',
                wild: false,
            },
            {
                label : 'Learning Paths',
                icon : 'learning-paths',
                url : '/e-learning/learning-paths',
                wild: false,
            },
            {
                label : 'Learners',
                icon : 'learners',
                url : '/e-learning/learners',
                wild: false,
            },
            {
                label : 'Courses',
                icon : 'courses',
                url : '/e-learning/courses',
                wild: false,
            },
            {
                label : 'Course Works',
                icon : 'course-works',
                url : '/e-learning/course-works',
                wild: false,
            },
        ]
    }
];

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state  = {
            sub : false,
            currentMenu : null,
            currentUrl : '/',
            content : {
                title : '',
                date : ''
            }
        };


    }


    componentDidMount() {
        this.setState({
            currentUrl : window.location.pathname
        }, () => this.setIsActiveFromUrl());
    }

    urlMatch(url, wild = false) {
        if(wild) {
           return this.state.currentUrl.includes(url);
        }
        return url === this.state.currentUrl;
    }

    setIsActiveFromUrl() {


        for (let i = 0; i < MENUS.length; i ++) {
           const parent =  MENUS[i];
            if(this.urlMatch(parent.url)) {
                return this.setState({ currentMenu : parent});
            }

            if(parent.hasOwnProperty('children')) {
                for (let c = 0; c < parent.children.length; c ++) {

                    const child = parent.children[c];

                    if(this.urlMatch(child.url, child.wild)) {
                        return this.setState({currentMenu : parent, sub: true});
                    }
                }
            }

        }


    }

    handleItemClick(item) {
        const hasChildren = item.hasOwnProperty('children');

        if(hasChildren) {
           return  this.setState({sub : true, currentMenu : item});
        }

        window.location = item.url;
    }

    isActive(item) {
        if(this.state.currentMenu){
            if(item.label === this.state.currentMenu.label) {
                return true;
            }

        }

        return this.urlMatch(item.url, item.wild);
    }


    renderItem(item, index) {
        const hasChildren = item.hasOwnProperty('children');
        const icon =   getIcon(item.icon) ;
        return(
            <div key={index} onClick={() => this.handleItemClick(item)} className={`sidebar-menu__item ${hasChildren ? 'sidebar-menu__item--with-children' : ''} ${this.isActive(item) ? 'active' : ''}`}>

                <div className="sidebar-menu__icon">{ icon }</div>
                <div className="sidebar-menu__text">{ item.label }</div>
                {
                    hasChildren &&
                   <div className={'sidebar-menu__chevron'}> <svg xmlns="http://www.w3.org/2000/svg" width="6.792" height="11.412" viewBox="0 0 6.792 11.412" >
                   <g id="Button_-_Arrow" data-name="Button - Arrow" transform="translate(0.706 0.706)">
                       <path id="Path_2" data-name="Path 2" d="M11.5,45.5l-5,5.586L1.5,45.5" transform="translate(-45.5 11.5) rotate(-90)" fill="none" stroke="#949ba7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                   </g>
               </svg> </div>
                }
            </div>
        );
    }

    renderMenus() {
        return MENUS.filter(item => {
            if(item.hasOwnProperty('permissions_required')) {

                if(Array.isArray(item.permissions_required)) {
                    for (let i = 0; i < item.permissions_required.length; i ++) {
                        if(Auth.hasPermission(item.permissions_required[i])) {
                            return true;
                        }
                    }
                    return false;
                }

                return !Auth.hasPermission(item.permissions_required);
            }
            return true;
        }).map((item, index) => {
            return this.renderItem(item, index);
        });
    }


    renderCurrentSubMenus() {
        if(this.state.currentMenu === null ){
            return [];
        }


        if(this.state.currentMenu.hasOwnProperty('children')) {
            return this.state.currentMenu.children.filter(item => {
                if(item.hasOwnProperty('permissions_required')) {

                    return Auth.hasPermission(item.permissions_required);
                }
                return true;
            }).map((item, index) => {
                return this.renderItem(item, index);
            });
        }

        return [];
    }

    render() {

        return (
            <div className={`sidebar ${this.state.sub ? 'open' : ''}`}>
                <div className="sidebar__inner">
                    <div className="sidebar__col">
                        <div className="sidebar-menu">
                            {
                                this.renderMenus()
                            }
                        </div>
                    </div>
                    <div className="sidebar__col">
                        <NavButton type="previous" onClick={() => this.setState({ sub : !this.state.sub})}> Back</NavButton>
                        {
                            this.renderCurrentSubMenus()
                        }
                    </div>

                </div>
            </div>
        );
    }
}

export default Sidebar;


const el = document.getElementById('sidebar');

if (el) {
    ReactDOM.render(<Sidebar/>, el);
}
