import { Component } from 'react';

import QuestionType from '../../../../components/question/question-type-container';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input-with-label';

import QuestionTypeField from './question-type-field';

export default class QuestionTypeText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placeholder: '',
            small_input: false,
            large_input: false,
            set_word_count: false,
            min_word_count: false,
            max_word_count: false
        }

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, this.props.question), () => console.log(this.state));
    }

    onInputChange(e) {
        const { name, value } = e.target;

        const { grantVersion, sectionIndex, blockIndex, questionIndex } = this.props;

        let params = { [ name ]: value }

        if (name == 'small_input') {
            params = {
                large_input: false,
                small_input: value,
            }
        }

        if (name == 'large_input') {
            params = {
                small_input: false,
                large_input: value,
            }
        }

        this.setState(params, () => {
            this.props.grantStateHandler.updateQuestion(grantVersion, sectionIndex, blockIndex, questionIndex, this.state);
        });
    }

    render() {
        return (
            <div className="question-type__outer">
                <QuestionType type='text' title="Text">
                    <Input value={this.state.placeholder}
                            name="placeholder"
                            label="Placeholder"
                            placeholder="Text Value"
                            onChange={this.onInputChange.bind(this)}/>
                </QuestionType>
                <QuestionType type='text' title="Text Settings">
                    <QuestionTypeField title="Field Size">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Checkbox checked={this.state.small_input}
                                        name="small_input"
                                        text="Small input field"
                                        onChange={this.onInputChange}/>

                            <Checkbox checked={this.state.large_input}
                                        name="large_input"
                                        text="Large input field"
                                        onChange={this.onInputChange}/>
                        </div>
                    </QuestionTypeField>
                    <QuestionTypeField title="Word Count">
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Checkbox checked={this.state.set_word_count}
                                        name="set_word_count"
                                        text="Set a word count on this field"
                                        onChange={this.onInputChange}/>
                        </div>
                        <div className="utils__row utils__wrap utils__gap--20">
                            <Input value={this.state.min_word_count} 
                                    name="min_word_count" 
                                    label="Minimum word count" 
                                    placeholder="Text Value" 
                                    type="number"
                                    onChange={this.onInputChange}/>

                            <Input value={this.state.max_word_count} 
                                    name="max_word_count" 
                                    label="Maximum word count" 
                                    placeholder="Text Value" 
                                    type="number"
                                    onChange={this.onInputChange}/>
                        </div>
                    </QuestionTypeField>
                </QuestionType>
            </div>
        )
    }
}
