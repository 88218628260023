import { useState, useRef, useEffect } from 'react';

import SelectContent from './dropdown-content';

export default ({
    items = [{ label: 'Test', value: 1 }],
    label = null,
    error = null,
    fit = false,
    reset = false,
    resetText = 'Reset',
    value = null,
    push = false,
    icon = 'arrow',
    opened = false,
    placeholder = 'Select something',
    defaultSelected = false,
    onChange = () => {},
    onReset = () => {}
}) => {
    const ref = useRef(null);

    const [ clicked, setClicked ] = useState(opened);
    const [ selected, setSelected ] = useState(null);
    const [ selectedIndex, setSelectedIndex ] = useState(null);

    const icons = {
        arrow: '/assets/dropdowns/downArrow.svg',
        filter: '/assets/dropdowns/filter.svg',
        sort2: '/assets/dropdowns/filter2.svg',
        sort: '/assets/dropdowns/sort.svg',
    }

    const onSelect = (item, index) => {
        setSelected(item);
        setSelectedIndex(index);
        onChange(item);
        setClicked(false);
    }

    const onResetClick = () => {
        setSelected(null);
        setClicked(false);
        onChange({
            value: '',
            label: '',
        });
        onReset();
    }

    const closeOpenMenus = (e)=>{
        if (ref.current && !ref.current.contains(e.target)){
          setClicked(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeOpenMenus)
    }, [])

    const getValue = (v) => {
        if (typeof v === 'number') {
            return items[v];
        }

        if (v == null || v?.length === 0) return false;

        if (typeof v !== 'object') {
            return  {
                label: v,
                v,
            }
        } else {
            if (v?.value?.length === 0) return false;
        }

        return v;
    }



    const formatted = getValue(value);

    return (
        <div className="select-dropdown__outer" ref={ref} style={{ width: fit ? '100%': 'unset' }}>
            { label && <p className="select-dropdown-outer__label">{ label }</p>}
            <div className={`select-dropdown ${ fit ? 'select-dropdown--fit' : '' } ${ clicked ? 'select-dropdown--toggled' : '' } ${ push ? 'select-dropdown--push' : '' }`}>
                <div className='select-dropdown__select'>
                    <div className="select-dropdown__text">
                        { formatted ? formatted.label : defaultSelected ? resetText : placeholder }
                    </div>
                    <div className="select-dropdown__icon" onClick={() => setClicked(!clicked)}>
                        <img className={clicked ? 'select-dropdown__arrow select-dropdown__arrow--toggled' : 'select-dropdown__arrow' } src={icons[icon] ? icons[icon] : icons.arrow}/>
                    </div>
                </div>

                { clicked && <SelectContent reset={reset} resetText={resetText} content={items} icons={icons} icon={icon} selectedIndex={selectedIndex} onReset={onResetClick} onSelect={onSelect}/> }
            </div>
            { error && <p className="select-dropdown-outer__error">{ error }</p> }
        </div>
    )
}
