import {Component, useState} from 'react';
import {sortableHandle} from 'react-sortable-hoc';

import CustomSwitch from "../ui/custom-switch";

import { CancelButton } from '../buttons';

const Arrow = ({toggled}) => <img style={{width: "10px", transform: toggled ? "rotate(180deg)" : ""}}
                                  src="/assets/downArrow-2.svg"/>

const Displayable = ({onClick, displayed}) => {
    return (
        <span class={"displayable " + (!displayed ? "displayable--not" : "")}
              onClick={onClick}> {displayed ? 'Displayed' : 'Hidden'} {displayed ? <img src="/assets/littleTick.svg"/> :
            <b>X</b>}</span>
    );
}

const DragHandle = sortableHandle(() => <img class="editor-dropdown__draghandle" src="/assets/drag-icon.svg"/>);


class EditableTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false
        };
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.setState({editing: false});
        }
    }

    componentDidMount() {
        this.setState({newTitle: this.props.title});
    }

    updateTitle(title) {
        const {config, sectionConfig, contentIndex} = this.props.params;
        this.props.handleFreeContentTitle(config, sectionConfig, contentIndex, title);
    }

    render() {
        if (this.state.editing) {
            return (
                <div class="editable-title">
                    <input value={this.props.title} placeholder=""
                           onChange={e => this.updateTitle(e.target.value)}
                           onKeyDown={this.handleKeyDown.bind(this)}/>

                    <CancelButton onClick={() => this.setState({editing: false})}>Close</CancelButton>
                </div>
            )
        }

        return (<p onClick={() => this.setState({editing: true})}>{this.props.title}</p>);
    }

}

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggled: false,
            over : false
        }

    }


    toggleDisplayed() {

    }

    handleContainer(r) {

    }

    handleMouseEnter() {
      this.setState({ over : true});
      this.props.onMouseEnter(this.props.type === 'section' ? this.props.sectionConfig : null);
    }

    handleMouseLeave() {
        this.setState({ over : false});
        this.props.onMouseLeave(this.props.type === 'section' ? this.props.sectionConfig : null);
    }


    render() {
        const {
            handleFreeContentTitle,
            onFreeItemDelete,
            children,
            free = false,
            params,
            title = "Default title",
            img = "/assets/arrows.svg"
        } = this.props;


        return (
            <div
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                 ref={r => this.handleContainer(r)} className={`editor-dropdown ${this.state.over ? 'editor-dropdown--highlighted': ''}`}>
                <div class="editor-dropdown__bar">
                    <div>
                        {free && <DragHandle/>}
                        <img src={img}/>
                        {free ? <EditableTitle title={title}
                                               params={params}
                                               handleFreeContentTitle={handleFreeContentTitle}
                        /> : <p>{title}</p>}
                    </div>
                    <div className="editor-dropdown__items">

                            <div onClick={() => this.setState({toggled: !this.state.toggled})}
                                 className="editor-dropdown__arrow">
                                <Arrow toggled={this.state.toggled}/>
                            </div>
                            {
                                this.props.displayable &&
                                <CustomSwitch
                                    onChange={this.props.handleDisplayed}
                                    checked={this.props.displayed}
                                />
                            }
                            {
                                free && <img className="editor-dropdown__delete" src="/assets/SVG/awesomefunction-delete.svg" onClick={onFreeItemDelete} />
                            }
                        </div>
                </div>
                {this.state.toggled && <div class="editor-dropdown__inner">{children}</div>}
            </div>
        );
    }
}

Dropdown.defaultProps = {
    onMouseEnter : c => console.log(c),
    onMouseLeave : c => console.log(c)
};

export default Dropdown;
