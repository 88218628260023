import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import LazyLoader from '../../js/components/lazy-loader';
import NewUser from "../../js/components/new-user";

import { SendButton } from '../components/buttons';

import ModifyMember from '../../js/components/modify-member';

import Breadcrumb from '../../js/components/breadcrumb';

import TeamMembers from '../../js/components/team-members';
import TeamMembersError from '../../js/components/team-members-error';
import MeApi from '../lib/users/me-api';

import axios from 'axios';

const MyTeam = () => {
    const [ loading, setLoading ] = useState(true);
    const [ popup, setPopup ] = useState(false);

    const [ team, setTeam ] = useState([]);

    const [ modifyMember, setModifyMember ] = useState(null);

    const [user, setUser]  = useState({});

    const inviteClick = () => setPopup(true);

    const crumbs = [{
        name: 'My Team',
        link: '/my-team'
    },
    {
        name: 'First Name',
        link: '#'
    }];

    useEffect(() => {
        axios.get('/api/admin/users', {params: {per_page: 100}}).then(({ data: { data } }) => setTeam(data)).catch(setTeam);
        MeApi.me().then(data => setUser(data));
    }, []);

    return (
        <>
            { popup && <NewUser user={user} setPopup={setPopup}/> }
            { modifyMember && <ModifyMember user={user} data={modifyMember} modifyMember={modifyMember} setModifyMember={setModifyMember}/> }

            <div className="myteam">
                <div class="website__topbar">
                    <Breadcrumb crumbs={crumbs} />
                    {/*<div className="website-topbar__settings">*/}
                    {/*    <img src="/assets/settings.svg"/>*/}
                    {/*    Settings*/}
                    {/*</div>*/}
                    {
                        user.super_admin === 1 &&
                        <SendButton onClick={() => inviteClick()}>Invite new user</SendButton>
                    }
                </div>
                <div class="website__content">
                    { team != null ? team.length > 0 ? <TeamMembers team={team} setModifyMember={setModifyMember}/>: <LazyLoader/> : <TeamMembersError/> }
                </div>
            </div>
        </>
    )
}

export default MyTeam;

if (document.getElementById('myteam')) {
    ReactDOM.render(<MyTeam />, document.getElementById('myteam'));
}
