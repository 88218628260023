export default ({ data, formatLink }) => {
    const { title, type, tags } = data;

    const link = () => {
        if(formatLink) {
            return formatLink(data);
        }

        return `/content/${data.id}/edit`;
    }

    return (
        <div class="result-item">
            <div class="result-item__left">
                <div class="result-item__ball"></div>
                <p class="result-item__title">{ title }</p>
            </div>
            <div class="result-item__right">
                <div class="result-item__item">
                    <p>{ type }</p>
                </div>
                <div class="result-item__item">
                    <p>{ tags.join(', ')}</p>
                </div>
                <div class="result-item__item">
                    <a href={link()} class="button button__fit button--alternate">View</a>
                </div>
            </div>
        </div>
    )
}
