import {Component} from 'react';
import ReactDOM from 'react-dom';
import CompaniesApi from '../lib/companies/companies-api';
import AlertManager from '../managers/alert/alert-manager';
import Popup from '../components/popup';
import { SaveButton, DeleteButton, CancelButton } from '../components/buttons';
import TabBar from '../../js/components/tab-bar';
import Breadcrumb from '../../js/components/breadcrumb';
import Settings from '../../js/components/companies/settings';
import CompanyPage from "../components/companies/company-page";

class Company extends Component {
    constructor(props) {
        super(props);

        this.tabs = [
            'Settings',
            'Company Page'
        ]

        this.state = {
            tab: 0,
            company: null,
            industries: [],
            popup : false,
            programmes: []
        }
    }

    setTab(id) {

        this.setState({tab: id});
    }

    componentDidMount() {
        this.getCompany();
        this.getIndustries();
    }

    getCompany() {
        CompaniesApi.get(this.props.companyId).then(company => this.setState({company: company}));
    }

    getIndustries() {
        CompaniesApi.getIndustries().then(response => this.setState({industries: response.data}));
    }

    handleChange(key, value) {

        if (key === 'profile') {
            return this.setState({
                company: Object.assign({}, this.state.company, {
                    company_profile: Object.assign({}, this.state.company.company_profile, {
                        [key]: value
                    })
                })
            }, () => console.log(this.state.company));
        }

        if (key === 'page') {
            return this.setState({
                company: Object.assign({}, this.state.company, {
                    company_profile: Object.assign({}, this.state.company.company_profile, {
                        page: value
                    })
                })
            }, () => console.log(this.state.company));
        }
        this.setState({
            company: Object.assign({}, this.state.company, {
                [key]: value
            })
        })

    }

    handleSave() {
        const {company} = this.state;

        CompaniesApi.update(company.id, company, errors => this.setState({errors: errors})).then(() => {
            CompaniesApi.updateProfile(company.id, company.company_profile).then(() => {
                AlertManager.success('Company updated');
            })
        });
    }

    deleteCompany() {
        CompaniesApi.delete(this.state.company.id).then(() => window.location = '/companies');
    }

    onProgrammeAdd(id) {
        let programmes = this.state.company.company_programmes !== undefined && this.state.company.company_programmes !== null ? this.state.company.company_programmes : [];

        programmes = programmes.map((key) => key.id);
        programmes.push(id);

        CompaniesApi.saveCompanyProgrammes(this.state.company.id, programmes).then(() => {
            this.getCompany();
        })
    }

    onProgrammeRemove(id) {
        let programmes = this.state.company.company_programmes !== undefined && this.state.company.company_programmes !== null ? this.state.company.company_programmes : [];

        programmes =  programmes.map((key) => key.id).filter((key) => key !== id);

        CompaniesApi.saveCompanyProgrammes(this.state.company.id, programmes).then(() => {
            this.getCompany();
        })
    }

    render() {
        if (this.state.company === null) return '';

        return (
            <div className="companies-outer">
                {
                    this.state.popup &&
                    <Popup type={'company-delete-popup'} >
                        <div className={'popup__inner'}>
                            <h1>Are you sure you want to delete {this.state.company.name}?</h1>
                            <div className="popup__content">
                                By clicking ‘Delete’, you will not be able to restore this company.
                            </div>
                            <div className="popup__buttons">
                                <DeleteButton responsive={false} onClick={this.deleteCompany.bind(this)}/>
                                <CancelButton responsive={false} onClick={() => this.setState({popup : false})}/>
                            </div>
                        </div>
                    </Popup>
                }
                <div class="website__topbar">
                    <Breadcrumb crumbs={[{
                        name: 'Companies',
                        link: '/companies'
                    },
                        {
                            name: this.state.company.name,
                            link: '#'
                        }]}/>

                    <div className="website-row">
                        <SaveButton onClick={this.handleSave.bind(this)}/>
                        <DeleteButton onClick={() => this.setState({popup : true})}/>
                    </div>
                </div>
                <div class="website__content">
                    <TabBar tabs={this.tabs} tab={this.state.tab} setTab={this.setTab.bind(this)}/>
                    {
                        this.state.tab == 0 ? <Settings company={this.state.company}
                                                        industries={this.state.industries}
                                                        onProgrammeAdd={this.onProgrammeAdd.bind(this)}
                                                        onProgrammeRemove={this.onProgrammeRemove.bind(this)}
                                                        onChange={this.handleChange.bind(this)}
                            /> :
                            this.state.tab == 1 ? <CompanyPage company={this.state.company} onChange={this.handleChange.bind(this)}/> : ''
                    }
                </div>
            </div>
        )
    }
}

export default Company;

let el = document.getElementById('company');

if (el) {
    ReactDOM.render(<Company companyId={el.getAttribute('data-company-id')}/>, el);
}
