export default {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
    }),
    menuList: (provided, state) => ({
        ...provided,
        borderRadius: "20px",
        border: "none",
        boxSizing: "border-box",
        padding: "5px",
    }),
    option: (provided, state) => ({
        ...provided,
        borderRadius: "20px",
        border: "none",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        minHeight: '30px',
        padding: '6px'
    }),

    multiValue: (provided, state) => ({
        ...provided,
        borderRadius: "11px",
        paddingLeft: "3px",
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        padding: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        minHeight: '30px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        marginLeft: "6px",
    })
};
