export default function Row({ gap, children, wrap = false, center, alignItems = null, justifyContent, className = '', style = {}, fill = false }) {
    const styles = {
        width: fill ? '100%' : 'unset',
        display: 'flex',
        gap: gap ? gap : '20px',
        alignItems: alignItems ? alignItems : center ? 'center' : 'unset',
        flexWrap: wrap ? 'wrap' : 'no-wrap',
        justifyContent: justifyContent ? justifyContent : '',
        ...style
    };

    return <div style={styles} className={className}>{ children }</div>
}
