import { useState } from 'react';

import Popup from '../../popup';

import TabBar from '../../tab-bar';

import Browse from './images-browse';
import Upload from './images-upload';

export default ({ onlyShow, onResourceSelect, onClose }) => {
    const [ tab, setTab ] = useState(0);

    const tabs = [{ type: 'browse', Component: Browse }, { type: 'upload', Component: Upload }].filter(t => onlyShow.includes(t.type));

    return (
        <Popup type="images-popup">
            <div class="popup__inner">
                <div class="images-popup__head">
                    <div onClick={onClose} class="images-popup__close">
                        <img src="/assets/rightArrow.svg" />
                        Close
                    </div>
                    <h2>Media Manager</h2>
                </div>
                <TabBar>
                    { tabs.map(({ type }, index) => {
                        return <button key={index} class={"button button__small button__fit " + ( index == tab ? "button--alternate" : "button--white" )} onClick={() => setTab(index)}>{ type }</button>
                    }) }
                </TabBar>
                { tabs.map(({ Component }, index) => {
                    if (tab == index) return <Component key={index} onResourceSelect={onResourceSelect}/>
                }) }
            </div>
        </Popup>
    )
}
