import Container from "../../../../../containers/container"

import List from "../../../../../components/list/list"

export default function Question({ title, description, children }) {
    return (
        <Container>
            <Container.Header style={{ marginBottom: '29px' }}>
                <List gap="18px">
                    { title && <Container.Title fontSize="20px" color="#354859">{ title }</Container.Title> }
                    { description && <Container.Text fontSize="16px" color="#929BA8">{ description }</Container.Text> }
                </List>
            </Container.Header>
            <Container.Content>
                { children }
            </Container.Content>
        </Container>
    )
}