import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { MinMaxLayout, SeventyThirtyLayout } from "../../../../containers/layout";


import ContentContainer from "../../../../components/layout/content-container";
import Container from '../../../../containers/container';

import { CreateButton } from '../../../../components/buttons';

import Sponsors from '../../../../components/sponsors';

import Navigation from '../../../grants/components/grants-application-navigation';

import SearchBarItem from '../../../../components/search/search-bar-item';

import ELearningPaths from '../../components/e-learning-path-list';
import ELearningView from '../../components/popups/e-learning-view';
import ELearningCreate from '../../components/popups/e-learning-create';
import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import ELearningFilters from '../../components/e-learning-path-filters';

import ResultsList from "../../../../components/results/results-list";
import ResultsListItem from "../../../../components/results/results-list-item";
import ResultsListItemMeta from '../../../../components/results/results-list-item-meta';
import ResultListItemDetails from "../../../../components/results/results-list-item-details";

import NavButton from '../../../../components/nav-button/nav-button';

import CourseWorksIndex from './components/course-works-index';

import { stringToFriendlyDatetime } from '../../../../lib/helpers/datetime-helpers';

import List from '../../../../components/list/list';
import CustomDropdown from '../../../../components/dropdowns/dropdown';
import Row from '../../../../utils/row';

const crumbs = [
    {
        name: 'E-Learning',
        link: ''
    }
]

class CourseWorks extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            courses: [],
           paths: [],
           courseWorks: [],
           meta: {},
           popup: null,
           loading: true,
           currentPage: 1,
           order_by: null,
           direction: null,
           term: '',
           filter: null,
        };
    }

    componentDidMount() {
        this.fetch();


    }

    async fetch() {
        let params = {
            term: this.state.term.length !== 0 ? this.state.term : null,
            per_page: 10,
            page: this.state.currentPage
        }

        if (this.state.order_by !== null) {
            params.order_by = this.state.order_by.value;
        }

        if (this.state.direction !== null) {
            params.order_direction = this.state.direction.value;
        }

        if (this.state.filter?.value === 'module_requiring_assessment_booking') {
            params.requiring_assessment_booking = 1
        }

        if (this.state.filter?.value === 'module_requiring_assessment') {
            params.requiring_assessment = 1
        }

        if (this.state.filter?.value === 'completion') {
            params.completed = 1
        }

        await new ELearningAPI().fetchCourses().then(({ data }) => {
            this.setState({ courses: data });
        });

        await new ELearningAPI().getAllCourseWork(params).then(({ data, meta }) => {
            this.setState({ courseWorks: data, meta: meta, loading: false });
        });
    }

    setPopup(popup) {
        this.setState({ popup: popup });
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.meta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    onSearchChange(e) {
        const { value } = e.target;

        this.setState({ term: value }, () => {
           this.fetch();
        });
    }

    onFilterBy(e) {
        this.setState({ direction: e }, () => this.fetch());
    }

    onSortBy(e) {
        this.setState({ order_by: e }, () => this.fetch());
    }

    onFilter(e) {
        this.setState({ filter: e }, () => this.fetch());
    }

    getStatus(courseWork) {
        if (courseWork.module_requiring_assessment?.length > 0) return <Container.Text color="#FF7612">Assessment requires marking</Container.Text>
        if (courseWork.module_requiring_assessment_booking?.length > 0) return <Container.Text color="#FF7612">Requires In person Assessment booking</Container.Text>

        if (courseWork.passed !== null) {
            return courseWork.passed === 1 ? <Container.Text color="#00C8C3">Passed</Container.Text> : <Container.Text color="#ED2855">Failed</Container.Text>;
        }

        return <Container.Text color="#FF7612">In Progress</Container.Text>;
    }

    render() {
        return (
            <>
                { this.state.popup && this.state.popup }

                <ContentContainer
                    title={'Course Works'}
                    count={this.state.courseWorks.length}
                    icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                    top={<div className="grant-index-top utils__rowSpaceBetween">
                        <SearchBarItem showButton={false} color="search-bar--white" onChange={this.onSearchChange.bind(this)}/>
                    </div>}

                    header={
                        <List>
                            <Row gap="20px" wrap>
                                <CustomDropdown items={[
                                    {
                                        label: 'Reset',
                                        value: null,
                                    },
                                    {
                                        label: 'Module requiring assessment booking',
                                        value: 'module_requiring_assessment_booking'
                                    },
                                    {
                                        label: 'Module requiring assessment',
                                        value: 'module_requiring_assessment',
                                    },
                                    {
                                        label: 'Completed',
                                        value: 'completion'
                                    }
                                ]} placeholder="Filter By" value={this.state.filter} icon="filter" onChange={this.onFilter.bind(this)}/>

                                <CustomDropdown items={[
                                    {
                                        label: 'Reset',
                                        value: null,
                                    },
                                    {
                                        label: 'Created At',
                                        value: 'created_at'
                                    },
                                    {
                                        label: 'Updated At',
                                        value: 'updated_at'
                                    },
                                ]} placeholder="Sort By" value={this.state.order_by} icon="filter" onChange={this.onSortBy.bind(this)}/>

                                <CustomDropdown items={[
                                    {
                                        label: 'Reset',
                                        value: null,
                                    },
                                    {
                                        label: 'Ascending',
                                        value: 'asc'
                                    },
                                    {
                                        label: 'Descending',
                                        value: 'desc'
                                    },
                                ]} placeholder="Sort Direction" value={this.state.direction} icon="filter" onChange={this.onFilterBy.bind(this)}/>
                            </Row>
                        </List>
                    }

                    bottom={<Navigation back={this.goBack.bind(this)}
                                        next={this.goNext.bind(this)}
                                        setPage={this.setPage.bind(this)}
                                        meta={this.state.meta}
                                        backDisabled={this.state.currentPage == 1}
                                        nextDisabled={this.state.currentPage == this.state.meta?.last_page}/>}>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <ResultsList
                            titleOne={'Course Name'}
                            titleTwo={''}
                            titleThree={''}>

                        { this.state.courseWorks?.map((courseWork, index) => {
                           const course = this.state.courses.find(c => c.id === courseWork.course_id);

                            return (
                                <ResultsListItem
                                    key={index}
                                    details={<ResultListItemDetails title={course?.name} subtitle={courseWork?.user?.name}/>}
                                    metaOne={<ResultsListItemMeta data={[`Last Edited: ${stringToFriendlyDatetime(courseWork.updated_at)}`]}/>}
                                    metaTwo={<ResultsListItemMeta data={[this.getStatus(courseWork)]} />}
                                    actions={ <NavButton mobile={true} onClick={() => window.location.href = `/e-learning/course-works/${courseWork?.id}`}/>}
                                />
                            )
                        })}
                       </ResultsList>
                    </div>
                </ContentContainer>
            </>

        );
    }
}

class Activity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: new Array(0).fill({
                message: 'Test',
            }),
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <ContentContainer title={'Activity'} icon={<img src="/assets/content-container/activity.svg" />}>
                
            </ContentContainer>
        );
    }
}

const App = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'e-learning-index'}>
                <SeventyThirtyLayout
                    main={<CourseWorks/>}
                    side={[
                        {
                            name: 'Activity',
                            component: <Activity/>
                        }
                    ]}
                />
            </div>
        </PageLayout>

    );
}

const el = document.getElementById('course-works');

if (el)
{
    ReactDOM.render(<App/>, el);
}
