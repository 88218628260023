import Switch from 'react-switch';

import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

export default (props) => {
    const { label, placeholder, fill } = props;
    
    return (
        <ErrorContainer errors={props?.errors}>
            <LabelContainer label={label} placeholder={placeholder} fill={fill}>
                <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor="#00C8C3"
                    offHandleColor="#EA1557"
                    { ...props }
                />
            </LabelContainer>
        </ErrorContainer>
    )
}
