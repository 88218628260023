export default function QuestionType({ 
    icon, 
    type = '', 
    className = '', 
    onClick = () => {} 
}) {
    const Icon = icon;

    return (
        <div className={`question-type-icon ${className ? `question-type-icon__${className}` : ''}`} onClick={onClick}>
            { icon && typeof icon === 'string' ? <img src={`/assets/question-types/question-type-${icon}.svg`}/> : <Icon/> }
            { type && <p>{ type }</p> }
        </div>
    )
}