import React, {Component} from 'react';

import DistApi from '../../lib/distributions/distribution-api'
import Pagination from "../../components/pagination";
import CompanyItem from "../../components/companies/company-item";
import NoDataError from "../../components/no-data-error";

class DistributionMenus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            pagination: {},
            page: 1
        };
    }

    componentDidMount() {
        this.getMenus();
    }

    getMenus(page = this.state.page) {
        DistApi.getMenus(this.props.distribution.id, page, 20).then(response => {
            this.setState({
                items: response.data,
                pagination: response.meta
            });
        });
    }

    handlePaginationChange(e) {
        this.setState({page: e}, () => this.getMenus());
    }

    render() {
        return (
            <div>
                <div className="website__content">
                    <div className="companies__content">
                        {this.state.items.length > 0 ? this.state.items.map((c, index) => {
                                const params = {
                                    key: index,
                                    title: c.name
                                };

                                return (<CompanyItem
                                    {...params}
                                    onClick={() => window.location = `/content/distributions/${this.props.distribution.id}/menus/${c.id}`}/>);
                            }) :
                            <NoDataError message='No contacts found'/>}
                    </div>
                </div>

                {
                    this.state.items.length >= 1 &&
                    <Pagination
                        pagination={this.state.pagination}
                        onChange={this.handlePaginationChange.bind(this)}
                    />
                }
            </div>
        );
    }
}

DistributionMenus.defaultProps = {
    distribution: {}
}

export default DistributionMenus;
