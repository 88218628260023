import AlertManager from "../managers/alert/alert-manager";

export default class ApiBase {

    constructor() {
        this.defaultError = 'System Error';
        this.defaultValidationHandler = errors => {
            console.error(errors)
        }
    }


    handleError(error = this.defaultError) {
        AlertManager.error(error);
        throw new Error(error);
    }

    call(method, path, params) {
        return axios.post('/api/connector/call', {
            path,
            params,
            method
        })
    }

    get(path, params) {
        return this.call('get', path, params);
    }

    post(path, params) {
        return this.call('post', path, params);
    }

    delete(path, params) {
        return this.call('delete', path, params);
    }

    async handleResponse(caller, validationCallback = this.defaultValidationHandler) {


        try {
            return await caller();

        } catch (error) {
            if(error.message === 'canceled') {
                return false;
            }
            const { response } = error;

            if(response.status === 422) {
                validationCallback(response.data.errors);
                throw error;
            }

            if(response.status === 403) {
                AlertManager.error('You do not have the correct permissions to use this resource.');
                return ;
            }
            this.handleError(response);
        }
    }
}
