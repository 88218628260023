import React, {Component} from 'react';
import Popup from "../popup";
import Input from '../input-with-label';
import EventsApi from '../../lib/events/events-api';
import Dropdown from "react-dropdown";

import { CreateButton, CancelButton } from '../buttons';

class NewEventPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            errors: {},
            start: '',
            event_type_id : null,
            duration_mins: 0
        };
    }


    createNew() {
        const {name, start, duration_mins, event_type_id} = this.state;
        EventsApi.storeEvent({
            name, start, duration_mins, event_type_id
        }, errors => this.setState({errors: errors})).then(data => {
                window.location = `/events/${data.id}`;
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

    }

    eventTypeOptions() {
        return this.props.eventTypes.map(i => {
           return {
               label : i.name,
               value : i.id
           }
        });
    }

    getSelectedEventType() {
        if(this.state.event_type_id !== null) {
            const index = _.findIndex(this.props.eventTypes, item =>{
                return item.id === this.state.event_type_id;
            });

            if(index !== -1) {
                const type = this.props.eventTypes[index];
                return {
                    label : type.name,
                    value : type.id
                }
            }
        }
    }
    getHours(durationMins = this.state.duration_mins) {
        const hours = Math.floor(durationMins / 60)

        return hours ? hours : 0;
    }

    getEventMinutes(durationMins = this.state.duration_mins) {
        const hours = Math.floor(durationMins / 60);
        const hoursInMins = hours * 60;

        return durationMins - hoursInMins;
    }

    render() {
        return (
            <Popup type={'new-event'} onClose={this.props.onClose}>
                <div className="popup__inner">
                    <h1>Create a new Event</h1>
                    <Input
                        placeholder={'Name'}
                        name={'name'}
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        errors={this.state.errors.name}
                    />
                    <Input
                        placeholder={'Date & Time'}
                        type={'datetime-local'}
                        name={'start'}
                        value={this.state.start}
                        onChange={this.handleChange.bind(this)}
                        errors={this.state.errors.start}
                    />
                    <Input
                        label={'Duration Hours'}
                        placeholder={'Duration Hours'}
                        type={'number'}
                        name={'duration_mins'}
                        value={(() => {
                            const hours =  this.getHours(event.duration_mins);
                            if(hours > 0) {
                                return hours.toString().replace(/^0+/, '');
                            }
                            return hours;
                        })()}
                        onChange={e => {
                            const value = e.target.value * 60;
                            const mins = this.getEventMinutes();
                            this.handleChange({target: {name: 'duration_mins', value: value + mins }})

                        }}
                        errors={this.state.errors.duration_mins}
                    />
                    <Input
                        label={'Duration Mins'}
                        placeholder={'Duration  Mins'}
                        type={'number'}
                        name={'duration_mins'}
                        max={59}
                        min={0}
                        value={(() => {
                            const mins =  this.getEventMinutes();
                            if(mins > 0) {
                                return mins.toString().replace(/^0+/, '');
                            }
                            return 0;
                        })()}
                        onChange={e => {
                            const mins =  parseInt(e.target.value) ? parseInt(e.target.value) : 0;
                            const hours = this.getHours(event.duration_mins) ? this.getHours(event.duration_mins) : 0;

                            this.handleChange({target: {name: 'duration_mins', value:  ( hours * 60) + mins }})
                        }}
                        errors={this.state.errors.duration_mins}
                    />
                    <div className="input-outer">
                        <label htmlFor="email">Choose a event type</label>
                        <Dropdown className="input input--idle" options={this.eventTypeOptions()} onChange={e => this.setState({event_type_id : e.value})}
                                  value={this.getSelectedEventType()} placeholder="Type"/>
                    </div>


                    <div className="popup__buttons">
                        <CreateButton responsive={false} onClick={this.createNew.bind(this)}/>
                        <CancelButton responsive={false} onClick={this.props.onClose}/>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default NewEventPopup;
