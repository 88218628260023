import {Component} from 'react';

import GrantsApi from '../../../lib/grants/grants-api';

import GrantEditStateHandler from "../../../lib/grants/grant-edit-state-handler";

import Section from './section';

import TabBar from '../../../components/tab-bar';

import {AddButton} from "../../../components/buttons";
import NavButton from "../../../components/nav-button/nav-button";

export default class Editor extends Component {
    constructor(props) {
        super(props);

        this.grantStateHandler = new GrantEditStateHandler();

        this.state = {
            grant: {},
            grantVersion: {},
            tabs: [],
            tab: 0,
        };
    }

    componentDidMount() {

        this.props.setStateHandler(this.grantStateHandler);
        this.getGrant();
    }

    setTab(index) {
        this.setState({tab: index})
    }

    setStateHandler() {
        this.grantStateHandler.setOnStateUpdate(state => {
            this.props.setCurrentGrant({grant: this.state.grant, grantVersion: state});
            this.setState({grantVersion: state, tabs: this.mapSectionNames(state)});
        });
    }

    mapSectionNames(latest) {
        const tabs = latest.config?.sections ? latest.config?.sections.map((item, index) => {

            if(item.hasOwnProperty('name') && item.name !== null) {
                if(item?.name.length >= 1) {
                    return  `${(index + 1)}: ` + item.name;
                }
            }


            return 'Section ' + (index + 1);
        }) : [];

        return tabs;
    }

    getGrant() {
        GrantsApi.get(this.props.grantId).then(data => {
            const latest = data.latest_grant_versions[0];
            this.setState({grant: data, grantVersion: latest, tabs: this.mapSectionNames(latest)}, () => {
                this.props.setCurrentGrant({grant: data, grantVersion: latest});
                this.setStateHandler();
            });
        });
    }

    setPopup(popup) {
        this.setState({activePopup: popup});
    }

    blockNumbers() {
        let count = 0;
        let blockByIndex = {};

        this.state.grantVersion.config?.sections.forEach((section, sectionIndex) => {
            section?.blocks.forEach((block, blockIndex) => {
                count++;
                blockByIndex[`${sectionIndex}${blockIndex}`] = count;
            });
        });


        return blockByIndex;

    }

    render() {

        const blockCountByIndex = this.blockNumbers();

        return (
            <>
                {this.state.activePopup && this.state.activePopup}

                <div className="utils__column utils__gap--20">
                    <TabBar>
                        {
                            this.state.tabs.map((name, index) => {
                                return (
                                    <div key={index} className={'tab-bar__tab'}><NavButton
                                        onClick={() => this.setTab(index) }> {name} </NavButton>
                                        <div onClick={() => this.grantStateHandler.removeSection(this.state.grantVersion, index)} className={'tab-bar__link'}>Remove</div>
                                    </div>
                                )
                            })
                        }


                            </TabBar>

                        {this.state.grantVersion.config?.sections[this.state.tab] && <Section blockCount={blockCountByIndex} sectionIndex={this.state.tab}
                            section={this.state.grantVersion.config.sections[this.state.tab]}
                            grantStateHandler={this.grantStateHandler}
                            grantVersion={this.state.grantVersion}
                            setPopup={this.setPopup.bind(this)}/>}
                            </div>
                            </>
                            );
                        }


}
