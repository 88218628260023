import { useEffect } from "react"
import MediaHelper from '../../../../lib/helpers/media';
import ImagesApi from '../../../../lib/media/images-api';
import AlertsManager from '../../../../managers/alert/alert-manager';

import { ProceedButton, ViewButton, DeleteButton } from '../../../buttons';

const FileIcons = {
    'application/pdf': 'pdf',
    'application/msword': 'document-icon',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document-icon',
    'text/plain': 'txt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
    'image/jpeg' : 'document-icon',
    'image/png' : 'document-icon'
}

export default ({ img, icon, viewDetailsClick, downloadPath = null, deletePath = null, onUseResource, imagePreview = true, onDelete = () => console.log('Image deleted'), readOnly = false, downloadOnly = false }) => {

    const handleDelete = () => {

        if(deletePath !== null ) {
            axios.delete(deletePath).then(() => {
                AlertsManager.success('File deleted');
                onDelete();
            });
            return;
        }

        ImagesApi.deleteImage(img.id).then(() => {
            AlertsManager.success('Image deleted');
            onDelete();
        })
    }

    // const previewImage = () => {

    //     if(imagePreview === false) {
    //         return `/assets/file-types/${FileIcons[img.mime_type]}.svg`;
    //     }

    //     return MediaHelper.formatPublicPath(`/images/${img.file_name}`);
    // }

    return (
        <div class="images-browse__display">
            <div class="images-browse__display-image" style={{ backgroundColor: icon ? 'transparent' : 'black' }}>
                { icon == null ? <div style={{ backgroundImage: `url(${MediaHelper.formatPublicPath(`/images/${img.file_name}`)})})` }}></div> : <img src={icon} /> }
            </div>
            <div class="images-browse__display-head">
                <div class="images-browse__display-text">
                    <p>FILE NAME</p>
                    <p>{ img?.name }</p>
                </div>
                {
                    downloadOnly === false &&
                        <>
                            <button onClick={onUseResource}>Use file</button>
                            <button onClick={viewDetailsClick}>View details</button>
                        </>
                }
                {
                    downloadOnly === true &&
                    <>
                        <a target={'_blank'} rel={'noreferrer'} href={downloadPath} className="images-browse__button">Download</a>
                        <button onClick={handleDelete}>Delete file</button>
                    </>
                }

                {
                    readOnly === false &&
                    <button onClick={handleDelete}>Delete image</button>
                }
            </div>
        </div>
    )
}
