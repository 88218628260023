import ApiBase from "../api-base";

class CompaniesApi extends  ApiBase {

    async getCompanies(page = 1, perPage = 20) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/companies`, {params : {page: page, per_page : perPage}});
            return response.data;
        });
    }

    async create(data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/companies`, data);
            return response.data.data;
        }, validationCallback);
    }

    async fetchProgrammes() {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/company-programmes`);
            return response.data.data;
        });
    }

    async saveCompanyProgrammes(id, programmes = [], validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/companies/${id}/set-programmes`, {
                company_programme_ids: programmes
            });

            return response.data.data;
        }, validationCallback);
    }

    async addProgramme(programme, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/company-programmes`, {
                name: programme
            });
            return response.data.data;
        }, validationCallback);
    }

    async deleteProgramme(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/company-programmes/${id}`, id);
            return response.data.data;
        }, validationCallback);
    }

    async delete(id) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/companies/${id}`);
            return response.data.data;
        });
    }

    async get(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/companies/${id}`);
            return response.data.data;
        });
    }

    async update(id, data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/companies/${id}`, data);
            return response.data.data;
        }, validationCallback);
    }

    async updateProfile(id, data, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/companies/${id}/profile`, data);
            return response.data.data;
        }, validationCallback);
    }

    async getIndustries(page = 1, perPage = 100) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/companies/industries`, {params : {page: page, per_page : perPage}});
            return response.data;
        });
    }

}


export default new CompaniesApi();
