export default function IndexCard({ title, subtitle, info, status, meta, buttons }) {
    return (
        <div className="grant-card">
            <div className="grant-card__top">
                <p className="color--blue">{meta}</p>
                { status }
            </div>
            <div className="grant-card__header">
                <h4>{ title }</h4>
                <p>{ subtitle }</p>
            </div>
            <div className="grant-card__content">
                <p>{ info }</p>
            </div>
            <div className="grant-card__buttons">
                {buttons }
            </div>
        </div>
    )
}
