import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import DistributionApi from '../../lib/distributions/distribution-api';
import Breadcrumb from "../../components/breadcrumb";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";

import AlertManager from "../../managers/alert/alert-manager";
import Input from "../../components/input-with-label";
import Popup from "../../components/popup";

import { SaveButton, DeleteButton, CancelButton } from '../../components/buttons';

class MenuEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: {
                name:  '',
                distribution : {}
            },
            configString: '',
            errors : {},
            popup : false
        };
    }


    componentDidMount() {
        this.getMenu();
    }

    getMenu() {
        DistributionApi.getMenu(
            this.props.distributionId,
            this.props.menuId
        ).then(data => this.setState({
            menu: data,
            configString: data.config ? JSON.stringify(data.config, null, 2) : ''
        }));
    }

    handleSave() {
        try {
            let params = this.state.menu;


            params.config = JSON.parse(this.state.configString);


            DistributionApi.updateMenu(this.props.distributionId, params.id, params, errors => this.setState({errors: errors})).then(success => {
                if (success) {
                    AlertManager.success('Menu Type updated');
                }
            });

        } catch (e) {
            this.setState({validJSON: false});

            return;
        }
    }

    handleDelete() {

        DistributionApi.deleteMenu(this.props.distributionId, this.props.menuId).then(success => {
                AlertManager.success('Menu delete');
                window.location = `/content/distributions/${this.props.distributionId}`;
        });
    }

    render() {
        return (
            <div>
                <>
                    {
                        this.state.popup &&
                        <Popup type={'company-delete-popup'}>
                            <div className={'popup__inner'}>
                                <h1>Are you sure you want to delete {this.state.menu.name}?</h1>
                                <div className="popup__content">
                                    By clicking ‘Delete’, you will not be able to restore this menu.
                                </div>
                                <div className="popup__buttons">
                                    <DeleteButton responsive={false} onClick={this.handleDelete.bind(this)}>Delete</DeleteButton>
                                    <CancelButton responsive={false} onClick={() => this.setState({popup: false})}>Cancel</CancelButton>
                                </div>
                            </div>
                        </Popup>
                    }</>
                <div className={'account'}>

                    <div className="website__topbar">
                        <Breadcrumb crumbs={[{
                            name: 'Distributions',
                            link: '/content/distributions'
                        },
                            {
                                name: this.state.menu.distribution.name,
                                link: `/content/distributions/${this.props.distributionId}`
                            },
                            {
                                name: this.state.menu.name,
                                link: '#'
                            }]}/>
                        <div className="website-row">
                            <SaveButton onClick={this.handleSave.bind(this)}>Save / Confirm</SaveButton>
                            <DeleteButton onClick={() => this.setState({popup: true})}>Delete</DeleteButton>
                        </div>
                    </div>
                </div>
                <div className="website__content">
                    <div className="account__inputs">
                        <div className="account__section">
                            <div className="website-row">
                                <Input name="name" value={this.state.menu.name} placeholder="Name"
                                       onChange={e => this.setState({
                                           menu: Object.assign({}, this.state.menu, {
                                               name: e.target.value
                                           })
                                       })} errors={this.state.errors.name}/>
                            </div>

                        </div>
                    </div>
                    {
                        this.state.menu.id &&
                        <CodeMirror
                            value={this.state.configString}
                            height="800px"
                            extensions={[javascript()]}
                            onChange={e => {
                                this.setState({configString: e, validJSON: true});
                            }}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default MenuEdit;


const el = document.getElementById('menu-edit');

if (el) {
    ReactDOM.render(<MenuEdit distributionId={el.getAttribute('data-distribution-id')}
                              menuId={el.getAttribute('data-menu-id')}/>, el);
}
