import React, {Component} from 'react';
import ReactDOM from "react-dom";
import GrantEditor from "./editor/grant-editor";
import Button from "../../components/buttons/button";
import GrantsApi from "../../lib/grants/grants-api";
import AlertManager from "../../managers/alert/alert-manager";

class GrantsPreviewStatusBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            checking: false,
            newSession: false,
            complete: false
        };

        this.timeout = null;
    }


    componentDidMount() {
        this.check();

        this.setListeners();
    }

    setListeners() {
        window.addEventListener('message', message => {
              if(message.data === 'complete') {
                  this.setState({complete : true})
              }
        });
    }

    check() {
        GrantsApi.checkPreview(this.props.grantId, {cache_id : this.props.cacheId}).then(data => {

            this.setState({change : data.cache_id_change, newSession : !data['exists']});



            setTimeout(() => {
                this.check();
            }, 5000);



        }).catch(() => {

            AlertManager.error("We have lost contact with the system and can't generate a preview");

        });
    }


    render() {
        return (
            <div className={`grants-preview-status-bar ${(this.state.change || this.state.complete ) ? 'change open' : ''} ${this.state.newSession ? 'new-session open' : '' }` }>

                <div className="grants-preview-status-bar__inner">

                    <div className="grants-preview-status-bar__info">
                        <div className="grants-preview-status-bar__icon">
                            <img src="/assets/buttons/view-icon.svg" alt=""/>
                        </div>
                        <div>
                            {
                                this.state.change &&
                                <h4>There has been changes to your Grant please refresh your preview</h4>
                            }
                            {
                                this.state.newSession &&
                                <h4>A new session has been started. Please close this window and generate a new preview</h4>
                            }
                            {
                                this.state.complete &&
                                <h4>You have completed the Application. Please refresh to restart the process</h4>
                            }
                            <p>Your are previewing your grant's application form</p>
                        </div>


                    </div>
                    {
                        this.state.newSession === false &&
                        <div className="grants-preview-status-bar__actions">
                            <Button onClick={() => window.location = window.location} arrow={false} color={'white'}
                                    icon={'refresh'}>Refresh preview</Button>
                        </div>
                    }

                </div>

            </div>
        );
    }
}

export default GrantsPreviewStatusBar;


const el = document.getElementById('grants-preview-status-bar');

if (el) {
    ReactDOM.render(<GrantsPreviewStatusBar grantId={el.getAttribute('data-grant-id')}
                                            token={el.getAttribute('data-token')} cacheId={el.getAttribute('data-cache-id')}/>, el);
}
