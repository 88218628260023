import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../components/breadcrumb";
import PageLayout from "../../containers/layout/page-layout";
import {SeventyThirtyLayout} from "../../containers/layout";

import ContentContainer from "../../components/layout/content-container";

import GrantApplicationDetails from './components/grant-application-details'

import ResultsList from "../../components/results/results-list";
import ResultsListItem from "../../components/results/results-list-item";
import ResultsListItemMeta from '../../components/results/results-list-item-meta';

import ActivityItem from '../../components/activity/activity-item';
import SearchBarItem from '../../components/search/search-bar-item';

import Pills from './components/grant-application-pills';
import Filters from './components/grant-application-filters';
import Navigation from './components/grants-application-navigation';
import ResultListItemDetails from "../../components/results/results-list-item-details";
import NavButton from "../../components/nav-button/nav-button";

import Sponsors from '../../components/sponsors';

import ApplicationsApi from '../../lib/applications/application-api';
import GrantsApi from '../../lib/grants/grants-api';

import { STATUSES } from '../../lib/helpers/application-statuses';

const crumbs = [{
        name: 'Grants',
        link: ''
    },
    {
        name: 'Applications',
        link: '#'
    }
];

class GrantsActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
        };
    }

    componentDidMount() {
        new ApplicationsApi().history().then(data => {
            this.setState({ activity: data })
        });
    }

    render() {
        return (
            <div className="application-activity">
                <ContentContainer title={'Activity'} icon={<img src="/assets/content-container/activity.svg" />}>
                    <ResultsList>
                        {
                            this.state.activity.map((item, index) =>{
                                return(
                                    <ActivityItem item={item} key={index} />
                                );
                            })
                        }
                    </ResultsList>
                </ContentContainer>
            </div>
        );
    }

}


class GrantsList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            applications: [],
            applicationsMeta: {},
            grants: [],
            selectedGrant: null,
            statuses: [],
            filters: [],
            term: '',
            acceptedRejected: null,
            direction: {
                label: 'Sort by Descending',
                value: 'desc'
            },
            orderBy: {
                label: 'Sort by Created at',
                value: 'created_at'
            },
            currentPage: 1,
        };
    }

    componentDidMount() {
        this.getApplications();
        this.getGrants();
    }

    getGrants() {
        GrantsApi.getGrants().then(({ data }) => {
            this.setState({ grants: data });
        })
    }

    getApplications() {
        const payload = this.fetchPayload();

        new ApplicationsApi().getApplicationsWithMeta(payload).then(({ data, meta }) => {
            this.setState({ applications: data, applicationsMeta: meta })
        });
    }

    getApplicationsByTerm() {
        const payload = this.fetchPayloadTerm();

        new ApplicationsApi().getApplicationsByTerm({
            term: this.state.term,
            ...payload
        }).then((data) => {
            this.setState({ applications: data })
        });
    }

    fetchPayload() {
        let payload = {
            page: this.state.currentPage,
            per_page: 40,
        }

        if (this.state.acceptedRejected?.value == 'accepted') {
            payload.accepted = 1;
        }

        if (this.state.acceptedRejected?.value == 'rejected') {
            payload.rejected= 1;
        }

        if (this.state.statuses.length > 0) {
            payload.status = this.state.statuses;
        }

        if (this.state.selectedGrant !== null && this.state.selectedGrant.value.length > 0) {
            payload.grant_id = this.state.selectedGrant.value;
        }

        if (this.state.direction !== null && this.state.direction.value.length > 0) {
            payload.order_dir = this.state.direction.value;
        }

        if (this.state.orderBy !== null && this.state.orderBy.value.length > 0) {
            payload.order_by = this.state.orderBy.value;
        }

        return payload;
    }

    fetchPayloadTerm() {
        let payload = {
            filters: []
        }

        if (this.state.acceptedRejected == 'accepted') {
            payload.filters.accepted = 1;
        }

        if (this.state.acceptedRejected == 'rejected') {
            payload.filters.rejected= 1;
        }

        if (this.state.statuses.length > 0) {
            payload.filters.status = this.state.statuses;
        }

        if (this.state.selectedGrant !== null && this.state.selectedGrant.value.length > 0) {
            payload.filters.grant_id = this.state.selectedGrant.value;
        }

        if (Object.keys(payload.filters).length == 0) payload.filters = [];

        return payload;
    }

    fetch() {
        if (this.state.term.length > 0) {
            this.getApplicationsByTerm();
        } else {
            this.getApplications();
        }
    }

    onStatusChange(status) {
        const { value } = status;
        let statuses = [ ...this.state.statuses ]

        if (this.state.statuses.includes(value)) {
            statuses = statuses.filter(s => s !== value)
        } else {
            statuses.push(value);
        }

        if (value.length == 0) {
            statuses = [];
        }

        this.setState({ statuses }, () => this.fetch());
    }

    removeStatus(index) {
        const status = this.state.statuses[index];

        this.onStatusChange({ value: status });
    }

    onSearchChange(e) {
        const { value } = e.target;

        this.setState({ term: value }, () => {
           if (value == 0) return this.getApplications();

           this.getApplicationsByTerm();
        });
    }

    onAcceptedRejected(e) {
        this.setState({ acceptedRejected: e }, () => this.fetch())
    }

    onDirectionChange(e) {
       this.setState({ direction: e }, () => this.fetch())
    }

    onOrderBy(e) {
        this.setState({ orderBy: e }, () => this.fetch())
    }

    onGrant(e) {
        this.setState({ selectedGrant: e }, () => this.fetch())
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.applicationsMeta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    render() {
        const statusParams = {
           onStatusChange: this.onStatusChange.bind(this),
           statuses: STATUSES,
           status: this.state.statuses.length > 0 ? this.state.statuses.length > 1 ? 'Multiple Selected...' : this.state.statuses[0] : null,
           onGrant: this.onGrant.bind(this),
           grants: this.state.grants,
           selectedGrant: this.state.selectedGrant,
           onAcceptedRejected: this.onAcceptedRejected.bind(this),
           acceptedRejected: this.state.acceptedRejected,
           onDirectionChange: this.onDirectionChange.bind(this),
           direction: this.state.direction,
           onOrderBy: this.onOrderBy.bind(this),
           orderBy: this.state.orderBy,
        }


        return (
            <>
                <ContentContainer
                    title={'Applications'}
                    // count={this.state.applications.length}
                    top={<SearchBarItem showButton={false} color="search-bar--white" onChange={this.onSearchChange.bind(this)}/>}
                    header={<><Pills statuses={this.state.statuses} statusRemove={this.removeStatus.bind(this)} /><Filters { ...statusParams }/></>}
                    bottom={<Navigation back={this.goBack.bind(this)}
                                next={this.goNext.bind(this)}
                                setPage={this.setPage.bind(this)}
                                meta={this.state.applicationsMeta}
                                backDisabled={this.state.currentPage == 1}
                                nextDisabled={this.state.currentPage == this.state.applicationsMeta.last_page}/>}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                        <ResultsList
                            titleOne={'Application Title and ID'}
                            titleTwo={'Application Details'}
                            titleThree={'Application Status'}>
                            {
                                this.state.applications.length > 0 && this.state.applications.map((item, index) => {
                                    const latest = item.hasOwnProperty('latest_application_versions') ? item.latest_application_versions.length > 0 ? item.latest_application_versions[0].application_id : item.id : item.id;

                                    if (!item.hasOwnProperty('latest_application_versions')) {
                                        item = {
                                            ...item,
                                            grant: {
                                                name: item.grant_name
                                            },
                                            company: {
                                                name: item.company_name
                                            },
                                            created_by: {
                                                name: item.created_by
                                            }
                                        }

                                    }

                                    return (
                                        <ResultsListItem
                                            key={index}
                                            details={<ResultListItemDetails title={item.grant.name} subtitle={`${item.name !== null ? `#${item.name.toUpperCase()}` : ''}`}/>}
                                            metaOne={<ResultsListItemMeta data={[item.created_by.name, item.company.name]}/>}
                                            metaTwo={<ResultsListItemMeta data={[item.status]} />}
                                            actions={ <NavButton mobile={true} onClick={() => window.location.href = `/grants/applications/${latest}`}/>}

                                        />
                                    )
                                })
                            }
                        </ResultsList>
                    </div>
                </ContentContainer>
            </>

        );
    }


}

const GrantApplications = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}
        >
            <div className={'grant-applications'}>
                <SeventyThirtyLayout
                    main={<GrantsList/>}
                    side={[
                        {
                            name: 'Activity',
                            component: <GrantsActivity/>
                        }
                    ]}
                />
            </div>
        </PageLayout>

    );
}

export default GrantApplications;


const el = document.getElementById('grant-applications');

if (el)
{
    ReactDOM.render(<GrantApplications/>, el);
}
