export const STATUS_PROGRESS = 'in progress';
export const STATUS_PENDING = 'pending';
export const STATUS_APPROVED = 'approved';
export const STATUS_REJECTED = 'rejected';
export const STATUS_OFFER_LETTER_SENT = 'offer letter sent';
export const STATUS_AWAITING_DOCUMENTS = 'awaiting documents';
export const STATUS_CLAIM_PROCESSED = 'claim processed';
export const STATUS_AMENDMENTS_REQUIRED = 'amendments required';
export const STATUS_OFFER_REJECTED = 'offer rejected';

export const STATUSES = [
    STATUS_PROGRESS,
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_REJECTED,
    STATUS_OFFER_LETTER_SENT,
    STATUS_AWAITING_DOCUMENTS,
    STATUS_CLAIM_PROCESSED,
    STATUS_AMENDMENTS_REQUIRED,
    STATUS_OFFER_REJECTED
]
