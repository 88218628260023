import React, {Component} from 'react';
import Input from "../../components/input-with-label";

class ContentTypeLabels extends Component {

    handleSectionChange(index, key, value) {
        let contentType = {...this.props.contentType};
        contentType.config.sections[index][key] = value;
        this.props.onChange(contentType);
    }

    renderContentItem(sectionIndex, contentIndex, contentItem) {
        return(
            <>
                <div key={`${sectionIndex}${contentIndex}`} className="website-row">
                    <Input
                        name="label"
                        value={contentItem.label ? contentItem.label : ''}
                        placeholder={contentItem.name}
                        onChange={e => {
                            this.handleContentChange(sectionIndex, contentIndex, e.target.name, e.target.value)
                        }}
                    />
                </div>
                <br />
            </>

        );
    }

    handleContentChange(sectionIndex, contentIndex, key, value) {
        let contentType = {...this.props.contentType};
        contentType.config.sections[sectionIndex].fixed_content[contentIndex][key] = value;
        this.props.onChange(contentType);
    }


    renderSection(sectionIndex, section) {
        return (
            <div key={`${sectionIndex}`}>
                <div className="account__inputs">
                    <div className="account__section">
                        <div className="website-row">
                            <h2>Section: {section.name}</h2>

                        </div>
                        <div className="website-row">
                            <Input
                                name="label"
                                value={section.label ? section.label : ''}
                                placeholder="Label"
                                onChange={e => {
                                    this.handleSectionChange(sectionIndex, e.target.name, e.target.value)
                                }}
                            />
                        </div>
                        {
                            section.fixed_content &&
                            <>
                                <h4>Fixed Content Items</h4>
                                <br />
                                    {
                                        section.fixed_content.map((contentItem, contentIndex) => this.renderContentItem(sectionIndex, contentIndex, contentItem))
                                    }
                            </>
                        }

                        <br/>
                    </div>
                </div>
            </div>
        );
    }

    sections() {

        if (this.props.contentType.hasOwnProperty('config')) {
            if (this.props.contentType.config.hasOwnProperty('sections')) {
                return this.props.contentType.config.sections;
            }
        }

        return [];
    }

    render() {

        return (
            <div>
                <br/>
                {
                    this.sections().map((section, index) => {
                        return this.renderSection(index, section);
                    })
                }
            </div>
        );
    }
}

ContentTypeLabels.defaultProps = {
    contentType: {
        config: {
            sections: []
        }
    }
};

export default ContentTypeLabels;
