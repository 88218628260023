import { Component } from 'react';

import List from '../../../../../components/list/list';
import Row from '../../../../../utils/row';
import Input from '../../../../../components/inputs/inputs-text';
import Divider from '../../../../../components/divider/divider';

import { 
    AddButton, 
    DeleteButton,
    SettingsButton,
} from '../../../../../components/buttons';

import ModuleSettingsInformation from '../../../components/popups/course/course-module-settings';
import ModuleSettingsDynamic from '../../../components/popups/course/course-module-settings-dynamic';
import ModuleSettingsManual from '../../../components/popups/course/course-module-settings-manual';

import ModuleDelete from '../../../components/popups/course/course-module-delete';
import LabelContainer from '../../../../../components/label-container';

import ModuleDropdown from './course-module-dropdown';

import PopupContext from '../../../../../context/context-popup';
import Container from '../../../../../containers/container';

export default class CourseModule extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        const module = this.getModule();

        console.log('Updated to', module)

        if (module.type === 'dynamic') {
            if (module.sections.length !== 0) return;

            this.addNewSection('dynamic');
        }

        if (module.type === 'manual') {
            if (module.sections.length !== 0) return;

            this.addNewSection('manual');
        }
    }

    editModule(e) {
        const { name, value } = e.target;

        const modules = this.getModules();
        const module = this.getModule();

        module[name] = value;

        this.props.onChange('modules', modules);
    }

    deleteModuleFromState() {
        const courseVersions = [ ...this.props.course.latest_course_versions ];
        const contentVersion = courseVersions[0];

        const modules = contentVersion?.modules;

        modules.splice(this.props.moduleId, 1);

        this.props.onChange('modules', modules);

        this.props.onModuleDelete();
    }

    addNewSection(type) {
        const modules = this.getModules();
        const module = this.getModule();

        const section = this.newSection(type);
        
        module.sections = [ ...module.sections, section ];

        this.props.onChange('modules', modules);
    }

    onSectionEdit(index, e) {
        const { name, value } = e.target;

        const modules = this.getModules();
        const section = this.getSection(index);

        section[name] = value;

        this.props.onChange('modules', modules);
    }

    onSectionDuplicate(index) {
        const modules = this.getModules();
        const module = this.getModule();
        const section = this.getSection(index);

        const sectionClone = JSON.parse(JSON.stringify(section));

        sectionClone.title += ' Copy';

        module.sections.push(sectionClone);

        this.props.onChange('modules', modules);
    }

    onSectionRemove(index) {
        const modules = this.getModules();
        const module = this.getModule();

        module.sections.splice(index, 1);

        this.props.onChange('modules', modules);
    }

    newSection(type) {
        return {
            "type" : type,
            "title": '',
            "questions" : []
        }
    }

    newQuestion({ label, type }) {
        return {
            "title" : null,
            "type" : type,
            "description" : "",
            "body" : "",
            "config" : {}
        }
    }

    onQuestionAdd(sectionIndex, question) {
        const modules = this.getModules();

        const section = this.getSection(sectionIndex);
        const newQuestion = this.newQuestion(question);

        section.questions.push(newQuestion);

        this.props.onChange('modules', modules);
    }

    onQuestionChange(sectionIndex, questionIndex, e) {
        const { name, value } = e.target;

        const modules = this.getModules();
        const section = this.getSection(sectionIndex);

        const question = section.questions[questionIndex];

        question[name] = value;

        this.props.onChange('modules', modules);
    }

    onQuestionRemove(sectionIndex, questionIndex) {
        const modules = this.getModules();
        const section = this.getSection(sectionIndex);
        
        section.questions.splice(questionIndex, 1);

        this.props.onChange('modules', modules);
    }

    getSections() {
        const module = this.getModule();

        return module?.sections || [];
    }

    getSection(index) {
        const module = this.getModule();

        return module?.sections[index];
    }

    getModule() {
        return this.props.module;
    }

    getModules() {
        const courseVersions = [ ...this.props.course.latest_course_versions ];
        const contentVersion = courseVersions[0];

        const modules = contentVersion?.modules;

        return modules;
    }

    moduleSettings() {
        let Settings = ModuleSettingsInformation;

        if (this.props.module.type === 'dynamic') {
            Settings = ModuleSettingsDynamic;
        }

        if (this.props.module.type === 'manual') {
            Settings = ModuleSettingsManual;
        }

        const { setPopup } = this.context;

        let props = {
            course: this.props.course,
            module: this.props.module,
            onChange: this.editModule.bind(this),
            save: this.props.save,
            onSuccess: () => {
                setPopup(null);
            }
        }

        setPopup(<Settings { ...props }/>)
    }

    deleteModule() {
        const { setPopup } = this.context;

        const props = {
            course: this.props.course,
            module: this.props.module,
            onSuccess: () => {
                setPopup(null);
                this.deleteModuleFromState();
            }
        }

        setPopup(<ModuleDelete { ...props }/>)
    }

    render() {
        if (this.props.module === undefined) return '';

        const sections = this.getSections();
        const module = this.props.module;
        
        return (
            <List gap="20px">
                <Row center={true} justifyContent="space-between" wrap={true}>
                    <Row wrap={true} gap="50px">
                        <LabelContainer label={<Container.Text color="#354859" fontWeight="500" fontSize="14px">Module Title</Container.Text>}>
                            { module.name }
                        </LabelContainer>
                        <LabelContainer label={<Container.Text color="#354859" fontWeight="500" fontSize="14px">Module Type</Container.Text>}>
                            <span style={{
                                background: 'transparent',
                                border: '2px solid #707070',
                                borderRadius: '25px',
                                padding: '10px 30px',
                                textAlign: 'center'
                            }}>{ module.type }</span>
                        </LabelContainer>
                    </Row>

                    <Row wrap={true}>
                        <SettingsButton onClick={this.moduleSettings.bind(this)}>Settings</SettingsButton>
                        <DeleteButton onClick={this.deleteModule.bind(this)}>Remove Module</DeleteButton>
                    </Row>
                </Row>

                <Divider top="10px" bottom="10px"/>

                <Row center={true} justifyContent="center">
                    { module.type === 'information' ?
                        <>
                            <AddButton onClick={() => this.addNewSection('content')}>Content Section</AddButton>
                            <AddButton onClick={() => this.addNewSection('data entry')}>Data Entry Section</AddButton>
                            <AddButton onClick={() => this.addNewSection('cta')}>CTA</AddButton>
                        </> : ''
                    }
                </Row>

                <Divider top="10px" bottom="10px"/>

                { sections.map((section, index) => {
                    return (
                        <ModuleDropdown 
                            key={index}
                            index={index} 
                            section={section}
                            setPopup={this.props.setPopup} 
                            onQuestionRemove={questionIndex => this.onQuestionRemove(index, questionIndex)}
                            onQuestionClick={question => this.onQuestionAdd(index, question)} 
                            onQuestionChange={(questionIndex, e) => this.onQuestionChange(index, questionIndex, e)}
                            onSectionDuplicate={() => this.onSectionDuplicate(index)}
                            onSectionEdit={(e) => this.onSectionEdit(index, e)}
                            removeSection={() => this.onSectionRemove(index)}
                        />
                    )
                })}
            </List>
        )
    }
}