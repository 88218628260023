export default function MobileSectionHandler({children, sections, selectedSection, onSectionClose}) {
    const section = sections[selectedSection] || null;

    return (
        <div className="mobile-section-handler">
            <div
                className={`mobile-section-handler__screen ${selectedSection != null ? 'mobile-section-handler__screen--toggled' : ''}`}>
                {selectedSection !== null && <div className="mobile-section-handler__screen__head">
                    <p>{section.name}</p>
                    <p className="mobile-section-handler__screen__close" onClick={onSectionClose}>Close</p>
                </div>
                }

                <div
                    className={`mobile-section-handler__mobile ${selectedSection !== null ? 'mobile-section-handler__mobile--toggled' : ''}`}>
                    {
                        sections.map((section, index) => {
                            return (
                                <div key={index}
                                     className={`mobile-section-handler__preview layout__inner-container ${selectedSection === index ? 'mobile-section-handler__preview--toggled' : ''}`}>
                                    {section.component}
                                </div>
                            )
                        })
                    }
                </div>

                <div
                    className={`mobile-section-handler__desktop ${selectedSection !== null ? 'mobile-section-handler__desktop--toggled' : ''}`}>
                    {
                        sections.map((item, index) => {
                            return (
                                <div key={index}
                                     className={`layout__inner-container`}>
                                    {item.component}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}
