import QuestionBlock from '../components/grant-question-block';
import TextArea from '../../../components/textarea-with-label';
import Input from '../../../components/input-with-label';
import {AddButton} from '../../../components/buttons';

import {Component} from 'react';

import FileUploader from '../../../components/popups/images/file-uploader-popup';
import FileTypes from '../../../lib/files/file-types';

export default class GrantsSection extends Component {
    constructor(props) {
        super(props);
    }

    handleChange(e) {
        this.props.grantStateHandler.updateSection(this.props.grantVersion, this.props.sectionIndex, {
            [e.target.name]: e.target.value,
        });
    }

    addBlockToSection() {
        this.props.grantStateHandler.addBlock(this.props.grantVersion, this.props.sectionIndex, this.props.grantStateHandler.makeBlock());
    }

    removeBlock(index) {
        this.props.grantStateHandler.removeBlock(this.props.grantVersion, this.props.sectionIndex, index);
    }

    addQuestion(index, question) {
        this.props.grantStateHandler.addQuestion(this.props.grantVersion, this.props.sectionIndex, index, question);
    }

    onQuestionType(index, type) {
        const question = this.props.grantStateHandler.makeQuestion(type, {type});

        this.addQuestion(index, question);
    }

    removeQuestion(blockIndex, questionIndex) {
        this.props.grantStateHandler.removeQuestion(this.props.grantVersion, this.props.sectionIndex, blockIndex, questionIndex);
    }

    render() {
        return (
            <>
                {/* <FileUploader toggled={true} 
                        onClose={() => {}} 
                        fileTypes={fileTypes} 
                        onlyShow={['upload']} 
                        endpoint={``}
                        onResourceSelect={(file) => {
                            
                        }}/>  */}

                <Input
                    label={'Section Name'}
                    value={this.props.section.name}
                    onChange={this.handleChange.bind(this)}
                    name={'name'}
                />
                <TextArea
                    label="Section Description"
                    value={this.props.section.description}
                    onChange={this.handleChange.bind(this)}
                    name={'description'}
                />

                {this.props.section.blocks.length > 0 && <div className="grant-editor-questions">
                    {this.props.section.blocks.map((data, index) => {
                        return <QuestionBlock
                            key={index}
                            data={data}
                            index={index}
                            blockNumber={this.props.blockCount[`${this.props.sectionIndex}${index}`]}
                            setPopup={this.props.setPopup}
                            grantVersion={this.props.grantVersion}
                            sectionIndex={this.props.sectionIndex}
                            blockIndex={index}
                            grantStateHandler={this.props.grantStateHandler}
                            onQuestionType={type => this.onQuestionType(index, type)}
                            removeQuestion={questionIndex => this.removeQuestion(index, questionIndex)}
                            removeQuestionBlock={() => this.removeBlock(index)}/>
                    })} </div>
                }


                <div className="utils__rowCentered">
                    <AddButton onClick={this.addBlockToSection.bind(this)}>Question Block</AddButton>
                </div>
            </>
        )
    }
}
