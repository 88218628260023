import ApiBase from "../api-base";

export default class ELearningApi extends ApiBase {
    async getLearningPaths(page = 1, per_page = 20, params = {}) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/learning-paths`, {
                params : { 
                    page : page, 
                    per_page : per_page,
                    ...params,
                }
            });

            return response.data;
        });
    }

    async getAllCourseWork(params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/course-work`, {
                params
            });
            return response.data;
        });
    }
    
    async getModuleSessions(id, params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/course-work/${id}/sessions`, {
                params
            });
            return response.data;
        });
    }
    
    async getCourseWorkSessions(id, params = {}) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/course-work/${id}/video-sessions`, {
                params
            });
            return response.data;
        });
    }

    async viewFile(id, fileId) {
        return this.handleResponse(async () => {
            const config = { 
                url: `/api/e-learning/course-work/${id}/file/${fileId}`, 
                method: "get", 
            }

            let response = await axios.request(config);
            return response.data;
        });
    }

    async submitModuleAssessment(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/course-work/${id}/submit-module-assessment`, params);

            return response.data.data;
        }, validationCallback);
    }

    async bookManualAssessment(params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/manual-assessment-bookings`, params);

            return response.data.data;
        }, validationCallback);
    }

    async removeManualAssessmentBooking(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/e-learning/manual-assessment-bookings/${id}`);

            return response.data.data;
        }, validationCallback);
    }

    async getManualAssessments(params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/manual-assessment-bookings`, {
                params: { 
                    ...params
                }
            });
            return response.data;
        });
    }

    async getCourseWork(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/course-work/${id}`);
            return response.data;
        });
    }

    async getLearningPath(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/learning-paths/${id}`);

            return response.data;
        });
    }

    async createTag(params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/tags`, params);

            return response.data;
        }, validationCallback);
    }

    async deleteTag(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/e-learning/tags/${id}`);

            return response.data;
        }, validationCallback);
    }

    async getTags() {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/tags`);

            return response.data;
        });
    }

    async createLearningPath(params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/learning-paths`, params);

            return response.data.data;
        }, validationCallback);
    }

    async updateLearningPath(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/learning-paths/${id}`, params);

            return response.data.data;
        }, validationCallback);
    }

    async deleteLearningPath(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/e-learning/learning-paths/${id}`);

            return response.data.data;
        }, validationCallback);
    }

    async archiveLearningPath(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/learning-paths/${id}/archive`);

            return response.data.data;
        }, validationCallback);
    }

    async getLearningPathCourses(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/courses`, {
                params : { 
                   learning_path_id: id
                }
            });

            return response.data;
        });
    }

    async addCourseToLearningPath(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/learning-paths/${id}/add-course`, params);

            return response.data.data;
        }, validationCallback);
    }

    async removeCourseFromLearningPath(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/learning-paths/${id}/remove-course`, params);

            return response.data.data;
        }, validationCallback);
    }

    async fetchCourse(id) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/courses/${id}`);

            return response.data;
        });
    }

    async syncManagers(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/courses/${id}/sync-managers`, params);

            return response.data.data;
        }, validationCallback);
    }

    async fetchCourses(params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/courses`, {
                params
            });

            return response.data;
        });
    }

    async fetchLearners(params) {
        return this.handleResponse(async () => {
            let response = await axios.get(`/api/e-learning/learners`, {
                params
            });

            return response.data;
        });
    }

    async publishCourse(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/courses/${id}/publish`);

            return response.data.data;
        }, validationCallback);
    }

    async createCourse(params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/courses`, params);

            return response.data.data;
        }, validationCallback);
    }

    async editCourse(id, params, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.post(`/api/e-learning/courses/${id}`, params);

            return response.data.data;
        }, validationCallback);
    }

    async deleteCourse(id, validationCallback = this.defaultValidationHandler) {
        return this.handleResponse(async () => {
            let response = await axios.delete(`/api/e-learning/courses/${id}`);

            return response.data.data;
        }, validationCallback);
    }
}
