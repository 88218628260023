function Divider({ top, bottom }) {
    const style = { 
        marginTop: top,
        marginBottom: bottom,
    }

    return (
        <div className="divider" style={style}></div>
    )
}

Divider.defaultProps = {
    top: '30px',
    bottom: '30px',
}

export default Divider;