import { useEffect, useRef, useState } from 'react';

import List from '../../../../../components/list/list';
import VideoContainer from '../../../../../components/video-container';

import Vimeo from '@u-wave/react-vimeo';
import Row from '../../../../../utils/row';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import Divider from '../../../../../components/divider/divider';
import ELearningApi from '../../../../../lib/e-learning/e-learning-api';

import moment from 'moment';
import { stringToDatetimeLocal } from '../../../../../lib/helpers/datetime-helpers';
import { duration } from '@mui/material';

export default function VideoTimeline({
    courseWorkId,
    module,
    videoSessions,
    question,
}) {
    const [ toggled, setToggled ] = useState(true);
    const [ hasRenderedVideo, setHasRenderedVideo ] = useState(false);

    const [ hovered, setHovered ] = useState(false);

    const MIN_WIDTH = 100;

    let ref = useRef(null);

    const [ data, setData ] = useState(null);
    const [ player, setPlayer ] = useState(null);

    const onReady = (player) => {
        setHasRenderedVideo(true);
        setPlayer(player);
    }

    const sessionClicked = (session) => {
        player?.setCurrentTime(session?.start);
    }

    if (!toggled || ref?.current === undefined) return null;

    const sessions = videoSessions?.all_sessions_grouped?.find(s => s.provider_id === Number(question?.body?.value))?.sessions;

    const test = sessions?.length > 0 ? sessions?.reduce((a, b) => a + (b?.end - b?.start), 0) : 0;
    const totalTime = moment.duration(test, 'seconds');
    
    return (
        <div className="video-timeline__outer">
            <VideoContainer style={{ width: '100%' }}>
                <Vimeo
                    onReady={onReady}
                    controls
                    video={question?.body?.value}
                    onTimeUpdate={setData}
                />
            </VideoContainer>

            { hasRenderedVideo && sessions?.length > 0 && <div className="video-timeline"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <div className="video-timeline__timeline" ref={ref}>
                    { sessions?.map((session, index) => {
                        const width = ((session?.end - session?.start) / session?.items?.[0]?.video_duration) * 100;
                        const offsetWidth = (width / 100) * ref?.current?.offsetWidth;

                        let style = {
                            position: 'absolute',
                            width: `${width}%`,
                            left: `${(session?.start / session?.items?.[0]?.video_duration) * 100}%`
                        };

                        const start = moment.duration(session?.start || 0, 'seconds');
                        const end = moment.duration(session?.end || 0, 'seconds');
                        const duration = moment.duration((session?.end - session?.start) || 0, 'seconds');

                        return <div 
                                key={index}
                                className={`video-timeline__session video-timeline__session-${index}`} 
                                style={style} 
                                onClick={() => sessionClicked(session)}
                            >
                                { hovered && offsetWidth > MIN_WIDTH && 
                                    <div className="video-timeline-session__text">
                                        <p>{ start.minutes()}m { start.seconds() }s</p> 
                                        <Divider top="0px" bottom="0px"/> 
                                        <p>{ end.minutes()}m { end.seconds() }s</p>
                                    </div> }
                                <Tooltip 
                                    anchorSelect={`.video-timeline__session-${index}`}
                                    content={
                                        <div className="video-timeline__tooltip">
                                            <p>Start: { start.minutes()}m { start.seconds() }s</p>
                                            <p>End: { end.minutes()}m { end.seconds() }s</p>
                                            <p>Duration: { duration.minutes() }m { duration.seconds() }s</p>
                                        </div>
                                    }
                                />
                        </div>
                    })}
                
                <div className="video-timeline__line"></div>

                <div className="video-timeline__bar" style={{
                    position: 'absolute',
                    left: `${(data?.seconds / data?.duration) * 100}%`
                }}></div>

                </div>
                <div className="video-timeline__info">
                    <p style={{ textDecoration: 'underline' }}>User Tracking</p>
                    <p>Total Time: <b>{ totalTime.minutes() }m { totalTime.seconds() }s</b></p>
                    <p>Total Sessions: <b>{ sessions?.reduce((a, b) => a + b?.items?.length, 0) }</b></p>
                </div>
            </div> }
        </div>
    )
}