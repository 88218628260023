import React, {Component} from 'react';
import NoDataError from "../../components/no-data-error";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <NoDataError message='Whoops!' subtitle={`There may be an issue with this content's configuration.`} body={'Please contact support for help in resolving this issue. '}/>

    } else {
            return this.props.children;
        }
    }

}

ErrorBoundary.defaultProps = {
    text : ''
};

export default ErrorBoundary;
