import ContainerGrid from "../../../../../../containers/container-grid";

import QuestionType from "../../../../../../components/question/question-type-icon";

const Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41.449" height="32.361" viewBox="0 0 41.449 32.361">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rectangle_279" data-name="Rectangle 279" width="41.449" height="32.361" fill="#77797a"/>
                </clipPath>
            </defs>
            <g id="Group_388" data-name="Group 388" clip-path="url(#clip-path)">
                <path id="Path_100" data-name="Path 100" d="M1.38,31A5.481,5.481,0,0,1,0,26.93V5.449A5.508,5.508,0,0,1,1.38,1.362,5.669,5.669,0,0,1,5.52,0H35.93a5.638,5.638,0,0,1,4.148,1.371A5.534,5.534,0,0,1,41.45,5.449V26.93A5.5,5.5,0,0,1,40.078,31a5.661,5.661,0,0,1-4.148,1.362H5.52A5.669,5.669,0,0,1,1.38,31m34.532-1.45a2.505,2.505,0,0,0,2.707-2.707V24.135l-8.085-7.489a4.14,4.14,0,0,0-1.345-.861,4.022,4.022,0,0,0-1.45-.281,4.2,4.2,0,0,0-2.83,1.125l-8.4,7.453-3.357-3.023a3.662,3.662,0,0,0-2.532-1.073,3.022,3.022,0,0,0-1.195.264,4.543,4.543,0,0,0-1.195.773l-5.4,4.711v1.073a2.72,2.72,0,0,0,.73,2.012,2.692,2.692,0,0,0,1.995.73ZM15.346,16.04a4.5,4.5,0,0,0,1.573-1.573,4.329,4.329,0,0,0,0-4.36,4.5,4.5,0,0,0-1.573-1.573,4.3,4.3,0,0,0-4.342,0,4.5,4.5,0,0,0-1.573,1.573,4.329,4.329,0,0,0,0,4.36A4.5,4.5,0,0,0,11,16.04a4.3,4.3,0,0,0,4.342,0" transform="translate(0 0)" fill="#77797a"/>
            </g>
        </svg>
    )
}

const questionTypes = {
    'content': [
        {
            label: 'Rich Text Box',
            type: 'rich-text',
            icon: 'rich-text',
        },
        {
            label: 'Image',
            type: 'image',
            icon: 'image',
        },
        {
            label: 'Embed Video',
            type: 'video',
            icon: 'video',
        },
        {
            label: 'HTML',
            type: 'html',
            icon: 'html',
        },
        {
            label: 'Gallery',
            type: 'gallery',
            icon: 'gallery',
        }
    ],
    'dynamic': [
        {
            label: 'Single choice',
            type: 'single-choice',
            icon: 'single-choice',
        },
        {
            label: 'Multi Choice',
            type: 'multi-choice',
            icon: 'multi-choice',
        },
        {
            label: 'Multi Choice Image',
            type: 'multi-choice-image',
            icon: 'multi-choice-image',
        },
        {
            label: 'Fill In The Blank',
            type: 'fill-blank',
            icon: 'fill-blank',
        },
        {
            label: 'Text Matching',
            type: 'text-matching',
            icon: 'text-matching',
        },
        {
            label: 'List Ranking',
            type: 'list-ranking',
            icon: 'list-ranking',
        }
    ],
    'data entry': [
        {
            label: 'Text Response',
            type: 'text-response',
            icon: 'text-response',
        },
        {
            label: 'Sliding Scale',
            type: 'sliding-scale',
            icon: 'sliding-scale',
        },
        {
            label: 'Radio Button',
            type: 'radio-buttons',
            icon: 'radio-buttons',
        },
        {
            label: 'Checkbox',
            type: 'checkbox',
            icon: 'checkboxes',
        },
        {
            label: 'Table',
            type: 'table',
            icon: 'table',
        },
        {
            label: 'Document Upload',
            type: 'document-upload',
            icon: 'upload-documents',
        },
    ],
    'manual': [
        {
            label: 'Text Response',
            type: 'text-response',
            icon: 'text-response',
        },
        {
            label: 'Radio Button',
            type: 'radio-buttons',
            icon: 'radio-buttons',
        },
        {
            label: 'Checkbox',
            type: 'checkbox',
            icon: 'checkboxes',
        },
        {
            label: 'Document Upload',
            type: 'document-upload',
            icon: 'upload-documents',
        },
    ],
    'cta': [
        {
            label: 'CTA',
            type: 'cta',
            icon: Icon,
        },
    ]
}

export default function QuestionTypes({ onQuestionClick, type = 'content' }) {
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            margin: '0px 21px 21px 21px',
            justifyContent: 'center'
        }}>
            { questionTypes[type] !== undefined && questionTypes[type].map((question, index) => {
                const { label, type, icon } = question;
                const Icon = icon;

                return <QuestionType key={index} type={label} icon={Icon} onClick={() => onQuestionClick(question)}/>
            })}
        </div>
    )
}