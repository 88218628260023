import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default function uploadTest(el) {
    const uppy = new Uppy();
    uppy.use(Dashboard, {
        id: 'Dashboard',
        inline: true,
        target: '#uppy-test',
        debug: true,
        autoProceed: false,
    }).use(XHRUpload, {
      endpoint: '/media/images'
    }).use(ImageEditor, {
        target : Dashboard,
        quality : 1
    });

}


let el = document.getElementById('uppy-test');

if (el) {
    uploadTest(el);
}
