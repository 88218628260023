import { useState } from 'react';

import EmbedVideo from "./embed-video"
import EmbedImage from './embed-image';

import List from "../../../components/list/list"
import Row from "../../../utils/row"

import { AddButton } from '../../../components/buttons';

export default function CourseEditFeatured({
    onChange,
    value,
}) {
    const getCurrentTab = () => {
        if (value?.trailer_video !== null && value?.trailer_video !== undefined) return 0;
        if (value?.featured_image !== null && value?.featured_image !== undefined) return 1;

        return null;
    }

    const [ tab, setTab ] = useState(getCurrentTab());

    return (
        <List gap="20px">
            <Row gap="10px">
                <AddButton onClick={() => setTab(0)} toggled={tab === 0}>Trailer Video</AddButton>
                <AddButton onClick={() => setTab(1)} toggled={tab === 1}>Featured Image</AddButton>
            </Row>

            { tab === 0 && <EmbedVideo onChange={onChange} value={value?.trailer_video}/> }
            { tab === 1 && <EmbedImage onChange={onChange} value={value?.featured_image}/> }
        </List>
    )
}