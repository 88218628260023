const ArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6.792" height="11.412" viewBox="0 0 6.792 11.412" >
            <g id="Button_-_Arrow" data-name="Button - Arrow" transform="translate(0.706 0.706)">
                <path id="Path_2" data-name="Path 2" d="M11.5,45.5l-5,5.586L1.5,45.5" transform="translate(-45.5 11.5) rotate(-90)" fill="none" stroke="#949ba7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
        </svg>
    )
}

export default function NavButton({ children, type, onClick = () => {}, disabled = false, mobile = false }) {
    const isDisabled = disabled ? 'nav-button__off' : '';

    const types = {
        off: 'nav-button__off',
        on: 'nav-button__on',
        previous: 'nav-button__previous',
        next: 'nav-button__next',
    }

    const getType = () => {
        return types[type] || '';
    }

    return (
        <button className={`nav-button ${getType()} ${isDisabled} ${mobile ? 'nav-button--mobile' : ''}`} onClick={onClick}>
            <div className={'nav-button__text'}>
                { children || 'Nav Name' }
            </div>
            <ArrowIcon />
        </button>
    )
}
