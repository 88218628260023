import { useState } from 'react';

import Container from '../../../containers/container';
import Row from '../../../utils/row';

import Input from '../../../components/inputs/inputs-text';
import Dropdown from '../../../components/inputs/inputs-dropdown';

const videoTypes = [
    {
        label: 'YouTube',
        value: 'youtube',
    },
    {
        label: 'Vimeo',
        value: 'vimeo',
    }
]

export default function EmbedVideo({ onChange, value }) {
    const data = value;
    const type = videoTypes.find((t) => t.value === value?.type);

    const change = (e) => {
        const { value } = e.target;

        onChange({
            target: {
                name: 'trailer_video',
                value: {
                    ...data,
                    value: value,
                }
            }
        });
    }

    const onVideoChange = (e) => {
        const { value } = e;

        onChange({
            target: {
                name: 'trailer_video',
                value: {
                    ...data,
                    type: value,
                }
            }
        });
    }

    return (
        <div className="embed-video">
            <Container>
                <Container.Header>
                    <Container.Title>Embed Video</Container.Title>
                </Container.Header>

                <Container.Content>
                    <Row>
                        <Dropdown label="Select Provider" value={type} items={videoTypes} onChange={onVideoChange} fill={true}/>
                        <Input label="Video ID" fill={true} value={value?.value} onChange={change}/>
                    </Row>
                </Container.Content>
            </Container>
        </div>
    )
}