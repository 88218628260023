export default {
    sections : [
        {
            name:  'hero',
            fields : [
                {
                    field: 'title',
                },
                {
                    field: 'background image',
                    type : 'image'
                }

            ]
        },
        {
            name:  'info',
            fields : [
                {
                    field: 'description',
                    type: 'wysiwyg'
                }
            ]
        },
        {
            name:  'gallery',
            fields : [
                {
                    field: 'image one',
                    type: 'image'
                },
                {
                    field: 'image two',
                    type: 'image'
                },
                {
                    field: 'image three',
                    type: 'image'
                },
                {
                    field: 'image four',
                    type: 'image'
                }
            ]
        }
    ]
}
