import { Component } from 'react';

export default class SeventyThirtyLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { main, side } = this.props;

        return (
            <div className="layout__inner layout__fifty-fifty">
                <div className="layout__main-container">{ main }</div>
                <div className="layout__inner-container">{ side }</div>
            </div>
        )
    }
}
