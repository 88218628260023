import { useEffect } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';

import QuestionType from "../../../../../../components/question/question-type-container";

export default function QuestionTypeImage({ 
    question,
    index,
    onQuestionChange,
}) {
    const onAltTagChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({ 
            target: { 
                name: 'config', 
                value: config
            }
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <List gap="20px">
                <Input label="Image Title" fill={true} name="title" onChange={onQuestionChange} value={question.title}/>
                <Input label="Alt Tag" fill={true} name="alt_tag" onChange={onAltTagChange} value={question?.config?.alt_tag} />
                <InputsFileSelect label="Image" fill={true} name="body" value={question.body} onChange={onQuestionChange}/>
            </List>
        </QuestionType>
    )
}