import Dropdown from 'react-dropdown';
import React, {Component} from "react";
import CustomSwitch from "../ui/custom-switch";
import Popup from '../popup';
import ContentApi from '../../lib/content/content-api';
import AlertManager from '../../managers/alert/alert-manager';

import { CreateButton, CancelButton } from '../buttons';

class DistributionMapPopup extends Component {


    async handleChange() {
        let {content, distribution, currentVersion, newVersion, currentDistributionMap} = this.props;
        if (currentVersion) {
            let response = await ContentApi.unDistributeVersion(content.id, currentVersion.id, distribution.id);

            if (response.status !== 200) {
                AlertManager.error('System error');

                throw new Error('Could not un-distribute');
            }
        }

        if (newVersion.id !== null) {
            let response = await ContentApi.distributeVersion(content.id, newVersion.id, distribution.id, currentDistributionMap? currentDistributionMap.published : false);
            if (response.status !== 200) {
                AlertManager.error('System error');
                throw new Error('Could not distribute');
            }
        }

        AlertManager.success('Content Version Distribution Changed');
        this.props.onChange();
        this.props.onClose();

    }


    render() {
        let {distribution, currentVersion, newVersion} = this.props;

        return (
            <Popup type={'distribution-map-popup'}>
                <div className="popup__inner">
                    <h1>Change published version for {distribution.name}</h1>

                    <div className={'popup__content'}>
                        {
                            newVersion.id === null &&
                            <>Are you sure you want to un-publish
                                version {currentVersion.name ? currentVersion.name : currentVersion.id} from {distribution.name}?</>
                        }
                        {
                            (newVersion.id !== null && currentVersion) &&
                            <> Are you sure you want to change version
                                from {currentVersion.name ? currentVersion.name : currentVersion.id} to {newVersion.name}?</>
                        }
                        {
                            (newVersion.id !== null && !currentVersion) &&
                            <> Are you sure want to publish version {newVersion.name} to {distribution.name}?</>
                        }


                    </div>

                    <div className="popup__buttons">
                        <CreateButton responsive={false} onClick={this.handleChange.bind(this)}/>
                        <CancelButton responsive={false} onClick={this.props.onClose}/>
                    </div>
                </div>
            </Popup>
        );
    }
}


class DistributionPublishItem extends Component {


    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            newVersion: null
        };
    }


    getCurrentDistributedVersionId() {
        return this.props.distributionMap ? this.props.distributionMap.content_version_id : null;
    }

    handleChange({value, label}) {
        if (value !== this.getCurrentDistributedVersionId()) {
            this.setState({popup: true, newVersion: {name: label, id: value}});
        }
    }

    async handlePublishMap() {
        let { content, distributionMap, onChange } = this.props;
        await ContentApi.distributeVersion(content.id, distributionMap.content_version_id, distributionMap.distribution_id, !distributionMap.published);
        onChange();
    }

    render() {
        let {distribution, content, distributionMap} = this.props;

        return (
            <>
                {this.state.popup &&
                <DistributionMapPopup
                    content={content}
                    newVersion={this.state.newVersion}
                    currentVersion={distributionMap ? distributionMap.content_version : null}
                    currentDistributionMap={distributionMap}
                    distribution={this.props.distribution}
                    onChange={this.props.onChange}
                    onClose={() => this.setState({popup: false, newVersion: null})}
                />
                }
                <div className="details-item">
                    <div>
                        <p className="content-editor__subtitle">{distribution.name}</p>
                        <p>{distribution.description}</p>
                    </div>
                    {
                        distributionMap &&
                        <div className="details-item__bottom">
                            <CustomSwitch
                                checked={distributionMap ? distributionMap.published : false}
                                onChange={this.handlePublishMap.bind(this)}
                            />
                        </div>
                    }
                </div>
                <div className="input-outer input-outer__row">
                    <label htmlFor="email">Choose your version</label>
                    <Dropdown
                        className="input input--idle"
                        options={this.props.options}
                        onChange={this.handleChange.bind(this)}
                        value={this.getCurrentDistributedVersionId()}
                        placeholder="None"
                    />
                </div>
            </>
        );
    }
}

class DistributionPublishing extends Component {
    getOptions() {

        let options = this.props.versions.map(({id, name}) => {
            if (name != null) return {
                id: id,
                value: id,
                label: name,
            };

            return {
                id: id,
                value: id,
                label: id,
            }
        });

        options.unshift({
            label: 'None',
            value: null
        });

        return options;

    }

    handleChange() {

    }

    getDistributionsMap(distributionId) {
        return this.props.distrutionMaps.find(map => {
            return map.distribution_id === distributionId;
        });
    }

    render() {

        let {distributions, content} = this.props;


        return (
            <div className="content-editor__item-divider">
                <p class={'content-editor__title'}>Distributions</p>
                {
                    distributions.map((distribution, index) => {
                        let params = {
                            key: index,
                            distribution,
                            distributionMap: this.getDistributionsMap(distribution.id),
                            options: this.getOptions(),
                            content,
                            onChange: this.props.onChange
                        };

                        return (
                            <DistributionPublishItem {...params}/>
                        );
                    })
                }
            </div>
        );

    }


}


DistributionPublishing.defaultProps = {
    distributions: [],
    distributionMaps: [],
    versions: [],
    content: {},
    onChange: () => console.log('Update in published distributions')
}

export default DistributionPublishing;
