import List from "./list/list"

export default function ErrorContainer({ center = false, errors = [], children, fill }) {
    let style = {};

    if (center) {
        style.alignItems = 'center';
    }
    
    return (
        <div className="error-container" style={{ width: fill ? '100%' : 'inherit' }}>
            { children }
            { errors.length > 0 && <List gap='10px' style={style} className="error-container__errors">{ errors?.map((err, index) => <p key={index}>{ err }</p>) }</List> }
        </div>
    )
}